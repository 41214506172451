import React from "react"
import moment from "moment"
import { Badge } from "antd"
import { tradingStatusEnum } from "./constant"
export default [
  {
    title: "交易方案ID",
    dataIndex: "id",
    fixed: "left",
  },
  {
    title: "交易方案名称",
    dataIndex: "name",
  },
  {
    title: "交易方案起始日期",
    dataIndex: "effectiveTime",
  },
  {
    title: "交易方案截止日期",
    dataIndex: "expirationTime",
  },
  {
    title: "交易类型",
    dataIndex: "tradeType",
  },
  {
    title: "状态",
    dataIndex: "statusName",
    render: (value) => <Badge {...tradingStatusEnum[value]} />,
  },
]
