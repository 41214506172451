import {
  OP_EVIDENCE,
  OP_INVOICE,
  OP_PUB_DATA,
  OP_SOURCE,
  OP_TRADE,
  OP_USER,
} from "../../../api/config/servicePort"
import UtilOp from "../../../services/serviceOp"
import Util from "../../../services/util"
const utilOp = UtilOp.getInstance()
const util = Util.getInstance()

// 根据文件id获取，文件链接
export const getUrlByFileId = (params: { zoom: boolean; fileIds: string }) => {
  return util
    .httpGetDomain(`/rscm/rscm-resource/files/ossUrlZoom`, params)
    .then((res) => res as { data: string[]; code: number })
}

// 创建组织信息
export const createTissue = (params: Tissue.Info) => {
  return utilOp.httpPost(OP_USER + "/organizations", params)
}
// 查询组织列表(翻页)
export const getTissueList = (params: Tissue.ListParams) => {
  return utilOp.httpGet<Tissue.ListResponse>(OP_USER + "/organizations", params)
}
// 组织列表-页面
export const getOrganizationsList = (params: Tissue.ListParams) => {
  return utilOp.httpGet<Tissue.ListResponse>(OP_USER + "/organizations/list", params)
}

// 查询组织详情
export const getTissueInfo = (id) => {
  return utilOp.httpGet<Tissue.Info>(OP_USER + `/organizations/${id}`)
}
// 修改组织详情
export const editTissueInfo = (params: Tissue.Info) => {
  return utilOp.httpPut(OP_USER + `/organizations/${params.id}`, params.values)
}
// 查询开户行
export const getTissueBankList = (params: any) => {
  return utilOp.httpGet(OP_PUB_DATA + "/bank", params)
}
// 查询省市区
export const getTissueCityTreeList = (params: any) => {
  return utilOp.httpGet(OP_PUB_DATA + "/city/tree", params)
}
// 查询联行号
export const getTissueCorrespondentList = (params: any) => {
  return utilOp.httpGet(OP_PUB_DATA + "/bankcnaps", params)
}
// orc营业执照
export const getOcrBusinessLicense = (url: any) => {
  return utilOp.httpGet(OP_SOURCE + "/ocr/businessLicense", url)
}
// orc身份证
export const getOcrIdCardFront = (url: any) => {
  return utilOp.httpGet(OP_SOURCE + "/ocr/idCard/front", url)
}
// //查询角色
export const getPlatCharactersList = (id: string, params?: any) => {
  return utilOp.httpGet(OP_USER + `/applications/${id}/roles`, params)
}
// 获取所有用户
export const getUserLists = (values?: any) => {
  return utilOp.httpGet(OP_USER + "/users", values).then((res) => res)
}

// 创建开通任务
export const getTasksTaxpayer = (params: any) => {
  return utilOp.httpPost(OP_INVOICE + "/taxpayer-tasks", params)
}

// 查询存证模版(翻页)
export const getOpEvidence = (params: any) => {
  return utilOp.httpGet<any>(OP_EVIDENCE + `/op/evidence`, params)
}
// 过去12个月交易额
export const getTissueTradeAmount = (params: any) => {
  return utilOp.httpGet(OP_TRADE + "/orders/trade-amount", params)
}
//  组织满足销方限定的交易方案
export const getTissueTradeStrategies = (params: any) => {
  return utilOp.httpGet(OP_TRADE + "/trade-strategies/organization", params)
}
//  查询销售方在交易方案的可用额度
export const getTissueStrategiesAvailable = (params: any) => {
  return utilOp.httpGet(OP_TRADE + "/trade-strategies/available-credit", params)
}
// 税区列表
export const getRegionsList = (params?: any) => {
  return utilOp.httpGet(OP_USER + "/handle-regions", params)
}
// * 获取组织列表
export const getOrgs = () => {
  return utilOp.httpGet(OP_USER + "/authorisations/organizations")
}
