/* eslint-disable react/display-name */
import { Card, Form, Select } from "antd"
import React, { memo, useEffect, useState } from "react"
import { getOperRegions } from "../../../service"
export const LocationInformation = memo((props: any) => {
  const { setNameLessFlag, initDetail } = props
  const FormItem = Form.Item
  const [regionsList, setRegionsList] = useState<any>([])
  const init = async () => {
    const result = await getOperRegions({
      status: 1,
    })
    if (result?.code == 200) {
      let resultList = result?.data?.records.map((ie: any) => {
        return {
          value: ie.id,
          label: ie.name,
        }
      })
      setRegionsList(resultList)
    }
  }
  useEffect(() => {
    init()
  }, [])
  return (
    <Card title="办理地信息">
      <Form.Item
        name="handleRegionId"
        label={"执照办理地"}
        rules={[{ required: true, message: "请选择执照办理地" }]}
      >
        <Select
          onChange={(e) => {
            console.log(e, "e")
            initDetail()
            setNameLessFlag(true)
          }}
          options={regionsList}
          style={{ width: 431 }}
          placeholder={"请选择执照办理地"}
        ></Select>
      </Form.Item>
    </Card>
  )
})

export default LocationInformation
