import ProTable, { ProColumns } from "@ant-design/pro-table"
import { ProFormInstanceType } from "@ant-design/pro-utils"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import { Button, FormInstance, Space } from "antd"
import React, { useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useQueryParamsStorage } from "../../../hooks/useQueryParamsStorage"
import { getRangeDateOnUnitOfTime } from "../../../utils/date/utils"
import { getContractList } from "./promises"
import { CustomRangePicker } from "../../../components/CustomRangePicker"
const ContractTemplate = () => {
  const [totalCount, setTotalCount] = useState<number>(0)
  const [list, setList] = useState([])
  const navigate = useNavigate()
  const columns: ProColumns[] = [
    {
      title: "模板ID",
      dataIndex: "id",
      search: false,
    },
    {
      title: "模板名称",
      dataIndex: "name",
      search: false,
    },
    {
      title: "模板类型",
      dataIndex: "typeDesc",
      search: false,
    },
    {
      title: "模板状态",
      dataIndex: "status",
      search: false,
      valueEnum: {
        true: {
          text: "有效",
          status: "Success",
        },
        false: {
          text: "失效",
          status: "Error",
        },
      },
    },
    {
      title: "操作",
      valueType: "option",
      key: "option",
      render: (text, record) => (
        <a onClick={() => navigate(`/entity/transaction/contractTemplate/detail/${record?.id}`)}>
          详情
        </a>
      ),
    },
    {
      title: "创建日期",
      dataIndex: "createTime",
      renderFormItem: () => {
        return <CustomRangePicker />
      },
      valueType: "dateRange",
      hideInTable: true,
      search: {
        transform: (value) => {
          return {
            ...getRangeDateOnUnitOfTime({
              stratTime: value?.[0],
              endTime: value?.[1],
            }),
          }
        },
      },
    },
    {
      title: "搜索",
      dataIndex: "keyWords",
      valueType: "text",
      key: "keyWords",
      hideInTable: true,
      fieldProps: {
        placeholder: "输入模板名称搜索",
      },
    },
  ]

  const actionRef = useRef()
  const formRef = useRef<FormInstance<any> & ProFormInstanceType<any>>()
  const queryParamsStorage = useQueryParamsStorage()

  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <span>合同模板管理</span>
          </Space>
        }
        breadCrumb={[
          { label: "合同管理", path: "" },
          { label: "合同模板管理", path: "" },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <DetailPageContent>
        <ProTable
          columns={columns}
          actionRef={actionRef}
          formRef={formRef}
          form={{
            initialValues: {
              ...queryParamsStorage.queryParams,
              createTime: queryParamsStorage.queryParams?.stratTime
                ? [
                    queryParamsStorage.queryParams?.stratTime,
                    queryParamsStorage.queryParams?.endTime,
                  ]
                : undefined,
            },
            onValuesChange: (values) => {
              return formRef?.current?.submit()
            },
          }}
          request={async (params, sort, filter) => {
            const { pageSize, keyWords, ...rest } = params
            console.log(rest, "rest")
            const response = await getContractList({
              //getContractList
              size: pageSize,
              searchCount: true,
              keyWords: keyWords?.trim(),
              ...rest,
              // "sorts[0].field": "createTime",
              // "sorts[0].direction": "DESCENDING",
            })
            setTotalCount(response.data.total)
            setList(response.data.records)
            queryParamsStorage.saveQueryParams({ ...params, pageSize: 20 })
            return {
              data: response.data.records,
              success: Boolean(response.data.records),
              total: response.data.total,
            }
          }}
          rowKey="id"
          search={{
            labelWidth: "auto",
            optionRender: false,
            collapsed: false,
          }}
          bordered={false}
          options={false}
          pagination={{
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal(total, range) {
              return `总共${total}条`
            },
          }}
          dateFormatter="string"
          headerTitle={`模板列表/${totalCount}`}
          toolBarRender={() => [
            <Button
              key="button"
              onClick={() => navigate(`/entity/transaction/contractTemplate/${"add"}`)}
              type="primary"
            >
              新建合同模板
            </Button>,
          ]}
        />
      </DetailPageContent>
    </DetailPageContainer>
  )
}
export default ContractTemplate
