import React from "react"
import { Badge } from "antd"
import { OrderLogisticsStatusEnum } from "../constant"

export default [
  {
    title: "企业名称",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "核心企业证件号",
    dataIndex: "certNo",
    key: "certNo",
  },
  {
    title: "可用融资产品",
    dataIndex: "phone",
    key: "phone",
    render: (_, record: any) => {
      let _products = record?.products?.map((ie: any) => ie.name)
      return _products?.join(",")
    },
  },
]
