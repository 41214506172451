/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from "react"
import UploadFile from "@src/components/UploadFile"
import { Button, message } from "antd"
import { UploadOutlined } from "@ant-design/icons"
import ModalCom from "@src/components/ModalCom"
import {
  getIndividualCancelSignFile,
  getIndividualCancelTaxFile,
  getIndividualCancelLicenseFile,
} from "../../promise"
import "./index.scss"
import { useParams } from "react-router-dom"

const SubmitInfo = (props: any) => {
  const { details, update, logOff, taxRole, licenseRole, isSuc } = props
  const searchParams: any = useParams()
  const [filesList, setFilesList] = useState<any>({})
  const [openParams, setOpenParams] = useState<any>({
    title: "",
    open: false,
  })
  const handleSubmit = async (isModal?: any) => {
    console.log(renderInfo, "renderInfo")
    if (!filesList?.fileId) {
      message.error("请上传图片")
      return
    }
    if (!isModal && ["TAX_WAIT_CLEAN", "LICENSE_WAIT_CANCEL"].includes(renderInfo?.value)) {
      setOpenParams({
        title: renderInfo?.value === "TAX_WAIT_CLEAN" ? "是否确认提交" : "是否完成注销",
        open: true,
      })
      return
    }
    let params: any = {
      id: details?.id,
    }
    let resultRequest: any = null
    switch (details.cancelStatus) {
      case "APPLY_FILE_WAIT_SIGN":
        resultRequest = getIndividualCancelSignFile
        params.fileId = filesList?.fileId
        break
      case "TAX_WAIT_CLEAN":
        resultRequest = getIndividualCancelTaxFile
        params.taxCleanFileOssId = filesList?.fileId
        break
      case "LICENSE_WAIT_CANCEL":
        resultRequest = getIndividualCancelLicenseFile
        params.licenseCancelFileOssId = filesList?.fileId
        break
      default:
        break
    }
    const res: any = await resultRequest(params)
    if (res?.code == 200) {
      setFilesList({})
      update && update(details?.id)
      setOpenParams({ open: false, title: "" })
    }
  }
  useEffect(() => {
    setFilesList({})
    return () => {
      setFilesList({})
    }
  }, [details])
  // 上传文件后 存储图片数据
  const handleUpload = (value: any) => {
    console.log(value, "value")
    if (value[0] && value[0]?.fileId) {
      setFilesList(value[0])
    } else {
      setFilesList({})
    }
  }
  // 根据不同的权限  来控制是否可以上传
  const renderUpload = (bool: boolean) => {
    return (
      <>
        {bool ? (
          <div>
            <Button disabled={bool} icon={<UploadOutlined />}>
              上传文件
            </Button>
          </div>
        ) : (
          <UploadFile
            ButtonText={<Button icon={<UploadOutlined />}>上传文件</Button>}
            accept="image/jpeg,image/png,image/jpg,"
            cb={(value: any) => handleUpload(value)}
            listType="text"
            type="image"
            filesValue={true}
          ></UploadFile>
        )}
      </>
    )
  }
  const renderDesc = useMemo(() => {
    return <span className="agency_info_content_desc">支持扩展名: .jpg、 .jpeg、 .png</span>
  }, [])
  const renderInfo = useMemo(() => {
    let submitInfoFiles = {
      APPLY_FILE_WAIT_SIGN: {
        title: "上传已签署的注销申请",
        formLabel: "上传注销申请书：",
        upload: renderUpload(!logOff),
        desc: renderDesc,
        value: "APPLY_FILE_WAIT_SIGN", // 存储用于提交数据的状态参数
        disabled: !logOff,
      },
      TAX_WAIT_CLEAN: {
        title: "清税证明",
        formLabel: "上传清税证明：",
        upload: renderUpload(false),
        desc: renderDesc,
        value: "TAX_WAIT_CLEAN",
        disabled: false,
      },
      LICENSE_WAIT_CANCEL: {
        title: "执照注销证明",
        formLabel: "上传执照注销证明：",
        upload: renderUpload(false),
        desc: renderDesc,
        value: "LICENSE_WAIT_CANCEL",
        disabled: false, // 控制按钮是否可以操作
      },
      CANCEL_COMPLETE: {},
    }
    let keys = details?.cancelStatus
    return submitInfoFiles[keys]
  }, [details, logOff, taxRole, licenseRole])
  return (
    <div className="agency_info">
      <div className="agency_info_title">{renderInfo?.title}</div>
      <div className="agency_info_content">
        <span className="agency_info_content_label">{renderInfo?.formLabel}</span>
        <div>
          {isSuc && renderInfo?.upload}
          <span style={{ paddingTop: 100 }}>{renderInfo?.desc}</span>
        </div>
      </div>
      <div className="agency_info_btn">
        <Button onClick={() => handleSubmit()} disabled={renderInfo?.disabled}>
          确认提交
        </Button>
      </div>
      <ModalCom
        title={openParams?.title}
        open={openParams?.open}
        width={500}
        className="submit_modal"
        onCancel={() => {
          setOpenParams({ open: false, title: "" })
        }}
        onOk={() => handleSubmit(true)}
      >
        <div style={{ paddingLeft: 24 }}>
          <div className="form_item_m">
            <span className="form_item_label">个体户名称:</span>
            <div>{details?.organizationVo?.name}</div>
          </div>
          <div className="form_item_m">
            <span className="form_item_label">法人姓名:</span>
            <div>{details?.organizationVo?.operatorLegal}</div>
          </div>
          <div className="form_item_m">
            <span className="form_item_label">法人身份证号:</span>
            <div>{details?.organizationVo?.legalIdNo}</div>
          </div>
          <div className="form_item_m">
            <span className="form_item_label">社会统一信用代码:</span>
            <div>{details?.organizationVo?.idNo}</div>
          </div>
        </div>
      </ModalCom>
    </div>
  )
}

export default SubmitInfo
