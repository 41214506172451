/* eslint-disable prettier/prettier */
import { transformDateType } from "../../../utils/formatNumber"

// 交易金额走势
export const transformOrder = (_optionsOrderTrend: any, _dataList: any) => {
  _optionsOrderTrend.xAxis.data = _dataList.map((item) => item.date)
  _optionsOrderTrend.series.data = _dataList.map((item) => Number(item.amount))
  return _optionsOrderTrend
}

// 入住新增趋势
export const transformOrgTrend = (_options: any, _dataList: any) => {
  _options.xAxis.data = _dataList.map((item) => item.date)
  let enterpriseNum: number[] = [],
    individual_business_num: number[] = [],
    individual_num: number[] = [],
    other_num: number[] = []
  _dataList.forEach((item: any) => {
    enterpriseNum.push(Number(item.enterprise_num))
    individual_business_num.push(Number(item.individual_business_num))
    individual_num.push(Number(item.individual_num))
    other_num.push(Number(item.other_num))
  })
  _options.series = _options.series.map((item) => {
    if (item.name == "企业") item.data = enterpriseNum
    else if (item.name == "个体户") item.data = individual_business_num
    else if (item.name == "自然人") item.data = individual_num
    else if (item.name == "其他") item.data = other_num
    return item
  })
  return _options
}
// 再生资源分类
export const transformCategory = (_options: any, _dataList: any) => {
  _options.xAxis.data = _dataList.map((item) => item.name)
  _options.series.data = _dataList.map((item) =>
    transformDateType(item.amount, { thousands: true })
  )
  return _options
}
