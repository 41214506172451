/* eslint-disable react/jsx-key */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Space,
  Collapse,
  DatePicker,
  Form,
  Input,
  Select,
  Radio,
  Table,
  message,
  Cascader,
  Tooltip,
} from "antd"
import React, { memo, forwardRef, useState, useRef, useEffect, useMemo } from "react"
import { FieldType, FromProps } from "../../interface"
import type { RadioChangeEvent } from "antd"
import {
  settlementOptions,
  invoiceLimitsOptions,
  settingOptions,
  handSettingOptions,
  settlementOptionsEnum,
  settlementOptionsTwo,
  settingOptionsPayment,
  settingOptionsCompanyPayment,
} from "../../constant"
import type { InputRef } from "antd"
import { CloseOutlined, MenuOutlined, InfoCircleOutlined } from "@ant-design/icons"
import { getTissueList } from "../../../promises"
import "./index.scss"
import { useParams } from "react-router-dom"
import type { DragEndEvent } from "@dnd-kit/core"
import { DndContext } from "@dnd-kit/core"
import { restrictToVerticalAxis } from "@dnd-kit/modifiers"
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
const { Option } = Select
interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string
}
const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  })
  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
  }
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children?.map(children, (child) => {
        if ((child as React.ReactElement).key === "sort") {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: "none", cursor: "move" }}
                {...listeners}
              />
            ),
          })
        }
        return child
      })}
    </tr>
  )
}
export const SettlementSetting = (props: FromProps) => {
  const {
    form,
    flag,
    setFlag,
    companyList,
    tissueList,
    setTlementOptionsList,
    settlementOptionsList,
    settlementOptionsTwoList,
    setTlementOptionsTwoList,
  } = props
  const history: any = useParams()
  const displayRender = (labels: string[]) => labels[labels.length - 1]
  const [value, setValue] = useState(1)
  const [newSettingOptions, setNewSettingOptions] = useState<any>([])
  const [payeeRoleOptions, setPayeeRoleOptions] = useState<any>([])
  // const [settlementOptionsList, setTlementOptionsList] = useState<any>([])
  // const [settlementOptionsTwoList, setTlementOptionsTwoList] = useState<any>([])
  const [settlementList, setSettlementList] = useState<any>([])
  const [taxCompanyFlag, setTaxCompanyFlag] = useState<any>(false)
  useMemo(() => {
    const resultData = [
      {
        label: "代缴税公司",
        value: "TAX_COMPANY",
        children: tissueList || [],
      },
    ]
    const newArrTing = settingOptions.concat(resultData)
    setNewSettingOptions(newArrTing)
    setPayeeRoleOptions(resultData)
  }, [companyList, tissueList])

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value)
  }
  useMemo(() => {
    if (history?.id !== "null") {
      setSettlementList(form?.getFieldValue("settlementPaths"))
      setTlementOptionsList(form?.getFieldValue("settlementPathsOne"))
      setTlementOptionsTwoList(form?.getFieldValue("settlementPathsTwo"))
    } else {
      setTlementOptionsList(settlementOptions)
      setTlementOptionsTwoList(settlementOptionsTwo)
    }
  }, [history, form?.getFieldValue("settlementPathsOne")])
  const filterOption = (
    input: string,
    option?: { label: string; value: string; children: string }
  ) => {
    return (option?.children ?? "").toLowerCase().includes(input.toLowerCase())
  }
  const columnsOne = [
    {
      key: "sort",
    },
    {
      title: (
        <>
          <Tooltip placement="top" title={"排序数字越小，优先级越高，支付时先支付优先级高的款项"}>
            优先级
            <InfoCircleOutlined style={{ marginLeft: 5 }} />
          </Tooltip>
        </>
      ),
      dataIndex: "sort",
    },
    {
      title: "支付方",
      dataIndex: "payerRole",
      key: "payerRole",
      render: (text: any, record: any) => {
        return <div>{settlementOptionsEnum[text]}</div>
      },
    },
    {
      title: "收款方",
      dataIndex: "payeeRole",
      key: "payeeRole",
      render: (text: any, record: any) => {
        return text == "TAX_COMPANY" || text == undefined || text[0] == "TAX_COMPANY" ? (
          <Select
            defaultValue={history?.id !== "null" ? [record?.taxCompanyId] : undefined}
            style={{ width: 250 }}
            placeholder="请选择"
            onChange={handPayeeRoleOne}
            filterOption={filterOption}
            allowClear
            showSearch
          >
            {tissueList &&
              tissueList?.map((item) => {
                return <Option value={item.value}>{item.label}</Option>
              })}
          </Select>
        ) : (
          // <Cascader
          //   defaultValue={
          //     history?.id !== "null" ? [record?.payeeRole, record?.taxCompanyId] : undefined
          //   }
          //   style={{ width: 130 }}
          //   placeholder="请选择待缴税公司"
          //   options={payeeRoleOptions}
          //   expandTrigger="hover"
          //   displayRender={displayRender}
          //   onChange={handPayeeRoleOne}
          // />
          <div>{settlementOptionsEnum[text]}</div>
        )
      },
    },
    {
      title: "支付项",
      dataIndex: "payOption",
      key: "payOption",
      render: (text: any, record: any) => {
        return <div>{settlementOptionsEnum[text]}</div>
      },
    },
  ]
  const handPayeeRoleOne = (value) => {
    const resultSettlement = settlementOptionsList?.filter((ie: any) => {
      if (ie?.payeeRole == "TAX_COMPANY" && value) {
        ie.payeeRole = "TAX_COMPANY"
        ie.taxCompanyId = value
      } else if (ie?.payeeRole == "TAX_COMPANY" && value == undefined) {
        ie.payeeRole = "TAX_COMPANY"
        ie.taxCompanyId = null
      }
      return ie
    })
    form.setFieldValue("settlementPathsOne", resultSettlement)
  }

  const columnsTwo = [
    {
      key: "sort",
    },
    {
      title: (
        <>
          <Tooltip placement="top" title={"排序数字越小，优先级越高，支付时先支付优先级高的款项"}>
            优先级
            <InfoCircleOutlined style={{ marginLeft: 5 }} />
          </Tooltip>
        </>
      ),
      dataIndex: "sort",
    },
    {
      title: "支付方",
      dataIndex: "payerRole",
      key: "payerRole",
      render: (text: any, record: any) => {
        return <div>{settlementOptionsEnum[text]}</div>
      },
    },
    {
      title: "收款方",
      dataIndex: "payeeRole",
      key: "payeeRole",
      render: (text: any, record: any) => {
        return text == "TAX_COMPANY" || text == undefined || text[0] == "TAX_COMPANY" ? (
          <Select
            defaultValue={history?.id !== "null" ? [record?.taxCompanyId] : undefined}
            style={{ width: 250 }}
            placeholder="请选择"
            onChange={handPayeeRoleTwo}
            filterOption={filterOption}
            allowClear
            showSearch
          >
            {tissueList &&
              tissueList?.map((item) => {
                return <Option value={item.value}>{item.label}</Option>
              })}
          </Select>
        ) : (
          // <Cascader
          //   defaultValue={
          //     history?.id !== "null" ? [record?.payeeRole, record?.taxCompanyId] : undefined
          //   }
          //   style={{ width: 130 }}
          //   placeholder="请选择待缴税公司"
          //   options={payeeRoleOptions}
          //   expandTrigger="hover"
          //   displayRender={displayRender}
          //   onChange={handPayeeRoleTwo}
          // />
          <div>{settlementOptionsEnum[text]}</div>
        )
      },
    },
    {
      title: "支付项",
      dataIndex: "payOption",
      key: "payOption",
      render: (text: any, record: any) => {
        return <div>{settlementOptionsEnum[text]}</div>
      },
    },
  ]
  const handPayeeRoleTwo = (value) => {
    const resultSettlement = settlementOptionsTwoList?.filter((ie: any) => {
      if (ie?.payeeRole == "TAX_COMPANY" && value) {
        ie.payeeRole = "TAX_COMPANY"
        ie.taxCompanyId = value
      } else if (ie?.payeeRole == "TAX_COMPANY" && value == undefined) {
        ie.payeeRole = "TAX_COMPANY"
        ie.taxCompanyId = null
      }
      return ie
    })
    form.setFieldValue("settlementPathsTwo", resultSettlement)
  }
  const spaceColumns = [
    {
      title: (
        <>
          <Tooltip placement="top" title={"排序数字越小，优先级越高，支付时先支付优先级高的款项"}>
            优先级
            <InfoCircleOutlined style={{ marginLeft: 5 }} />
          </Tooltip>
        </>
      ),
      dataIndex: "sort",
    },
    {
      title: "支付方",
      dataIndex: "payerRole",
      key: "payerRole",
    },
    {
      title: "收款方",
      dataIndex: "payeeRole",
      key: "payeeRole",
      width: 250,
    },
    {
      title: "支付项",
      dataIndex: "payOption",
      key: "payOption",
    },
    {
      title: "操作",
    },
  ]
  const handPayee = (value) => {
    console.log(value, "value")
    const payer = form.getFieldValue("settlementPaths")
    const payerFlag = payer?.some((item: any) => item.payerRole === item.payeeRole)
    if (payerFlag) {
      message.error("支付方收款方不能是同一选项")
    }
  }

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    let newArrayMove: any = []
    if (active.id !== over?.id) {
      setTlementOptionsList((previous) => {
        const activeIndex = previous.findIndex((i) => i?.sort === active?.id)
        const overIndex = previous.findIndex((i) => i?.sort === over?.id)
        newArrayMove = arrayMove(previous, activeIndex, overIndex)
        return arrayMove(previous, activeIndex, overIndex)
      })
      let arrList = newArrayMove.map((item, index) => {
        return {
          ...item,
          sort: index + 1 + "",
        }
      })
      if (arrList?.length > 0) {
        setTlementOptionsList(arrList)
      }
    }
    console.log(newArrayMove, "newArrayMove")
    // form.setFieldValue("settlementPathsOne", newArrayMove)
  }

  const onTwoDragEnd = ({ active, over }: DragEndEvent) => {
    let newArrayMove: any = []
    if (active?.id !== over?.id) {
      setTlementOptionsTwoList((previous) => {
        const activeIndex = previous.findIndex((i) => i?.sort === active?.id)
        const overIndex = previous.findIndex((i) => i?.sort === over?.id)
        newArrayMove = arrayMove(previous, activeIndex, overIndex)
        return arrayMove(previous, activeIndex, overIndex)
      })
      let arrList = newArrayMove.map((item, index) => {
        return {
          ...item,
          sort: index + 1 + "",
        }
      })
      if (arrList?.length > 0) {
        setTlementOptionsTwoList(arrList)
      }
    }

    // form.setFieldValue("settlementPathsTwo", newArrayMove)
  }

  return (
    <>
      <div className="settlement_setting">
        <Form.Item<FieldType> name="enable">
          <Radio.Group onChange={onChange} value={value} defaultValue={1}>
            <div className="settlement_setting_Group">
              <div className="settlement_setting_Group_left">
                <Radio value={1}>
                  <span style={{ fontSize: 16 }}>配置一</span>
                  <span className="settlement_setting_Group_text">
                    买家将订单价格(含税)支付给卖家，卖家向平台缴纳税款
                  </span>
                </Radio>
                <Form.Item<FieldType> name={["settlementPathsOne"]}>
                  <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                    <SortableContext
                      // rowKey array
                      items={settlementOptionsList?.map((i) => i.sort) || []}
                      strategy={verticalListSortingStrategy}
                    >
                      <Table
                        components={{
                          body: {
                            row: Row,
                          },
                        }}
                        rowKey="sort"
                        style={{ paddingTop: 10 }}
                        columns={columnsOne}
                        dataSource={settlementOptionsList}
                        pagination={false}
                      />
                    </SortableContext>
                  </DndContext>
                </Form.Item>
              </div>
              <div className="settlement_setting_Group_right">
                <Radio value={2}>
                  <span style={{ fontSize: 16 }}>配置二</span>
                  <span className="settlement_setting_Group_text">
                    买家将卖方净得价支付给卖家，买家向平台缴纳税款
                  </span>
                </Radio>
                <Form.Item<FieldType> name={["settlementPathsTwo"]}>
                  <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onTwoDragEnd}>
                    <SortableContext
                      // rowKey array
                      items={settlementOptionsTwoList?.map((i) => i.sort) || []}
                      strategy={verticalListSortingStrategy}
                    >
                      <Table
                        components={{
                          body: {
                            row: Row,
                          },
                        }}
                        rowKey="sort"
                        style={{ paddingTop: 10 }}
                        dataSource={settlementOptionsTwoList}
                        columns={columnsTwo}
                        pagination={false}
                      />
                    </SortableContext>
                  </DndContext>
                </Form.Item>
              </div>
            </div>
            {/* 自定义配置 */}

            {flag ? (
              <div className="settlement_setting_add_table">
                <Radio value={3}>
                  <span style={{ fontSize: 16 }}>自定义结算路径</span>
                </Radio>
                <div className="settlement_setting_add_table_list">
                  <Form.Item<FieldType> name={["custom"]}>
                    <Form.List name={["settlementPaths"]}>
                      {(FromSubFields, FromSubOpt) => (
                        <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
                          <Space className="antd-space-table">
                            <Table
                              style={{ paddingTop: 12 }}
                              dataSource={[]}
                              columns={spaceColumns}
                              pagination={false}
                            />
                          </Space>
                          {FromSubFields.map((FromSubField, index) => (
                            <>
                              <Space
                                key={FromSubField.key}
                                style={{
                                  borderBottom: "1px solid #f0f0f0",
                                  display: "flex",
                                  // justifyContent: "space-between",
                                }}
                              >
                                <div className="from-sub-fie fromIndex">{index + 1}</div>
                                <Form.Item
                                  name={[FromSubField.name, "payerRole"]}
                                  style={{ marginLeft: 60 }}
                                  className="from-sub-fie "
                                >
                                  <Select
                                    style={{ minWidth: 130 }}
                                    placeholder="选择支付方"
                                    onChange={handPayee}
                                  >
                                    {settingOptionsPayment &&
                                      settingOptionsPayment?.map((item) => {
                                        return <Option value={item.value}>{item.label}</Option>
                                      })}
                                  </Select>
                                </Form.Item>
                                <Form.Item
                                  className="from-sub-fie fromInput"
                                  style={{ marginLeft: 70 }}
                                  name={[FromSubField.name, "payeeRole"]}
                                >
                                  {/* <Select
                                    defaultValue={[
                                      // settlementList[index]?.payeeRole,
                                      settlementList[index]?.taxCompanyId,
                                    ]}
                                    style={{ width: 250 }}
                                    placeholder="请选择"
                                    onChange={(value) => {
                                      console.log(value, "value")
                                    }}
                                    filterOption={filterOption}
                                    allowClear
                                    showSearch
                                  >
                                    {newSettingOptions &&
                                      newSettingOptions?.map((item) => {
                                        return <Option value={item.value}>{item.label}</Option>
                                      })}
                                  </Select> */}
                                  <Cascader
                                    // defaultValue={[
                                    //   settlementList[index]?.payeeRole,
                                    //   settlementList[index]?.taxCompanyId,
                                    // ]}
                                    style={{ minWidth: 250 }}
                                    placeholder="选择收款方"
                                    options={newSettingOptions}
                                    expandTrigger="hover"
                                    showSearch
                                    // onChange={handPayeeCustom}
                                    displayRender={displayRender}
                                  />
                                </Form.Item>

                                <Form.Item
                                  name={[FromSubField.name, "payOption"]}
                                  style={{ marginLeft: 180 }}
                                  className="from-sub-fie fromInput"
                                >
                                  <Select style={{ minWidth: 130 }} placeholder="选择支付项">
                                    {handSettingOptions &&
                                      handSettingOptions?.map((item) => {
                                        return <Option value={item.value}>{item.label}</Option>
                                      })}
                                  </Select>
                                </Form.Item>
                                <div className="from-sub-fie fromInput" style={{ marginLeft: 90 }}>
                                  <a
                                    onClick={() => {
                                      FromSubOpt.remove(FromSubField.name)
                                    }}
                                  >
                                    删除
                                  </a>
                                </div>
                              </Space>
                            </>
                          ))}
                          {FromSubFields.length > 9 ? (
                            ""
                          ) : (
                            <Button
                              type="dashed"
                              onClick={() => FromSubOpt.add()}
                              block
                              style={{
                                maxWidth: "100%",
                                marginTop: 2,
                                height: 40,
                                color: "rgba(24, 144, 255, 1)",
                                marginBottom: 24,
                              }}
                            >
                              + 新增支付路径
                            </Button>
                          )}
                        </div>
                      )}
                    </Form.List>
                  </Form.Item>
                </div>
              </div>
            ) : null}
            <Button
              type="dashed"
              onClick={() => {
                setFlag(true)
              }}
              block
              style={{
                maxWidth: "100%",
                marginTop: 45,
                color: "rgba(24, 144, 255, 1)",
                display: `${flag ? "none" : ""}`,
                height: 40,
              }}
            >
              + 新增配置
            </Button>
          </Radio.Group>
        </Form.Item>
      </div>
    </>
  )
}

export default SettlementSetting
