import { FC, memo } from "react"
import { Descriptions, Space, Steps } from "antd"
import { useRequest } from "ahooks"
import dayjs from "dayjs"

type props = {
  orderId: string | number
  logData: any
}
export type ReviewLogProps = props
export const ReviewLog: FC<React.PropsWithChildren<ReviewLogProps>> = memo((props) => {
  const { orderId, logData } = props
  console.log(logData, "logData")
  return (
    <div style={{ padding: "16px 24px" }}>
      <Steps
        progressDot
        current={1000}
        direction="vertical"
        items={logData
          ?.map((item) => ({
            step: item.type,
            time: item.createTime,
            reviewer: item.auditUserName,
            reason: item.remark,
          }))
          ?.map((item) => ({
            title: (
              <Space>
                <span>{item.step}</span>{" "}
                <Space>
                  {item.time ? (
                    <span> {dayjs(item.time).format("YYYY-MM-DD HH:mm:ss")}</span>
                  ) : undefined}
                </Space>
              </Space>
            ),
            description: (
              <Descriptions column={1}>
                {item.reviewer ? (
                  <Descriptions.Item style={{ padding: 0 }} label="审核人">
                    {item.reviewer}
                  </Descriptions.Item>
                ) : undefined}
                {item.reason ? (
                  <Descriptions.Item
                    style={{ padding: 0 }}
                    label="驳回原因"
                    // contentStyle={{ color: "#F5222DD9" }}
                  >
                    {item.reason}
                  </Descriptions.Item>
                ) : undefined}
              </Descriptions>
            ),
          }))}
      />
    </div>
  )
})
ReviewLog.displayName = "审核记录"
