import React from "react"
import { Space, message } from "antd"
import { DetailPageContent } from "../../../components/DetailPageComponents/Content"
import { DetailPageHeader } from "../../../components/DetailPageComponents/Header"
import { DetailPageContainer } from "../../../components/DetailPageComponents/PageContainer"
import CustomerMap from "../../../components/CustomerMap"
import FormItemCom from "../../../components/FormItem"
import { getCarPaths } from "../promises"
import dayjs from "dayjs"
/**
 * 物流轨迹
 * @returns
 */
export default function Trajectory() {
  const [pathList, setPathList] = React.useState([])
  const [carStatus, setCarStatus] = React.useState("")
  const formColumns = [
    {
      label: "车牌号",
      dataIndex: "vehicleNo",
      type: "Input",
      params: {
        placeholder: "请输入",
        allowClear: true,
        maxLength: 10,
        onChange: (e: any) => {
          const value = e.target.value.split("")
        },
      },
    },
    {
      label: "起始时间",
      dataIndex: "time",
      type: "DatePickers",
      params: {
        showTime: true,
        style: { width: 380 },
        defaultValue: [
          dayjs(new Date().getTime() - 1000 * 60 * 60 * 24),
          dayjs(new Date().getTime()),
        ],
      },
    },
  ]
  const handleSearch = async (values: any) => {
    let params = {
      vehicleNo: values?.vehicleNo.trim(),
      startTime: values.time ? dayjs(new Date(values?.time[0])).format("YYYY-MM-DD HH:mm:ss") : "",
      endTime: values.time ? dayjs(new Date(values?.time[1])).format("YYYY-MM-DD HH:mm:ss") : "",
    }
    if (!values?.vehicleNo) {
      message.error("请输入车牌号")
      return
    }
    if (!params?.startTime || !params?.endTime) {
      message.error("请选择时间")
      return
    }
    const res: any = await getCarPaths(params)

    if (res.code === 200) {
      if (res.data.truckTrajectory) {
        setPathList(res.data.truckTrajectory)
      }
      setCarStatus(res.data.truckOnlineStatusDesc)
    }
  }
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={"物流轨迹"}
        breadCrumb={[
          { label: "物流管理", path: "" },
          { label: "物流轨迹", path: "" },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <DetailPageContent>
        <div className="container table_content">
          <div className="query_header">
            <FormItemCom formParams={formColumns} onSearch={handleSearch}></FormItemCom>
          </div>
          <div className="table_content_list">
            <div style={{ marginBottom: 20 }}>
              车辆状态：<a>{carStatus}</a>
            </div>
            <CustomerMap pathList={pathList}></CustomerMap>
          </div>
        </div>
      </DetailPageContent>
    </DetailPageContainer>
  )
}
