/* eslint-disable prettier/prettier */
import { BadgeProps } from "antd"
import { transOptionsToMap } from "../utils/utils"

export const INVOICE_TYPE_OPTIONS = [
  { label: "纸质普票", value: "PAPER_SPECIAL" },
  { label: "纸质专票", value: "PAPER_GENERAL" },
  { label: "电子普票", value: "ELECTRONIC_GENERAL" },
  { label: "电子专票", value: "ELECTRONIC_SPECIAL" },
  { label: "全电普票", value: "FULLY_ELECTRONIC_GENERAL" },
  { label: "全电专票", value: "FULLY_ELECTRONIC_SPECIAL" },
]

export const INVOICE_TYPE_MAP = transOptionsToMap(INVOICE_TYPE_OPTIONS)

export const InvoiceItemStatusEnum: Record<string, BadgeProps> = {
  ISSUED: {
    status: "success",
    text: "已开票",
  },
  PENDING: {
    status: "warning",
    text: "待开票",
  },
  CANCELED: {
    status: "error",
    text: "已撤销",
  },
  ERROR: {
    status: "error",
    text: "开票失败",
  },
  REVERSING: {
    status: "processing",
    text: "冲红中",
  },
  REVERSED: {
    status: "success",
    text: "已冲红",
  },
}
