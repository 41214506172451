import React from "react"
export default [
  {
    title: "发票序号",
    dataIndex: "name",
    fixed: "left",
  },
  {
    title: "购买方",
    dataIndex: "orgName",
  },
  {
    title: "发票不含税金额",
    dataIndex: "totalPrice",
    align: "right",
  },
  {
    title: "增值税税率",
    dataIndex: "taxRate",
    align: "right",
  },
  {
    title: "发票增值税",
    dataIndex: "taxAmount",
    align: "right",
  },
  {
    title: "发票价税合计",
    dataIndex: "amountWithTax",
    align: "right",
  },
  {
    title: "项目名称",
    dataIndex: "itemName",
  },
  {
    title: "规格型号",
    dataIndex: "specification",
  },
  {
    title: "票种",
    dataIndex: "invoiceType",
    enum: {
      SPECIAL: "专票",
      GENERAL: "普票",
    },
    render: (text: string) => (
      <span>{text == "SPECIAL" ? "专票" : text == "GENERAL" ? "普票" : ""}</span>
    ),
  },
  {
    title: "关联订单id",
    dataIndex: "orders",
    render: (item: any) => {
      return <span>{item?.map((k: any) => k?.orderId).join("、")}</span>
    },
  },
]
