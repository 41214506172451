import { OP_EVIDENCE } from "@/api/config/servicePort"
import UtilOp from "../../../../services/serviceOp"
const util = UtilOp.getInstance()
type get_evidence_template_list = {
  templateName?: string
  createUser?: string | number
  size: string | number
  current: string | number
}

const getEvidenceTemplateList: (values: get_evidence_template_list) => Promise<any> = (
  values: get_evidence_template_list
) => {
  return util.httpGet(OP_EVIDENCE + "/secure-evidence-templates", values).then((res) => res)
}

// 新增模板
const addTemplateName = (params: any) => {
  return util
    .httpPost(OP_EVIDENCE + "/secure-evidence-templates", params)
    .then((res) => res)
    .catch((err) => err)
}
// 客户管理-详情-存证模板详情
const getTemplateInfo = (id: any) => {
  return util
    .httpGet(`${OP_EVIDENCE}/op/evidence/${id}`)
    .then((res) => res)
    .catch((err) => err)
}
// 存证模板详情
const getInfos = (id?: any) => {
  return util.httpGet(OP_EVIDENCE + `/secure-evidence-templates/${id}`).then((res) => res)
}

// 客户存证场景启用或者停用
const changeStatus = (id: any, status?: any) => {
  return util
    .httpPost(`${OP_EVIDENCE}/op/evidence/`, { id, status })
    .then((res) => res)
    .catch((err) => err)
}
export { getEvidenceTemplateList, addTemplateName, getTemplateInfo, changeStatus, getInfos }
