/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react"
import { getIndividualRelationShip } from "../promise"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import { Button, Card, Checkbox, Form, Select, Space, message } from "antd"
import "./index.scss"
import { useNavigate } from "react-router"
import {
  getOpeRegions,
  getTissueList,
  getIndividualCancelAdd,
  getIndividualCancelFiles,
  download,
} from "../promise"
import UploadFile from "@src/components/UploadFile"
import { UploadOutlined } from "@ant-design/icons"
import { getFileUrl } from "@src/utils/file.ts"

// 注销流程
const NewLogout = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [tissueList, setTissueList] = useState<any>([]) //组织列表
  const [regionsList, setRegionsList] = useState<any>([])
  const [filesList, setFilesList] = useState<any>({})
  const [isCheck, setIsCheck] = useState<any>(false)
  const [relationShip, setRelationShip] = useState<any>(false)

  const init = async () => {
    const result = await getOpeRegions({})
    if (result?.code == 200) {
      let resultList = result?.data?.records.map((ie: any) => {
        return {
          value: ie.id,
          label: ie.name,
        }
      })
      setRegionsList(resultList)
    }
  }
  //组织列表
  const tissueInit = async () => {
    const response = await getTissueList({
      size: -1,
      searchCount: true,
      types: "INDIVIDUAL_BUSINESS",
    })
    if (response.code == 200) {
      const result = response.data.records.map((ie) => {
        return {
          value: ie.id,
          label: ie?.name
            ? ie.cancelStatus == "NORMAL"
              ? ie.name
              : ie.cancelStatus == "CANCEL_APPLY"
              ? `(注销中) ${ie.name}`
              : `(已注销) ${ie.name}`
            : ie.operatorLegal,
          disabled: ie.cancelStatus !== "NORMAL",
          contact: ie.contact,
        }
      })
      setTissueList(result)
    }
  }
  useEffect(() => {
    init()
    tissueInit()
  }, [])
  const onHandFinish = async (value: any) => {
    let params = {
      ...value,
      cancelApplyFileOssFileId: filesList?.fileId,
    }
    const resultData: any = await getIndividualCancelAdd(params)
    if (resultData.code == 200) {
      navigate(`/agencyBusiness/OutSetup`)
      message.success("发起注销申请成功")
    }
  }
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo)
  }
  // 上传文件后 存储图片数据
  const handleUpload = (value: any) => {
    if (value[0] && value[0]?.fileId) {
      setFilesList(value[0])
    } else {
      setFilesList({})
    }
  }
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <span>新建个体户注销申请</span>
          </Space>
        }
        breadCrumb={[
          { label: "业务代办", path: "" },
          { label: "工商税务开办", path: "" },
          { label: "新建个体户注销申请", path: "" },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <DetailPageContent>
        <Form
          form={form}
          name="basic"
          // initialValues={{}}
          onFinish={onHandFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="newLogout-from"
        >
          <Card title="选择注销个体户">
            <div style={{ display: "flex" }}>
              <Form.Item
                name="organizationId"
                label={"搜索组织"}
                rules={[{ required: true, message: "请选择" }]}
              >
                <Select
                  style={{ width: 336 }}
                  placeholder="请选择"
                  onChange={async (e) => {
                    const result = await getIndividualRelationShip(e)
                    if (result.code == 200) {
                      if (result?.data?.id) {
                        form.setFieldValue("handRegionId", result?.data?.id)
                        setRelationShip(true)
                      } else {
                        form.setFieldValue("handRegionId", null)
                        setRelationShip(false)
                      }
                    }
                  }}
                  filterOption={(input: any, option: any) =>
                    (option?.label ?? "").includes(input) || (option?.contact ?? "").includes(input)
                  }
                  options={tissueList}
                  allowClear
                  showSearch
                ></Select>
              </Form.Item>
              <Form.Item
                name="handRegionId"
                label={"选择注销区域"}
                rules={[{ required: true, message: "请选择" }]}
              >
                <Select
                  onChange={(e) => {
                    console.log(e, "e")
                  }}
                  disabled={relationShip}
                  options={regionsList}
                  style={{ width: 336 }}
                  placeholder={"请选择"}
                ></Select>
              </Form.Item>
            </div>
          </Card>
          <Card title="注销信息补充" className="logout_files">
            <p className="logout_files_text">非必填，若已完成线下签署，可在此上传注销申请书</p>
            <div>
              <Form.Item
                name="handleRegionId"
                label={"上传注销申请"}
                rules={[{ required: false, message: "请选择" }]}
              >
                <UploadFile
                  ButtonText={<Button icon={<UploadOutlined />}>选择文件</Button>}
                  accept=".doc, .docx, .pdf, .jpeg, .png, .jpg"
                  cb={(value: any) => handleUpload(value)}
                  listType="text"
                  filesValue={false}
                ></UploadFile>
                <div className="files_span">
                  <span>
                    若已完成线下签署，可在此上传注销申请书{" "}
                    <a
                      onClick={async () => {
                        let params = form.getFieldsValue()
                        if (params.organizationId && params.handRegionId) {
                          getIndividualCancelFiles(params).then((res) => {
                            console.log(11)
                          })
                        }
                      }}
                    >
                      下载注销申请书，线下签署
                    </a>
                  </span>
                  <span> 支持扩展名：.doc、.docx、.pdf、.jpg、.jpeg、.png</span>
                </div>
              </Form.Item>
            </div>
            <div className="logout_checkbox">
              <Checkbox
                className="checkbox"
                checked={isCheck}
                onChange={(e) => {
                  setIsCheck(e.target.checked)
                }}
              />
              发起申请前，请仔细阅读
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    "https://doc.weixin.qq.com/pdf/d3_AbAAYQZ_ABsVBXU0RZKRb2eK0nKBf?scode=AHEAzQfIAFYsz9dmRpAbAAYQZ_ABs"
                  )
                }}
              >
                《个体户注销条件》
              </span>
            </div>
          </Card>
          <div className="cardButton">
            <Card className="cardButton">
              <Button onClick={() => navigate(`/agencyBusiness/OutSetup`)}>取消</Button>
              <Button
                type="primary"
                disabled={!isCheck}
                style={{ marginLeft: 10 }}
                htmlType="submit"
              >
                发起注销申请
              </Button>
            </Card>
          </div>
        </Form>
      </DetailPageContent>
    </DetailPageContainer>
  )
}

export default NewLogout
