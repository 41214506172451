/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { Button, message, Row, Col, Form, Card, Space, Input } from "antd"
import { getOcrBusinessLicense } from "../../../../service"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import { inputDefaultRules, inputDefaultRexChineseRules } from "@src/utils/RegExp"
import { useNavigate, useParams } from "react-router-dom"
import { getOperAgenciessDetails, getApprovalUpdate } from "../../../../service"
import "./index.scss"
const FormItem = Form.Item
const colProps = {
  span: 8,
}
const ModifyInformation = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const historyId: any = useParams()

  const [individualPrefix, setIndividualPrefix] = useState<any>("") //前缀
  const [individualSuffix, setIndividualSuffix] = useState<any>("") //后缀
  const [detailInfo, setDetailInfo] = useState<any>({})
  const init = async () => {
    console.log("初始化页面", historyId?.id)
    if (!historyId?.id) return
    const data = await getOperAgenciessDetails(historyId.id)
    if (data?.code == 200) {
      setDetailInfo(data?.data)
      form.setFieldsValue({ ...data.data, businessScope: data?.data?.businessScope })
      setIndividualPrefix(data?.data?.namePrefix)
      setIndividualSuffix(data?.data?.nameSuffix)
    }
  }
  useEffect(() => {
    init()
  }, [])
  //保存校验
  const onFinish = async (values: any) => {
    console.log(values, "values")
    let params: any = {
      ...values,
      id: detailInfo.id,
    }
    const result: any = await getApprovalUpdate(params)
    if (result.code == 200) {
      history.go(-1)
      message.success("修改成功")
    } else {
      message.error(result.message)
    }
  }
  //错误验证
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo)
  }
  return (
    <>
      <DetailPageContainer>
        <DetailPageHeader
          title={
            <Space>
              <span>修改申请信息</span>
            </Space>
          }
          breadCrumb={[
            { label: "业务代办", path: "" },
            { label: "工商税务开办", path: "" },
            { label: "个体户开办申请", path: "" },
            { label: "修改申请信息", path: "" },
          ]}
          rightContent={""}
        ></DetailPageHeader>
        <DetailPageContent>
          <Card title="申请信息" className="inform-action">
            <Form
              form={form}
              name="basic"
              colon={false}
              initialValues={{ remember: true, isNeedDual: true, isNeedAgreement: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Row className="registration">
                <Col {...colProps}>
                  <Form.Item
                    label="个体户名称前缀"
                    className="registration-bottom"
                    name="namePrefix"
                    rules={[
                      {
                        required: true,
                        message: "请输入",
                      },
                      {
                        validator: async (rule: any, value: string) =>
                          inputDefaultRexChineseRules(value, "个体户名称前缀", 10, true),
                      },
                    ]}
                  >
                    <Input
                      placeholder="请输入个体户名称前缀"
                      style={{ width: "434px" }}
                      showCount
                      maxLength={10}
                      onChange={(e) => {
                        setIndividualPrefix(e.target.value)
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="个体户名称后缀"
                    className="registration-bottom"
                    name="nameSuffix"
                    rules={[
                      {
                        required: true,
                        message: "请输入",
                      },
                      {
                        validator: async (rule: any, value: string) =>
                          inputDefaultRexChineseRules(value, "个体户名称后缀", 10, true),
                      },
                    ]}
                  >
                    <Input
                      placeholder="请输入个体户名称后缀"
                      style={{ width: "434px" }}
                      showCount
                      maxLength={10}
                      onChange={(e) => {
                        setIndividualSuffix(e.target.value)
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="预览企业/个体户名称"
                    name="name4"
                    style={{ width: 632 }}
                    className="registration-bottom"
                  >
                    <Input
                      addonBefore={individualPrefix}
                      addonAfter={individualSuffix}
                      disabled={true}
                      defaultValue="企业名称"
                    />
                  </Form.Item>
                  <Form.Item
                    name="businessScope"
                    className="registration-bottom"
                    label="经营范围"
                    style={{ width: 630, height: 88 }}
                    rules={[
                      {
                        required: true,
                        message: "请输入移动端营业执照办理成功后提示",
                      },
                      {
                        validator: async (rule: any, value: string) =>
                          inputDefaultRules(value, "移动端营业执照办理成功后提示", 50), //特殊字符
                      },
                    ]}
                  >
                    <Input.TextArea placeholder="请输入" style={{ width: 600, height: 88 }} />
                  </Form.Item>
                </Col>
              </Row>
              <div className="NewTradingScheme_Content_button_save">
                <Button
                  className="NewTradingScheme_Content_button_save_left"
                  onClick={() => history.go(-1)}
                >
                  取消
                </Button>
                <Button type="primary" htmlType="submit">
                  保存
                </Button>
              </div>
            </Form>
          </Card>
        </DetailPageContent>
      </DetailPageContainer>
    </>
  )
}

export default ModifyInformation
