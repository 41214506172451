/* eslint-disable react-hooks/exhaustive-deps */
import ProTable from "@ant-design/pro-table"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import { Button, message, Modal, Space, Spin, Tooltip } from "antd"
import React, { useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useQueryParamsStorage } from "../../../../hooks/useQueryParamsStorage"
import { getRangeDateOnUnitOfTime } from "../../../../utils/date/utils"
import SecondaryAuthentication from "../components/QRCodeSecondaryAuthentication"
import AdjustInformation from "./AdjustInformation/index"
import QuanDianModalLogin from "../components/QuanDian"
import useInvoiceHandler from "../components/useInvoiceHandler"
import { invoiceTypeObjs, makeStatusList, statusList, taskSourceObjs } from "./config"
import "./index.scss"
import { createInvoces, getInvocesList, getCalculateSelect } from "./promise"
import { formatNumber } from "@/utils/formatNumber"
import { ProColumns } from "@ant-design/pro-table/es/typing"
import { CustomRangePicker } from "../../../../components/CustomRangePicker"

const InvoicingTask = () => {
  const navigate = useNavigate()
  const [codeModalSwitch, setCodeModalSwitch] = useState(false)
  const [accountData, setAccountData] = useState<any>("")
  const [accountCode, setAccountCode] = useState<string>("")
  const [totalCount, setTotalCount] = useState<number>(0)
  const [previewSummary, setPreviewSummary] = useState<any>([]) //订单列表信息汇总
  const [previewSelect, setPreviewSelect] = useState<any>([]) //订单列表信息汇总
  const [information, setInformation] = useState<any>({
    open: false,
    record: [],
  }) //开票调整

  const actionRef = useRef()
  const formRef = useRef()
  const [invoicingFlag, setInvoicingFlag] = useState<any>(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const refreshFunction = () => {
    setInvoicingFlag(false)
    actionRef.current?.reload()
  }
  const {
    visibleQuanDianModalLogin,
    visibleSecondaryAuthentication,
    invoiceInfo,
    invoiceCode,
    nsrsbh,
    setNsrsbh,
    handleInvoiceResponse,
    closeQuanDianModalLogin,
  } = useInvoiceHandler(refreshFunction)

  const onSelectChange = async (newSelectedRowKeys: React.Key[]) => {
    console.log(newSelectedRowKeys, "newSelectedRowKeys")
    const response = await getCalculateSelect({ ids: newSelectedRowKeys.join(",") })
    if (newSelectedRowKeys?.length > 0) {
      setPreviewSummary(response?.data)
    } else {
      setPreviewSummary(previewSelect)
    }
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const columns: ProColumns<Record<string, any>, string>[] = [
    {
      title: "任务编号",
      search: false,
      dataIndex: "taskId",
    },
    {
      title: "来源",
      search: false,
      dataIndex: "taskSource",
      render: (record: any) => {
        return <span>{taskSourceObjs[record]}</span>
      },
    },
    {
      title: "订单ID",
      search: false,
      dataIndex: "orderNo",
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },
    {
      search: false,
      title: "开票方",
      dataIndex: "sellerName",
    },
    {
      search: false,
      title: "受票方",
      dataIndex: "buyerName",
    },
    {
      title: "反向开票",
      dataIndex: "reverseInvoicing",
      valueType: "select",
      valueEnum: makeStatusList,
    },
    {
      title: "发票类型",
      search: false,
      dataIndex: "invoiceType",
      render: (record: any) => {
        return <span>{invoiceTypeObjs[record]}</span>
      },
    },
    {
      title: "价税合计",
      search: false,
      dataIndex: "amountWithTax",
    },
    {
      title: "不含税金额",
      search: false,
      dataIndex: "amountWithoutTax",
    },
    {
      title: "数量",
      search: false,
      dataIndex: "itemQuantity",
    },

    // {
    //   title: "已开金额",
    //   dataIndex: "invoicedAmount",
    // },
    {
      title: "开票员",
      dataIndex: "invoiceIssuer",
    },
    {
      title: "状态",
      dataIndex: "taskStatus",
      valueType: "select",
      valueEnum: statusList,
    },
    {
      title: "备注",
      search: false,
      dataIndex: "failReason",
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      valueType: "dateTime",
      search: false,
    },
    {
      title: "完成时间",
      valueType: "dateTime",
      dataIndex: "completeTime",
      search: false,
    },

    {
      title: "创建时间",
      hideInTable: true,
      valueType: "dateRange",
      dataIndex: "createTime",
      renderFormItem: () => {
        return <CustomRangePicker />
      },
      search: {
        transform: (value) => {
          return {
            ...getRangeDateOnUnitOfTime({
              createTimeBegin: value?.[0],
              createTimeEnd: value?.[1],
            }),
          }
        },
      },
    },
    {
      title: "完成时间",
      valueType: "dateRange",
      renderFormItem: () => {
        return <CustomRangePicker />
      },
      hideInTable: true,
      dataIndex: "completeTime",
      search: {
        transform: (value) => {
          return {
            ...getRangeDateOnUnitOfTime({
              completeTimeBegin: value?.[0],
              completeTimeEnd: value?.[1],
            }),
          }
        },
      },
    },
    {
      title: "操作",
      search: false,
      fixed: "right",
      width: 240,
      render: (record: any) => {
        return (
          <div>
            {["PENDING"].includes(record?.taskStatus) && (
              <Button
                type="link"
                disabled={invoicingFlag}
                onClick={() => {
                  handleInvoicing(record)
                }}
              >
                重开票
              </Button>
            )}

            <Button
              type="link"
              onClick={() => {
                navigate(`/ticket/InvoicingTask/info/${record?.id}`)
              }}
            >
              查看详情
            </Button>
            {record?.taskStatus == "PENDING" &&
            record?.taskType == "BLUE" &&
            !record?.orderNo?.includes(",") ? (
              <Button
                type="link"
                onClick={() => {
                  setInformation({ open: true, record: record })
                }}
              >
                调整
              </Button>
            ) : null}
          </div>
        )
      },
    },
    {
      title: "关键字搜索",
      dataIndex: "query",
      valueType: "text",
      key: "query",
      hideInTable: true,
      fieldProps: {
        placeholder: "请输入任务编号、开票方、受票方、订单ID",
      },
    },
  ]
  // 重开票
  const handleInvoicing = async (record?: any) => {
    if (!record?.id) return
    setNsrsbh(record.sellerTaxNo)
    setInvoicingFlag(true)
    try {
      const res: any = await createInvoces(record?.id)
      handleInvoiceResponse(res?.data)
      if (res?.code === 200) {
        message.success("重开成功")
        actionRef.current?.reload()
      }
    } catch (error) {
      console.error("Error creating invoices:", error)
    } finally {
      setInvoicingFlag(false)
    }
  }
  //扫码完成
  const handCodeDone = async () => {
    if (!accountData?.id) return
    const res: any = await createInvoces(accountData?.id)
    if (res?.code === 200) {
      setCodeModalSwitch(false)
      message.success("重开成功")
      actionRef.current?.reload()
    }
  }
  const queryParamsStorage = useQueryParamsStorage()
  const rowSelection = {
    alwaysShowAlert: true,
    selectedRowKeys,
    onChange: onSelectChange,
  }
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <span>开票任务</span>
          </Space>
        }
        breadCrumb={[
          { label: "税票系统", path: "" },
          { label: "开票任务", path: "" },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <Spin spinning={invoicingFlag}>
        <DetailPageContent>
          <div className={"purchase-order-page"}>
            <ProTable
              columns={columns}
              actionRef={actionRef}
              formRef={formRef}
              expandable={{ showExpandColumn: false }}
              form={{
                onValuesChange: (values) => {
                  return formRef?.current?.submit()
                },
                initialValues: {
                  ...queryParamsStorage.queryParams,
                  createTime: queryParamsStorage.queryParams?.createTimeStart
                    ? [
                        queryParamsStorage.queryParams?.createTimeStart,
                        queryParamsStorage.queryParams?.createTimeEnd,
                      ]
                    : undefined,
                },
              }}
              scroll={{ x: "max-content" }}
              request={async (params, sort, filter) => {
                const { pageSize, itemQuantity, invoiceIssuer, query, ...rest } = params
                const response = await getInvocesList({
                  size: pageSize,
                  searchCount: true,
                  itemQuantity: itemQuantity?.trim(),
                  invoiceIssuer: invoiceIssuer?.trim(),
                  query: query?.trim(),
                  ...rest,
                })
                setTotalCount(response.data.total)
                setPreviewSummary(response?.data?.map)
                setPreviewSelect(response?.data?.map)
                queryParamsStorage.saveQueryParams({ ...params, pageSize: 20 })
                return {
                  data: response.data.records,
                  success: Boolean(response.data.records),
                  total: response.data.total,
                }
              }}
              rowKey="id"
              search={{
                labelWidth: "auto",
                optionRender: false,
                collapsed: false,
              }}
              bordered={false}
              options={false}
              pagination={{
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal(total, range) {
                  return `总共${total}条`
                },
              }}
              tableAlertRender={() => (
                <>
                  <div style={{ padding: "0 16", background: "#fff" }}>
                    <div className="procure-information">
                      <span>
                        已选择 <a style={{ fontWeight: 600 }}>{selectedRowKeys.length}</a> 条
                      </span>
                      <span>
                        不含税金额：
                        {formatNumber(previewSummary?.sumAmountWithoutTax, {
                          type: "number",
                          bit_number: 2,
                          thousands: true,
                        })}
                        元
                      </span>
                      <span>
                        价格合计：
                        {formatNumber(previewSummary?.sumAmountWithTax, {
                          type: "number",
                          bit_number: 2,
                          thousands: true,
                        })}
                        元
                      </span>
                    </div>
                  </div>
                </>
              )}
              dateFormatter="string"
              rowSelection={rowSelection}
              headerTitle={`开票任务列表/${totalCount}`}
            />
          </div>

          <Modal
            width={400}
            destroyOnClose={true}
            maskClosable={false}
            title={"请使用税务官方app进行扫码"}
            open={codeModalSwitch}
            onCancel={() => setCodeModalSwitch(false)}
            footer={false}
          >
            <div className="code-modal">
              <Space direction="vertical" align="center">
                <img src={accountCode} alt="" />
                <Button type="primary" style={{ marginBottom: 10 }} onClick={handCodeDone}>
                  扫码完成
                </Button>
              </Space>
            </div>
          </Modal>
          {visibleQuanDianModalLogin && (
            <QuanDianModalLogin
              visible={visibleQuanDianModalLogin}
              onCancel={closeQuanDianModalLogin}
              invoiceCode={invoiceCode}
              nsrsbh={nsrsbh}
            />
          )}
          {visibleSecondaryAuthentication && (
            <SecondaryAuthentication
              visible={visibleSecondaryAuthentication}
              onCancel={closeQuanDianModalLogin}
              invoiceInfo={invoiceInfo}
              nsrsbh={nsrsbh}
            />
          )}
        </DetailPageContent>

        <AdjustInformation
          information={information}
          setInformation={setInformation}
          reload={() => actionRef.current?.reload()}
        />
      </Spin>
    </DetailPageContainer>
  )
}

export default InvoicingTask
