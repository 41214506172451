import React, { useState, useEffect } from "react"
import { Table, Button } from "antd"
import { useNavigate } from "react-router-dom"
import { getEvidenceTemplateList } from "../../../Promise"

const EvidenceTable = (props: any) => {
  const { customerId } = props
  const navigate = useNavigate()
  const [pageOptions, setPageOptions] = useState<any>({
    pageSize: 10,
    current: 1,
    total: 0,
  })
  const [list, setList] = useState<any>([])
  const getlist = (params: any) => {
    if (!params?.customerId || params?.customerId === "") return
    const data = {
      size: pageOptions.pageSize,
      current: pageOptions.current,
      ...params,
    }
    getEvidenceTemplateList(data).then((res: any) => {
      if (res.successful) {
        setList(res?.data?.records)
        setPageOptions({
          pageSize: res.data.page,
          current: res.data.current,
          total: res.data.total,
        })
      }
    })
  }
  useEffect(() => {
    getlist({
      customerId: customerId,
    })
  }, [customerId]) // eslint-disable-line
  const columns = [
    {
      title: "存证场景",
      dataIndex: "secureEvidenceSceneTemplateDescription",
    },
    {
      title: "场景配置人",
      dataIndex: "createUserName",
    },
    {
      title: "存证链阶段概述",
      dataIndex: "secureEvidenceLinkNames",
    },
    {
      title: "状态",
      dataIndex: "status",
      render: (record: any) => {
        return <span>{record ? "启用" : "停用"}</span>
      },
    },
    {
      title: "操作",
      render: (record: any) => {
        return (
          <Button
            type="link"
            onClick={() => {
              navigate("/evidence/platform/template/add", {
                state: {
                  id: record?.id,
                  info: true,
                  current: 1,
                  isConnection: true, // 标记为客户管理-详情-模板
                  connectionStatus: record?.status,
                  customerId: customerId,
                  type: "manage",
                },
              })
            }}
          >
            详情
          </Button>
        )
      },
    },
  ]

  return (
    <div>
      <Table
        rowKey={(record: any) => record?.id}
        columns={columns}
        dataSource={list}
        pagination={pageOptions}
        // onChange={handlePage}
      ></Table>
    </div>
  )
}

export default EvidenceTable
