import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from "react"
import { Form, Input, Radio, Checkbox, InputNumber } from "antd"
import UploadCom from "../../../UploadCom"
import { useDispatch } from "react-redux"
import { UPDATE_BASIC, UPDATE_RULE } from "../../../../store/constants"
import "./index.scss"
const { TextArea } = Input
const FormItem = Form.Item
/**
 * @param{type} string 区别是限制条件或者基本类型数据
 * */

const layout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 24,
  },
}
const radioList = [
  {
    label: "读写",
    value: "WRITE",
  },
  {
    label: "只读",
    value: "READ",
  },
  {
    label: "隐藏",
    value: "HIDE",
  },
]

const checkboxOptions = [
  {
    label: ".txt",
    value: ".txt",
  },
  {
    label: ".doc/.docx",
    value: ".doc/.docx",
  },
  {
    label: ".pdf",
    value: ".pdf",
  },
  {
    label: ".xls/.xlsx",
    value: ".xls/.xlsx",
  },
  {
    label: ".ppt/.pptx",
    value: ".ppt/.pptx",
  },
  {
    label: ".zip/.rar/.7z",
    value: ".zip/.rar/.7z",
  },
]

const FileTemplate = (props: any, ref: any) => {
  const { infoType = "basicInfo", infoData, isInfo } = props
  const [formBacis] = Form.useForm()
  const [formCondition] = Form.useForm()
  const dispatch = useDispatch<any>()
  const [basic, setBasic] = useState<any>({})

  // rules
  const [rules, setRules] = useState<any>({})
  // 本地存贮 最大最小
  const [curData, setCurData] = useState({
    minValue: 0,
    maxValue: 0,
  })
  const numberRef = useRef({
    minValue: "",
    maxValue: "",
  })
  // 本地缓存数据
  const [local, setLocal] = useState<any>({})
  useEffect(() => {
    setCurData({
      minValue: infoData?.rules?.limitUploadQuantity?.minValue || 0,
      maxValue: infoData?.rules?.limitUploadQuantity?.maxValue || 0,
    })
    if (infoData.id && infoData.id !== undefined) {
      if (!local?.id || local?.id !== infoData?.id) {
        setLocal(infoData)

        numberRef.current.minValue = infoData?.rules?.limitUploadQuantity?.minValue || ""
        numberRef.current.maxValue = infoData?.rules?.limitUploadQuantity?.maxValue || ""
      }
    }
  }, [infoData, local?.id])

  const updateState = (type: string, value: any) => {
    if (infoType === "basicInfo") {
      setBasic({
        ...basic,
        [type]: value,
      })
      dispatch({
        type: UPDATE_BASIC,
        payload: {
          [type]: value,
        },
      })
    }
    if (infoType === "condition") {
      setRules({
        ...rules,
        [type]: value,
      })
      dispatch({
        type: UPDATE_RULE,
        payload: {
          [type]: value,
        },
      })
    }
  }
  const formValidate = () => {
    formBacis.validateFields()
    formCondition.validateFields()
  }
  const reset = () => {
    formBacis.resetFields()
    formCondition.resetFields()
  }
  useImperativeHandle(ref, () => ({
    reset,
    formValidate,
  }))
  if (infoType === "basicInfo") {
    return (
      <div>
        <Form {...layout} form={formBacis}>
          <FormItem
            name="name"
            label="字段名称"
            rules={[
              {
                required: true,
                message: "请输入字段名称",
              },
            ]}
          >
            <Input
              disabled={isInfo}
              maxLength={10}
              placeholder="请输入"
              style={{ width: 400 }}
              defaultValue={infoData?.name}
              className="ipt"
              onChange={(e) => {
                updateState("name", e.target.value)
                formBacis.setFieldValue("name", e.target.value)
              }}
            ></Input>
            <span className="span_color">不超过10个字，支持中文，英文，数字，字母组合</span>
          </FormItem>
          <FormItem label="示例模板">
            <span className={`${isInfo && "not_allowed"}`}>
              <UploadCom
                multiple
                value={infoData?.defaultValue?.fileInfos}
                cb={(value: any, file: any) => {
                  console.log(file, "===")
                  // const fileType = file.name.lastIndexOf('.')
                  // let str = file.name.substring(fileType)
                  updateState("fileInfos", value)
                }}
                name="文件"
                accept=".docx,.pdf,.xls,.xlsx,.ppt,.pptx,.zip,.rar,.7z,.txt,.doc"
                type="file"
              ></UploadCom>
            </span>
          </FormItem>
          <FormItem label="用户权限" className="user-crud">
            <Radio.Group
              disabled={isInfo}
              defaultValue={infoData?.accessType}
              onChange={(e) => {
                updateState("accessType", e.target.value)
              }}
            >
              {radioList.map((item: any) => (
                <Radio key={item.value} value={item.value}>
                  {item.label}
                </Radio>
              ))}
            </Radio.Group>
            <span className="user-crud-span span_color">
              读写:允许用户查看和编辑该字段;只读:用户只能查看该字段;隐藏:用户无法查看该字段
            </span>
          </FormItem>
          {/* <FormItem label='数据取值'>
          <Checkbox>以某字段的数据录入源为准</Checkbox> <br />
          <Checkbox>小程序录入</Checkbox> <br />
          <Checkbox>现场摄像头采集</Checkbox>
          <Select options={options} placeholder='选择摄像头采集字段' style={{width:'200px', marginBottom: '10px'}}></Select> <br />
          <Checkbox>地磅采集</Checkbox>
          <Select options={options} placeholder='选择摄像头采集字段' style={{width:'200px'}}></Select> <br />
          <Checkbox>电脑端手动上传</Checkbox> <br />
          <Checkbox>系统对接</Checkbox>
        </FormItem> */}
          <FormItem label="字段说明">
            <Input
              disabled={isInfo}
              defaultValue={infoData?.comment}
              className="ipt"
              placeholder="请输入"
              onChange={(e) => {
                updateState("comment", e.target.value)
              }}
            ></Input>
            <span className="span_color">
              字段说明:显示在字段输入框之后(一段在输入框下方),用于提示用户输入内容及规则
            </span>
          </FormItem>
          <FormItem label="详细说明" name="haveRequire">
            <Radio.Group
              disabled={isInfo}
              defaultValue={infoData?.rules?.haveRequire}
              onChange={(e) => {
                updateState("haveRequire", e.target.value)
              }}
            >
              {[
                { label: "需要", value: 1 },
                { label: "不需要", value: 0 },
              ].map((item: any) => (
                <Radio key={item.value} value={item.value}>
                  {item.label}
                </Radio>
              ))}
            </Radio.Group>
          </FormItem>
          <FormItem name="requireTitle" label="详细说明标题">
            <Input
              disabled={isInfo}
              defaultValue={infoData?.rules?.requireTitle}
              placeholder="详细说明标题"
              className="ipt"
              onChange={(e) => updateState("requireTitle", e.target.value)}
            ></Input>
          </FormItem>
          <FormItem label="详细说明" name="requireContent">
            <TextArea
              disabled={isInfo}
              defaultValue={infoData?.rules?.requireContent}
              rows={4}
              placeholder="详细说明"
              style={{ width: "400px" }}
              onChange={(e) => updateState("requireContent", e.target.value)}
            />
          </FormItem>
        </Form>
      </div>
    )
  }
  return (
    <div>
      <Form {...layout} form={formCondition}>
        <FormItem label={"限制上传数量"}>
          <span>
            最少:{" "}
            <InputNumber
              disabled={isInfo}
              defaultValue={infoData?.rules?.limitUploadQuantity?.minValue}
              value={numberRef.current.minValue}
              style={{ width: 200, marginLeft: 5 }}
              max={numberRef.current.maxValue}
              placeholder="最少"
              onChange={(e) => {
                numberRef.current.minValue = e
                setCurData({
                  ...curData,
                  minValue: e,
                })
                updateState("limitUploadQuantity", {
                  ...curData,
                  ...numberRef.current,
                  minValue: e,
                })
              }}
            ></InputNumber>
          </span>

          <span style={{ margin: "0 16px" }}>
            {" "}
            最多:{" "}
            <InputNumber
              disabled={isInfo}
              defaultValue={infoData?.rules?.limitUploadQuantity?.maxValue}
              style={{ width: 200 }}
              placeholder="最多"
              min={numberRef.current.minValue}
              value={numberRef.current.maxValue}
              onChange={(e) => {
                numberRef.current.maxValue = e

                setCurData({
                  ...curData,
                  maxValue: e,
                })
                updateState("limitUploadQuantity", {
                  ...curData,
                  ...numberRef.current,
                  maxValue: e,
                })
              }}
            ></InputNumber>{" "}
          </span>
          <span className="span_color">
            支持纯数字, 无小数点。若需要配置相等关系，最大最少配置的数量相等即可
          </span>
        </FormItem>
        <FormItem label={"限制上传体积"}>
          最大：
          <Input
            disabled={isInfo}
            defaultValue={infoData?.rules?.limitUploadVolume?.maxValue}
            style={{ width: 200 }}
            placeholder="最大"
            suffix="mb"
            onChange={(e) => {
              updateState("limitUploadVolume", {
                ...rules.limitUploadVolume,
                maxValue: e,
              })
            }}
          ></Input>
        </FormItem>
        <FormItem label={"允许输入内容"}>
          <Checkbox.Group
            disabled={isInfo}
            defaultValue={infoData?.rules?.limitUploadFileType?.contentType}
            options={checkboxOptions}
            onChange={(e) => {
              updateState("limitUploadFileType", {
                contentType: e,
              })
            }}
          ></Checkbox.Group>
        </FormItem>
        {/* <FormItem label='配置警示文案'>
        <Input placeholder="请输入警示文案内容，不超过20个汉字" maxLength={20} className="ipt"></Input>
        <span>警示文案可不填，按照限制条件的默认规则进行警示</span>
      </FormItem> */}
      </Form>
    </div>
  )
}

export default forwardRef(FileTemplate)
