import { ProColumns } from "@ant-design/pro-table"

// 合同类型
export const contractTypeEnum = [
  {
    value: "PURCHASE",
    label: "采购合同",
  },
  {
    value: "DELEGATION",
    label: "委托协议",
  },
  {
    value: "SUPPLY",
    label: "供货协议",
  },
  {
    value: "PROOF_OPERATION",
    label: "经营证明",
  },
  {
    value: "LOGOUT_APPLY",
    label: "注销申请",
  },
  {
    value: "REVERSE_INVOICE",
    label: "反向开票授权",
  },
]

// 签章类型
export const signTypeEnum = new Map([
  ["SIGN", { text: "签字" }],
  ["SEAL", { text: "签章" }],
  ["SIGN_SEAL", { text: "签字和签章" }],
  ["SIGN_TIME", { text: "签署时间" }],
])
// 签署人类型
export const partTypeListEnum = {
  A: "甲方",
  B: "乙方",
  C: "丙方",
  D: "丁方",
  E: "戊方",
  F: "己方",
  G: "庚方",
}
// 签署人类型
export const partTypeEnum = new Map([
  ["A", { text: "甲方" }],
  ["B", { text: "乙方" }],
  ["C", { text: "丙方" }],
  ["D", { text: "丁方" }],
  ["E", { text: "戊方" }],
  ["F", { text: "己方" }],
  ["G", { text: "庚方" }],
])
// 签章类型
export const typeEnum = new Map([
  [1, { text: "企业" }],
  [0, { text: "个人" }],
])
export const columnsPublic: ProColumns[] = [
  {
    title: "序号",
    readonly: true,
    valueType: "index",
    dataIndex: "index",
  },
  {
    title: "签署人",
    dataIndex: "part",
    valueType: "select",
    valueEnum: partTypeEnum,
    formItemProps: () => {
      return {
        rules: [{ required: true, message: "此项为必填项" }],
      }
    },
  },
  {
    title: "签署方类型",
    dataIndex: "type",
    valueType: "select",
    valueEnum: typeEnum,
    formItemProps: () => {
      return {
        rules: [{ required: true, message: "此项为必填项" }],
      }
    },
  },
  {
    title: "签章类型",
    valueType: "select",
    dataIndex: "signType",
    valueEnum: signTypeEnum,
    formItemProps: () => {
      return {
        rules: [{ required: true, message: "此项为必填项" }],
      }
    },
  },
  {
    title: "页码",
    valueType: "digit",
    dataIndex: "posPage",
    formItemProps: () => {
      return {
        rules: [
          { required: true, message: "此项为必填项" },
          { pattern: /^\d*$/, message: "页码不可为小数" },
        ],
      }
    },
  },
  {
    title: "X坐标",
    valueType: "digit",
    dataIndex: "posX",
    formItemProps: () => {
      return {
        rules: [
          { required: true, message: "此项为必填项" },
          { pattern: /^\d*$/, message: "X坐标不可为小数" },
        ],
      }
    },
  },
  {
    valueType: "digit",
    title: "Y坐标",
    dataIndex: "posY",
    formItemProps: () => {
      return {
        rules: [
          { required: true, message: "此项为必填项" },
          { pattern: /^\d*$/, message: "Y坐标不可为小数" },
        ],
      }
    },
  },
]
