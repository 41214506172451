import { Badge, Tag } from "antd"
import React, { ReactNode, memo } from "react"
import classnames from "classnames"
import "./index.scss"
const defaultProps = {}
export type TagCustomStyleProps = {
  status: "default" | "processing" | "success" | "error" | "warning"
  text: ReactNode
  noBGColor?: boolean
  size?: "normal" | "large"
}
const classPrefix = "tag-custom"
export type TagProps = TagCustomStyleProps & React.HTMLAttributes<HTMLDivElement>
export const TagCustomStyle: React.FC<React.PropsWithChildren<any>> = memo((props) => {
  const { status, text, children, noBGColor, size = "normal" } = props
  return (
    <Tag
      className={classnames(classPrefix, [classPrefix, status].join("-"), {
        [classPrefix + "-no-bg-color"]: noBGColor,
        [classPrefix + "-size-" + size]: true,
      })}
    >
      <Badge color="" text={text || children}></Badge>
    </Tag>
  )
})
TagCustomStyle.displayName = "新之标签组件"
TagCustomStyle.defaultProps = defaultProps
