import React from "react"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import { Card, Space } from "antd"
import Image from "../../../assets/data/video.png"
import "./index.scss"
import Meta from "antd/es/card/Meta"
import { useNavigate } from "react-router"

export const LargeScreen = (props) => {
  const nav = useNavigate()

  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <span>数据大屏</span>
          </Space>
        }
        breadCrumb={[
          { label: "数据统计", path: "" },
          { label: "数据大屏", path: "" },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <DetailPageContent>
        <div className="large-screen">
          <Card
            hoverable
            onClick={() => {
              window.open("/video/surveillance")
              // nav("/video/surveillance")
            }}
          >
            <div>
              <span className="large-text">视频监控大屏</span>
              <img style={{ marginTop: 10 }} alt="example" width={328} height={250} src={Image} />
            </div>
          </Card>
        </div>
      </DetailPageContent>
    </DetailPageContainer>
  )
}

export default LargeScreen
