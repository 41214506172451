/* eslint-disable react-hooks/exhaustive-deps */
import ProTable, { ProColumns } from "@ant-design/pro-table"
import React, { useEffect, useRef, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import { Button, Card, Descriptions, message, Modal, Space, Switch } from "antd"
import "./index.scss"
import { columnsPublic } from "../constant"
import { useRequest } from "ahooks"
import { editContractStatus, getContractInfo } from "../promises"
import { RollbackOutlined } from "@ant-design/icons"
import UsableTissue from "./usableTissue"
import { getOrderUploadByPath } from "../../orderManagement/ProcureOrder/detail/service"
const ContractTemplateDetail = () => {
  const params = useParams<{ id: string }>()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const contractInfoService = useRequest(getContractInfo, {
    manual: true,
    defaultParams: [params.id!],
    refreshDeps: [params.id],
  })
  const editContractStatusService = useRequest(editContractStatus, {
    manual: true,
    onSuccess: () => {
      contractInfoService.run(params.id)
    },
  })
  useEffect(() => {
    contractInfoService.run(params.id)
  }, [])
  const data = contractInfoService?.data?.data
  const onChangeSwitch = (checked: boolean) => {
    editContractStatusService.run({
      id: params.id,
      status: checked,
    })

    // editTissueInfoService.run({
    //   values: { ...data, authStatus: checked ? "true" : "false" },
    //   id: tissueService.id,
    // })
  }
  //查看文件
  const handContract = async () => {
    let params = {
      filePaths: [data?.bookFileId],
    }
    const result: any = await getOrderUploadByPath(params)
    if (result.code == 200) {
      window.open(result?.data?.files[0].downloadUrl, "_blank")
    } else {
      message.warning(result?.message || "查看失败")
    }
  }
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <Button
              onClick={() => history.go(-1)}
              icon={<RollbackOutlined />}
              style={{ color: "#1890FF" }}
            >
              返回
            </Button>
            <span>合同模板详情</span>
          </Space>
        }
        breadCrumb={[
          { label: "合同管理", path: "" },
          { label: "合同模板管理", path: "" },
          { label: "合同模板详情", path: "" },
        ]}
        rightContent={
          <Space size="large" align="center" style={{ whiteSpace: "nowrap" }}>
            <div>模板状态：</div>
            <Switch
              checked={data?.status}
              checkedChildren="有效"
              unCheckedChildren="无效"
              onChange={onChangeSwitch}
            />
            <Button
              type="primary"
              onClick={() =>
                navigate(`/entity/transaction/contractTemplate/${"edit"}/${params.id}`)
              }
            >
              编辑
            </Button>
          </Space>
        }
      ></DetailPageHeader>
      <DetailPageContent>
        <Card title={"基本信息"}>
          <Descriptions column={1}>
            <Descriptions.Item label="合同类型">{data?.typeDesc}</Descriptions.Item>
            <Descriptions.Item label="合同模板" style={{ alignItems: "baseline" }}>
              <Space>
                <div> {data?.name}</div>
                <Button style={{ color: "#1890ff" }} onClick={handContract}>
                  下载模板
                </Button>
              </Space>
            </Descriptions.Item>
            <Descriptions.Item label="可用组织" style={{ padding: "0px" }}>
              <a type="link" onClick={() => setIsModalOpen(true)}>
                查看全部列表
              </a>
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <Card
          className="contract_template_detail"
          title={`签署配置 / ${data?.templateConfig?.signConfigs.length || 0}`}
          extra={
            <Button
              onClick={() => window.open("https://open.esign.cn/tools/seal-position", "_blank")}
            >
              获取文档坐标
            </Button>
          }
        >
          <ProTable
            search={false}
            options={false}
            pagination={false}
            columns={columnsPublic}
            dataSource={data?.templateConfig?.signConfigs}
          ></ProTable>
        </Card>
      </DetailPageContent>
      <Modal
        title="可用组织"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        width={600}
        footer={false}
      >
        <UsableTissue id={params.id} dataSource={data?.organizationVos} />
      </Modal>
    </DetailPageContainer>
  )
}
export default ContractTemplateDetail
