import { Button, Descriptions, Space, Tag, message } from "antd"
import React from "react"
import { connect } from "react-redux"
import ModalForm from "../../../../../../../components/ModalForm"
import {
  PaymentRecordOfflineForm,
  PaymentRecordOfflineFormType,
} from "../../../../../../../components/FormTemplate/paymentRecordOffline"
import { uploadOfflinePayment } from "../../../../../promises"
import { PaymentRecordOfflineFormTwo } from "../../../../../../../components/FormTemplate/paymentRecordOfflineTwo"
import moment from "moment"
import { validatePayments } from "../../utils"

export const offlinePayment = (props) => {
  const {
    context,
    roleListSeller,
    roleListTaxCompany,
    roleListTaxFees,
    useHiddenOpen,
    form,
    contentPayment,
  } = props
  return (
    <>
      <Space>
        <ModalForm<PaymentRecordOfflineFormType>
          onFinish={async (values: any) => {
            console.log(values, "values")
            let _contentPayment =
              contentPayment == "线下付款" ? "付款金额不能为0" : "退款金额不能为0"
            let paymentOne = {}
            let paymentTwo = {}
            let paymentThree = {}
            let _validatePayments = validatePayments(values)
            if (!_validatePayments) {
              message.error(_contentPayment)
              return Promise.reject(new Error(_contentPayment))
            }
            if (
              values.paymentOne &&
              Number(values?.paymentOne?.amount) !== 0 &&
              roleListSeller?.length == 1
            ) {
              paymentOne = {
                ...values.paymentOne,
                payerName: roleListSeller[0]?.payerName,
                payeeName: roleListSeller[0]?.payeeName,
                payOption: roleListSeller[0]?.payOption,
              }
            }
            if (
              values.paymentTwo &&
              Number(values?.paymentTwo?.amount) !== 0 &&
              roleListTaxCompany?.length == 1
            ) {
              paymentTwo = {
                ...values.paymentTwo,
                payerName: roleListTaxCompany[0]?.payerName,
                payeeName: roleListTaxCompany[0]?.payeeName,
                payOption: roleListTaxCompany[0]?.payOption,
              }
            }
            if (
              values.paymentThree &&
              Number(values?.paymentThree?.amount) !== 0 &&
              roleListTaxFees?.length == 1
            ) {
              paymentThree = {
                ...values.paymentThree,
                payerName: roleListTaxFees[0]?.payerName,
                payeeName: roleListTaxFees[0]?.payeeName,
                payOption: roleListTaxFees[0]?.payOption,
              }
            }
            let newValues = [paymentOne, paymentTwo, paymentThree]?.filter((obj) => {
              return Object.keys(obj).length !== 0
            })
            newValues?.forEach(async (ie: any) => {
              await uploadOfflinePayment(context?.data && [context?.data.id], {
                channel: "EP",
                payOption: ie.payOption,
                amount: contentPayment == "线下付款" ? Number(ie?.amount) : Number(-ie?.amount),
                paidTime: moment(ie?.paidTime).format("YYYY-MM-DD 00:00:00"),
                payerName: ie?.payerName,
                payeeName: ie?.payeeName,
                receipts: [ie?.fileId],
              })
            })
            message.success("操作成功")
            setTimeout(() => {
              context?.refresh()
            }, 1000)
          }}
          fieldProps={{
            form: form,
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
          }}
          modalProps={{ width: 650, destroyOnClose: true }}
          title={contentPayment}
          trigger={
            contentPayment == "线下付款" ? (
              <Button
                hidden={useHiddenOpen}
                style={{
                  background: "#1890FF",
                  color: "#fff",
                }}
              >
                {contentPayment}
              </Button>
            ) : (
              <Button hidden={useHiddenOpen} danger>
                {contentPayment}
              </Button>
            )
          }
        >
          <Descriptions size="small" column={1} style={{ paddingLeft: 35, paddingTop: 20 }}>
            <Descriptions.Item label="关联订单">
              <Tag
                color="#f4f7fc"
                style={{ color: "#000", borderColor: "#E9EDF6" }}
                key={context?.data.id}
              >
                {context?.data.id}
              </Tag>
            </Descriptions.Item>
          </Descriptions>

          {roleListSeller?.length == 1 ? (
            <PaymentRecordOfflineForm
              typeTitle="货款"
              filed="paymentOne"
              form={form}
              orders={[
                {
                  id: context?.data.id,
                  sellerName: context?.data.supplier?.name,
                  buyerName: context?.data.buyer?.name,
                },
              ]}
              roleList={roleListSeller}
              contentPayment={contentPayment}
            ></PaymentRecordOfflineForm>
          ) : null}
          {roleListTaxCompany?.length == 1 ? (
            <PaymentRecordOfflineForm
              typeTitle="代扣税费合计"
              filed="paymentTwo"
              form={form}
              orders={[
                {
                  id: context?.data.id,
                  sellerName: context?.data.supplier?.name,
                  buyerName: context?.data.buyer?.name,
                },
              ]}
              roleList={roleListTaxCompany}
              contentPayment={contentPayment}
            ></PaymentRecordOfflineForm>
          ) : null}
          {roleListTaxFees?.length == 1 ? (
            <PaymentRecordOfflineForm
              typeTitle="平台预扣费"
              filed="paymentThree"
              form={form}
              orders={[
                {
                  id: context?.data.id,
                  sellerName: context?.data.supplier?.name,
                  buyerName: context?.data.buyer?.name,
                },
              ]}
              roleList={roleListTaxFees}
              contentPayment={contentPayment}
            ></PaymentRecordOfflineForm>
          ) : null}
        </ModalForm>
      </Space>
    </>
  )
}

export default offlinePayment
