import React, { useState, useEffect } from "react"

import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import { Button } from "antd"
import "./index.scss"

export default function PdfView(props) {
  const { imageData, flag, preview = false } = props
  const handleDownload = (record: any) => {
    const link = document.createElement("a")
    link.href = record.original
    link.download = ""
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  return (
    <>
      {flag ? (
        <ImageGallery
          items={imageData}
          renderItem={(item) => (
            <div className="custom-image-wrapper">
              {/* 使用ImageGallery原有的渲染方式 */}
              <img src={item.original} width={1000} height={700} />
              {preview ? null : (
                <Button
                  onClick={() => handleDownload(item)}
                  style={{
                    position: "fixed",
                    left: "0px",
                    top: "0px",
                  }}
                >
                  下载
                </Button>
              )}
            </div>
          )}
        />
      ) : null}
    </>
  )
}
