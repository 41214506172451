import React, { useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import "./App.scss"
import "./page.scss"
import Util from "./services/util"
// import "./services/axios"
import "dayjs/locale/zh-cn"
import RoutesHome from "./routers/index"
const App = () => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect((): void => {
    const util = Util.getInstance()
    util.overtimeLogOut()
    if (!util.isLogin()) {
      // navigate('/login');
    } else {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      navigate(location.pathname)
    }
  }, []) // eslint-disable-line

  return (
    // <ConfigProvider locale={locale}>
    <div className="App">
      <RoutesHome />
    </div>
    // </ConfigProvider>
  )
}

export default App
