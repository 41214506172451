import React, { useEffect, useContext } from "react"
import { Alert } from "antd"
import { getOrderCheck } from "../../service"
import "./index.scss"
import { useParams } from "react-router"
import { OrderDetailContext } from "../../useOrderDetail"
// 风险等级
const Level = {
  0: {
    msg: "较低风险",
    level: "success",
  },
  1: {
    msg: "严重风险",
    level: "error",
  },
}
/**
 * 风险等级
 * @returns
 */
const OrderStatus = () => {
  const context = useContext(OrderDetailContext)
  const [info, setInfo] = React.useState<{ descriptions: string; level: number }[]>([])
  /**
   * 获取风险信息
   */
  const getInfo = async () => {
    if (!context?.logisticsDetailService?.data?.id) return
    const res = await getOrderCheck(context?.logisticsDetailService?.data?.id || "")
    if (res.code == 200 && res.data?.length) {
      setInfo(res.data)
    }
  }
  useEffect(() => {
    getInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.logisticsDetailService?.data?.id])
  return (
    <ul className="level-info">
      {info.map((i) => {
        return (
          <li key={i.descriptions}>
            <Alert message={Level[i.level].msg} type={Level[i.level].level} showIcon />
            <p>{i.descriptions}</p>
          </li>
        )
      })}
    </ul>
  )
}

export default OrderStatus
