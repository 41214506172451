import { OP_EVIDENCE } from "@/api/config/servicePort"
import UtilOp from "../../../../../services/serviceOp"
const utilOp = UtilOp.getInstance()
type get_evidence_list = {
  name?: string
  id?: string | number
  attribute?: string | number
  contactName?: string
  contactPhone?: string | number
  startTime?: string | number
  endTime?: string | number
  size: string | number
  current: string | number
}

type add_evidence = {
  name: string
  address?: string
  contactName: string
  contactPhone: string
  attribute: number
  permission: string
  serverDateLimit: number
  remark?: string
  logo?: string
  businessLicense?: string
  socialCreditCode?: string
  establishmentDate?: string
  industry?: string
  introduction?: string
  id?: string
}
const getEvidenceList: (values: get_evidence_list) => Promise<any> = (
  values: get_evidence_list
) => {
  return utilOp.httpGet(`${OP_EVIDENCE}/op/evidence`, values).then((res) => res)
}

const addEvidence: (values: add_evidence) => Promise<any> = (values: add_evidence) => {
  return utilOp
    .httpPost(`${OP_EVIDENCE}/op/evidence`, values)
    .then((res) => res)
    .catch((msg) => msg.response.data)
}

// 获取客户详情
const getEvidenceInfo: (id: string) => Promise<any> = (id: string) => {
  return utilOp.httpGet(`${OP_EVIDENCE}/op/evidence/${id}`).then((res) => res)
}
// 根据图片id 获取图片的地址
const getImageUrl = (params: any) => {
  return utilOp
    .httpGetDomain(`${OP_EVIDENCE}/files/ossUrlZoom`, params)
    .then((res) => res)
    .catch((err) => err)
}

const getEvidenceTemplateList = (values: any) => {
  return utilOp.httpGet(`${OP_EVIDENCE}/op/evidence`, values).then((res) => res)
}

// 获取所有的村正场景
const getAllEvi = (params: any) => {
  return utilOp
    .httpGet(`${OP_EVIDENCE}/secure-evidence-templates`, params)
    .then((res) => res)
    .catch((err) => err)
}
// 获取存证详情
const getEvidenInfo = (id: any) => {
  return utilOp
    .httpGet(`${OP_EVIDENCE}/secure-evidence-templates/${id}`)
    .then((res) => res)
    .catch((err) => err)
}
// 客户详情-新增或者编辑存证
const editEvidence = (params: any) => {
  return utilOp
    .httpPost(`${OP_EVIDENCE}/op/evidence`, params)
    .then((res) => res)
    .catch((err) => err)
}

// 获取客户-> 模板-> 详情
const getManageTempalteInfo = (id: any) => {
  return utilOp.httpGet(`${OP_EVIDENCE}/op/evidence/${id}`).then((res) => {
    return res
  })
}
export {
  getEvidenceList,
  addEvidence,
  getEvidenceInfo,
  getImageUrl,
  getEvidenceTemplateList,
  getAllEvi,
  getEvidenInfo,
  editEvidence,
  getManageTempalteInfo,
}
