/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
import ProDescriptions, { ProDescriptionsItemProps } from "@ant-design/pro-descriptions"
import { Card } from "antd"
import React, { memo } from "react"
import { useNavigate } from "react-router-dom"

import { prodListJoinIdEnum } from "../../../../PlatformSetup/TaxDistrict/constant"
import "./index.scss"
export const MateriaUpload = memo((props: any) => {
  const { list } = props
  const navigate = useNavigate()
  const headerDescColumns: ProDescriptionsItemProps<Tissue.Info>[] = [
    {
      title: "办理地行政区划分",
      key: "text",
      dataIndex: "text",
      ellipsis: true,
      render(value, record) {
        return <span>{record?.handleRegion?.name}</span>
      },
    },
    {
      title: "创建无名称个体户",
      key: "state2",
      dataIndex: "state2",
      render(value, record) {
        return <span>{record?.needName ? "需要名称" : "不需要名称"}</span>
      },
    },
    {
      title: "企业/个体户名称",
      key: "name",
      dataIndex: "name",
      copyable: true,
    },
    {
      title: "备注1",
      key: "name1",
      dataIndex: "name1",
      copyable: true,
    },
    {
      title: "备注2",
      key: "name2",
      dataIndex: "name2",
      copyable: true,
    },
    // {
    //   title: "店铺网址",
    //   key: "money",
    //   dataIndex: "money",
    //   copyable: true,
    //   render(value, record) {
    //     return (
    //       <a
    //         href={`/agencyBusiness/storeDetail/${record?.siteId}`}
    //       >{`http://${location.host}/agencyBusiness/storeDetail/${record?.siteId}`}</a>
    //     )
    //   },
    // },
  ]
  return (
    <Card title="名称申报" className="materiaUpload">
      <ProDescriptions column={4} columns={headerDescColumns} dataSource={list}>
        <ProDescriptions.Item dataIndex="money" label="店铺网址" copyable={true}>
          {prodListJoinIdEnum[list?.handleRegion?.flowConfig?.siteShowType] + `${list?.siteId}`}
          {/* {`http://${location.host}/agencyBusiness/storeDetail/${list?.siteId}`} */}
        </ProDescriptions.Item>
      </ProDescriptions>
    </Card>
  )
})

export default MateriaUpload
