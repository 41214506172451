import { Button, Space, Collapse, DatePicker, Form, Input } from "antd"
import React, { memo, forwardRef, useImperativeHandle, useState, useEffect } from "react"
import { FieldType, FromProps } from "../../interface"
import { inputDefaultRules } from "@src/utils/RegExp"
import "./index.scss"
const { Panel } = Collapse
type ExpandIconPosition = "start" | "end"

export const RateSetting = (props: FromProps) => {
  const { form } = props

  return (
    <div className="rateSetting-component">
      <Form.Item<FieldType>
        labelCol={{ span: 8 }}
        label="增值税率"
        name="valueAddTaxRate"
        rules={[
          {
            required: true,
            message: "请输入增值税率",
          },
          {
            validator: async (rule: any, value: string) =>
              inputDefaultRules(value, "增值税率", 50, true), //特殊字符
          },
        ]}
      >
        <Input placeholder="请输入增值税率" style={{ maxWidth: "180px" }} suffix="%" />
      </Form.Item>
      <Form.Item<FieldType>
        labelCol={{ span: 8 }}
        label="代扣增值税率"
        name="valueAddTaxCollectionRate"
        className="RateSetting_Collapse_time"
        rules={[
          {
            required: true,
            message: "请输入代扣增值税率",
          },
          {
            validator: async (rule: any, value: string) =>
              inputDefaultRules(value, "代扣增值税率", 50, true), //特殊字符
          },
        ]}
      >
        <Input placeholder="请输入代扣增值税率" style={{ maxWidth: "180px" }} suffix="%" />
      </Form.Item>
      <Form.Item<FieldType>
        labelCol={{ span: 8 }}
        label="平台预扣费率"
        name="withholdTaxRate"
        rules={[
          {
            required: true,
            message: "请输入平台预扣费率",
          },
          {
            validator: async (rule: any, value: string) =>
              inputDefaultRules(value, "平台预扣费率", 50, true), //特殊字符
          },
        ]}
      >
        <Input placeholder="请输入平台预扣费率" style={{ maxWidth: "180px" }} suffix="%" />
      </Form.Item>
      <Form.Item<FieldType>
        className="incomeTaxRateReverse"
        label="（反向开票）预扣个税及附加费率"
        name="incomeTaxRateReverse"
        rules={[
          {
            required: true,
            message: "预扣个税及附加费率",
          },
          {
            validator: async (rule: any, value: string) =>
              inputDefaultRules(value, "预扣个税及附加费率", 50, true), //特殊字符
          },
        ]}
      >
        <Input placeholder="请输入预扣个税及附加费率" style={{ minWidth: "210px" }} suffix="%" />
      </Form.Item>
    </div>
  )
}

export default RateSetting
