/**
 * @author 阮东辉
 * @time 2023/3/14
 */
import React, { Suspense, useEffect } from "react"
import RouteData from "./routeData"

const Index = () => {
  return (
    <Suspense fallback={<h2>...loading</h2>}>
      {/* {routeData} */}
      <RouteData></RouteData>
    </Suspense>
  )
}

export default Index
