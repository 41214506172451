/* eslint-disable prettier/prettier */
import LeftNavbar from "@src/components/LeftNavbar"
import TopNavbar from "@src/components/TopNavbar"
import React, { memo, useState } from "react"
import { Outlet } from "react-router-dom"
// import "./index.scss"
import { useSelector } from "react-redux"

const Logistics = (props) => {
  const roleList =
    useSelector((state: any) => state?.meunList.filter((ie: any) => ie.name == "物流管理")) || []
  return (
    <div className="platformSetup-container">
      <TopNavbar config={{ value: "logistics" }} />
      <div className="platformSetup-container-content">
        {<LeftNavbar lists={roleList[0]?.children} />}
        <Outlet />
      </div>
    </div>
  )
}
export default Logistics
