/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-key */
import React, { useEffect, useMemo, useState } from "react"
import ImageHeader from "../../../assets/data/dataHeader.png"
import dataPlate from "../../../assets/data/1/left-1.png"
import dataPlateIcon from "../../../assets/data/1/1.png"
import dataPlate1 from "../../../assets/data//1/left-2.png"
import dataPlateIcon1 from "../../../assets/data/1/2.png"
import dataPlate2 from "../../../assets/data/1/left-3.png"
import dataPlateIcon2 from "../../../assets/data/1/3.png"
import dataFrame from "../../../assets/data/1/map.png"
import dataTop from "../../../assets/data/1/right-1.png"
import dataTopIcon from "../../../assets/data/1/4.png"
import dataTop1 from "../../../assets/data/1/right-2.png"
import dataTopIcon1 from "../../../assets/data/1/5.png"

import dataBottom from "../../../assets/data/1/bottom-1.png"
import dataTopIcon2 from "../../../assets/data/1/5.png"

import { formatDateTime, contractStatusColors, handNewData } from "../constant"
import LineCharts from "./lineCharts"
import CameraCharts from "./cameraCharts"
import ToroidalCharts from "./toroidalCharts"
import CityMapChart from "./mapCharts"
import ReactPlayer from "react-player"
import { getDeviceLargeData, getIotLiveBroadcast, getIotLiveBroadcastOff } from "../promises"
import VideoPlayer from "@src/components/Aliplayer/aliplayer"

import "./index.scss"

export const VideoSurveillance = (props) => {
  const [currentDateTime, setCurrentDateTime] = useState(new Date())
  const [activeTab, setActiveTab] = useState<any>("tab1")
  const [closeNewDeviceCode, setCloseNewDeviceCode] = useState<any>([])
  const [deviceList, setDeviceList] = useState<any>([])
  const [liveBroadcast, setLiveBroadcast] = useState<any>([])
  const [isFirstTime, setIsFirstTime] = useState(true)

  const tick = () => {
    setCurrentDateTime(new Date())
  }
  const handDetails = async () => {
    const result: any = await getDeviceLargeData({})
    if (result.code == 200) {
      setDeviceList(result.data)
      let _newDeviceCode: any = []
      result?.data?.onlineDevices?.forEach((item: any) => {
        _newDeviceCode.push(item.deviceCode)
      })
      setCloseNewDeviceCode(_newDeviceCode)
      handDeviceUrl(_newDeviceCode, result.data.onlineDevices, 0)
    }
  }
  //获取直播 url
  const handDeviceUrl = async (newDeviceCode?, data?, count?) => {
    let _liveAddressFlag: any = null
    let minDelay = Infinity
    let minDelayIndex = -1
    let allValuesExist = false
    let _mergedArray: any = []
    const result = await getIotLiveBroadcast({ deviceCodes: newDeviceCode.join(",") })
    if (result.code == 200) {
      const mergedArray = data?.reduce((acc, obj1) => {
        const matchingObj = result?.data?.find((obj2) => obj2?.deviceCode === obj1?.deviceCode)
        if (matchingObj) {
          acc.push({ ...obj1, ...matchingObj })
        }
        return acc
      }, [])
      if (count == 3) {
        _mergedArray = mergedArray?.filter((ie) => ie?.liveAddress)
      } else {
        _mergedArray = mergedArray
      }
      allValuesExist = _mergedArray?.every(
        (obj) => obj.liveAddress !== null && obj.liveAddress !== undefined
      )
      console.log(_mergedArray, "_mergedArray")
      console.log(allValuesExist, "allValuesExist")
      _mergedArray?.forEach(async (item, index) => {
        if (allValuesExist && item) {
          _liveAddressFlag = false
          return new Promise<void>((resolve, reject) => {
            const _newData = handNewData(item.expiredTime) - 10000
            const delay = Math.max(_newData, 0)
            console.log(delay, "delay")
            // 更新最小延时和对应的索引
            if (delay < minDelay) {
              minDelay = delay
              minDelayIndex = index
            }
          })
        } else {
          _liveAddressFlag = true
        }
      })

      setLiveBroadcast(_mergedArray)
      if (minDelayIndex !== -1) {
        await new Promise((resolve) => setTimeout(resolve, minDelay))
        handDeviceUrl(newDeviceCode, data, 0)
      }
      if (_liveAddressFlag && count < 3) {
        await new Promise((resolve) => setTimeout(resolve, 5000))
        handDeviceUrl(newDeviceCode, data, count + 1)
      }
    }
  }

  const handOnlineDevices = useMemo(() => {
    return (
      <>
        {liveBroadcast?.map((item, index) => {
          return (
            <div className="video-footer-player-delta">
              <VideoPlayer videoUrl={item.liveAddress} index={index} height={150} />
              <span className="video-footer-player-delta-span">
                {item.city + item.district + "-" + item.area}
              </span>
            </div>
          )
        })}
      </>
    )
  }, [liveBroadcast])
  useEffect(() => {
    // 在第一次进入页面时触发函数
    if (isFirstTime) {
      // 触发播放函数
      handDetails()
      setIsFirstTime(false)
    }
    // 每隔30分钟执行一次播放操作
    const intervalId = setInterval(
      () => {
        handDetails()
      },
      30 * 60 * 1000
    )
    return () => clearInterval(intervalId)
  }, [isFirstTime])

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000)
    return () => clearInterval(timerID)
  }, [])
  // 处理标签点击事件
  const handleTabClick = (tabName) => {
    setActiveTab(tabName) // 更新选中的标签
  }
  useEffect(() => {
    const handleUnload = async (event) => {
      //关闭浏览器 清除直播
      await getIotLiveBroadcastOff({ deviceCodes: closeNewDeviceCode.join(",") })
    }
    window.addEventListener("unload", handleUnload)
    return () => {
      window.removeEventListener("unload", handleUnload)
    }
  }, [])
  return (
    <div className="video-surveillance">
      <div className="video-header">
        <div className="video-header-time">{formatDateTime(currentDateTime)}</div>
        <span></span>
        <img src={ImageHeader} alt="" />
        <span></span>
      </div>
      <div className="video-content">
        <div className="video-content-left">
          <div className="video-content-left-up">
            <img src={dataPlate} alt="" />
            <div className="video-icon">
              <img src={dataPlateIcon} alt="" />
              <span>基地数据</span>
            </div>
            <div className="video-content-left-up-count">
              <div>
                <p>基地数量：</p>
                <p>{deviceList?.orgCount} 个</p>
              </div>
              <div>
                <p>基地区域数量：</p>
                <p>{deviceList?.areaCount} 个</p>
              </div>
            </div>
          </div>
          <div className="video-content-left-the">
            <img src={dataPlate1} alt="" />
            <div className="video-icon">
              <img src={dataPlateIcon1} alt="" />
              <span>摄像头数量</span>
            </div>
            <div className="video-content-left-the-allCount">
              {deviceList?.cameraCount} <span>个</span>
            </div>
            <div className="video-content-left-the-count">
              <div>
                <p>在线：</p>
                <p>{deviceList?.cameraOnlineCount} 个</p>
              </div>
              <div>
                <p>离线：</p>
                <p>{deviceList?.cameraOfflineCount} 个</p>
              </div>
            </div>
          </div>
          <div className="video-content-left-under">
            <div className="video-icon">
              <img src={dataPlateIcon2} alt="" />
              <span>摄像头在线趋势</span>
            </div>
            <img src={dataPlate2} alt="" />
            <div className="video-content-left-under-text">
              <p
                style={{ marginRight: 16, color: activeTab == "tab1" ? "#fff" : "" }}
                onClick={() => handleTabClick("tab1")}
              >
                基地
              </p>
              <p
                style={{ color: activeTab == "tab2" ? "#fff" : "" }}
                onClick={() => handleTabClick("tab2")}
              >
                摄像头
              </p>
            </div>
            <div className="video-content-left-under-charts">
              {activeTab == "tab1" ? (
                <LineCharts list={deviceList?.lines} />
              ) : (
                <CameraCharts list={deviceList?.lines} />
              )}
            </div>
          </div>
        </div>
        <div className="video-content-context">
          <img src={dataFrame} alt="" />
          <div className="video-icon">
            <span>基地摄像头部署地图</span>
          </div>
          <div className="video-content-context-map">
            {<CityMapChart markersData={deviceList?.devices} />}
          </div>
        </div>
        <div className="video-content-right">
          <div className="video-content-right-up">
            <img src={dataTop} alt="" />
            <div className="video-icon">
              <img src={dataTopIcon} alt="" />
              <span>基地部署排行</span>
            </div>
            <div className="video-content-right-up-city">
              <div className="video-content-right-up-city-downtown">
                <p style={{ marginLeft: 60 }}>市区</p>
                <p>数量</p>
              </div>
              <div className="video-content-right-up-city-list">
                {deviceList?.tops?.slice(0, 7).map((item, index) => {
                  return (
                    <div key={index} className="video-content-right-up-city-list-ranking">
                      <span
                        style={{
                          width: 30,
                          color: [4, 5, 6, 7].includes(item.sort) ? "rgba(0, 163, 255, 1)" : "#fff",
                          background: [4, 5, 6, 7].includes(item.sort)
                            ? "rgba(0, 102, 175, 0.1)"
                            : contractStatusColors[index]?.color,
                          border: [4, 5, 6, 7].includes(item.sort)
                            ? "1px solid rgba(0, 163, 255, 0.7)"
                            : `1px solid ${contractStatusColors[index]?.border}`,
                          textAlign: "center",
                        }}
                      >
                        {item.sort}
                      </span>

                      <span style={{ width: "70%" }}>{item.name}</span>
                      <span style={{ marginRight: 10 }}> {item.count}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="video-content-right-under">
            <img src={dataTop1} alt="" />
            <div className="video-icon">
              <img src={dataTopIcon1} alt="" />
              <span>厂内部署位置</span>
            </div>
            <div className="video-content-right-under-charts">
              <ToroidalCharts list={deviceList?.percents} />
            </div>
          </div>
        </div>
      </div>
      <div className="video-footer">
        <img src={dataBottom} alt="" />
        <div className="video-icon">
          <img src={dataTopIcon2} alt="" />
          <span>在线监控</span>
        </div>
        <div className="video-footer-player">{handOnlineDevices}</div>
      </div>
    </div>
  )
}

export default VideoSurveillance
