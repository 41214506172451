/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react"

const AliplayerComponent = ({ videoUrl, index, height = 500 }) => {
  const playerRef = useRef(null)

  useEffect(() => {
    // @ts-ignore
    const aliplayer = new Aliplayer(
      {
        id: `J_prismPlayer${index}`,
        isLive: true, // 是否为直播播放。
        autoplay: true,
        source: videoUrl,
        vid: "1ef18765ce644f2282b9a6bce30b2394",
        playauth: "your_playauth_value_here", // 添加 playauth 属性
      },
      playerRef.current
    )

    return () => {
      // 清理资源，例如停止播放视频等
      aliplayer.dispose()
    }
  }, [videoUrl])

  return (
    <div
      ref={playerRef}
      id={`J_prismPlayer${index}`}
      style={{ width: "100%", height: height }}
    ></div>
  )
}

export default AliplayerComponent
