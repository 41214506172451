import React, { useState } from "react"
import "./index.scss"
import classNames from "classnames"

interface ContarinerP {
  title: string
  filed: string
  children: React.ReactNode
  onChange: Function
}
const Contariner = (props: ContarinerP) => {
  const filterList = [
    { label: "近7日", value: 7 },
    { label: "近30日", value: 30 },
    { label: "近90日", value: 90 },
  ]
  const { title, children, onChange, filed } = props
  const [filter, setFilter] = useState(0)

  const handleChange = (value: number, index) => {
    onChange(filed, value)
    setFilter(index)
  }
  return (
    <div className="wrapper echarts-contain">
      <div>
        <div className="header">
          <div className="title">{title}</div>
          <div className="filter">
            {filterList.map((item: any, index: number) => {
              return (
                <div
                  className={classNames({
                    checked: filter == index,
                  })}
                  key={item.value}
                  onClick={() => handleChange(item.value, index)}
                >
                  {item.label}
                </div>
              )
            })}
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}
export default Contariner
