/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { Button, Space, Tabs, TabPaneProps } from "antd"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import DetailModule from "./DetailModule" //组件
import DetailModuleCust from "./DetailModuleCust" //模块
import DetailModulePoint from "./DetailModulePoint" //功能点
import DetailCharacters from "./DetailCharacters" //角色
import { getApplicationsDetail } from "./promises"
import { useParams, useNavigate } from "react-router-dom"
import { RollbackOutlined } from "@ant-design/icons"

import "./index.scss"

const PlatformSetupApplyDetail = () => {
  const navigate = useNavigate()

  const searchParams = useParams()
  const [info, setInfo] = useState<any>({})
  const tabs: (TabPaneProps & { key?: React.Key })[] = [
    { tab: "组件", children: <DetailModule info={info}></DetailModule>, key: 1 },
    { tab: "角色", children: <DetailCharacters info={info}></DetailCharacters>, key: 2 },
    // { tab: "模块", children: <DetailModuleCust> </DetailModuleCust>, key: 3 },
    { tab: "功能点", children: <DetailModulePoint info={info}></DetailModulePoint>, key: 4 },
  ]
  const getInfo = async (id: any) => {
    if (!id) return
    const res = await getApplicationsDetail(id)
    if (res?.code === 200) {
      setInfo(res?.data)
    }
  }
  useEffect(() => {
    getInfo(searchParams?.id)
  }, [])
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <div>
            <Space>
              <Button
                onClick={() => navigate(`/platformSetup/apply`)}
                icon={<RollbackOutlined />}
                style={{ color: "#1890FF" }}
              >
                返回
              </Button>
              <span style={{ paddingLeft: 4 }}>{searchParams?.type}</span>
            </Space>
          </div>
        }
        breadCrumb={[
          { label: "平台设置", path: "" },
          { label: "应用管理", path: "" },
          { label: "详情", path: "" },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <Tabs defaultActiveKey="1">
        {tabs.map(({ children, ...props }) => (
          <Tabs.TabPane key={props.key} {...props}>
            <DetailPageContent>
              {/* <div className="PlatformSetupApplyDetail_Content">
                <div className="PlatformSetupApplyDetail_Content_basie">{children}</div>
              </div> */}
              {children}
            </DetailPageContent>
          </Tabs.TabPane>
        ))}
      </Tabs>
    </DetailPageContainer>
  )
}
export default PlatformSetupApplyDetail
