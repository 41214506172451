import Decimal from "decimal.js"

export default [
  {
    title: "序号",
    dataIndex: "id",
    fixed: "left",
  },
  {
    title: "商品分类",
    dataIndex: "itemProjectName",
  },
  {
    title: "规格型号",
    dataIndex: "itemSpecification",
  },
  {
    title: "数量",
    dataIndex: "itemQuantity",
  },
  {
    title: "不含税金额",
    dataIndex: "amountWithoutTax",
  },
  {
    title: "税率",
    dataIndex: "taxRate",
    render: (_) => {
      return (Number(new Decimal(_).mul(new Decimal("100"))) || 0) + "%"
    },
  },
  {
    title: "增值税",
    dataIndex: "taxAmount",
  },
  {
    title: "价税合计",
    dataIndex: "amountWithTax",
  },
]
