/* eslint-disable no-debugger */
import React, { useEffect, useState } from "react"
import _ from "lodash"
import Echarts from "./components/charts/index"
import CardInfo from "./components/cardInfo/card"
import Container from "./components/ecahrtsContainer"
import Segemtation from "./components/segmentation"
import { barOption, maxLineOption, lineOption, pieOptions, minLine } from "./options"
import { getBoardData } from "./promises"
import { transformOrgTrend, transformCategory, transformOrder } from "./content"
import { transformDateType } from "../../../utils/formatNumber"
import "./index.scss"
import { options } from "numeral"

const Index = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [orgInfoData, setOriInfoData] = useState<any>({
    info: {},
    pieOptions: pieOptions,
  })
  const [frightTrendData, setFrightTrendData] = useState<any>({
    info: {
      type: "line",
      title: "再生资源流通总量（吨）",
      value: "0",
      changeValue: "0",
    },
    minLine: minLine,
  })
  const [orderInfoData, setOrderInfoData] = useState<any>({
    info: {
      type: "line",
      title: "交易金额（元）",
      value: "0",
      changeValue: "0",
    },
    minLine: minLine,
  })
  const [orgTrendData, setOrgTrendData] = useState<any>(maxLineOption)
  const [orderTrendData, setOrderTrendData] = useState<any>(lineOption)
  const [freightCategoryTrend, setFreightCategoryTrend] = useState<any>(barOption)

  const [changeData, setChangeData] = useState<any>({
    orgTrend: [],
    orderTrend: [],
    freightCategory: [],
  })

  const [flag, setFlag] = useState(true)

  const init = async () => {
    const res1 = await getBoardData({
      codes:
        "orgInfo,frightTrend,orderTrend,frightInfo,freightCategoryTrendWeek,freightCategoryTrendMonth,freightCategoryTrendQuarter",
    })
    const res2 = await getBoardData({
      codes: "orgTrend,orderInfo,",
    })
    let _changeData = _.cloneDeep(changeData)
    if (res1.code == 200 && res2.code == 200) {
      const {
        orgInfo,
        frightTrend,
        orderTrend,
        frightInfo,
        freightCategoryTrendWeek,
        freightCategoryTrendMonth,
        freightCategoryTrendQuarter,
      } = res1.data
      const { orgTrend, orderInfo } = res2.data
      // 组织信息(累计入驻单位)
      if (orgInfo[0]) {
        let _data: any = {
          type: "pie",
          title: "累计入驻单位（家）",
          value: orgInfo[0]?.total,
          changeValue: orgInfo[0]?.day_num,
        }
        const _options: any = _.cloneDeep(orgInfoData?.pieOptions)
        _options.series.data = [
          { value: Number(orgInfo[0]?.individual_num), name: "自然人" },
          { value: Number(orgInfo[0]?.enterprise_num), name: "企业" },
          { value: Number(orgInfo[0]?.individual_business_num), name: "个体户" },
          { value: Number(orgInfo[0]?.other_num), name: "其他" },
        ]
        setOriInfoData({ info: _data, pieOptions: _options })
        // 再生资源流通总量
      }
      // 资源流通总量
      if (frightTrend?.length) {
        let _data: any = {
          type: "line",
          title: "再生资源流通总量（吨）",
          value: transformDateType(frightInfo[0]?.total_amount, {
            thousands: false,
            bit_number: "0",
          }),
          changeValue: transformDateType(frightInfo[0]?.day_amount, {
            thousands: false,
            bit_number: "0",
          }),
        }
        const _options: any = _.cloneDeep(frightTrendData?.minLine)
        const _dataList = filterData(frightTrend, 7)
        _options.xAxis.data = _dataList.map((item) => item.date)
        _options.series.data = _dataList.map((item) => Number(item.amount))
        setFrightTrendData({ info: _data, minLine: _options })
      }
      // 交易金额 & 交易金额走势
      if (orderTrend?.length) {
        let _data: any = {
          type: "line",
          title: "交易金额（元）",
          value:
            transformDateType(orderInfo[0]?.total_amount, { thousands: true, bit_number: 2 }) ||
            "0",
          changeValue:
            transformDateType(orderInfo[0]?.day_amount, {
              thousands: true,
              bit_number: 2,
            }) || "0",
        }
        // 交易金额
        let _options: any = _.cloneDeep(orderInfoData?.minLine)
        const _dataList = filterData(orderTrend, 7)
        _changeData.orderTrend = orderTrend
        _options.xAxis.data = _dataList.map((item) => item.date)
        _options.series.data = _dataList.map((item) => Number(item.amount))
        setOrderInfoData({ info: _data, minLine: _options })
        // 交易金额走势
        let _optionsOrderTrend: any = _.cloneDeep(orderTrendData)
        _optionsOrderTrend = transformOrder(_optionsOrderTrend, _dataList)
        setOrderTrendData(_optionsOrderTrend)
      }
      // 入驻新增趋势
      if (orgTrend?.length) {
        let _options: any = _.cloneDeep(orgTrendData)
        const _dataList: any = filterData(orgTrend, 7)
        _changeData.orgTrend = orgTrend
        _options = transformOrgTrend(_options, _dataList)
        setOrgTrendData(_options)
      }
      // 再生资源分类
      if (freightCategoryTrendWeek?.length) {
        let _options = _.cloneDeep(freightCategoryTrend)
        const _dataList = freightCategoryTrendWeek
        _changeData.freightCategory = [
          freightCategoryTrendWeek,
          freightCategoryTrendMonth,
          freightCategoryTrendQuarter,
        ]
        _options = transformCategory(_options, _dataList)
        setFreightCategoryTrend(_options)
      }
      setChangeData(_changeData)
      setLoading(false)
    }
  }
  // 数据截取
  const filterData = (list: any, type: number) => {
    let _list = _.cloneDeep(list)
    if (_list.length < type) return _list
    else return _list.slice(-type)
  }
  // 日期筛选
  const onChange = (filed: string, _length: number) => {
    let _options: any = {},
      _dataList: any = []
    if (filed == "orgTrend") {
      _dataList = filterData(changeData[filed], _length)
      _options = transformOrgTrend(orgTrendData, _dataList)
      setOrgTrendData(_options)
    } else if (filed == "orderTrend") {
      _dataList = filterData(changeData[filed], _length)
      _options = transformOrder(orderTrendData, _dataList)
      setOrderTrendData(_options)
    } else {
      _dataList = changeData.freightCategory[_length == 7 ? 0 : _length == 30 ? 1 : 2]
      _options = transformCategory(freightCategoryTrend, _dataList)
      setFreightCategoryTrend(_options)
    }
    setFlag(!_.cloneDeep(flag))
  }
  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      {!loading && (
        <div className="wrapper" style={{ background: "#f0f2f5" }}>
          <div className="cardList">
            <CardInfo {...orgInfoData.info}>
              <Echarts options={orgInfoData?.pieOptions} type="min" />
            </CardInfo>
            <Segemtation />
            <CardInfo {...frightTrendData.info}>
              <Echarts options={frightTrendData?.minLine} type="min" />
            </CardInfo>
            <Segemtation />
            <CardInfo {...orderInfoData.info}>
              <Echarts options={orderInfoData.minLine} type="min" />
            </CardInfo>
          </div>
          <div className="lineEcharts">
            <Container title="入驻单位新增趋势" filed="orgTrend" onChange={onChange}>
              <Echarts options={orgTrendData} type="normal" />
            </Container>
            <Segemtation value={32} />
            <Container title="交易金额走势" filed="orderTrend" onChange={onChange}>
              <Echarts options={orderTrendData} type="normal" />
            </Container>
          </div>
          <div className="barEcharts">
            <Container title="再生资源分类汇总" filed="freightCategory" onChange={onChange}>
              <Echarts options={freightCategoryTrend} flag={flag} type="normal" />
            </Container>
          </div>
        </div>
      )}
    </>
  )
}
export default Index
