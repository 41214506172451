import { CreditCardOutlined, LockOutlined, MobileOutlined } from "@ant-design/icons"
import {
  ModalForm,
  ProFormCaptcha,
  ProFormInstance,
  ProFormSelect,
  ProFormText,
} from "@ant-design/pro-form"
import classnames from "classnames"
import React, { useRef, useState } from "react"
import { RexSocialCreditCode } from "../../../../../constant/RegExp"
import "../index.scss"

import { AuthenticationLogin } from "../promise"
import SecondaryAuthentication from "../QRCodeSecondaryAuthentication"

interface Props {
  onCancel: () => void
  visible: boolean
  invoiceCode: any
  nsrsbh: string
}

const QuanDianModalLogin: React.FC<Props> = (props) => {
  const { onCancel, visible, invoiceCode, nsrsbh } = props
  const [visibleSecondaryAuthentication, setVisibleSecondaryAuthentication] =
    useState<boolean>(false)
  const captchaRef = useRef<null | undefined>()
  const formRef = useRef<ProFormInstance>()
  return (
    <>
      <ModalForm
        title="企业账号登录"
        visible={visible}
        modalProps={{
          className: classnames("quanDianModal"),
          onCancel: () => onCancel(),
        }}
        style={{ padding: "0px 16px" }}
        autocomplete="on"
        formRef={formRef}
        onFinish={async () => {
          const response = await AuthenticationLogin({
            ...formRef.current?.getFieldsValue(),
            loginType: "LOGIN_WITH_CODE",
          })
          if (response?.code === 200 || response?.data?.code === 200) {
            onCancel()
          }
        }}
        initialValues={{
          zrrlx: "09",
          nsrsbh,
        }}
      >
        <ProFormText
          name="nsrsbh"
          placeholder="纳税人识别号"
          rules={[
            {
              required: true,
            },
            {
              pattern: RexSocialCreditCode,
              message: "纳税人识别号格式不正确",
            },
          ]}
          fieldProps={{ prefix: <CreditCardOutlined /> }}
        />

        <ProFormText
          name="username"
          placeholder="电子税务局账号"
          fieldProps={{ prefix: <MobileOutlined /> }}
          rules={[{ required: true }]}
        />
        <ProFormText.Password
          name="password"
          placeholder="电子税务局密码"
          rules={[{ required: true }]}
          fieldProps={{ prefix: <LockOutlined /> }}
        />

        <ProFormSelect
          rules={[{ required: true, message: "请选择责任人" }]}
          options={[
            { label: "法定代表人", value: "01" },
            { label: "财务代表人", value: "02" },
            { label: "办税员", value: "03" },
            { label: "管理员", value: "05" },
            { label: "领票员", value: "07" },
            { label: "社保经办人", value: "08" },
            { label: "开票员", value: "09" },
            { label: "销售人员", value: "10" },
          ]}
          name="zrrlx"
        />
        {invoiceCode != "11011" && (
          <ProFormCaptcha
            fieldProps={{
              size: "large",
              prefix: <LockOutlined className={"prefixIcon"} />,
              autoComplete: "off",
            }}
            fieldRef={captchaRef}
            captchaProps={{
              size: "large",
            }}
            placeholder={"请输入短信验证码"}
            captchaTextRender={(timing, count) => {
              if (timing) {
                return `${count} ${"获取验证码"}`
              }
              return "获取验证码"
            }}
            name="smsCode"
            // rules={[{ required: true }]}
            onGetCaptcha={async () => {
              return new Promise((resolve, reject) => {
                formRef?.current?.validateFields()
                const executeAsync = async () => {
                  let obj = formRef.current?.getFieldsValue()
                  delete obj.smsCode

                  // 对 nsrsbh 进行正则校验
                  if (!RexSocialCreditCode.test(obj.nsrsbh)) {
                    reject()
                    return
                  }

                  let flag = false
                  for (let key in obj) {
                    if (obj[key] === undefined) {
                      flag = true
                      break
                    }
                  }

                  if (flag) {
                    reject()
                  } else {
                    captchaRef.current?.startTiming()
                    try {
                      const result = await AuthenticationLogin({
                        ...formRef.current?.getFieldsValue(),
                        loginType: "LOGIN_SEND_CODE",
                      })
                      resolve()
                    } catch (error) {
                      console.error("Failed to get SMS code:", error)
                      reject()
                    }
                  }
                }
                executeAsync()
              })
            }}
          />
        )}
      </ModalForm>
      {visibleSecondaryAuthentication && (
        <SecondaryAuthentication
          visible={visibleSecondaryAuthentication}
          invoiceInfo={invoiceInfo}
          onCancel={() => setVisibleSecondaryAuthentication(false)}
          nsrsbh={formRef.current?.getFieldValue?.("nsrsbh")}
        />
      )}
    </>
  )
}

export default QuanDianModalLogin
