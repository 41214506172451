const withAllowClear = {
  allowClear: true,
}
export default {
  type: "object",
  labelWidth: 76,
  properties: {
    name: {
      title: "搜索",
      type: "string",
      widget: "input",
      placeholder: "企业名称",
      props: withAllowClear,
    },
  },
}
