import React from "react"
import { Badge } from "antd"
const CHECK_STATUS = {
  WAIT_CHECK: "warning",
  CHECK_REJECT: "error",
  CHECK_PASS: "success",
}

export default [
  {
    title: "申请时间",
    dataIndex: "createTime",
    key: "createTime",
  },
  {
    title: "申请编号",
    dataIndex: "applyNo",
    key: "applyNo",
  },
  {
    title: "入驻组织",
    dataIndex: "orgName",
    key: "orgName",
  },
  {
    title: "证件号",
    dataIndex: "orgTaxNo",
    key: "orgTaxNo",
  },
  {
    title: "联系人",
    dataIndex: "contactPerName",
    key: "contactPerName",
  },
  {
    title: "联系方式",
    dataIndex: "contactPerPhone",
    key: "contactPerPhone",
  },

  {
    title: "所在地",
    dataIndex: "cityName",
    render: (text, row) => {
      return (
        <>
          {row.provinceName}-{row.cityName}-{row.areaName}
        </>
      )
    },
  },
  {
    title: "提交人",
    dataIndex: "createUserName",
    key: "createUserName",
  },
  {
    title: "提交人电话",
    dataIndex: "createUserPhone",
    key: "createUserPhone",
  },
  {
    title: "审核人",
    dataIndex: "checkUserName",
    key: "checkUserName",
  },
  {
    title: "审核时间",
    dataIndex: "checkPassTime",
    key: "checkPassTime",
  },
  {
    title: "审核状态",
    dataIndex: "checkStatusDesc",
    render: (text, row) => {
      return (
        <>
          <Badge status={CHECK_STATUS[row.checkStatus]} text={row.checkStatusDesc} />
        </>
      )
    },
  },
]
