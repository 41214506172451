/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { OrderDetailContext } from "../useOrderDetail"
import { AmountCard } from "../../components/AmountCard"
import classname from "classnames"
import { Badge, Button, Card, Divider, Space, Table, message, Modal, Descriptions, Tag } from "antd"
import { ColumnsType } from "antd/lib/table"
import {
  checkFile,
  getTradingSchemePayment,
  postClosePaymentItem,
  uploadOfflinePayment,
  cancelPrepay,
} from "../../../../promises"
import { checkValueToContinue, confirmToContinue } from "../../../../../../utils/utils"
import {
  PaymentItemStatusEnum,
  PaymentItemTypeMap,
  PayTypeEnumsMap,
} from "../../../../../../constant/order"
import { useForm } from "antd/es/form/Form"
import ModalForm from "../../../../../../components/ModalForm"
import PreviewFile from "@src/components/PreviewFile"
import { useMount, useRequest } from "ahooks"
import {
  PaymentRecordOfflineForm,
  PaymentRecordOfflineFormType,
} from "../../../../../../components/FormTemplate/paymentRecordOffline"
import { PaymentRecordOfflineFormTwo } from "../../../../../../components/FormTemplate/paymentRecordOfflineTwo"
import moment from "moment"
import "./index.scss"
import { getFileType, getUrl } from "../utils"
import PdfView from "../../../../../../components/PdfView"
import numeral from "numeral"
import OfflinePayment from "./components/offlinePayment"
export const OrderPaymentInfo: React.FC<{}> = memo(() => {
  const context: any = useContext(OrderDetailContext)
  const roleListSeller =
    context?.data?.payments?.orderReceivableList.filter(
      (ie: any) => ie.payOption != "TAX" && ie.payOption != "WITHHOLDING_FEES"
    ) || []
  const roleListTaxCompany =
    context?.data?.payments?.orderReceivableList.filter((ie: any) => ie.payOption == "TAX") || []
  const roleListTaxFees =
    context?.data?.payments?.orderReceivableList.filter(
      (ie: any) => ie.payOption == "WITHHOLDING_FEES"
    ) || []
  const [imageData, setImageData] = useState<any>("")
  const [imageFlag, setImageFlag] = useState<any>(false)
  const [hidden, setHidden] = useState<any>(false)
  const [fileUrl, setFileUrl] = useState<string>("")
  const [pdfModal, setPdfModal] = useState<any>({
    visible: false,
    urls: [],
  })

  const columns: ColumnsType<API.Payment.Info["paymentItemList"][0]> = [
    { title: "支付ID", dataIndex: "id", fixed: "left" },
    { title: "创建时间", dataIndex: "createTime" },
    { title: "支付编号", dataIndex: "receiptNo" },
    { title: "付款方", dataIndex: "payer" },
    { title: "收款方", dataIndex: "payeeName" },
    // {title:'收款方账号',dataIndex:'payeeAccount'},
    // {title:'订单关联货款(元)',dataIndex:'grossAmount'},
    { title: "支付金额", dataIndex: "amount" },
    // {title:'订单关联税款',dataIndex:'taxAmount'},
    // {title:'支付来源',dataIndex:'paymentSource'},
    // { title: "是否为预付款", dataIndex: "", render: () => "否" },
    {
      title: "支付渠道",
      dataIndex: "payType",
      render: (payOption) => PayTypeEnumsMap[payOption],
    },
    {
      title: "支付状态",
      dataIndex: "status",
      render: (value) => <Badge {...PaymentItemStatusEnum[value]} />,
      fixed: "right",
    },
    { title: "到账时间", dataIndex: "paidTime", fixed: "right" },
    {
      title: "操作",
      dataIndex: "paymentId",
      fixed: "right",
      render: (_, record) => {
        return (
          <Space>
            <a
              hidden={!record.fileId}
              onClick={async () => {
                await checkValueToContinue(record?.fileId, "未找到文件内容")
                let resultData: any = await checkFile([record?.fileId])
                const fileType = getFileType(resultData[0])
                if (fileType == "image") {
                  let newResultData = resultData?.map((item, index) => {
                    return {
                      thumbnail: item,
                      original: item,
                      description: "图片" + (index + 1),
                    }
                  })
                  setImageFlag(true)
                  setImageData(newResultData)
                } else {
                  if (record?.fileId?.split(",")?.length > 1) {
                    setPdfModal({
                      visible: true,
                      urls: record?.fileId?.split(","),
                    })
                  } else {
                    const _url = await getUrl(record?.fileId)
                    setFileUrl(_url)
                  }
                }
              }}
            >
              查看凭证
            </a>
            {record.payType == "DEDUCTION" && (
              <a
                hidden={record?.status === "CLOSE" || context?.data?.invoiceStatus == "COMPLETED"}
                onClick={async () => {
                  await confirmToContinue({ title: "提示", content: <>是否撤销抵扣</> })
                  await checkValueToContinue(record.id)
                  await cancelPrepay(record.id)
                  message.success("操作成功")
                  await context?.refresh()
                }}
              >
                撤销抵扣
              </a>
            )}
            <a
              hidden={
                record.payType == "DEDUCTION" ||
                record?.status === "CLOSE" ||
                record?.payType == "ONLINE"
              }
              onClick={async () => {
                await confirmToContinue({ title: "提示", content: <>是否关闭支付</> })
                await checkValueToContinue(record.id)
                await postClosePaymentItem(record.id)
                message.success("操作成功")
                await context?.refresh()
              }}
            >
              关闭支付
            </a>
          </Space>
        )
      },
    },
  ]
  const handleOpenPdfList = async (url: string) => {
    const _url = await getUrl(url)
    setFileUrl(_url)
  }
  const formatAmount = (amount: number | undefined) => {
    return numeral(amount).format("0,0.00")
  }
  const useHiddenOpen = useMemo(() => {
    let arr = context?.data?.payments?.orderReceivableList
    if (arr?.length === 1) {
      return arr[0]?.remainReceivable === 0
    }
    if (arr?.length === 2) {
      return arr[0]?.remainReceivable === 0 && arr[1]?.remainReceivable === 0
    }
    return false
  }, [context?.data?.payments])

  const useRefundOpen = useMemo(() => {
    let arr = context?.data?.payments?.orderReceivableList
    if (arr?.length === 1) {
      return arr[0]?.paidAmount === 0
    }
    if (arr?.length === 2) {
      return arr[0]?.paidAmount === 0 && arr[1]?.paidAmount === 0
    }
    return false
  }, [context?.data?.payments])
  const handHidden = async () => {
    let params: any = {
      orderIds: context.data.id,
    }
    const result: any = await getTradingSchemePayment(params)
    if (result?.code == 200) {
      let hidden: any = result?.data?.some(
        (ie: any) =>
          numeral(ie?.receivable)
            .subtract(ie?.appliedAmount)
            .value() == 0
      )
      setHidden(hidden)
    }
  }
  useEffect(() => {
    if (context.data.id) {
      handHidden()
    }
  }, [context.data])
  const [form] = useForm<PaymentRecordOfflineFormType>()
  //线下付款
  const handPaymentUpdate = useMemo(() => {
    return (
      <>
        <OfflinePayment
          context={context}
          roleListSeller={roleListSeller} // 货款
          roleListTaxCompany={roleListTaxCompany} // 税费及附加费
          roleListTaxFees={roleListTaxFees} // 平台预扣税
          useHiddenOpen={useHiddenOpen} // 可支付是否等于0
          contentPayment={"线下付款"}
          form={form}
        />
      </>
    )
  }, [context, hidden, roleListSeller, roleListTaxCompany, roleListTaxFees, useHiddenOpen])
  //退款回单
  const handPaymentRefundUpdate = useMemo(() => {
    return (
      <>
        <OfflinePayment
          context={context}
          roleListSeller={roleListSeller} // 货款
          roleListTaxCompany={roleListTaxCompany} // 税费及附加费
          roleListTaxFees={roleListTaxFees} // 平台预扣税
          useHiddenOpen={useRefundOpen} // 可支付是否等于0
          contentPayment={"退款回单"}
          form={form}
        />
      </>
    )
  }, [context, hidden, roleListSeller, roleListTaxCompany, roleListTaxFees, useRefundOpen])
  return (
    <Card
      title="支付信息"
      extra={
        <Space>
          {handPaymentUpdate}
          {handPaymentRefundUpdate}
        </Space>
      }
    >
      {roleListSeller[0]?.receivableAmount > 0 && (
        <div className="page-detail-payment-info-item">
          <div className="page-detail-payment-info-item-title">应付项：货款</div>
          <div className="page-detail-payment-info-item-content">
            <div>
              <span>付款方：{roleListSeller[0]?.payerName}</span>
            </div>
            <div>
              <span>收款方：{roleListSeller[0]?.payeeName}</span>
            </div>
            <div>
              <span>应付总额：{formatAmount(roleListSeller[0]?.receivableAmount)}元</span>
            </div>
            <div>
              <span>已支付：{formatAmount(roleListSeller[0]?.paidAmount)}</span>
            </div>
            <div>
              <span>
                未支付：
                {formatAmount(
                  roleListSeller[0]?.remainReceivable + roleListSeller[0]?.pendingAmount
                )}
              </span>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={context?.data?.payments?.paymentItemList?.filter(
              (item: any) => item.payOption != "TAX" && item.payOption != "WITHHOLDING_FEES"
            )}
            scroll={{
              x: true,
            }}
          />
        </div>
      )}
      {roleListTaxCompany[0]?.receivableAmount > 0 && (
        <div className="page-detail-payment-info-item">
          <div className="page-detail-payment-info-item-title">应付项：代扣税费合计</div>
          <div className="page-detail-payment-info-item-content">
            <div>
              <span>付款方： {roleListTaxCompany[0]?.payerName}</span>
            </div>
            <div>
              <span>收款方：{roleListTaxCompany[0]?.payeeName}</span>
            </div>
            <div>
              <span>应付总额：{formatAmount(roleListTaxCompany[0]?.receivableAmount)}元</span>
            </div>
            <div>
              <span>已支付：{formatAmount(roleListTaxCompany[0]?.paidAmount)}</span>
            </div>
            <div>
              <span>
                未支付：
                {formatAmount(
                  roleListTaxCompany[0]?.remainReceivable + roleListTaxCompany[0]?.pendingAmount
                )}
              </span>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={context?.data?.payments?.paymentItemList?.filter(
              (item: any) => item.payOption == "TAX"
            )}
            scroll={{
              x: true,
            }}
          />
        </div>
      )}
      {roleListTaxFees[0]?.receivableAmount > 0 && (
        <div className="page-detail-payment-info-item">
          <div className="page-detail-payment-info-item-title">应付项：平台预扣费</div>
          <div className="page-detail-payment-info-item-content">
            <div>
              <span>付款方： {roleListTaxFees[0]?.payerName}</span>
            </div>
            <div>
              <span>收款方：{roleListTaxFees[0]?.payeeName}</span>
            </div>
            <div>
              <span>应付总额：{formatAmount(roleListTaxFees[0]?.receivableAmount)}元</span>
            </div>
            <div>
              <span>已支付：{formatAmount(roleListTaxFees[0]?.paidAmount)}</span>
            </div>
            <div>
              <span>
                未支付：
                {formatAmount(
                  roleListTaxFees[0]?.remainReceivable + roleListTaxFees[0]?.pendingAmount
                )}
              </span>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={context?.data?.payments?.paymentItemList?.filter(
              (item: any) => item.payOption == "WITHHOLDING_FEES"
            )}
            scroll={{
              x: true,
            }}
          />
        </div>
      )}

      {fileUrl && <PdfView pdfSrc={fileUrl} />}
      <Modal
        title="文件列表"
        open={pdfModal.visible}
        footer={false}
        onCancel={() => setPdfModal({ visible: false, urls: [] })}
      >
        {pdfModal.urls?.map((item: any, index: number) => {
          return (
            <p
              key={item}
              onClick={() => {
                handleOpenPdfList(item)
              }}
              style={{ cursor: "pointer", color: "#1890ff" }}
            >
              凭证文件{index + 1}
            </p>
          )
        })}
      </Modal>
      <Modal open={imageFlag} width={1000} footer={null} onCancel={() => setImageFlag(false)}>
        <div className="preview-image">
          <PreviewFile flag={imageData ? true : false} imageData={imageData} />
        </div>
      </Modal>
    </Card>
  )
})
