/* eslint-disable react/jsx-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
import { Button, Form, Space, Card, Upload, UploadFile, Col, Radio, Tooltip } from "antd"
import React, { memo, useEffect, useMemo, useState } from "react"
import { namelessOptionEnum } from "../../../constants"
import { UploadOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import ComponUpload from "../../components/componUpload/index"
import { OperableImage } from "@src/components/IndividualAbleImage"
import { cross_env } from "@src/services/serviceOp"
import "./index.scss"
const FormItem = Form.Item
export const MateriaUpload = memo((props: any) => {
  const {
    form,
    detailInfo,
    handTaxDistrict,
    taxArrNum,
    setTaxArrNum,
    setTaxPassNumUpload,
    taxPassNumUpload,
    history,
  } = props
  const handFilePath = (file) => {
    let options = file?.map((ie, index) => {
      return {
        uid: index + 1,
        name: ie.name,
        url: ie.path,
        status: "done",
        percent: 10 + index + 1,
      }
    })
    return options
  }
  const [delegationAgreement, setDelegationAgreement] = useState<UploadFile[]>() // 委托人证明
  const [fileListOther, setFileListOther] = useState<UploadFile[]>() // 其他补充材料上传
  const [MaterialsIdCardRType, setMaterialsIdCardRType] = useState<any>([]) //税税通截图
  const [typeFile, setTypeFile] = useState<any>("0")
  const [taxArrNumFlag, setTaxArrNumFlag] = useState<any>(false)
  const [TaxPassNumUploadNumFlag, setTaxPassNumUploadNumFlag] = useState<any>(false)
  useEffect(() => {
    setDelegationAgreement(handFilePath(detailInfo?.delegatedAgreement?.files))
    setFileListOther(handFilePath(detailInfo?.otherFile))
  }, [detailInfo])
  useMemo(() => {
    if (TaxPassNumUploadNumFlag) {
      setTimeout(() => {
        setTaxPassNumUploadNumFlag(false)
      }, 10)
    }
  }, [TaxPassNumUploadNumFlag])
  const handImageStatus: any = useMemo(() => {
    // setTaxPassNumUploadNumFlag(true)
    let imgStatusList = handTaxDistrict?.extraFileConfigs?.filter((ie, index) => {
      return ie.fileType == "IMAGE"
    })
    let fileStatusList = handTaxDistrict?.extraFileConfigs?.filter((ie, index) => {
      return ie.fileType == "FILE"
    })
    if (taxArrNum.length > 0) {
      form.setFieldValue("idCardRType", "image")
    }
    if (taxPassNumUpload.length > 0) {
      form.setFieldValue("idFileNameTypes", "files")
    }
    return (
      <>
        {imgStatusList?.map((ie: any, index: number) => {
          if (ie.fileType == "IMAGE") {
            setTaxArrNumFlag(false)
            return (
              <Col style={{ display: "flex" }} key={index} className="mater-image">
                <Form.Item
                  name={["idCardRType"]}
                  label={ie.fileName}
                  rules={[{ required: ie?.allowEmpty, message: "请上传截图" }]}
                >
                  <div className="mater-icon">
                    <Tooltip title={ie?.fileRequire}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </div>
                  <div className="mater-operable">
                    <OperableImage
                      width={120}
                      height={120}
                      isUpdate={false}
                      action={`${cross_env}/rscm/rscm-resource/oss/endpoint`}
                      src={taxArrNum[index] || []}
                      taxArrNum={taxArrNum}
                      taxArrNumFlag={taxArrNumFlag}
                      setTaxArrNumFlag={setTaxArrNumFlag}
                      index={index}
                      numInfo={ie.fileNumbers}
                      setTaxArrNum={setTaxArrNum}
                      uploadFileProps={{
                        name: "file",
                        onChange: async ({ file }) => {
                          let arr = taxArrNum
                          if (file.response?.data?.downloadLink) {
                            let params = {
                              ...file,
                              data: ie,
                            }
                            const newFiles = Array.from([params])
                            console.log(...newFiles, "newFiles")
                            if (arr[index]) {
                              arr[index].push(newFiles[0])
                            } else {
                              arr[index] = newFiles
                            }
                            console.log(arr, "arr")
                            setTaxArrNum(arr)
                            form.setFieldValue("idCardRType", arr)
                            setMaterialsIdCardRType([...MaterialsIdCardRType, ...newFiles])
                          }
                        },
                      }}
                    >
                      上传
                    </OperableImage>
                    <span style={{ color: "#00000073" }}>请上传{ie.fileNumbers}张截图</span>
                  </div>
                </Form.Item>
              </Col>
            )
          }
        })}
        {fileStatusList?.map((ie, index) => {
          return (
            <Col style={{ display: "flex" }} className="mater-files">
              <Form.Item
                rules={[{ required: ie.allowEmpty, message: "请上传文件" }]}
                // name={[index + "idFileNameTypes"]}
                name={"idFileNameTypes"}
                label={ie.fileName}
                className="FormItemUpload"
              >
                <div className="mater-icon-file">
                  <Tooltip title={ie?.fileRequire}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </div>
                <div className="mater-load">
                  <Upload<Common.Res<{ fileKey: string }>>
                    maxCount={ie.fileNumbers}
                    action={`${cross_env}/rscm/rscm-resource/oss/endpoint`}
                    fileList={taxPassNumUpload[index]}
                    onRemove={(file: any) => {
                      let fileArr = taxPassNumUpload
                      let newIndex =
                        fileArr &&
                        fileArr[index]?.filter((ie) => {
                          ie?.response?.data?.filePath !== file?.response?.data?.filePath ||
                            ie?.path !== file?.path
                        })
                      fileArr[index] = newIndex
                      setTaxPassNumUpload(fileArr)
                      form.setFieldValue("idFileNameTypes", fileArr)
                    }}
                    method="put"
                    headers={{
                      Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem("operation_authorization") || "{}"
                      )?.access_token_op}`,
                    }}
                    onChange={(info) => {
                      let arr: any = taxPassNumUpload
                      if (info.file.status == "done") {
                        let params = {
                          ...info.file,
                          data: ie,
                        }
                        const newFiles = Array.from([params])
                        if (arr[index]) {
                          arr[index]?.push(newFiles[0])
                        } else {
                          arr[index] = newFiles
                        }
                        console.log(arr, "arr1s")
                        setTaxPassNumUpload(arr)
                        form.setFieldValue("idFileNameTypes", arr)
                      }
                    }}
                  >
                    <Space>
                      <Button icon={<UploadOutlined />}>点击上传</Button>
                      <span style={{ color: "#00000073" }}>请上传{ie.fileNumbers}个文件</span>
                    </Space>
                  </Upload>
                </div>
              </Form.Item>
            </Col>
          )
        })}
      </>
    )
  }, [
    handTaxDistrict,
    MaterialsIdCardRType,
    taxPassNumUpload,
    taxArrNum,
    taxArrNumFlag,
    TaxPassNumUploadNumFlag,
  ])
  return (
    <Card title="材料上传">
      {handImageStatus}
      {handTaxDistrict?.flowConfig?.isNeedAgreement ? (
        <>
          <Col>
            <Form.Item name="type" label="委托协议">
              <Radio.Group
                onChange={(e: any) => {
                  setTypeFile(e?.target?.value)
                }}
              >
                <Radio value={"0"}>手动上传</Radio>
                <Radio value={"1"}>线上签署</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          {typeFile == "0" ? (
            <ComponUpload
              title={"委托协议上传"}
              form={form}
              rules={true}
              numInfo={10}
              formName={"contracts"}
              fileLists={delegationAgreement}
              handTaxDistrict={handTaxDistrict}
              setFileList={setDelegationAgreement}
            />
          ) : null}
        </>
      ) : null}

      <ComponUpload
        title={"其他补充材料上传"}
        form={form}
        rules={false}
        numInfo={10}
        formName={"otherFile"}
        fileLists={fileListOther}
        setFileList={setFileListOther}
      />
    </Card>
  )
})

export default MateriaUpload
