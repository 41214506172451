import { useState } from "react"

const useInvoiceHandler = (refreshFunction) => {
  const [visibleQuanDianModalLogin, setVisibleQuanDianModalLogin] = useState(false)
  const [visibleSecondaryAuthentication, setVisibleSecondaryAuthentication] = useState(false)
  const [invoiceInfo, setInvoiceInfo] = useState(null)
  const [invoiceCode, setInvoiceCode] = useState(null)
  const [nsrsbh, setNsrsbh] = useState("") // 纳税人识别号

  const handleInvoiceResponse = (res) => {
    console.log(res, res?.code, "res")

    if (res?.code == "1101") {
      setVisibleQuanDianModalLogin(true)
    } else if (res?.code == "1102") {
      setVisibleSecondaryAuthentication(true)
      const messageStr = res?.message
      const messageObj = JSON.parse(messageStr)
      setInvoiceInfo(messageObj)
    } else if (res?.code == "11011") {
      setVisibleQuanDianModalLogin(true)
      setInvoiceCode(res?.code)
    }
  }

  const closeQuanDianModalLogin = () => {
    setVisibleQuanDianModalLogin(false)
    setVisibleSecondaryAuthentication(false)
    refreshFunction()
  }

  return {
    visibleQuanDianModalLogin,
    visibleSecondaryAuthentication,
    invoiceInfo,
    invoiceCode,
    nsrsbh,
    setNsrsbh,
    handleInvoiceResponse,
    closeQuanDianModalLogin,
  }
}

export default useInvoiceHandler
