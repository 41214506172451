import React, { forwardRef, useRef, useImperativeHandle } from "react"
import Image from "./Components/Image"
import File from "./Components/File"
import Text from "./Components/Text"
import Time from "./Components/Time"
import Money from "./Components/Money"
import Select from "./Components/Select"

const templateObject = {
  photo: Image,
  file: File,
  text: Text,
  time: Time,
  date: Time,
  video: Image,
  audio: Image,
  money: Money,
  select: Select,
  weight: Money,
  phone: Money,
  id_num: Money,
  vehicle: Money,
  email: Money,
  coordinate: Money,
}

const FieldTemplate = forwardRef((props: any, ref) => {
  const { type = "image", params } = props
  console.log(props, "index")
  const childRef = useRef<any>()
  const Com = templateObject[type]
  const formValidator = () => {
    childRef.current.formValidate()
  }
  const reset = () => {
    childRef.current?.reset()
  }
  useImperativeHandle(ref, () => ({
    reset,
    formValidator,
  }))
  return Com ? <Com {...params} type={type} ref={childRef} /> : null
})
FieldTemplate.displayName = "FieldTemplate"
export default FieldTemplate
