import { Dropdown, Menu, Button } from "antd"
import { DownOutlined } from "@ant-design/icons"
import { getInvoices } from "../../../Entity/promises"
import React, { useMemo } from "react"

const ExportInvoice = (props: any) => {
  const { orderNos } = props
  console.log(orderNos.map((item: any) => item.invoiceStatus))
  // 订单id集合
  const ids = useMemo(() => {
    return orderNos?.map((item: any) => item.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderNos.length])

  // 是否可以下载（状态均为开票中或已开票状态） PROCESSING, COMPLETED;
  const flag = useMemo(() => {
    if (!orderNos?.length) return true
    return !orderNos?.every(
      (item: any) => item.invoiceStatus == "PROCESSING" || item.invoiceStatus == "COMPLETED"
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderNos.length])

  const handleDown = async (type: string) => {
    if (flag) return
    const result: any = await getInvoices({
      formatType: type,
      orderNos: ids?.join(","),
    })
    if (result?.code == 200) {
      window.open(result.data)
    }
  }
  // "PDF", "OFD", "XML";
  const downTypes = ["PDF"].map((item) => {
    return {
      key: item,
      label: <span>{item}</span>,
      onClick: () => {
        handleDown(item)
      },
    }
  })

  const menu = <Menu items={downTypes}></Menu>

  return (
    <>
      {downTypes?.length > 1 ? (
        <Dropdown overlay={menu} placement="bottom" disabled={flag}>
          <Button type="primary">
            导出发票 <DownOutlined />
          </Button>
        </Dropdown>
      ) : (
        <Button type="primary" disabled={flag} onClick={() => handleDown("PDF")}>
          导出发票
        </Button>
      )}
    </>
  )
}
export default ExportInvoice
