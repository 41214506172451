import numeral from "numeral"
export const transDigitByUnit = (digit: number = 0, originUnit?: string, targetUnit?: string) => {
  switch (originUnit) {
    case "吨":
      if (targetUnit == "吨") {
        return digit
      } else if (targetUnit == "千克") {
        return numeral(digit).multiply(1000).value()
      } else if (targetUnit == "克") {
        return numeral(digit).multiply(1000000).value()
      }
      break
    case "千克":
      if (targetUnit == "吨") {
        return numeral(digit).divide(1000).value()
      } else if (targetUnit == "千克") {
        return digit
      } else if (targetUnit == "克") {
        return numeral(digit).multiply(1000).value()
      }
      break
    case "克":
      if (targetUnit == "吨") {
        return numeral(digit).divide(1000000).value()
      } else if (targetUnit == "千克") {
        return numeral(digit).divide(1000).value()
      } else if (targetUnit == "克") {
        return digit
      }
      break
  }
  return digit
}
