/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { PropsWithChildren, useEffect, useMemo, useRef, useState } from "react"
import { Cropper, ReactCropperProps } from "react-cropper"
import "cropperjs/dist/cropper.css"
import classNames from "classnames"
import { Button, Col, message, Modal, Progress, Row, Slider, Space, Upload } from "antd"
import "./index.scss"
import { UploadOutlined, ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons"
import ReactDOM from "react-dom"
import { limitFileSize } from "@src/utils/card/utils"
import { blobToCanvas, blobToUrl, canvasToBlob } from "@src/utils/card/dataFormatConversion"
import { useDebounceFn } from "ahooks"
// import { createRoot } from "react-dom/client"
interface Props {
  title?: React.ReactNode
  isOnlyCrop?: boolean
  file: Blob
  submit?: (file: Blob) => void
  cancel?: () => void
  reupload?: boolean
  aspectRatio?: number
  required?: boolean
  cropProps?: ReactCropperProps
  defaultCorpBoxInfo?: Partial<{
    left: number
    top: number
    width: number
    height: number
  }>
  imageType?: "image/png" | "image/jpeg"
  customActions?: (actions: {
    preview: React.ReactNode
    zoomControl: React.ReactNode
    rotateControl: React.ReactNode
    reuploadControl: React.ReactNode
  }) => React.ReactNode
}
const rotateMarks = {
  "-360": "-360°",
  0: "0°",
  360: "360°",
}

export const imageSizeType = {
  card: 1.58 / 1,
  oneInch: 5 / 7,
}
const componentDefaultCorpBoxInfo = {
  left: 60,
  top: 37.5,
  width: 435,
  height: 275,
}
const CropperWrapper: React.FC<PropsWithChildren<Props>> = (props) => {
  const cropperRef = useRef(null)

  const {
    required,
    file,
    defaultCorpBoxInfo = componentDefaultCorpBoxInfo,
    aspectRatio = imageSizeType.card,
    title = "裁剪",
    reupload,
    submit = () => {},
    cancel = () => {},
    customActions,
    className,
    isOnlyCrop,
    cropProps,
    imageType = "image/jpeg",
  } = props

  const [fileList, setFileList] = useState<any>([])
  const [preview, setPreview] = useState<string>("")
  const [init, setInit] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)

  const zip = async (file: Blob) => {
    return limitFileSize(file.size, 5)
      ? await canvasToBlob(await blobToCanvas(file), imageType, 0.8)
      : await file
  }

  const cropperFile = useMemo(() => {
    return fileList[0] ? blobToUrl(fileList[0]) : undefined
  }, [fileList])
  const fileHandle = async (data: Blob) => {
    if (cropperFile) URL.revokeObjectURL(cropperFile)
    setFileList([await zip(data)])
  }

  useEffect(() => {
    fileHandle(file)
  }, [file])

  const _onChange = (info: { fileList: { originFileObj: Blob }[] }) => {
    info?.fileList[1]?.originFileObj && fileHandle(info.fileList[1].originFileObj)
  }

  const _build = () => {
    if (cropperRef?.current) {
      // cropperRef.current.cropper.setCanvasData({
      //   left: 60,
      //   top: 37.5,
      //   width: 435,
      //   height: 275,
      // });
      // cropperRef.current.cropper?.setCropBoxData(defaultCorpBoxInfo);
      setInit(true)
    }
  }
  const _crop = async (data: {
    target: { cropper: { getCroppedCanvas: () => HTMLCanvasElement } }
  }) => {
    if (preview) URL.revokeObjectURL(preview)
    setPreview(blobToUrl(await canvasToBlob(data.target?.cropper?.getCroppedCanvas(), imageType)))
    setInit(false)
  }

  const crop = useDebounceFn(_crop, { wait: 500 })
  const zoomHandle = (isAdd: boolean = true) => {
    cropperRef?.current.cropper.zoom(isAdd ? 0.1 : -0.1)
  }

  const _cancel = () => {
    let cropper = document.getElementById("cropper-modal")
    // eslint-disable-next-line react/no-deprecated
    cropper && ReactDOM.unmountComponentAtNode(cropper)
  }

  const handleOk = () => {
    setLoading(true)
    cropperRef?.current?.cropper?.getCroppedCanvas().toBlob(async (blob) => {
      await submit(blob)
      await _cancel()
    }, imageType)
  }

  const handleCancel = async () => {
    await cancel()
    _cancel()
  }
  const rotateHandle = (value) => {
    cropperRef?.current?.cropper?.rotateTo(value)
  }

  if (!file) {
    message.warning("请先选择图片！")
    return null
  }

  const zoomControl = (
    <div style={{ display: "flex", justifyContent: "space-between", gap: 10 }}>
      <Button type="primary" block icon={<ZoomInOutlined />} onClick={zoomHandle}>
        {" "}
        放大{" "}
      </Button>
      <Button
        type="primary"
        block
        icon={<ZoomOutOutlined />}
        onClick={() => {
          zoomHandle(false)
        }}
      >
        {" "}
        缩小{" "}
      </Button>
    </div>
  )
  const previewControl = (
    <>
      <b>预览:</b>
      <img
        style={{ objectFit: "contain" }}
        width={200}
        height={150}
        src={init ? cropperFile : preview}
      />
    </>
  )
  const rotateControl = (
    <>
      <b>旋转角度:</b>
      <Slider
        min={-360}
        step={5}
        max={360}
        defaultValue={0}
        marks={rotateMarks}
        onChange={rotateHandle}
      />
    </>
  )

  const reuploadControl = (
    <Upload
      name="file"
      accept=".jpg, .jpeg, .png"
      onChange={_onChange}
      action={undefined}
      fileList={fileList}
      showUploadList={{
        showPreviewIcon: false,
        showRemoveIcon: false,
        showDownloadIcon: false,
      }}
    >
      <Button hidden={!reupload} icon={<UploadOutlined />}>
        选择文件
      </Button>
    </Upload>
  )
  return (
    <Modal
      title={<b>{title}</b>}
      visible={true}
      width={600}
      className="cropper_modal"
      style={{ padding: 16 }}
      onOk={handleOk}
      okText="确认"
      cancelText="取消"
      destroyOnClose
      maskClosable={!required}
      onCancel={handleCancel}
      okButtonProps={{
        loading,
      }}
      cancelButtonProps={{
        disabled: required,
      }}
    >
      <Cropper
        style={{ height: 350, width: "100%" }}
        ref={cropperRef}
        size={2}
        aspectRatio={aspectRatio}
        guides={true}
        src={cropperFile}
        className={classNames({
          [init ? "cropper-initWrap" : "cropper-wrap"]: true,
          [className]: Boolean(className),
        })}
        ready={_build}
        crop={crop.run}
        dragMode="move"
        rotatable={true}
        {...cropProps}
      />
      <br />
      {customActions ? (
        customActions({
          preview: previewControl,
          zoomControl,
          rotateControl,
          reuploadControl,
        })
      ) : (
        <>
          <Row gutter={8}>
            <Col span={10}>{previewControl}</Col>
            <Col span={14}>
              {zoomControl}
              {rotateControl}
              {reuploadControl}
            </Col>
          </Row>
        </>
      )}
    </Modal>
  )
}

const CropperModal = (() => {
  let cropper = document.getElementById("cropper-modal")
  if (!cropper) {
    cropper = document.createElement("div")
    cropper.setAttribute("id", "cropper-modal")
    document.body.appendChild(cropper)
  }
  return (props: Props) => {
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.render(<CropperWrapper {...props} />, cropper)
  }
})()

export default CropperModal
