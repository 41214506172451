import { OPL_INVOICE, OP_TRADE, OP_USER } from "../../api/config/servicePort"

// 日期字段处理
const formatTime = (params: any, key: string[]) => {
  for (let i in key) {
    if (params[key[i]]) {
      let timeArr = typeof params[key[i]] == "string" ? params[key[i]].split(",") : params[key[i]],
        str = ``
      str += timeArr[0] + " 00:00:00," + timeArr[1] + " 23:59:59"
      params[key[i]] = str
    }
  }
  return params
}

export const downConfig = {
  货单管理列表: {},
  原料入库列表: {},
  原料出库列表: {},
  入库结算列表: {},
  出库结算列表: {},
  采购订单列表: {
    columns: {
      id: "订单ID",
      buyerName: "采购方",
      sellerName: "销售方",
      sellerLegal: "法人",
      categoryName: "品类",
      contractNo: "合同编号",
      receiveOrgName: "收货单位",
      quantity: "数量",
      unitOfMeasure: "单位",
      sellerIncomeAmt: "卖方净得价",
      beforeVatAmt: "不含税金额",
      afterVatAmt: "含税总额",
      vatAmt: "应缴增值税",
      platFeeAmt: "应缴附加税",
      paidRatio: "金额完成度",
      paidGrossAmount: "代扣货款",
      paidVatTaxAmount: "代扣增值税",
      paidOtherTaxAmount: "代扣附加税费",
      paidTaxAmount: "代扣税款总额",
      invoiceCategoryConfigName: "开票名称",
      orderDate: "订单创建日期",
      auditUserName: "审核人",
      invoiceMonth: "开票月份",
      lastInvoiceDate: "开票日期",
      contractStatusName: "合同签署",
      logisticsName: "物流状态",
      paymentStatusName: "支付状态",
      invoiceStatusName: "开票状态",
      reviewStatusName: "审核状态",
      relationSupplyContractName: "供货协议",
    },
    downUrl: `${OP_TRADE}/orders`,
    exportUrl: `${OP_TRADE}/orders/excel-export-result`,
    apiParams: {
      keywordType: "TRADE",
    },
    formatParams: (obj: any) => {
      obj.invoiceTime = obj?.INVOICE_TIME
      console.log(obj, 6512313)
      obj = formatTime(obj, ["createdTime", "invoiceTime"])
      obj.createTime = obj?.createdTime
      delete obj?.createdTime
      return obj
    },
  },
  销售订单列表: {},
  供应商列表: {},
  客户管理列表: {},
  入驻审核列表: {
    columns: {
      createTime: "申请时间",
      applyNo: "申请编号",
      orgName: "入驻组织",
      orgTaxNo: "证件号",
      contactPerName: "联系人",
      contactPerPhone: "联系方式",
      cityName: "所在地",
      createUserName: "提交人",
      createUserPhone: "提交人电话",
      checkUserName: "审核人",
      checkPassTime: "审核时间",
      checkStatusDesc: "审核状态",
    },
    downUrl: `${OP_USER}/settlement-application`,
    exportUrl: `${OP_USER}/excel/export`,
    apiParams: {},
    formatParams: (obj: any) => {
      obj.invoiceTime = obj?.INVOICE_TIME
      console.log(obj, 6512313)
      obj = formatTime(obj, ["createdTime", "invoiceTime"])
      obj.createTime = obj?.createdTime
      delete obj?.createdTime
      return obj
    },
  },
}
