import { OP_LOGISTIC, OP_USER } from "../../../api/config/servicePort"
import UtilOp from "../../../services/serviceOp"
const utilOp = UtilOp.getInstance()

// 营业执照详情
export const getAddministrstionInfo = (id?: string) => {
  return utilOp
    .httpGet(`/sinzetech-agency-processing/individual-business-deregister/${id}`)
    .then((res) => res)
}

interface Deregister {
  id: string
  file?: string
  nextDeregisterStatus?: string
  rejectReason?: string
}

//办理区域列表
export const getOpeRegions = (params?: any) => {
  return utilOp.httpGet(OP_USER + "/handle-regions", params).then((res) => res)
}
// 查询组织列表(翻页)
export const getTissueList = (params?: Tissue.ListParams) => {
  return utilOp.httpGet<Tissue.ListResponse>(OP_USER + "/organizations", params)
}
//个体户注销列表
export const getIndividualCancelList = (params?: any) => {
  return utilOp.httpGet(OP_USER + "/individual-cancel", params)
}
//个体户注销详情
export const getIndividualCancelDetails = (id?: any) => {
  return utilOp.httpGet(OP_USER + `/individual-cancel/${id}`)
}
//新建个体户注销
export const getIndividualCancelAdd = (params: any) => {
  return utilOp.httpPost(OP_USER + "/individual-cancel", params)
}

//重发短信
export const getIndividualCancelNote = (id: any) => {
  return utilOp.httpPost(OP_USER + `/individual-cancel/${id}/retry-note/`)
}
//撤销注销申请
export const getIndividualCancellation = (id: any) => {
  return utilOp.httpPut(OP_USER + `/individual-cancel/${id}`, id)
}
//无法清税，申请驳回
export const getIndividualCancelReject = (params: any) => {
  return utilOp.httpPost(OP_USER + `/individual-cancel/tax-clearance-reject`, params)
}
//上传签署的注销申请
export const getIndividualCancelSignFile = (params: any) => {
  return utilOp.httpPost(OP_USER + `/individual-cancel/${params.id}/sign-file`, params)
}
//上传清税证明
export const getIndividualCancelTaxFile = (params: any) => {
  return utilOp.httpPost(OP_USER + `/individual-cancel/upload-tax-clearance`, params)
}
//上传执照注销证明
export const getIndividualCancelLicenseFile = (params: any) => {
  return utilOp.httpPost(OP_USER + `/individual-cancel/upload-license-cancel`, params)
}
//查询注销区域
export const getIndividualRelationShip = (id?: any) => {
  return utilOp.httpGet(OP_USER + `/individual-cancel/${id}/relationShip`)
}
//申请界面下载注销文件
export const getIndividualCancelFiles = (params: any) => {
  return utilOp
    .httpPostDownload(OP_USER + "/individual-cancel/document", params, "签署")
    .then((res: any) => {
      return res
    })
    .catch((err) => {
      console.log(err, "err")
    })
}
