/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react"
import { Button, Row, Col, Card, Space, Input, Form, Select, Radio, message } from "antd"
import { useParams, useNavigate } from "react-router-dom"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import Access from "@src/components/Access"
import { AccessEnum } from "@src/contents/accessEnum"
import { PercentageOutlined, RollbackOutlined, UserOutlined } from "@ant-design/icons"
import {} from "../columns"
import {
  getFinanceProductsDetails,
  getFinanceProductsAdd,
  postCancelInvoiceUpdate,
  getLoanCompany,
} from "../../service"
import Decimal from "decimal.js"

import "./index.scss"
import { inputDefaultRules } from "@src/utils/RegExp"

const FinancingDetails = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const searchParams = useParams()
  const [repaymentModal, setRepaymentModal] = useState<any>(true)
  const [info, setInfo] = useState<any>({})
  const [LoanCompanyList, setLoanCompanyList] = useState<any>([])
  const [value, setValue] = useState(0)
  const getInfo = async (id: any) => {
    if (!id) return
    const res = await getFinanceProductsDetails(id)
    if (res?.code === 200) {
      setInfo(res?.data)
      console.log(res.data, "data")
      form.setFieldsValue({
        ...res.data,
        status: res.data.status + "",
        companyNames: res.data.companyIds.split(","),
        annualInterestRate: Number(
          new Decimal(res?.data?.annualInterestRate).mul(new Decimal("100"))
        ),
      })
    }
  }
  //获取核心企业
  const handGetLoanCompany = async () => {
    const resultData = await getLoanCompany({
      isStartBook: false,
      size: 9999,
      current: 1,
    })
    if (resultData?.code === 200) {
      let resultList = resultData.data.records.map((ie) => {
        return {
          label: ie.name,
          value: ie.id,
        }
      })
      setLoanCompanyList(resultList)
    }
  }
  const validateMaxValue = (_, value) => {
    if (value && parseInt(value) > 1000000) {
      return Promise.reject("最大借款金额不能超过1000000")
    }
    return Promise.resolve()
  }
  const handFinancing = async () => {
    await form.validateFields()
    const values = form.getFieldsValue()
    console.log(values, "values")
    const resultValues = LoanCompanyList?.filter(
      (ie) => values?.companyNames?.includes(ie.value)
    ).map((ie) => ({ name: ie.label, id: ie.value }))
    console.log(resultValues, "resultValues")
    const params = {
      ...values,
      companies: resultValues,
      prepaymentAllowed: false, //是否支持提前还款 默认false
      repaymentMethod: "BULLET_REPAYMENT", //还款模式 暂时默认为 BULLET_REPAYMENT
      annualInterestRate: Number(new Decimal(values?.annualInterestRate).div(new Decimal("100"))),
    }
    if (searchParams.id == "null") {
      const resultData: any = await getFinanceProductsAdd(params)
      if (resultData.code == 200) {
        message.success("添加成功")
        navigate("/finance/product")
      }
    } else {
      params.id = searchParams.id
      const resultData: any = await postCancelInvoiceUpdate(params)
      if (resultData.code == 200) {
        message.success("修改成功")
        navigate("/finance/product")
      }
    }
  }

  useMemo(() => {
    if (searchParams.id !== "null") {
      getInfo(searchParams?.id)
      setRepaymentModal(false)
    }
  }, [])
  useMemo(() => {
    handGetLoanCompany()
  }, [])

  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <Button
              onClick={() => navigate("/finance/product")}
              icon={<RollbackOutlined />}
              style={{ color: "#1890FF" }}
            >
              返回
            </Button>
            <span style={{ paddingLeft: 4 }}>
              {repaymentModal ? "添加融资产品" : "编辑融资产品"}
            </span>
          </Space>
        }
        breadCrumb={[
          { label: "供应链金融", path: "" },
          { label: "融资订单", path: "" },
          { label: `详情`, path: "" },
        ]}
        rightContent={<></>}
      ></DetailPageHeader>
      <DetailPageContent>
        <Card title="申请信息" style={{ marginTop: 16 }}>
          <Form
            form={form}
            name="basic"
            autoComplete="off"
            className="newLogout-from"
            initialValues={{
              status: "1",
              loanTerm: "ORDER_COMPLETE",
            }}
          >
            <Form.Item name="status" label="状态">
              <Radio.Group
                onChange={(e: any) => {
                  // setIsNeedDual(e?.target?.value)
                }}
              >
                <Radio value={"1"}>启用</Radio>
                <Radio value={"0"}>禁用</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="loanTerm" label="借款周期">
              <Radio.Group
                onChange={(e: any) => {
                  // setIsNeedDual(e?.target?.value)
                }}
              >
                <Radio value="ORDER_COMPLETE">按订单结算日期</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="prepaymentAllowed" label={"是否支持提前还款"}>
              <span>否</span>
            </Form.Item>
            <Form.Item name="repaymentMethod" label={"还款模式"}>
              <span>到期一次还本付息</span>
            </Form.Item>
            <Form.Item
              name="name"
              label={"产品名称"}
              rules={[
                { required: true, message: "请输入" },
                {
                  validator: async (rule: any, value: string) =>
                    inputDefaultRules(value, "产品名称", 20), //特殊字符
                },
              ]}
            >
              <Input placeholder="请输入" style={{ width: 336 }}></Input>
            </Form.Item>
            <Form.Item
              name="financeCompany"
              label={"融资公司"}
              rules={[
                { required: true, message: "请输入" },
                {
                  validator: async (rule: any, value: string) =>
                    inputDefaultRules(value, "融资公司", 20), //特殊字符
                },
              ]}
            >
              <Input placeholder="请输入" style={{ width: 336 }}></Input>
            </Form.Item>
            <Form.Item
              name="maximumLoanAmount"
              label={"最大借款金额"}
              rules={[
                { required: true, message: "请输入" },
                { validator: validateMaxValue },
                {
                  pattern: /^[+-]?\d+(?:\.\d{1,100})?$/,
                  message: "只能输入数字",
                },
              ]}
            >
              <Input placeholder="请输入" style={{ width: 336 }}></Input>
            </Form.Item>
            <Form.Item
              name="annualInterestRate"
              label={"年利率"}
              rules={[
                { required: true, message: "请输入" },
                {
                  pattern: /^[+-]?\d+(?:\.\d{1,100})?$/,
                  message: "只能输入数字",
                },
              ]}
            >
              <Input
                placeholder="请输入"
                suffix={<PercentageOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                style={{ width: 336 }}
              ></Input>
            </Form.Item>
            <Form.Item
              name="companyNames"
              label={"核心企业"}
              rules={[{ required: true, message: "请选择" }]}
            >
              <Select
                style={{ width: 336 }}
                placeholder="可多选"
                mode="multiple"
                onChange={async (e) => {}}
                filterOption={(input: any, option: any) =>
                  (option?.label ?? "").includes(input) || (option?.contact ?? "").includes(input)
                }
                options={LoanCompanyList}
                allowClear
                showSearch
              ></Select>
            </Form.Item>
          </Form>
        </Card>
        <div className="details_primary">
          <Row justify="center">
            <Col>
              <Space>
                <Button onClick={() => navigate("/finance/product")}>取消</Button>
                <Button type="primary" onClick={handFinancing}>
                  保存
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
      </DetailPageContent>
    </DetailPageContainer>
  )
}

export default FinancingDetails
