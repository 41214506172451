/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-key */
/* eslint-disable react-hooks/exhaustive-deps */
import ProDescriptions, { ProDescriptionsItemProps } from "@ant-design/pro-descriptions"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import { OperableImage } from "@src/components/OperableImage"
import { RexPostalCode, RexSocialCreditCode } from "@src/constant/RegExp"
import {
  Button,
  Card,
  Cascader,
  Checkbox,
  Form,
  Modal,
  Radio,
  RadioChangeEvent,
  Space,
  Switch,
  Table,
  message,
} from "antd"
import { useNavigate, useParams } from "react-router-dom"
import { prodListJoinIdEnum } from "../../TaxDistrict/constant"
import EvidenceConfigList from "../components/evidenceConfigList"
import MakeOutEditAnInvoice from "../components/makeOutEditAnInvoice"
import MakeOutAnInvoice from "../components/makeOutInfoAnInvoice"

import { formatAmount } from "@/utils/utils"
import { RollbackOutlined } from "@ant-design/icons"
import Access from "@src/components/Access"
import { AccessEnum } from "@src/contents/accessEnum"
import { useRequest } from "ahooks"
import React, { useCallback, useEffect, useState } from "react"
import { handAccess } from "../../../../components/Access/indexAccess"
import { SignatureSetting } from "../../../../components/contractSignaturesSetting/contractSignaturesSetting"
import { RexIndividualCode } from "../../../../constant/RegExp"
import { getFileToBase64 } from "../../../../utils/file"
import ApplicationManagement from "../components/applicationManagement"
import {
  DeclarationCycleEnum,
  DeclarationCycleList,
  DeclarationCycleListEnum,
  LineTypeEnum,
  OrgTypeEnum,
  OrgTypeOutStatusEnum,
  OrgTypeRegistrationEnum,
  OrgTypeStatusEnum,
  applyToEnum,
  handleData,
  intervalTypeEnum,
  priceTypeEnum,
} from "../constant"
import {
  PlatformSetupTissuePropsContext,
  uploadFileAndGettingRes,
  usePlatformSetupTissue,
} from "../hooks/usePlatformSetupTissue"
import {
  editTissueInfo,
  getTissueCityTreeList,
  getTissueStrategiesAvailable,
  getTissueTradeAmount,
  getTissueTradeStrategies,
  getUserLists,
} from "../promises"
import "./index.scss"

let newData: any = []
const PlatformSetupTissueInfo = () => {
  const [form] = Form.useForm()
  const [paymentForm] = Form.useForm()
  const [AutomaticForm] = Form.useForm()
  const paramsId = useParams<{ id: string }>()
  const tissueService: any = usePlatformSetupTissue()
  const authAccess: any = handAccess("op_platform_tissue_feature_detail_edit")
  const navigate = useNavigate()
  const [appData, setAppData] = useState()
  const [tissueList, setTissueList] = useState({})
  const [tissueListTheTop, setTissueListTheTop] = useState()
  const [bankApplicationFlag, setBankApplicationFlag] = useState(false) //银行控制
  const [makeOutAnInvoiceFlag, setMakeOutAnInvoiceFlag] = useState(false) //银行控制
  const [makeFlag, setMakeFlag] = useState(false) //银行控制
  const [tradeLoading, setTradeLoading] = useState(false)
  const [getTaxMin, setGetTaxMin] = useState<any>([])
  const [tradeStrategies, setTradeStrategies] = useState<any>([]) //组织满足销方限定的交易方案
  const [tradeStrategyCredit, setTradeStrategyCredit] = useState<any>([]) //
  const [radioValue, setRadioValue] = useState<any>("")
  const [valueQuota, setValueQuota] = useState("")
  const purchaseColumns: any = [
    {
      title: "作用对象",
      dataIndex: "applyTo",
      render: (_: any, record: any) => {
        return `${applyToEnum[_]}连续${record?.intervalCount}个自然${
          intervalTypeEnum[record?.intervalType]
        }`
      },
    },
    {
      title: "最大限额",
      dataIndex: "limitAmount",
      render: (_: any, record: any) => {
        return (
          <span>
            {formatAmount(_)}元
            <span style={{ color: "red" }}>({priceTypeEnum[record?.priceType]})</span>
          </span>
        )
      },
    },
    {
      title: "已用额度",
      dataIndex: "usedCredit",
      render: (_: any) => {
        return `${formatAmount(_)}元`
      },
    },
    {
      title: "剩余额度",
      dataIndex: "surplusCredit",
      render: (_: any) => {
        return `${formatAmount(_)}元`
      },
    },
  ]
  //获取办税人 运营端角色
  const handGetPlatCharactersList = async (data?: any) => {
    let params = { size: -1, applicationId: 1, roleNames: "办税人", current: 1 }
    const result: any = await getUserLists(params)
    if ((result.code = 200)) {
      const res = result.data.records.map((ie: any) => {
        return {
          label: ie.name,
          value: ie.id,
        }
      })
      setGetTaxMin(res)
    } else {
      message.error("请求失败")
    }
  }
  //过去12个月交易额
  const handGetTissueTradeAmount = async (data?: any) => {
    let params = { organizationId: paramsId.id }
    const result: any = await getTissueTradeAmount(params)
    if (result.code == 200) {
      setTissueListTheTop({
        ...data,
        annualTradeAmount: result?.data?.annualTradeAmount,
      })
    } else {
      setTissueListTheTop({ ...data })
    }
  }
  //组织满足销方限定的交易方案
  const handGetTissueTradeStrategies = async (data?: any) => {
    let params = { organizationId: paramsId.id }
    const result: any = await getTissueTradeStrategies(params)
    if (result.code == 200) {
      const _resultList = result?.data?.map((ie: any) => {
        return {
          label: ie.name,
          value: ie.id,
        }
      })
      setTradeStrategies(_resultList)
      setValueQuota(_resultList[0]?.value)
      handGetTissueStrategiesAvailable(_resultList[0]?.value)
    }
  }
  useEffect(() => {
    tissueService.tissueInfoService.run(tissueService.id)
    handGetPlatCharactersList()
    handGetTissueTradeStrategies()
  }, [])
  const data: any = tissueService.tissueInfoService.data?.data
  const [cityTreeList, setCityTreeList] = useState<any>([]) //省市区
  const [visible, setVisible] = useState<any>(false)
  useEffect(() => {
    if (tissueService.tissueInfoService.data?.data) {
      tissueService.fileUrlService.run([
        tissueService.tissueInfoService.data?.data.businessLicense,
        tissueService.tissueInfoService.data?.data.idCardFront,
        tissueService.tissueInfoService.data?.data.idCardBack,
      ])
      console.log(data, 61231313)
      // if (!data.legalIdNo) {
      //   data.legalIdNo = data.idCardNo
      // }
      setTissueList({
        ...data,
        name1: `http://${location.host}/agencyBusiness/storeDetail/${data?.siteId}`,
      })
      handGetTissueTradeAmount(data)
      setRadioValue(tissueService?.tissueInfoService?.data?.data?.paymentApplyConfig)
      handBankTree()
      let result = tissueService?.tissueInfoService?.data?.data?.applications.map((ie) => ie.id)
      setAppData(result)
      paymentForm.setFieldsValue({
        ...tissueService.tissueInfoService.data?.data,
      })
      let _tissueService = tissueService.tissueInfoService.data?.data
      console.log(tissueService.tissueInfoService.data?.data, "data?.data")
      AutomaticForm.setFieldsValue({
        buyerOrderAuto: _tissueService.buyerOrderAuto && [_tissueService.buyerOrderAuto],
        sellerOrderAuto: _tissueService.sellerOrderAuto && [_tissueService.sellerOrderAuto],
      })
    }
  }, [tissueService.tissueInfoService.data?.data])
  const [businessLicense, idCardFront, idCardBack] = tissueService.fileUrlService.data || []
  const handBankTree = async () => {
    const [resultCityTreeList] = await Promise.allSettled([getTissueCityTreeList({})])

    if (resultCityTreeList.status == "fulfilled") {
      setCityTreeList(handleData(resultCityTreeList?.value?.data))
    }
  }
  const handLimit: any = [
    {
      dataIndex: "totalCredit",
      title: "额度",
      editable: false,
      renderText: (text, record) => {
        return (
          <>
            {text ? (
              <span>
                {DeclarationCycleList[record?.declarationCycle]}:{formatAmount(text)}
              </span>
            ) : (
              "-"
            )}
          </>
        )
      },
    },
    {
      dataIndex: "availableCredit",
      title: "剩余授信额度",
      renderText: formatAmount,
      editable: false,
    },
  ]
  if (data?.type !== "ENTERPRISE" && data?.type !== "INDIVIDUAL_BUSINESS") {
    handLimit.length = 0
  }
  const handEditable = useCallback(() => {
    return data?.cancelStatus == "NORMAL" ? true : false
  }, [data])
  const headerDescColumns: ProDescriptionsItemProps<Tissue.Info>[] = [
    {
      dataIndex: "type",
      title: "组织类型",
      editable: false,
      valueEnum: OrgTypeEnum,
    },
    {
      dataIndex: "interconnectionConfig",
      title: "互联配置",
      valueType: "select",
      valueEnum: LineTypeEnum,
    },
    {
      dataIndex: `${data?.cancelStatus == "NORMAL" ? "status" : "cancelStatus"}`,
      title: "组织状态",
      valueType: "select",
      editable: handEditable,
      valueEnum: handEditable() ? OrgTypeStatusEnum : OrgTypeOutStatusEnum,
    },
    { dataIndex: "createTime", title: "创建时间", valueType: "dateTime", editable: false },
    { dataIndex: "modifyTime", title: "更新时间", valueType: "dateTime", editable: false },
    {
      dataIndex: "annualTradeAmount",
      title: "过去12个月交易额",
      editable: false,
      render: (_, record) => {
        return <span style={{ color: "red" }}> {formatAmount(_)}(不含税)</span>
      },
    },
    ...handLimit,
  ]
  //开票信息
  const invoiceDescColumns: ProDescriptionsItemProps<Tissue.Info>[] = [
    {
      dataIndex: "invoiceBankName",
      title: "开票行名称",
    },
    {
      dataIndex: "invoiceBankAccount",
      title: "开票行账号",
    },
    {
      dataIndex: "invoicePhone",
      title: "开票行预留手机号",
    },
    {
      dataIndex: "invoiceAddress",
      title: "开票地址",
    },
  ]
  const taxBasicInfoColumns: any = {
    dataIndex: "declarationCycle",
    title: "申报周期",
    // copyable: true,
    valueType: "select",
    fieldProps: {
      options: DeclarationCycleListEnum,
    },
    renderText: (text, record) => {
      return DeclarationCycleEnum[text]
    },
  }

  const taxColumns: any = {
    dataIndex: "invoiceClerk",
    title: "开票员",
    // editable: true,
    // renderText: (value) => value?.split(","),
    valueType: "select",
    fieldProps: {
      // mode: "multiple",
      options: getTaxMin,
    },
  }
  const placeholderColumns: any = {
    editable: false,
  }
  const basicInfoColumns: ProDescriptionsItemProps<Tissue.Info>[] = [
    {
      dataIndex: "name",
      title: "组织名称",
      copyable: true,
      fieldProps: {
        maxLength: 30,
      },
    },
    {
      dataIndex: "idNo",
      title: "统一信用代码",
      copyable: true,
      formItemProps: {
        rules: [
          { required: true },
          {
            message: "请填写正确格式",
            pattern: data?.type == "INDIVIDUAL_BUSINESS" ? RexIndividualCode : RexSocialCreditCode,
          },
        ],
      },
    },
    {
      dataIndex: "siteShortId",
      title: "店铺网址",
      copyable: true,
      editable: false,
      renderText: (text, record) => {
        return prodListJoinIdEnum[record?.siteShowType] + `${record?.siteShortId}`
      },
    },
  ]
  const basicAddressInfoColumns: ProDescriptionsItemProps<Tissue.Info>[] = [
    {
      title: "省市区",
      dataIndex: "bankCityTissue",
      render: (_, record, index, dom) => {
        return record?.provinceName + "-" + record?.cityName + "-" + record?.areaName
      },
      renderFormItem: (item) => {
        console.log(data, "data")
        return (
          <Cascader
            defaultValue={[data?.provinceName, data?.cityName, data?.areaName]}
            options={cityTreeList}
            onChange={(value, options) => {
              newData = options
              console.log(value, options)
            }}
            placeholder="请选择"
          />
        )
      },
    },
    {
      dataIndex: "detailAddress",
      title: "详细地址",
      copyable: true,
      fieldProps: {
        maxLength: 30,
      },
    },
    {
      dataIndex: "postCode",
      title: "邮政编码",
      copyable: true,
      formItemProps: {
        rules: [{ required: true }, { message: "请填写正确格式", pattern: RexPostalCode }],
      },
    },
  ]
  const basicTaxInfoColumns: ProDescriptionsItemProps<Tissue.Info>[] = [
    {
      dataIndex: "taxRegistrationStatus",
      title: "税务状态",
      valueType: "select",
      valueEnum: OrgTypeRegistrationEnum,
    },
    data?.taxRegistrationStatus == "REGISTERED" ? taxColumns : placeholderColumns,
    data?.taxRegistrationStatus == "REGISTERED" ? taxBasicInfoColumns : placeholderColumns,
  ]
  const basicAfterInfoColumns: ProDescriptionsItemProps<Tissue.Info>[] = [
    {
      dataIndex: "taxRegistrationId",
      title: "所属税区",
      copyable: true,
      valueType: "select",
      fieldProps: {
        dropdownStyle: {
          width: 200,
        },
        maxLength: 30,
        options: tissueService.taxRegionsList,
      },
      renderText: (text, record) => record.taxRegistrationName,
      editable: (value, row) => {
        if (row.applyNo) {
          return false
        } else {
          return true
        }
      },
    },
    {
      dataIndex: ["operationAuth", "operationId"],
      title: "运营方",
      copyable: true,
      formItemProps: {
        initialValue: tissueList?.operationAuth?.map((item) => item.operationId),
      },
      fieldProps: {
        dropdownStyle: {
          width: 200,
        },
        maxLength: 30,
        mode: "multiple",
        options: tissueService.tissueAuthList,
      },
      render: (_, record) => {
        return record?.operationAuth?.map((item) => item.operationName).join("、")
      },
      valueType: "select",
    },
    placeholderColumns,
  ]
  const basicInfoLegalIdNoColumns: any = {
    dataIndex: "legalIdNo",
    title: "经营者身份证",
    copyable: true,
  }
  const legalPersonColumns: any = {
    dataIndex: "operatorLegal",
    title: "法人",
    copyable: true,
  }
  const operatorColumns: any = {
    dataIndex: "operatorLegal",
    title: "经营者",
    copyable: true,
  }
  // 企业
  const basic_ENTERPRISE_InfoColumns: ProDescriptionsItemProps<Tissue.Info>[] | any = [
    ...basicInfoColumns,
    data?.type == "INDIVIDUAL_BUSINESS" ? operatorColumns : legalPersonColumns,
    data?.type == "INDIVIDUAL_BUSINESS" ? basicInfoLegalIdNoColumns : "",
    data?.taxRegistrationStatus == "REGISTERED" ? taxBasicInfoColumns : "",
    data?.taxRegistrationStatus == "REGISTERED" ? taxColumns : "",
    {
      dataIndex: "contact",
      title: "联系电话",
      copyable: true,
    },
    {
      editable: false,
    },
    ...basicAddressInfoColumns,
    ...basicTaxInfoColumns,
    ...basicAfterInfoColumns,
    {
      dataIndex: "idCardFront",
      title: "身份证",
      editable: false,
      render: (dom, record) => (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <OperableImage
              filename="身份证"
              width={140}
              height={94}
              tailor={true}
              uploadFileProps={{
                onChange: async (value) => {
                  const response = await uploadFileAndGettingRes(value.file)
                  delete record.operationAuth
                  await editTissueInfoService.run({
                    values: { ...record, idCardFront: response.data.filePath },
                    id: tissueService.id,
                  })
                },
              }}
              src={idCardFront}
            ></OperableImage>
            <div style={{ paddingLeft: 10 }}>
              <OperableImage
                width={140}
                height={94}
                tailor={true}
                uploadFileProps={{
                  onChange: async (value) => {
                    const response = await uploadFileAndGettingRes(value.file)
                    delete record.operationAuth
                    await editTissueInfoService.run({
                      values: { ...record, idCardBack: response.data.filePath },
                      id: tissueService.id,
                    })
                  },
                }}
                src={idCardBack}
              ></OperableImage>
            </div>
          </div>
        </>
      ),
    },
    {
      dataIndex: "idCardHead",
      title: "人像照",
      editable: false,
      render: (dom, record) => (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <OperableImage
              filename="人像照"
              width={67}
              height={94}
              uploadFileProps={{
                accept: "image/png,image/jpeg,image/jpg",
                beforeUpload: (file) => {
                  getFileToBase64(file).then((base64) => {
                    console.log(base64)
                    delete record.operationAuth
                    editTissueInfoService.run({
                      values: {
                        ...record,
                        idCardHead: base64.split(",")?.[1],
                        // idCardHead: advancedInfo.Portrait,
                      },
                      id: tissueService.id,
                    })
                  })
                  return false
                },
                // onChange: async (value) => {
                //   value.file.
                //   const response = await uploadFileAndGettingRes(value.file)
                //   // const avator = await getOcrIdCardFront({ url: response?.data.downloadLink })
                //   // const advancedInfo = JSON.parse(avator.data.advancedInfo)
                //   await editTissueInfoService.run({
                //     values: {
                //       ...record,
                //       idCardHead: response?.data.filePath,
                //       // idCardHead: advancedInfo.Portrait,
                //     },
                //     id: tissueService.id,
                //   })
                // },
              }}
              src={
                tissueService.tissueInfoService.data?.data.idCardHead
                  ? "data:image/jpeg;base64," +
                    tissueService.tissueInfoService.data?.data.idCardHead
                  : undefined
              }
            ></OperableImage>
          </div>
        </>
      ),
    },
    {
      dataIndex: "businessLicense",
      title: "营业执照",
      editable: false,
      render: (dom, record) => (
        <>
          <OperableImage
            filename="营业执照"
            width={178}
            height={121}
            uploadFileProps={{
              onChange: async (value) => {
                const response = await uploadFileAndGettingRes(value.file)
                delete record.operationAuth
                await editTissueInfoService.run({
                  values: { ...record, businessLicense: response.data.filePath },
                  id: tissueService.id,
                })
              },
            }}
            src={businessLicense}
          ></OperableImage>
        </>
      ),
    },
  ]
  // 个体户
  const basic_INDIVIDUAL_BUSINESSInfoColumns: ProDescriptionsItemProps<Tissue.Info>[] = [
    ...basicInfoColumns,
    {
      dataIndex: "operatorLegal",
      title: "经营者",
      copyable: true,
    },
    {
      dataIndex: "legalIdNo",
      title: "经营者身份证",
      copyable: true,
      fieldProps: {
        maxLength: 30,
      },
    },
    {
      dataIndex: "contact",
      title: "联系电话",
      copyable: true,
    },
    ...basicAddressInfoColumns,
    ...basicTaxInfoColumns,
    ...basicAfterInfoColumns,

    {
      dataIndex: "idCardFront",
      title: "身份证",
      editable: false,
      render: (dom, record) => (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <OperableImage
              filename="身份证"
              width={140}
              height={94}
              tailor={true}
              uploadFileProps={{
                onChange: async (value) => {
                  const response = await uploadFileAndGettingRes(value.file)
                  delete record.operationAuth
                  await editTissueInfoService.run({
                    values: { ...record, idCardFront: response.data.filePath },
                    id: tissueService.id,
                  })
                },
              }}
              src={idCardFront}
            ></OperableImage>
            <div style={{ paddingLeft: 10 }}>
              <OperableImage
                width={140}
                height={94}
                tailor={true}
                uploadFileProps={{
                  onChange: async (value) => {
                    const response = await uploadFileAndGettingRes(value.file)
                    delete record.operationAuth
                    await editTissueInfoService.run({
                      values: { ...record, idCardBack: response.data.filePath },
                      id: tissueService.id,
                    })
                  },
                }}
                src={idCardBack}
              ></OperableImage>
            </div>
          </div>
        </>
      ),
    },
    {
      dataIndex: "idCardHead",
      title: "人像照",
      editable: false,
      render: (dom, record) => (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <OperableImage
              filename="人像照"
              width={67}
              height={94}
              uploadFileProps={{
                accept: "image/png,image/jpeg,image/jpg",
                beforeUpload: (file) => {
                  getFileToBase64(file).then((base64) => {
                    console.log(base64)
                    delete record.operationAuth
                    editTissueInfoService.run({
                      values: {
                        ...record,
                        idCardHead: base64.split(",")?.[1],
                        // idCardHead: advancedInfo.Portrait,
                      },
                      id: tissueService.id,
                    })
                  })
                  return false
                },
                // onChange: async (value) => {
                //   value.file.
                //   const response = await uploadFileAndGettingRes(value.file)
                //   // const avator = await getOcrIdCardFront({ url: response?.data.downloadLink })
                //   // const advancedInfo = JSON.parse(avator.data.advancedInfo)
                //   await editTissueInfoService.run({
                //     values: {
                //       ...record,
                //       idCardHead: response?.data.filePath,
                //       // idCardHead: advancedInfo.Portrait,
                //     },
                //     id: tissueService.id,
                //   })
                // },
              }}
              src={
                tissueService.tissueInfoService.data?.data.idCardHead
                  ? "data:image/jpeg;base64," +
                    tissueService.tissueInfoService.data?.data.idCardHead
                  : undefined
              }
            ></OperableImage>
          </div>
        </>
      ),
    },
    {
      dataIndex: "businessLicense",
      title: "营业执照",
      editable: false,
      render: (dom, record) => (
        <>
          <OperableImage
            filename="营业执照"
            width={178}
            height={121}
            uploadFileProps={{
              onChange: async (value) => {
                const response = await uploadFileAndGettingRes(value.file)
                delete record.operationAuth
                await editTissueInfoService.run({
                  values: { ...record, businessLicense: response.data.filePath },
                  id: tissueService.id,
                })
              },
            }}
            src={businessLicense}
          ></OperableImage>
        </>
      ),
    },
  ]
  // 自然人
  const basic_INDIVIDUAL_InfoColumns: ProDescriptionsItemProps<Tissue.Info>[] = [
    {
      dataIndex: "name",
      title: "组织名称",
      copyable: true,
      fieldProps: {
        maxLength: 30,
      },
    },
    {
      dataIndex: "idNo",
      title: "身份证号 ",
      copyable: true,
      fieldProps: {
        maxLength: 30,
      },
    },
    {
      dataIndex: "contact",
      title: "联系电话",
      copyable: true,
    },
    ...basicAfterInfoColumns,
    {
      dataIndex: "idCardFront",
      title: "身份证",
      editable: false,
      render: (dom, record) => (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <OperableImage
              filename="身份证"
              width={140}
              height={94}
              tailor={true}
              uploadFileProps={{
                onChange: async (value) => {
                  const response = await uploadFileAndGettingRes(value.file)
                  delete record.operationAuth
                  await editTissueInfoService.run({
                    values: { ...record, idCardFront: response.data.filePath },
                    id: tissueService.id,
                  })
                },
              }}
              src={idCardFront}
            ></OperableImage>
            <div style={{ paddingLeft: 10 }}>
              <OperableImage
                width={140}
                height={94}
                tailor={true}
                uploadFileProps={{
                  onChange: async (value) => {
                    const response = await uploadFileAndGettingRes(value.file)
                    delete record.operationAuth
                    await editTissueInfoService.run({
                      values: { ...record, idCardBack: response.data.filePath },
                      id: tissueService.id,
                    })
                  },
                }}
                src={idCardBack}
              ></OperableImage>
            </div>
          </div>
        </>
      ),
    },
  ]
  // 其他
  const basic_OTHER_InfoColumns: ProDescriptionsItemProps<Tissue.Info>[] = [
    {
      dataIndex: "name",
      title: "组织名称",
      copyable: true,
      fieldProps: {
        maxLength: 30,
      },
    },
    {
      dataIndex: "idNo",
      title: "唯一识别号 ",
      copyable: true,
      fieldProps: {
        maxLength: 30,
      },
    },
    {
      dataIndex: "contact",
      title: "管理员手机号",
      copyable: true,
    },
    {
      dataIndex: "operatorLegal",
      title: "管理员姓名",
      copyable: true,
    },
    ...basicAfterInfoColumns,
  ]
  const appSave = () => {
    let _AutomaticData = AutomaticForm.getFieldsValue()
    console.log(radioValue, "radioValue")
    delete data.operationAuth
    editTissueInfoService.run({
      values: {
        ...data,
        applicationIds: appData,
        applications: appData,
        paymentApplyConfig: radioValue,
        buyerOrderAuto:
          _AutomaticData?.buyerOrderAuto?.length > 0 ? _AutomaticData?.buyerOrderAuto[0] : false,
        sellerOrderAuto:
          _AutomaticData?.sellerOrderAuto?.length > 0 ? _AutomaticData?.sellerOrderAuto[0] : false,
      },
      id: tissueService.id,
    })
  }

  const onChange = (checked: boolean) => {
    delete data.operationAuth
    editTissueInfoService.run({
      values: { ...data, authStatus: checked ? "true" : "false" },
      id: tissueService.id,
    })
  }
  const editTissueInfoService = useRequest(editTissueInfo, {
    manual: true,
    onSuccess: async (e) => {
      await tissueService.tissueInfoService.refreshAsync()
      e.data?.code == 200 && message.success("更新成功")
    },
  })
  const handApplicationBank = (type) => {
    let params = form.getFieldsValue()
    delete data.operationAuth
    editTissueInfoService.run({
      values: { ...data, ...params },
      id: tissueService.id,
    })
    type == "bank" ? setBankApplicationFlag(false) : setMakeOutAnInvoiceFlag(false)
  }
  const applicationBankButton = useCallback(
    (flag, type) => {
      return (
        <>
          <Access accessible={AccessEnum.op_platform_tissue_feature_detail_edit}>
            <Button
              type="primary"
              style={{ display: flag ? "none" : "" }}
              onClick={() => {
                if (type == "bank") {
                  form.setFieldsValue({ ...data })
                  setBankApplicationFlag(true)
                } else {
                  form.setFieldsValue({ ...data })
                  setMakeOutAnInvoiceFlag(true)
                }
              }}
            >
              编辑
            </Button>
          </Access>

          {flag
            ? [
                <Button type="primary" onClick={() => handApplicationBank(type)}>
                  保存
                </Button>,
                <Button
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    type == "bank" ? setBankApplicationFlag(false) : setMakeOutAnInvoiceFlag(false)
                  }}
                >
                  取消
                </Button>,
              ]
            : null}
        </>
      )
    },
    [bankApplicationFlag, makeOutAnInvoiceFlag, data]
  )
  const onRadioChange = (e: any) => {
    console.log("radio checked", e.target.value)
    setRadioValue(e.target.value)
  }
  const handGetTissueStrategiesAvailable = async (value) => {
    setTradeLoading(true)
    const result = await getTissueStrategiesAvailable({
      sellerId: paramsId.id,
      tradeStrategyId: value,
    })
    if (result.code == 200) {
      setTradeStrategyCredit(result?.data?.tradeStrategyCredit)
      setTradeLoading(false)
    }
  }
  const onChangeQuota = async ({ target: { value } }: RadioChangeEvent) => {
    setValueQuota(value)
    handGetTissueStrategiesAvailable(value)
  }
  console.log(tissueService?.tissueInfoService, "tissueService?.tissueInfoService")
  return (
    <PlatformSetupTissuePropsContext.Provider value={data}>
      <DetailPageContainer>
        <DetailPageHeader
          breadCrumb={[{ label: "平台设置" }, { label: "组织管理" }, { label: "详情" }]}
          title={
            <Space>
              <Button
                onClick={() => history.go(-1)}
                icon={<RollbackOutlined />}
                style={{ color: "#1890FF" }}
              >
                返回
              </Button>
              <span>{data?.name}</span>
            </Space>
          }
          rightContent={
            <Space size="large" align="center" style={{ whiteSpace: "nowrap" }}>
              <div>认证状态</div>
              <Switch
                style={{ marginLeft: -12 }}
                checked={Boolean(data?.authStatus)}
                checkedChildren="认证"
                unCheckedChildren="非认证"
                onChange={onChange}
                disabled={!authAccess}
              />
            </Space>
          }
        >
          <ProDescriptions
            column={4}
            editable={
              authAccess && {
                onSave: (keypath, newInfo, oriInfo) => {
                  delete newInfo.operationAuth
                  editTissueInfoService.run({ values: newInfo, id: tissueService.id })
                },
              }
            }
            columns={headerDescColumns}
            dataSource={tissueListTheTop}
          ></ProDescriptions>
        </DetailPageHeader>
        <DetailPageContent>
          <Card title="基本信息">
            <ProDescriptions
              loading={tissueService.tissueInfoService?.loading}
              editable={
                authAccess && {
                  onSave: (keypath, newInfo, oriInfo) => {
                    console.log(newInfo, keypath, "newInfo")
                    let params: any = {}
                    let handleOperationAuth = {}
                    if (newData?.length > 0) {
                      params = {
                        provinceCode: newData[0]?.value,
                        cityCode: newData[1]?.value,
                        areaCode: newData[2]?.value,
                        provinceName: newData[0]?.label,
                        cityName: newData[1]?.label,
                        areaName: newData[2].label,
                      }
                    }
                    if (keypath[0] == "operationAuth") {
                      handleOperationAuth = {
                        operationAuth: {
                          ...newInfo.operationAuth,
                          operationAuthType: "ORGANIZATION",
                        },
                      }
                    } else {
                      delete newInfo.operationAuth
                    }
                    let handleParams = {
                      ...newInfo,
                      legalIdNo: newInfo?.legalIdNo,
                      idCardNo: newInfo?.legalIdNo,
                      idNo: newInfo.idNo.trim(),
                      invoiceClerk:
                        typeof newInfo?.invoiceClerk == "string"
                          ? [newInfo?.invoiceClerk]
                          : newInfo?.invoiceClerk,
                      taxRegistrationId: newInfo.taxRegistrationId ? newInfo.taxRegistrationId : "",
                    }
                    editTissueInfoService.run({
                      values: {
                        ...handleParams,
                        ...params,
                        ...handleOperationAuth,
                      },
                      id: tissueService.id,
                    })
                  },
                }
              }
              columns={
                data?.type === "INDIVIDUAL_BUSINESS"
                  ? basic_INDIVIDUAL_BUSINESSInfoColumns
                  : data?.type === "OTHER"
                  ? basic_OTHER_InfoColumns
                  : data?.type === "INDIVIDUAL"
                  ? basic_INDIVIDUAL_InfoColumns
                  : basic_ENTERPRISE_InfoColumns
              }
              dataSource={tissueList}
            ></ProDescriptions>
          </Card>
          {data?.type == "INDIVIDUAL_BUSINESS" || data?.type == "INDIVIDUAL" ? (
            <Card title="交易限额">
              <div className="tissue-radio">
                <Radio.Group
                  options={tradeStrategies}
                  onChange={onChangeQuota}
                  value={valueQuota}
                  optionType="button"
                />
              </div>
              <div className="limit-info" style={{ marginTop: "12px" }}>
                <Table
                  columns={purchaseColumns}
                  loading={tradeLoading}
                  dataSource={tradeStrategyCredit}
                  pagination={false}
                ></Table>
              </div>
            </Card>
          ) : null}
          <Form form={form} name="basic" initialValues={{ remember: true }} autoComplete="off">
            {/* <Card title="银行信息" extra={applicationBankButton(bankApplicationFlag, "bank")}>
              {bankApplicationFlag ? (
                <ApplicationBank form={form} />
              ) : (
                <ApplicationInfoBank data={data} />
              )}
            </Card> */}
            <Card
              title="开票信息"
              style={{ marginTop: 16 }}
              extra={applicationBankButton(makeOutAnInvoiceFlag, "make")}
            >
              {makeOutAnInvoiceFlag ? (
                <MakeOutEditAnInvoice form={form} />
              ) : (
                <MakeOutAnInvoice
                  getTaxMin={getTaxMin}
                  editTissueInfoService={editTissueInfoService}
                  data={data}
                />
              )}
            </Card>
          </Form>
          {["INDIVIDUAL", "OTHER", "INDIVIDUAL_BUSINESS", undefined].includes(
            data?.type
          ) ? undefined : (
            <Card title="签章管理" styles={{ body: { minHeight: 500 } }}>
              <SignatureSetting
                vendor={{ orgId: tissueService?.tissueInfoService?.data?.data?.idNo, id: "" }}
              />
            </Card>
          )}
          <Card
            title="应用管理"
            extra={
              <Access accessible={AccessEnum.op_platform_tissue_feature_detail_edit}>
                <Button type="primary" onClick={() => appSave()}>
                  保存
                </Button>
              </Access>
            }
          >
            <ApplicationManagement setAppData={setAppData} appData={appData} />
          </Card>
          <Card
            title="订单审核配置"
            extra={
              <Button type="primary" onClick={() => appSave()}>
                保存
              </Button>
            }
          >
            <Form form={AutomaticForm} style={{ display: "flex", marginLeft: -20 }}>
              <Form.Item name="buyerOrderAuto" label="自动审核">
                <Checkbox.Group style={{ width: "100%" }}>
                  <Checkbox value={true}>作为购方订单自动审核通过</Checkbox>
                </Checkbox.Group>
              </Form.Item>
              <Form.Item name="sellerOrderAuto">
                <Checkbox.Group style={{ width: "100%" }}>
                  <Checkbox value={true}>作为销方订单自动审核通过</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Form>
          </Card>
          <Card
            title="付款申请配置"
            extra={
              // <Access accessible={AccessEnum.op_platform_tissue_feature_detail_edit}>
              <Button type="primary" onClick={() => appSave()}>
                保存
              </Button>
              // </Access>
            }
          >
            <Form form={paymentForm}>
              <Form.Item
                name="paymentApplyConfig"
                label="付款申请审批"
                style={{ marginLeft: -20, marginBottom: -5 }}
              >
                <Radio.Group onChange={onRadioChange}>
                  <Radio value="AUTO"> 自动审批通过 </Radio>
                  <Radio value="EASY"> 简易审批 </Radio>
                </Radio.Group>
              </Form.Item>
            </Form>
          </Card>
          <Card
            title="存证配置"
            extra={
              <Button
                type="primary"
                onClick={() =>
                  navigate(`/evidence/platform/add_evidence`, {
                    state: {
                      organizationId: tissueService.id,
                    },
                  })
                }
              >
                新增存证
              </Button>
            }
          >
            <EvidenceConfigList id={tissueService.id} />
          </Card>
        </DetailPageContent>
        <Modal
          visible={visible}
          title="Select Country"
          onCancel={() => setVisible(false)}
          onOk={() => setVisible(false)}
        >
          <Cascader
            options={cityTreeList}
            onChange={(value) => console.log(value, "value")}
            placeholder="Please select"
          />
        </Modal>
      </DetailPageContainer>
    </PlatformSetupTissuePropsContext.Provider>
  )
}
export default PlatformSetupTissueInfo
