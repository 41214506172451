import React from "react"
import { Outlet, useNavigate, useLocation } from "react-router-dom"
import TopNavbar from "../../components/TopNavbar"
import LeftNavbar from "../../components/LeftNavbar"
import { TableOutlined, DashboardOutlined, DatabaseOutlined } from "@ant-design/icons"
import { useSelector } from "react-redux"
import "./index.scss"

const Entity = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const roleList =
    useSelector((state: any) => state?.meunList.filter((ie: any) => ie.name == "代办业务")) || []
  return (
    <div className="evidence-container">
      <TopNavbar config={{ value: "agencyBusiness" }} />
      <div className="evidence-container-content">
        <LeftNavbar lists={roleList[0]?.children} />
        <Outlet></Outlet>
      </div>
    </div>
  )
}

export default Entity
