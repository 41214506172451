import { Button, Form, Input, Select, Upload, Space, UploadFile } from "antd"
import React, { useState, memo, useContext } from "react"
import { UploadOutlined } from "@ant-design/icons"
import { ModalFormContext } from "@/components/ModalForm"
import { cross_env } from "../../../services/serviceOp"
export interface SupplyContractOfflineFormValues extends API.Logistics.purchaseAgreement {
  validationDate: [string, string]
  purchasePlanId: string
}
const defaultProps = {}
type props = {
  notes?: string
}
export type SupplyContractOfflineFormProps = Required<typeof defaultProps> &
  props &
  React.HTMLAttributes<HTMLDivElement>
export const SupplyContractOfflineForm: React.FC<
  React.PropsWithChildren<SupplyContractOfflineFormProps>
> = memo((props) => {
  const [fileList, setFileList] = useState<UploadFile[]>()
  const modalFormContext = useContext(ModalFormContext)
  return (
    <>
      <Form.Item
        name="contractType"
        label="选择合同类型"
        rules={[{ required: true, message: "请选择" }]}
      >
        <Select
          placeholder="请选择"
          allowClear
          options={[
            { value: "SUPPLY", label: "供货协议" },
            { value: "PURCHASE", label: "采购合同" },
          ]}
        />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: "请上传合同" }]}
        name="agreementUploadFiles"
        label="上传合同"
      >
        <Upload<Common.Res<{ fileKey: string }>>
          maxCount={1}
          action={`${cross_env}/rscm/rscm-resource/oss/endpoint`}
          fileList={fileList}
          method="put"
          headers={{
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("operation_authorization") || "{}"
            )?.access_token_op}`,
          }}
          onChange={({ fileList }) => {
            setFileList(fileList)
            // 全都完成了
            if (!fileList.some((file) => file.status !== "done")) {
              modalFormContext?.form.setFieldValue(
                "agreementUploadFiles",
                fileList.map((file) => file.response?.data)
              )
            }
          }}
        >
          <Space>
            <Button icon={<UploadOutlined />}>点击上传</Button>
            <span style={{ color: "#00000073" }}>支持jpg/png/pdf文件，最多上传1个</span>
          </Space>
        </Upload>
      </Form.Item>
    </>
  )
})
SupplyContractOfflineForm.displayName = "线下供货合同上传表单"
SupplyContractOfflineForm.defaultProps = defaultProps
