const withAllowClear = {
  allowClear: true,
}
export default {
  type: "object",
  labelWidth: 76,
  properties: {
    status: {
      title: "状态",
      type: "string",
      widget: "select",
      enum: ["true", "false"],
      enumNames: ["正常", "停用"],
      placeholder: "请选择",
      props: withAllowClear,
    },
    provider: {
      title: "运营标识",
      type: "string",
      widget: "select",
      placeholder: "请选择",
      props: withAllowClear,
    },
    keywords: {
      title: "搜索",
      type: "string",
      widget: "input",
      placeholder: "输入姓名,账号,手机号搜索",
      props: withAllowClear,
    },
  },
}
