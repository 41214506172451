import numeral from "numeral"
import { getOrderUploadByPath } from "./service"
const unitMap = {
  KG: "千克",
  G: "克",
  T: "吨",
  PCS: "台",
  P: "个",
}
export function convertData(
  orderDetail?: API.Schema.OrderDetail,
  logistics?: API.OrderLogistics[]
) {
  const invoices = orderDetail?.invoices?.map((invoice) => ({
    ...invoice,
    invoiceStatus:
      invoice.invoiceStatus === "ISSUED"
        ? Number(invoice.amountWithTax) < 0
          ? "FLUSHED"
          : "ISSUED"
        : invoice.invoiceStatus,
  }))
  const invoicedAmounts = invoices?.reduce(
    (sumObj, invoice) =>
      invoice.invoiceStatus === "CANCELED"
        ? sumObj
        : {
            totalAmount: numeral(sumObj.totalAmount).add(invoice.amountWithTax).value() || 0,
            vatTax: numeral(sumObj.vatTax).add(invoice.taxAmount).value() || 0,
            quantity: numeral(sumObj.quantity).add(invoice.itemQuantity).value() || 0,
          },
    { totalAmount: 0, vatTax: 0, quantity: 0 }
  )
  const localData: Partial<API.Order.Info> = {
    ...(orderDetail?.order
      ? {
          ...orderDetail.order,
          afterVatAmt: orderDetail?.order.afterVatAmt,
          invoiceTotalAmountWithPending: orderDetail?.order.invoiceTotalAmountWithPending,
          supplyStatusName: orderDetail?.order.supplyStatusName,
          logisticsName: orderDetail?.order.logisticsName,
          quantity: orderDetail?.order.quantity,
          id: orderDetail.order.id?.toString(),
          channelName: orderDetail.order.channel,
          orderNo: orderDetail.order.orderNo,
          paymentStatus: orderDetail.order?.paymentStatus,
          invoiceStatus: orderDetail.order?.invoiceStatus,
          // @ts-ignore
          unitOfMeasureShow: unitMap[orderDetail?.unitOfMeasure] || orderDetail?.unitOfMeasure,
          unitOfMeasure: orderDetail.order?.unitOfMeasure,
          subChannel: orderDetail.order?.subChannel,
          supplier: orderDetail?.seller
            ? {
                id: orderDetail?.seller.id,
                idNumber: orderDetail?.seller.orgNo,
                name: orderDetail?.seller.name,
                type: orderDetail?.seller.type,
                taxNo: orderDetail?.seller.idNo, // 根据实际情况填充
              }
            : undefined,
          buyer: orderDetail?.buyer
            ? {
                id: orderDetail?.buyer.id,
                name: orderDetail?.buyer.name,
                type: orderDetail?.buyer.type,
                taxNo: orderDetail?.buyer.idNo, // 根据实际情况填充
              }
            : undefined,
          receiveOrgName: orderDetail?.order?.receiveOrgName,
          receiveDistrict: orderDetail?.order?.receiveDistrict,
          invoiceCategoryConfigName: orderDetail?.order?.invoiceCategoryConfigName,
          merchandises: [
            {
              unitOfMeasure:
                // @ts-ignore
                unitMap[orderDetail.order?.unitOfMeasure] || orderDetail.order?.unitOfMeasure,
              name: orderDetail.order.categoryName,
              quantity: orderDetail.order?.quantity?.toString(),
              unitPrice: orderDetail.order?.unitPrice,
              totalPrice: orderDetail.order?.beforeVatAmt,
              vatAmount: orderDetail.order?.vatAmt,
              totalAmount: orderDetail.order?.afterVatAmt,
            },
            // 可根据需要添加更多 merchandise 转换
          ],
          differentialAmount: orderDetail.order.inputAward,
          // 1.0不提供采购合同
          contract: {
            supplier: orderDetail.sellSigner
              ? {
                  idCard: orderDetail.order.sel.sellSigner.idCard,
                  signer: orderDetail.sellSigner.name,
                  phone: orderDetail.sellSigner.phone,
                  signStatus: ["SELLER_COMPLETED", "ALL_COMPLETED"].includes(
                    orderDetail.contractStatus
                  ),
                }
              : undefined,
            buyer: orderDetail.buySigner
              ? {
                  idCard: orderDetail.buySigner.idCard,
                  signer: orderDetail.buySigner.name,
                  phone: orderDetail.buySigner.phone,
                  signStatus: ["BUYER_COMPLETED", "ALL_COMPLETED"].includes(
                    orderDetail.contractStatus
                  ),
                }
              : undefined,
            signStatus: orderDetail?.contractStatus,
            contractStatusName: orderDetail?.contractStatusName,
            contractNo: orderDetail?.contractNo,
            generateTime: orderDetail.contractCreateDate,
          },
          amounts: {
            invoicedAmounts: invoicedAmounts,
            unInvoicedAmounts: {
              totalAmount: numeral(orderDetail.order?.afterVatAmt)
                .subtract(invoicedAmounts?.totalAmount)
                .value(),
              vatTax: numeral(orderDetail.order?.vatAmt)
                .subtract(invoicedAmounts?.vatTax)
                .value(),
              quantity: numeral(orderDetail.order?.quantity)
                .subtract(invoicedAmounts?.quantity)
                .value(),
            },
            totalAmount: orderDetail?.payments.totalReceivableAmount,
            paidAmount: orderDetail?.payments.paidReceivableAmount,
            unpaidAmount: orderDetail?.payments.unpaidReceivableAmount,
            taxAmount: orderDetail?.order?.vatAmt,
            paidTaxAmount: orderDetail?.order?.paidVatAmt,
            unpaidTaxAmount: numeral(orderDetail?.order?.vatAmt || 0)
              .subtract(orderDetail?.order?.paidVatAmt || 0)
              .value(),
            grossAmount: orderDetail?.payments.sellerReceivableAmount,
            paidGrossAmount: orderDetail?.payments.paidSellerReceivable,
            unpaidGrossAmount: orderDetail?.payments.unpaidSellerReceivable,
            deductAmount: 0, // 根据实际情况填充
            vatAmount: orderDetail?.order?.vatAmt,
            paidVatAmount: orderDetail?.order?.paidVatAmt,
            unpaidVatAmount: numeral(orderDetail?.order?.vatAmt || 0)
              .subtract(orderDetail?.order?.paidVatAmt || 0)
              .value(),
            invoiceAmount: orderDetail?.order?.invoiceAmount,
          },
          createTime: orderDetail?.order.createdDate,
        }
      : {}),
    auditLogs: orderDetail?.auditLogs,
    payments: orderDetail?.payments,
    logistics: logistics,
    invoices: invoices,
    // @ts-ignore
    supplyContracts: orderDetail?.contracts?.map((contract) => ({
      ...contract,
      fileIds: [contract.fileId],
    })),
  }

  return localData
}

export const getFileType = (url) => {
  const extension = url.split("?")[0]
  const parts = extension.split("/").pop().split(".")
  if (parts?.length > 1) {
    if (
      parts[1] === "jpg" ||
      parts[1] === "jpeg" ||
      parts[1] === "png" ||
      parts[1] === "gif" ||
      parts[1] === "bmp"
    ) {
      return "image"
    } else {
      return "file"
    }
  }
}

/**
 * 获取文件信息，下载文件
 * @param fileKey
 */
export const getFileUrl = async (fileKey: string) => {
  let params = {
    filePaths: [fileKey],
  }
  const result: any = await getOrderUploadByPath(params)
  if (result.code == 200) {
    let download_a = document.createElement("a")
    download_a.setAttribute("href", result?.data?.files[0]?.downloadUrl)
    download_a.setAttribute("target", "_blank")
    download_a.setAttribute("id", "camnpr")
    document.body.appendChild(download_a)
    download_a.click()
    document.body.removeChild(download_a)
  }
}

export const getUrl = async (fileKey: string) => {
  let params = {
    filePaths: [fileKey],
  }
  const result: any = await getOrderUploadByPath(params)
  if (result.code == 200) {
    return result?.data?.files[0]?.downloadUrl
  }
}
export function validatePayments(payments: any) {
  const amounts = Object.values(payments).map((payment: any) => payment.amount)
  const zeroCount = amounts.filter((amount) => amount === 0).length
  const nonZeroCount = amounts.length - zeroCount

  if (Object.keys(payments).length === 1) {
    // 只有一个支付项，amount 不能为 0
    if (zeroCount > 0) {
      return false
    } else {
      return true
    }
  } else if (Object.keys(payments).length >= 2) {
    // 两个或更多支付项，最多只有一个 amount 可以为 0
    if (zeroCount > 1) {
      return false
    } else if (nonZeroCount === 0) {
      return false
    } else {
      return true
    }
  } else {
    return true // 根据需要处理其他情况
  }
}
