const withAllowClear = {
  allowClear: true,
}
export default {
  type: "object",
  labelWidth: 110,
  properties: {
    legalName: {
      title: "经营者姓名",
      type: "string",
      widget: "input",
      placeholder: "请输入",
      props: withAllowClear,
    },
    organizationName: {
      title: "组织名称",
      type: "string",
      widget: "input",
      placeholder: "请输入",
      props: withAllowClear,
    },
    regionId: {
      title: "所属税区",
      type: "string",
      widget: "select",
      enum: [],
      enumNames: [],
      placeholder: "请选择",
      props: withAllowClear,
    },
    status: {
      title: "注销状态",
      type: "string",
      widget: "select",
      enum: [
        "APPLY_FILE_WAIT_SIGN",
        "TAX_WAIT_CLEAN",
        "LICENSE_WAIT_CANCEL",
        "CANCEL_COMPLETE",
        "REVOKE",
      ],
      enumNames: ["申请书待签署", "待清税", "执照待注销", "已注销", "已撤销"],
      placeholder: "请选择",
      props: { ...withAllowClear },
    },
    applyNo: {
      title: "注销申请编号",
      type: "string",
      widget: "input",
      placeholder: "请输入",
      props: withAllowClear,
    },
    isConnectMe: {
      title: "查看范围",
      type: "string",
      widget: "select",
      enum: [false, true],
      enumNames: ["全部", "与我相关"],
      placeholder: "请选择",
      props: { ...withAllowClear },
    },
  },
}
