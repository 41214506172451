import { OPL_INVOICE, OP_INVOICE, OP_TRADE } from "../../../api/config/servicePort"
import UtilOp from "../../../services/serviceOp"
const util = UtilOp.getInstance()
const utilOp = UtilOp.getInstance()
const currentDate = new Date()
const year = currentDate.getFullYear() // 获取年
const month = currentDate.getMonth() + 1 // 获取月份（注意要加1，因为月份是从0开始的）
const day = currentDate.getDate() // 获取日
// 删除申请
export const deleteLicense = (id: string) => {
  return util
    .httpDeletel(OP_INVOICE + `/sinzetech-agency-processing/license/${id}`)
    .then((res) => res)
}

// 开票方列表
export const getInvoicingList = (params: any) => {
  return util.httpGet(OP_INVOICE + `/taxpayers`, params).then((res) => res)
}
// 手动更新
export const upDataCredit = (params: any) => {
  return util.httpPut(OP_INVOICE + "/taxpayers/manual-update-credit", params)
}
// 自动更新
export const apiUpdateCredit = (id: any, params?: any) => {
  return util.httpPut(OP_INVOICE + `/taxpayers/${id}/api-update-credit`, params)
}

// 开票任务列表
export const getInvoicingTasksList = (params: any) => {
  return util.httpGet(`${OP_INVOICE}/taxpayer-tasks`, params).then((res) => res)
}
// 开票渠道
export const getupDataManualCredit = (params: any) => {
  return util.httpPut(OP_INVOICE + `/taxpayer-tasks/${params.id}?channel=${params.channel}`, params)
}
// 发票台账列表
export const getInvoicesList = (params: any) => {
  return util.httpGet(`${OP_INVOICE}/invoices`, params).then((res) => res)
}
// 发票台账列表
export const getInvoicesPreviewList = (params: any) => {
  return util.httpGet(`${OP_INVOICE}/invoices/preview/${params.id}`).then((res) => res)
}
// 开票方列表编辑
export const getTaxpayersInfoEdit = (params: any) => {
  return util.httpPut(OP_INVOICE + `/taxpayers/update-tax-info`, params)
}

export const exportDownloadExcel = (params: any) => {
  return utilOp
    .httpGet(OPL_INVOICE + `/invoices/export-file`, params, {
      responseType: "blob", // 设置响应数据类型为二进制流
    })
    .then((response: any) => {
      window.open(response.data)
    })
}
