/* eslint-disable react/jsx-key */
/* eslint-disable react-hooks/exhaustive-deps */
import { UploadOutlined } from "@ant-design/icons"
import { EditableFormInstance, EditableProTable, ProColumns } from "@ant-design/pro-table"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import { useRequest } from "ahooks"
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Select,
  Space,
  Upload,
  UploadFile,
  message,
} from "antd"
import { RcFile } from "antd/es/upload"
import _ from "lodash"
import React, { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { getOrgs } from "../../../../api/service"
import { cross_env } from "../../../../services/serviceOp"
import { getTissueList } from "../../promises"
import { columnsPublic, contractTypeEnum, partTypeListEnum } from "../constant"
import { createContract, editContractInfo, getContractInfo } from "../promises"
import "./index.scss"
const { Option } = Select
let _type = "add"
const findDuplicates = (arr) => {
  const uniqueObjects = []
  const duplicateObjects = []
  arr.forEach((obj, index) => {
    const isDuplicate = uniqueObjects.some((uniqueObj) =>
      ["part", "signType", "posPage", "posX", "posY"].every((prop) =>
        _.isEqual(uniqueObj[prop], obj[prop])
      )
    )
    if (isDuplicate) {
      duplicateObjects.push({ ...obj, index })
    } else {
      uniqueObjects.push({ ...obj, index })
    }
  })
  return duplicateObjects
}
const ContractTemplateAdd = () => {
  const [form] = Form.useForm()
  const params = useParams()
  const editorFormRef = useRef<EditableFormInstance<DataSourceType>>()
  const actionRef = useRef<EditableFormInstance>()
  const [fileList, setFileList] = useState<UploadFile[]>()
  const [editableKeys, setEditableRowKeys] = useState<any>(() => [])
  const [tissueAuthList, setTissueAuthList] = useState<any>([]) //组织当前列表

  const tissueListService = useRequest(getTissueList, {
    manual: true,
  })
  const editContractInfoService = useRequest(editContractInfo, {
    debounceWait: 800,
    manual: true,
    onSuccess: (data) => {
      message.success("操作成功")
      history.go(-1)
    },
  })
  const createContractService = useRequest(createContract, {
    debounceWait: 800,
    manual: true,
    onSuccess: (data) => {
      if (data?.data?.code == 200 || data?.code == 200) {
        message.success("操作成功")
        history.go(-1)
      }
    },
  })
  const contractInfoService = useRequest(getContractInfo, {
    manual: true,
    defaultParams: [params.id!],
    refreshDeps: [params.id],
    onSuccess: (response) => {
      form.setFieldsValue({
        type: response.data.type,
        organizationIds: response.data.organizationVos?.map((item) => item.id),
        bookFileId: response.data?.bookFileId,
        name: response.data?.name,
        operationAuth: {
          operationAuthType: "CONTRACT",
          operationId: response.data?.operationAuth?.length
            ? response.data?.operationAuth?.map((item) => item.operationId)
            : [
                JSON.parse(
                  localStorage.getItem("operation_tissue") || "{}"
                )?.organizationId?.toString(),
              ],
        },
      })
      setFileList([
        {
          status: "done",
          name: response.data.name,
          type: "picture",
        },
      ])
      // actionRef.current?.setFieldsValue(response.data.templateConfig.signConfigs)
    },
  })
  //组织当前列表
  const tissueAuthInit = async () => {
    const response = await getOrgs()
    if (response.code == 200) {
      const result = response.data?.map((ie) => {
        return {
          label: ie.name,
          value: ie.id,
        }
      })
      setTissueAuthList(result)
    }
  }
  useEffect(() => {
    if (params.id) {
      contractInfoService.run(params.id)
    }
  }, [params.id])
  useEffect(() => {
    tissueAuthInit()
    tissueListService.run({
      size: -1,
      searchCount: true,
    })
  }, [])
  const onFinish = async (values: Contract.Info) => {
    let data = {
      ...form.getFieldsValue(),
      organizationIds:
        form.getFieldValue("organizationIds")?.length === 0 ||
        !form.getFieldValue("organizationIds")
          ? [-1]
          : form.getFieldValue("organizationIds"),
    }
    const duplicates = findDuplicates(data?.templateConfig?.signConfigs || [])
    console.log(duplicates, data?.name?.lastIndexOf("."), "duplicates")
    if (data?.name?.length > 30) {
      message.error(`模板名称字数不可超过30个字`)
      return
    }
    if (duplicates?.length > 0) {
      message.error(`序号${duplicates[0]?.index + 1}为重复项，请删除多余行`)
      return
    }
    form.validateFields().then(async () => {
      params.type === "edit"
        ? editContractInfoService.run({ ...data, id: params.id })
        : createContractService.run({ ...data })
    })
  }
  const columns: ProColumns[] = [
    ...columnsPublic,
    {
      title: "操作",
      valueType: "option",
      // eslint-disable-next-line no-sparse-arrays
      render: (text, record, recordIndex, action) => [
        <a
          key="edit"
          onClick={() => {
            _type = "edit"
            action?.startEditable?.(record.id)
            setEditableRowKeys([record.id])
          }}
        >
          编辑
        </a>,
        <a
          key="delete"
          onClick={() => {
            const tableDataSource = form?.getFieldValue(["templateConfig", "signConfigs"])
            form?.setFieldsValue({
              templateConfig: {
                signConfigs: tableDataSource.filter((item) => item.id !== record.id),
              },
            })
          }}
        >
          删除
        </a>,
      ],
    },
  ]
  const beforeUpload = (file: RcFile, FileList: RcFile[]): boolean => {
    const isDoc =
      file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      file.type === "application/msword"
    if (!isDoc) {
      message.error("只能上传.doc,.docx格式的文件")
    }
    // 如果需要阻止列表展现，可以通过返回 Upload.LIST_IGNORE 实现。
    return isDoc || Upload.LIST_IGNORE
  }
  const handDefaultDom = (row: any, type: string) => {
    return (
      <>
        <a
          onClick={() => {
            const tableDataSource = form?.getFieldValue(["templateConfig", "signConfigs"])
            console.log(tableDataSource, "tableDataSource")
            const existingItemIndex = tableDataSource.findIndex((item) => item.id === row.id)
            if (existingItemIndex > -1) {
              tableDataSource[existingItemIndex] = row
            } else {
              tableDataSource.push(row)
            }
            form?.setFieldsValue({
              templateConfig: {
                signConfigs: tableDataSource,
              },
            })
            setEditableRowKeys([])
          }}
        >
          {type}
        </a>
      </>
    )
  }

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <span>{params.type === "add" ? "新建" : "编辑"}合同模板</span>
          </Space>
        }
        breadCrumb={[
          { label: "合同管理", path: "" },
          { label: "合同模板管理", path: "" },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <DetailPageContent>
        <Form
          form={form}
          className="contract_template_create"
          initialValues={{
            type: contractInfoService.data?.data.type,
            organizationIds: contractInfoService?.data?.data.organizationVos?.map(
              (item) => item.id
            ),
            bookFileId: contractInfoService?.data?.data?.bookFileId,
            name: contractInfoService?.data?.data?.name,
            templateConfig: {
              signConfigs: contractInfoService?.data?.data?.templateConfig.signConfigs,
            },
            operationAuth: {
              operationId: contractInfoService?.data?.data?.operationAuth?.length
                ? contractInfoService?.data?.data?.operationAuth?.map((item) => item.operationId)
                : [
                    JSON.parse(
                      localStorage.getItem("operation_tissue") || "{}"
                    )?.organizationId?.toString(),
                  ],
              operationAuthType: "CONTRACT",
            },
          }}
          colon={false}
          style={{ background: "#fff" }}
        >
          <Card title={"合同信息"} bordered={false}>
            <Form.Item name={["operationAuth", "operationAuthType"]} hidden={true}></Form.Item>
            <Form.Item
              name={["operationAuth", "operationId"]}
              label="运营方"
              rules={[{ required: true, message: "请选择" }]}
            >
              <Select
                // placeholder="选择运营方"
                mode="multiple"
                style={{ width: 521 }}
                showSearch
                maxTagCount={6}
                onChange={(e) => {}}
                filterOption={filterOption}
                options={tissueAuthList}
              />
            </Form.Item>
            <Form.Item name="type" label="选择合同类型" rules={[{ required: true }]}>
              <Select style={{ width: 521 }} options={contractTypeEnum} />
            </Form.Item>
            <Form.Item>
              <Space style={{ alignItems: "baseline" }}>
                <Form.Item name="organizationIds" label="选择可用组织">
                  <Select
                    mode="multiple"
                    style={{ width: 521 }}
                    allowClear
                    optionFilterProp="children"
                    showSearch
                    maxTagCount={6}
                    onSearch={async (e) => {
                      await tissueListService.run({
                        keywords: e,
                        size: -1,
                        searchCount: true,
                      })
                    }}
                  >
                    {tissueListService.data?.data?.records?.map((buyer) => (
                      <Option value={buyer.id} key={buyer.id}>
                        {buyer.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <span style={{ color: "#00000073" }}>若不选择关联组织，则默认应用全部</span>
              </Space>
            </Form.Item>
            <Form.Item
              name="bookFileId"
              rules={[{ required: true, message: "请上传合同模板" }]}
              label="上传合同模板"
              // getValueFromEvent={normFile}
            >
              <Upload
                listType="picture"
                maxCount={1}
                accept=".doc,.docx"
                action={`${cross_env}/rscm/rscm-resource/oss/endpoint`}
                headers={{
                  Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem("operation_authorization") || "{}"
                  )?.access_token_op}`,
                }}
                onChange={({ fileList }) => {
                  setFileList(fileList)
                  // 全都完成了
                  if (fileList.some((file) => file.status == "done")) {
                    form?.setFieldsValue(
                      {
                        bookFileId: fileList[0]?.response?.data?.filePath,
                        name: fileList[0]?.name,
                      }
                      // fileList.map((file) => file.response?.data?.downloadLink)
                    )
                  }
                }}
                beforeUpload={beforeUpload}
                fileList={fileList}
                method="put"
              >
                <Space>
                  <Button icon={<UploadOutlined />}>选择文件</Button>
                  <span style={{ color: "#00000073" }}>支持.doc、.docx格式文件</span>
                </Space>
              </Upload>
            </Form.Item>
            <Form.Item name="name" label="模板名称" rules={[{ required: true }]}>
              <Input style={{ width: 521 }} maxLength={30} />
            </Form.Item>
          </Card>
          <Card
            bordered={false}
            title={"签署配置"}
            extra={
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    _type = "add"
                    actionRef.current?.addEditRecord?.({
                      id: (Math.random() * 1000000).toFixed(0),
                    })
                  }}
                >
                  添加一行
                </Button>
                <Button
                  onClick={() => window.open("https://open.esign.cn/tools/seal-position", "_blank")}
                >
                  获取文档坐标
                </Button>
              </Space>
            }
          >
            <EditableProTable
              rowKey="id"
              name={["templateConfig", "signConfigs"]}
              actionRef={actionRef}
              editableFormRef={editorFormRef}
              recordCreatorProps={false}
              request={async (_, sort, filter) => {
                const response = params.id && (await getContractInfo(params.id))
                // 因不返回id,导致编辑时删除会将所有数据删除，添加index为索引
                const data = response?.data?.templateConfig.signConfigs.map(
                  (signConfig, index) => ({
                    ...signConfig,
                    id: index,
                  })
                )
                return {
                  data: data,
                  success: Boolean(response?.data?.templateConfig.signConfigs),
                  total: response?.data?.templateConfig.signConfigs?.length || 0,
                }
              }}
              editable={{
                editableKeys,
                onSave: async () => {
                  await form.validateFields()
                  let formData = form.getFieldsValue()
                  // 根据 part 分组
                  const groupedData = {}
                  formData?.templateConfig?.signConfigs?.forEach((item) => {
                    if (!groupedData[item.part]) {
                      groupedData[item.part] = []
                    }
                    groupedData[item.part].push(item)
                  })
                  // 检查每个 part 下的 type 是否相同
                  for (const part in groupedData) {
                    const types = new Set(groupedData[part].map((item) => item.type + ""))
                    if (types.size > 1) {
                      message.error(`${partTypeListEnum[part]} 签署方类型不一致，请检查并修正`)
                      throw new Error(`签署方类型，请检查并修正`)
                    }
                  }
                },
                onChange: setEditableRowKeys,
                actionRender: (row, config, defaultDom) => {
                  let _defaultDom =
                    _type == "add" ? (
                      <>
                        <a
                          onClick={() => {
                            const tableDataSource = form?.getFieldValue([
                              "templateConfig",
                              "signConfigs",
                            ])
                            form?.setFieldsValue({
                              templateConfig: {
                                signConfigs: tableDataSource.filter((item) => item.id !== row.id),
                              },
                            })
                            setEditableRowKeys([])
                            // actionRef.current?.reload()
                          }}
                        >
                          取消
                        </a>
                      </>
                    ) : (
                      handDefaultDom(row, "取消")
                    )
                  return [handDefaultDom(row, "保存"), _defaultDom]
                },
              }}
              columns={columns}
            />
          </Card>
          <Divider style={{ margin: 0 }} />
          <div style={{ textAlign: "center", padding: "12px 0px" }}>
            <Space align="center">
              <Button
                onClick={() => {
                  history.go(-1)
                  // form.resetFields()
                  // actionRef.current?.resetFields?.()
                  // actionRef.current?.reload?.()
                  // setEditableRowKeys([])
                }}
              >
                取消
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                loading={createContractService?.loading || editContractInfoService?.loading}
                onClick={() => {
                  onFinish()
                }}
              >
                保存
              </Button>
            </Space>
          </div>
        </Form>
      </DetailPageContent>
    </DetailPageContainer>
  )
}
export default ContractTemplateAdd
