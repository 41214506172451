import { useSessionStorageState } from "ahooks"
import dayjs from "dayjs"

export const useQueryParamsStorage = <T extends Record<string, any>>() => {
  const orgID = sessionStorage.getItem("tenantId")
  const pathList = window.location.pathname.split("/") || []
  const path = pathList[pathList.length - 1]
  console.log(`KEY_${path}_${orgID}`)
  const [queryParams, saveQueryParams] = useSessionStorageState<T>(`KEY_${path}_${orgID}`)

  return {
    queryParams,
    saveQueryParams,
  }
}
