/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Dropdown } from "antd"
import "../index.scss"
import { CheckOutlined } from "@ant-design/icons"
import gsap from "gsap"
import _ from "lodash"
import classNames from "classnames"
import { ACCESS_KEYS } from "../../../api/config/servicePort"
import Util from "../../../services/util"
import type { MenuProps } from "antd"
import { changeOrg, getOperAppsLists } from "../../../api/service"
import { jwtGetInfo } from "../../../utils/jwtToken"

const OrgChange: any = () => {
  const nav = useNavigate()
  const util = Util.getInstance()
  let OrgList = JSON.parse(sessionStorage.getItem("ORGCHANGE") || "[]")
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const currentTenantId = sessionStorage.getItem("tenantId")
  const ltdName = sessionStorage.getItem("tenantName")
  const navigate = useNavigate()

  // 切换企业
  const handleChangeLtd = async (item: any) => {
    if (item.tenantId == currentTenantId) return
    const res: any = await changeOrg({
      grant_type: "client_credentials",
      orgId: item.tenantId,
    })
    if (res.code == 200) {
      const tokenMessage = {
        access_token_op: res.data.jwtToken,
      }
      let resultJwtToken = jwtGetInfo(res.data.jwtToken)
      window.localStorage?.setItem("operation_tissue", JSON.stringify(resultJwtToken))
      util.setSessiontoken(JSON.stringify(tokenMessage))
      sessionStorage.setItem("tenantId", item.tenantId)
      sessionStorage.setItem("tenantName", item.name)
      const result = await getOperAppsLists({})
      if (result.code == 200) {
        const resultData = result?.data?.map((ie) => {
          return ie.code
        })
        sessionStorage.setItem(ACCESS_KEYS, JSON.stringify(resultData))
      }
      document.title = item.name
      window.location.reload()
    }
  }

  const handleMoveOver = _.throttle(() => {
    if (isOpen) return
    setIsOpen(true)
    gsap.to(".triangle", {
      transform: "rotateX(180deg)",
    })
  }, 500)

  const handleMoveLeave = () => {
    setIsOpen(false)
    gsap.to(".triangle", {
      transform: "rotateX(0deg)",
    })
  }

  const items: MenuProps["items"] | any = OrgList?.map((item: any) => {
    return {
      key: item.tenantId,
      tenantId: item.tenantId,
      name: item.name,
      label: (
        <div style={{ display: "flex" }} onClick={() => handleChangeLtd(item)}>
          <div style={{ width: "24px", display: currentTenantId == item?.tenantId ? "" : "none" }}>
            {<CheckOutlined className="icon" />}
          </div>
          <div style={{ flex: 1, textAlign: "center" }}>{item.name}</div>
        </div>
      ),
    }
  })
  return (
    <>
      <div onMouseOver={handleMoveOver} onMouseLeave={handleMoveLeave} className="user_info_change">
        <Dropdown menu={{ items }} placement="bottom" arrow>
          <div
            className={classNames({
              "change-ltd": true,
              "change-ltd-bg": isOpen,
            })}
          >
            {ltdName}
            <span className="triangle"></span>
          </div>
        </Dropdown>
      </div>
    </>
  )
}

export default OrgChange
