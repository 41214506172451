import { Button, DatePicker, Form, Input, InputNumber, Select, Upload } from "antd"
import React, { useContext, memo } from "react"
import { UploadOutlined } from "@ant-design/icons"
import { FormItemProps } from "antd/es/form"
import numeral from "numeral"
import { useUpdate } from "ahooks"
import moment from "moment"
import { getInvoiceInfoByOCR } from "../../../pages/Entity/promises"
import { ModalFormContext } from "../../ModalForm"
import { EasyUpload } from "../../EasyUpload"
import { INVOICE_TYPE_OPTIONS } from "../../../constant/invoice"
import { useWatch } from "antd/es/form/Form"
import { formatAmount } from "../../../utils/utils"

const combineList = (listA: any[], listB: any[] | undefined) => {
  return listA.concat(listB ? listB : [])
}

const defaultProps = {}
type props = {
  config: { [key in "quantity" | "totalAmount" | "taxAmount" | "amount"]: FormItemProps }
  formProps: any
}
export type InvoiceOfflineFormProps = Required<typeof defaultProps> &
  props &
  React.HTMLAttributes<HTMLDivElement>
const Component: React.FC<React.PropsWithChildren<InvoiceOfflineFormProps>> = memo((props) => {
  const modalFormContext = useContext(ModalFormContext)
  const update = useUpdate()
  const _unit = useWatch("unit", modalFormContext?.form)
  const { formProps } = props

  const numberPointRegTwo = /^-?[0-9][0-9]*([\.][0-9]{1,2})?$/
  const numberPointReg = /^-?[0-9][0-9]*([\.][0-9]{1,6})?$/
  // 输入框默认校验
  const inputDefaultRules = (
    value: string,
    numberRegFlag: boolean = false,
    numberRegTwo = false
  ) => {
    if (!value) {
      return Promise.resolve()
    }
    //小数点后六位
    if (numberRegFlag && !numberPointReg.test(value)) {
      return Promise.reject(new Error("只能输入正整数或小数后六位以内"))
    }
    // 小数点后两位
    if (numberRegTwo && !numberPointRegTwo.test(value)) {
      return Promise.reject(new Error("只能输入正整数或小数后两位以内"))
    }

    return Promise.resolve()
  }
  const concatRules = combineList.bind(null, [
    { required: true, message: "请输入" },
    {
      validator: async (rule: any, value: string) => inputDefaultRules(value, false, true), //特殊字符
    },
  ])

  return (
    <>
      {/* <Form.Item hidden name="fileId"></Form.Item> */}
      {formProps?.invoiceColor == "blue" && (
        <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
          <div style={{ textAlign: "right", width: "25%", color: "rgba(0, 0, 0, 0.45)" }}>
            关联订单：
          </div>
          <div>{window.location?.search?.split("?")[1]?.split("&")[0]?.split("=")[1]}</div>
        </div>
      )}
      {formProps?.invoiceColor == "red" && (
        <>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
            <div style={{ textAlign: "right", width: "25%", color: "rgba(0, 0, 0, 0.45)" }}>
              关联订单：
            </div>
            <div>{formProps?.records?.orders?.map((item: any) => item.orderId)?.join(",")}</div>
          </div>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
            <div style={{ textAlign: "right", width: "25%", color: "rgba(0, 0, 0, 0.45)" }}>
              发票号：
            </div>
            <div>{formProps?.records?.invoiceNo}</div>
          </div>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
            <div style={{ textAlign: "right", width: "25%", color: "rgba(0, 0, 0, 0.45)" }}>
              价税合计金额：
            </div>
            <div>
              {formatAmount(
                formProps?.records?.orders?.reducer(
                  (pre: any, cut: any) => pre + Number(cut.amountWithTax),
                  0
                )
              )}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
            <div style={{ textAlign: "right", width: "25%", color: "rgba(0, 0, 0, 0.45)" }}>
              数量：
            </div>
            <div>
              {formProps?.records?.itemQuantity}
              {formProps?.records?.itemUnit}
            </div>
          </div>
        </>
      )}
      <Form.Item rules={[{ required: true, message: "请上传发票" }]} name="fileId" label="发票">
        <EasyUpload
          showUploadList
          method="PUT"
          maxCount={1}
          accept="image/png,image/jpg,image/jpeg,application/pdf"
          onSuccess={({ file }) => {
            getInvoiceInfoByOCR(file.response?.data.downloadLink)
              .then((res) => {
                const firstItem = res.data.items[0] || {}
                const totalAmounts = res.data.items.reduce(
                  (sumObj: any, item: any) => {
                    return {
                      quantity: (numeral(sumObj.quantity)
                        .add(Number(item.quantity) || 0)
                        .value() || 0) as any,
                      totalAmount: (numeral(sumObj.totalAmount)
                        .add(
                          numeral(Number(item.amountWithoutTax) || 0)
                            .add(Number(item.taxAmount) || 0)
                            .value()
                        )
                        .value() || 0) as any,
                      taxAmount: (numeral(sumObj.taxAmount)
                        .add(Number(item.taxAmount) || 0)
                        .value() || 0) as any,
                      taxRate: (numeral(String(sumObj.taxRate).replace("%", ""))
                        .add(Number(String(item.taxRate).replace("%", "")) || 0)
                        .value() || 0) as any,
                      amount: (numeral(sumObj.amount)
                        .add(Number(item.amount) || 0)
                        .value() || 0) as any,
                    }
                  },
                  { totalAmount: 0, taxAmount: 0, quantity: 0, taxRate: "0", amount: 0 }
                )
                return {
                  invoiceNo: res.data.invoiceCode, // 发票号
                  projectName: firstItem.name, // 项目名称
                  specification: firstItem.spec, // 规格
                  quantity: totalAmounts.quantity, // 数量
                  invoiceDate: res.data.invoiceDate
                    ? moment(res.data.invoiceDate, "YYYY-MM-DD")
                    : "", // 日期
                  totalAmount: numeral(totalAmounts.quantity || 0)
                    .multiply(firstItem.unitPrice || 0)
                    .add(totalAmounts.taxAmount || 0)
                    .format("0.00"), // 价税合计
                  amount: numeral(totalAmounts.quantity || 0)
                    .multiply(firstItem.unitPrice || 0)
                    .format("0.00"), // 金额
                  taxAmount: numeral(totalAmounts.taxAmount)?.format("0.00"), // 增值税
                  taxRate: totalAmounts.taxRate, // 税率
                  unit: firstItem.unit, // 单位
                  unitPrice: firstItem.unitPrice, // 单价
                  // 识别为在中文票种无法直接使用，先手动选择
                  // invoiceType: res.data.type
                }
              })
              .then((invoiceData) => {
                console.log(invoiceData, "invoiceData")
                modalFormContext?.form.setFieldsValue({
                  ...invoiceData,
                  fileId: file.response?.data.filePath,
                })
                update()
              })
          }}
        >
          <Button icon={<UploadOutlined />}>点击上传</Button>
        </EasyUpload>
      </Form.Item>
      <Form.Item name="invoiceNo" label="发票号" rules={[{ required: true, message: "请输入" }]}>
        <Input placeholder="请输入"></Input>
      </Form.Item>
      <Form.Item
        name="projectName"
        label="发票项目名称"
        rules={[{ required: true, message: "请输入" }]}
      >
        <Input placeholder="请输入" required></Input>
      </Form.Item>
      <Form.Item name="specification" label="规格">
        <Input placeholder="请输入"></Input>
      </Form.Item>
      <Form.Item
        name="invoiceDate"
        label="开票日期"
        rules={[{ required: true, message: "请选择" }]}
      >
        <DatePicker placeholder="请选择"></DatePicker>
      </Form.Item>
      <Form.Item
        name="quantity"
        label="数量"
        rules={combineList(
          [
            { required: true, message: "请输入" },
            {
              validator: async (rule: any, value: string) => inputDefaultRules(value, true), //特殊字符
            },
          ],
          props.config["quantity"]?.rules
        )}
      >
        <Input placeholder="请输入" type="number" required></Input>
      </Form.Item>
      <Form.Item name="unit" label="单位" rules={[{ required: true, message: "请输入" }]}>
        <Input placeholder="请输入" required></Input>
      </Form.Item>
      <Form.Item name="unitPrice" label="单价" rules={[{ required: true, message: "请输入" }]}>
        <Input
          placeholder="请输入"
          type="number"
          required
          addonAfter={<>元/{_unit || "吨"}</>}
        ></Input>
      </Form.Item>
      <Form.Item name="amount" label="金额" rules={concatRules(props.config["amount"]?.rules)}>
        <Input placeholder="请输入" type="number" required addonAfter={"元"}></Input>
      </Form.Item>
      <Form.Item
        name="taxAmount"
        label="增值税"
        rules={concatRules(props.config["taxAmount"]?.rules)}
      >
        <Input placeholder="请输入" type="number" required addonAfter={"元"}></Input>
      </Form.Item>
      <Form.Item name="taxRate" label="税率" rules={[{ required: true, message: "请输入" }]}>
        <Input placeholder="请输入" type="number" required addonAfter={"%"}></Input>
      </Form.Item>
      <Form.Item
        name="totalAmount"
        label="价税合计"
        rules={concatRules(props.config["totalAmount"]?.rules)}
      >
        <Input placeholder="请输入" type="number" required addonAfter={"元"}></Input>
      </Form.Item>
      <Form.Item name="invoiceType" label="票种" rules={[{ required: true, message: "请选择" }]}>
        <Select placeholder="请选择" options={INVOICE_TYPE_OPTIONS}></Select>
      </Form.Item>
    </>
  )
})
Component.displayName = "线下发票上传表单"
Component.defaultProps = defaultProps
export const InvoiceOfflineForm = Component
