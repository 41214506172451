/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import "./index.scss"
import SKTable from "@src/components/SkTable"
import { Table } from "antd"

const Logs = (props: any) => {
  const { details } = props
  const columns = [
    {
      title: "操作时间",
      dataIndex: "createTime",
    },
    {
      title: "操作人",
      dataIndex: "operationUserName",
    },
    {
      title: "操作步骤",
      dataIndex: "operationType",
    },
    {
      title: "详细内容",
      width: "300",
      dataIndex: "operationDesc",
    },
  ]

  return (
    <div className="out_employed">
      <div className="out_employed_title">操作日志</div>
      <div className="out_employed_table">
        <Table
          pagination={{
            pageSize: 10,
            showQuickJumper: true,
            showTotal(total, range) {
              return `总共${total}条`
            },
          }}
          dataSource={details?.operationLogVos}
          columns={columns}
        ></Table>
      </div>
    </div>
  )
}

export default Logs
