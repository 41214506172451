export const handleData = (start: any[]) => {
  const arr: any = start.map((item) => {
    if (item?.children?.length) {
      return {
        value: item.code,
        label: item.name,
        children: handleData(item.children),
      }
    } else {
      return {
        value: item.code,
        label: item.name,
        children: [],
      }
    }
  })
  return arr
}
