import React, { useEffect, useState } from "react"
import { Button, Modal, Form, Col, Row, Input, Select, message } from "antd"
import { getAddUserLists } from "../../../service"
import "./index.scss"
type type_modal_operator = {
  (a: boolean): void
}

type type_user_modal = {
  isAdd: boolean
  addModal: type_modal_operator
  cb: () => void
  options: any
}
const UserModal: React.FC<type_user_modal> = ({ isAdd, addModal, cb, options }) => {
  const [form] = Form.useForm()
  const [messageApi, contextHolder] = message.useMessage()
  const handleCancel = () => {
    form.resetFields()
    addModal(false)
  }

  const onFinish = (values: any) => {
    let userFlag = values.cfmPwd == values.password
    if (!userFlag) {
      message.error("密码输入不一致")
      return
    }
    console.log(values, "values")
    const addData = {
      name: values.name,
      account: values.account,
      phone: values.phone,
      password: values.password,
      provider: values?.provider,
      cfmPwd: values.cfmPwd,
    }
    getAddUserLists(addData).then((data: any) => {
      if (data.successful) {
        messageApi.open({
          type: "success",
          content: "新增成功",
          onClose: () => {
            handleCancel()
            cb()
          },
        })
      }
    })
  }

  return (
    <>
      <Modal
        open={isAdd}
        title="新增用户"
        onCancel={handleCancel}
        centered
        width={600}
        footer={null}
      >
        {contextHolder}
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          // initialValues={value}
          onFinish={onFinish}
        >
          <Row>
            <Col span={20}>
              <Form.Item
                style={{ paddingTop: 24 }}
                label="姓名"
                name="name"
                rules={[{ required: true, message: "请输入！" }]}
              >
                <Input placeholder="请输入" maxLength={15} />
              </Form.Item>
            </Col>
            <Col span={20} style={{ paddingTop: 3 }}>
              <Form.Item
                label="手机号"
                name="phone"
                rules={[
                  { required: true, message: "请输入！" },
                  { pattern: /^1[0-9]{10}/, message: "请输入手机号格式的账号" },
                ]}
              >
                <Input maxLength={11} placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={20} style={{ paddingTop: 3 }}>
              <Form.Item
                label="账号"
                name="account"
                rules={[{ required: true, message: "请输入！" }]}
              >
                <Input autoComplete="off" maxLength={20} placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={20} style={{ paddingTop: 3 }}>
              <Form.Item
                label="密码"
                name="password"
                rules={[{ required: true, message: "请输入！" }]}
              >
                <Input maxLength={11} placeholder="请输入！" />
              </Form.Item>
            </Col>
            <Col span={20} style={{ paddingTop: 3 }}>
              <Form.Item
                label="确认密码"
                name="cfmPwd"
                rules={[{ required: true, message: "请输入！" }]}
              >
                <Input maxLength={11} placeholder="请输入！" />
              </Form.Item>
            </Col>
            <Col span={20} style={{ marginBottom: 24, paddingTop: 3 }}>
              <Form.Item
                label="运营标识"
                name="provider"
                rules={[{ required: true, message: "请输入选择！" }]}
              >
                <Select options={options} placeholder="请选择" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item wrapperCol={{ span: 16 }} style={{ borderTop: "1px solid #f0f0f0" }}>
            <div
              className="form_footer"
              style={{ display: "flex", marginBottom: 10, paddingTop: 10 }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  textAlign: "right",
                  marginLeft: "110%",
                }}
              >
                <Button
                  style={{ marginRight: "12px" }}
                  onClick={(e) => {
                    handleCancel()
                  }}
                >
                  取消
                </Button>
                <Button type="primary" htmlType="submit">
                  确定
                </Button>
              </div>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
export default UserModal
