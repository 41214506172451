import React, { useState } from "react"
import { Form, Input, Table, Typography, DatePicker, Modal, Spin, InputNumber } from "antd"
import dayjs from "dayjs"
import styles from "./index.module.scss"
import { preview } from "../../../promise"
import { Page, Document, pdfjs } from "react-pdf"
import { getBase64 } from "../../../config"
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
const invoiceStatusObjs = {
  PEDDING: "待开具",
  ISSUED: "已开具",
  CANCELED: "已冲红",
  ERROR: "开票失败",
  NEGATIVE_ISSUED: "“负数发票已开具",
}
interface Item {
  key: string
  name: string
  age: number
  address: string
}
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean
  dataIndex: string
  title: any
  inputType: "number" | "text"
  record: Item
  index: number
  children: React.ReactNode
  element?: React.ReactNode
  message?: string
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  element,
  message,
  ...restProps
}) => {
  // const inputNode = inputType === "number" ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: message || `请输入${title}!`,
            },
          ]}
        >
          {element}
          {/* {inputNode} */}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}
interface Invoices {
  onCancel?: (value: any) => void
}
const InvoicesDetails = (props: any) => {
  const {
    onCancel,
    onSave,
    onDelete,
    isSavedList,
    isSaved,
    loading = false,
    detailData = {},
  } = props
  console.log(detailData, "detailData")
  const [open, setOpen] = useState(false)
  const [pdfSrc, setPdfSrc] = useState("")
  const [numPages, setNumPages] = useState<number>(1)
  const [form] = Form.useForm()
  // const [data, setData] = useState(originData);
  const [editingKey, setEditingKey] = useState("")
  // 待保存之前  将数据缓存  默认为第一个数据
  const isEditing = (record: any) => {
    // console.log(record, "recordsss")
    if (isSaved) {
      // 带保存页面数据  需要判断当前添加的数据是否存在id
      let objs = isSavedList[0]
      console.log(objs, "objs")
      form.setFieldsValue({
        ...objs,
        invoiceDate: dayjs(objs.invoiceDate),
        invoiceCode: objs.invoiceCode,
        taxAmount: objs.taxAmount,
        invoiceNo: objs.invoiceCode,
      })
      return record.id === objs.id
    } else {
      return record.id === editingKey
    }
  }

  const edit = (record: any) => {
    form.setFieldsValue({
      ...record,
      invoiceDate: dayjs(record.invoiceDate),
    })
    setEditingKey(record.id)
  }

  const cancel = () => {
    setEditingKey("")
    onCancel && onCancel()
  }

  const save = async (params: any) => {
    try {
      const row = await form.validateFields()
      let vatInvoiceResponse = {}
      if (isSaved) {
        let objs = params?.items ? params?.items[0] : {}
        let keys: any = Object.keys(objs)
        keys.map((item: any) => {
          if (!objs[item]) {
            objs[item] = 0
          }
          return item
        })
        vatInvoiceResponse = {
          ...objs,
          taxAmount: row.taxAmount,
          quantity: row.itemQuantity,
          remark: params.remark,
          invoiceCode: row.invoiceNo,
          type: params.type,
          invoiceDate: dayjs(row?.invoiceDate).format("YYYY-MM-DD"),
          amountWithTax: row.amountWithTax,
          //   invoiceNo: row.invoiceNo,
        }
      }
      onSave && onSave(vatInvoiceResponse)
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo)
    }
  }
  const handlePreview = (record: any) => {
    if (!record?.id) return
    preview(record?.id).then(async (res: any) => {
      if (res?.code === 200) {
        window.open(res.data)
      }
    })
  }
  const columns = [
    {
      title: "发票日期",
      dataIndex: "invoiceDate",
      width: "300",
      editable: true,
      element: <DatePicker style={{ width: "150px" }} />,
      message: "请选择日期",
      render: (record: any) => {
        return <span>{record ? dayjs(record).format("YYYY-MM-DD") : ""}</span>
      },
    },
    {
      title: "发票号码",
      dataIndex: "invoiceNo",
      width: "250",
      editable: true,
      element: <Input></Input>,
    },
    {
      title: "合计税额",
      dataIndex: "taxAmount",
      width: "200",
      editable: true,
      element: <InputNumber></InputNumber>,
    },
    {
      title: "价税合计",
      dataIndex: "amountWithTax",
      width: "200",
      editable: true,
      element: <InputNumber></InputNumber>,
    },
    {
      title: "重量",
      dataIndex: "itemQuantity",
      width: "100",
      editable: true,
      element: <InputNumber></InputNumber>,
    },
    {
      title: "订单ID",
      dataIndex: "orderNo",
      width: "150",
      render: (t: string, record: any) => detailData?.orderNo,

      // editable: true,
      // element: <Input></Input>,
    },
    {
      title: "开票方",
      dataIndex: "sellerName",
      render: (t: string) => t || detailData?.sellerName,
    },
    {
      title: "买方名称",
      dataIndex: "buyerName",
      render: (t: string) => t || detailData?.buyerName,
    },
    {
      title: "开票状态",
      dataIndex: "invoiceStatus",
      render: (record: any) => {
        return <span>{invoiceStatusObjs[record]}</span>
      },
    },
    {
      title: "操作",
      width: 150,
      // fixed: "right",
      render: (_: any, record: any) => {
        const editable = isEditing(record)
        return editable ? (
          <span>
            <Typography.Link onClick={() => save(record)} style={{ marginRight: 8 }}>
              保存
            </Typography.Link>
            <Typography.Link style={{ marginRight: 8 }} onClick={() => cancel()}>
              取消
            </Typography.Link>
            {/* <Typography.Link
                            onClick={() => {
                                onDelete && onDelete(record);
                            }}
                        >
                            删除
                        </Typography.Link> */}
          </span>
        ) : (
          <>
            {/* <Typography.Link
                            disabled={editingKey !== ""}
                            onClick={() => edit(record)}
                            style={{ marginRight: 8 }}
                        >
                            编辑
                        </Typography.Link> */}
            <Typography.Link disabled={editingKey !== ""} onClick={() => handlePreview(record)}>
              预览
            </Typography.Link>
          </>
        )
      },
    },
  ]

  const mergedColumns: any = columns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        element: col.element,
        message: col.message,
      }),
    }
  })
  return (
    <Spin spinning={loading}>
      <div className={styles.details}>
        <Form form={form} component={false}>
          <Table
            rowKey={(record: any) => record.id}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            bordered
            dataSource={isSavedList}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={{
              onChange: cancel,
            }}
          />
        </Form>
        {/* 预览发票 */}

        <Modal
          open={open}
          title={null}
          footer={null}
          onCancel={() => {
            setOpen(false)
            setPdfSrc("")
          }}
          width={800}
        >
          {pdfSrc && (
            <Document
              file={[pdfSrc]}
              loading="努力加载中..."
              renderMode="canvas"
              onLoadSuccess={(e: any) => {
                setNumPages(e.numPages)
              }}
              options={{
                // 解决电子发票部分样式 无法识别
                cMapUrl: `https://cdn.jsdelivr.net/npm/pdfjs-dist@2.16.105/cmaps/`,
                cMapPacked: true,
                disableWorker: true,
              }}
            >
              {new Array(numPages).fill("").map((item, index) => {
                return (
                  <Page
                    key={index}
                    pageNumber={index + 1}
                    width={750}
                    loading="努力加载中..."
                    renderMode="canvas"
                    renderInteractiveForms={true}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                  />
                )
              })}
            </Document>
          )}
        </Modal>
      </div>
    </Spin>
  )
}

export default InvoicesDetails
