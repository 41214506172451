import React from "react"
import moment from "moment"
import { Badge } from "antd"

import { outDelegationStatusEnum } from "../constants"
export default [
  {
    title: "注销申请编号",
    dataIndex: "applyNo",
    key: "applyNo",
    fixed: "left",
  },
  {
    title: "经营者姓名",
    dataIndex: "legalName",
    key: "legalName",
  },
  {
    title: "组织名称",
    dataIndex: "organizationName",
    key: "organizationName",
  },
  {
    title: "所属税区",
    dataIndex: "taxRegionName",
    key: "taxRegionName",
  },
  {
    dataIndex: "cancelStatus",
    title: "注销状态",
    key: "cancelStatus",
    render: (value) => {
      return value ? <Badge {...outDelegationStatusEnum[value]} /> : "--"
    },
  },
  {
    title: "执照办理员",
    dataIndex: "licenseOfficeName",
    key: "licenseOfficeName",
  },
  {
    title: "办税人",
    dataIndex: "taxOfficerName",
    key: "taxOfficerName",
  },
  {
    title: "注销创建人",
    dataIndex: "createUserName",
    key: "createUserName",
  },
]
