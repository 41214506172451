/* eslint-disable react/jsx-key */
/* eslint-disable react/no-children-prop */
import { DownloadFileButton } from "@/components/DownloadFileButton"
import {
  DownloadOutlined,
  LoadingOutlined,
  PlusOutlined,
  ReloadOutlined,
  SyncOutlined,
  DeleteOutlined,
} from "@ant-design/icons"
import classNames from "classnames"
import React, { PropsWithChildren, useMemo } from "react"
import { EditableImage, EditableImageProps } from "../EditableImage"
import { EasyUpload, EasyUploadFileProps } from "../EasyUpload"
import "./index.scss"
import { cross_env } from "@src/services/util"
import { uuid } from "@src/utils"
import { cross_file_url_env } from "../../services/util"

const defaultProps = {}
type props = {
  src?: string | any
  editableImageProps?: EditableImageProps
  action?: string
  uploadFileProps?: EasyUploadFileProps
  updateFileProps?: EasyUploadFileProps
  createFileProps?: EasyUploadFileProps
  width?: number
  height?: number
  onSuccess?: EasyUploadFileProps["onSuccess"]
  onError?: EasyUploadFileProps["onError"]
  title?: string
  filename?: string
  isUpdate?: boolean // 是否更新
  isDownload?: boolean // 是否下载
  classFileName?: any
  tailor?: Boolean
  setTaxArrNum?: any
  numInfo?: any
  taxArrNum?: any
  index?: any
  setTaxArrNumFlag?: any
  taxArrNumFlag?: any
}
const actionStyle = { color: "#fff", fontSize: 16 }

export type OperableImageProps = Partial<typeof defaultProps> & props

export const OperableImage: React.FC<PropsWithChildren<OperableImageProps>> = (props) => {
  const {
    src,
    children,
    editableImageProps,
    uploadFileProps,
    width,
    height,
    onSuccess,
    onError,
    title,
    filename,
    action,
    isUpdate = true,
    isDownload = true,
    classFileName = "",
    tailor = false,
    setTaxArrNum,
    numInfo,
    taxArrNum,
    index,
    setTaxArrNumFlag,
    taxArrNumFlag,
  } = props
  const { updateFileProps = uploadFileProps, createFileProps = uploadFileProps } = props
  // console.log(src, "src")
  return (
    <div className="operableImage">
      {src?.length > 0 ? (
        <div style={{ width, height }} title={title}>
          <div style={{ display: "flex" }}>
            {src?.map((ie) => {
              return (
                <div style={{ paddingLeft: 5 }}>
                  <EditableImage
                    title={title}
                    width={width}
                    height={height}
                    src={ie?.response?.data?.downloadLink || ie?.newFile}
                    {...editableImageProps}
                    actions={[
                      isUpdate && (
                        <EasyUpload
                          onSuccess={onSuccess}
                          onError={onError}
                          action={action}
                          tailor={tailor}
                          method="PUT"
                          {...updateFileProps}
                        >
                          <div style={actionStyle} title="更新图片">
                            <SyncOutlined />
                          </div>
                        </EasyUpload>
                      ),
                      isDownload && (
                        <DownloadFileButton
                          href={ie?.downloadLink}
                          filename={filename ? filename : title}
                        >
                          {(props) => (
                            <div
                              onClick={() => {
                                let newTaxArrNum = taxArrNum
                                let newIndex = src.indexOf(ie)
                                if (newIndex > -1) {
                                  src.splice(newIndex, 1)
                                }
                                newTaxArrNum[index] = src
                                setTaxArrNum(newTaxArrNum)
                                setTaxArrNumFlag(true)
                              }}
                            >
                              {props.loading ? <DeleteOutlined /> : <DeleteOutlined />}
                            </div>
                          )}
                        </DownloadFileButton>
                      ),
                    ]}
                  />
                </div>
              )
            })}
          </div>
        </div>
      ) : (
        ""
      )}
      {src?.length < numInfo ? (
        <div>
          <EasyUpload
            onSuccess={onSuccess}
            onError={onError}
            listType="picture-card"
            action={action}
            tailor={tailor}
            method="PUT"
            {...createFileProps}
          >
            {(props) => (
              <EasyUpload.ImageTypeUI
                {...props}
                children={
                  <>
                    {classFileName !== "" ? null : (
                      <>
                        <PlusOutlined />
                        <span style={{ marginTop: 8 }}>{children}</span>
                      </>
                    )}
                  </>
                }
              />
            )}
          </EasyUpload>
        </div>
      ) : null}
    </div>
  )
}

OperableImage.defaultProps = defaultProps
