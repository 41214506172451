/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import * as echarts from "echarts"
import "./index.scss"
interface EchartsP {
  type: any
  options: any
  flag?: boolean
}
const Echarts = (props: EchartsP) => {
  const { type, options, flag } = props
  const id = Math.random().toString(36).substr(2, 9)
  console.log("进入了echarts")
  useEffect(() => {
    const myChart = echarts.init(document.getElementById(`echartsContent-${id}`) as HTMLElement)
    console.log("渲染ecahrts")
    myChart.setOption(options)
    window.addEventListener("resize", () => {
      if (myChart) {
        myChart.resize()
      }
    })
    return () => {
      window.removeEventListener("resize", () => {
        if (myChart) {
          myChart.resize()
        }
      })
    }
  }, [options?.series?.data, options?.series[0]?.data, flag])

  return (
    <div
      style={{ width: "100%", height: type == "min" ? "80px" : "300px", paddingTop: "8px" }}
      id={`echartsContent-${id}`}
    ></div>
  )
}
export default Echarts
