/* eslint-disable react-hooks/exhaustive-deps */
import { OperateOptions, RecycleOptions } from "@src/constant/applicationManagement"
import { Checkbox, Divider, Form } from "antd"
import { CheckboxChangeEvent, CheckboxProps } from "antd/es/checkbox"
import { CheckboxGroupProps, CheckboxValueType } from "antd/es/checkbox/Group"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { getAppsList } from "../../service"
import { PlatformSetupTissuePropsContext } from "../hooks/usePlatformSetupTissue"

type ApplyModuleProps = CheckboxProps &
  CheckboxGroupProps & { title: string; onChangeBox?: (e: any) => void }

const CheckboxGroup = Checkbox.Group
const ApplicationManagement = (props) => {
  const { appData, setAppData } = props
  const contextProps: any = useContext(PlatformSetupTissuePropsContext)
  // const [operateCheckedList, setOperateCheckedList] = useState<CheckboxValueType[]>(appData.a)
  // const [recycleCheckedList, setRecycleCheckedList] = useState<CheckboxValueType[]>(appData.b)

  // const allAList = OperateOptions.map((item) => item.value)
  // const allBList = RecycleOptions.map((item) => item.value)

  // const onGroupChange = useCallback(
  //   (list: CheckboxValueType[], type) => {
  //     switch (type) {
  //       case "a":
  //         setOperateCheckedList(list)
  //         setAppData({ ...appData, a: list })
  //         break
  //       case "b":
  //         setRecycleCheckedList(list)
  //         setAppData({ ...appData, b: list })
  //         break
  //       default:
  //         setOperateCheckedList(list)
  //         setAppData({ ...appData, a: list })
  //     }
  //   },
  //   [appData, setAppData]
  // )
  // const onCheckAllChange = useCallback(
  //   (e: CheckboxChangeEvent, type) => {
  //     switch (type) {
  //       case "a":
  //         if (e.target.checked) {
  //           setOperateCheckedList(allAList)
  //           setAppData({ ...appData, a: allAList })
  //         } else {
  //           setOperateCheckedList([])
  //           setAppData({ ...appData, a: [] })
  //         }
  //         break
  //       case "b":
  //         if (e.target.checked) {
  //           setRecycleCheckedList(allBList)
  //           setAppData({ ...appData, b: allBList })
  //         } else {
  //           setRecycleCheckedList([])
  //           setAppData({ ...appData, b: [] })
  //         }
  //         break
  //       default:
  //         if (e.target.checked) {
  //           setOperateCheckedList(allAList)
  //           setAppData({ ...appData, a: allAList })
  //         } else {
  //           setOperateCheckedList([])
  //           setAppData({ ...appData, a: [] })
  //         }
  //     }
  //   },
  //   [appData, setAppData]
  // )
  // const applyModule: ApplyModuleProps[] = [
  //   {
  //     title: "新之——运营端",
  //     indeterminate:
  //       operateCheckedList?.length > 0 && operateCheckedList?.length < OperateOptions?.length,
  //     onChangeBox: (e) => onCheckAllChange(e, "a"),
  //     checked: OperateOptions?.length === operateCheckedList?.length,
  //     options: OperateOptions,
  //     value: operateCheckedList,
  //     onChange: (list) => onGroupChange(list, "a"),
  //   },
  //   {
  //     title: "新之——回收小程序",
  //     indeterminate:
  //       recycleCheckedList?.length > 0 && recycleCheckedList?.length < RecycleOptions?.length,
  //     onChangeBox: (e) => onCheckAllChange(e, "b"),
  //     checked: RecycleOptions?.length === recycleCheckedList?.length,
  //     options: RecycleOptions,
  //     value: recycleCheckedList,
  //     onChange: (list) => onGroupChange(list, "b"),
  //   },
  // ]

  const [appListOptions, setAppListOptions] = useState([])
  const getAppList = async () => {
    const result = await getAppsList()
    const handleAppList = result?.data?.records?.map((item) => ({
      label: item.name,
      value: item.id,
    }))
    setAppListOptions(handleAppList)
  }
  useEffect(() => {
    getAppList()
  }, [])
  const onChange = (checkedValues) => {
    setAppData(checkedValues)
  }
  return (
    <>
      {/* {applyModule.map((item) => {
        return (
          <>
            <p>
              <Checkbox
                indeterminate={item.indeterminate}
                onChange={item.onChangeBox}
                checked={item.checked}
              >
                {item.title}
              </Checkbox>
            </p>
            <CheckboxGroup options={item.options} value={item.value} onChange={item.onChange} />
            <Divider />
          </>
        )
      })} */}
      <Checkbox.Group options={appListOptions} value={appData} onChange={onChange} />
    </>
  )
}
export default ApplicationManagement
