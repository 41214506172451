/* eslint-disable no-debugger */
import React from "react"
import { useEffect, useState, useMemo } from "react"
import { Radio, Modal, Button, Checkbox, Divider, message } from "antd"
import type { RadioChangeEvent } from "antd"
import type { CheckboxProps } from "antd"
import { getFileUrlById } from "@/api/service"
import { pollUntilConditionMet, stopPolling, startPolling, download } from "./content"
import http from "@/services/serviceOp"
import moment from "moment"
import { useLocation } from "react-router-dom"
import { cloneDeep } from "lodash"
const utilOp = http.getInstance()

const CheckboxGroup = Checkbox.Group

type configT = {
  type: "current" | "all"
  currentHeader: string[]
}

interface XZExportT {
  fileName: string
  isExportAll?: boolean
  defaultCheckedList?: string[]
  configInfo: any
  requestParams: any
  saveKey: string
}

/**
 * 组件描述

 * @param {String} fileName 导出文件名，可选项
 * @param {Boolean} isExportAll 是否默认导出全部数据， 可选项
 * @param {Any} configInfo 全部下载信息
 * @param {Any} requestParams 表格请求参数
 */

const XZExport = (props: XZExportT) => {
  const location = useLocation()

  const {
    fileName,
    isExportAll,
    defaultCheckedList,
    configInfo,
    requestParams,
    saveKey = "",
  } = props
  const currentPageInfo = configInfo[fileName]
  const [columns, SetColumns] = useState<any>([])
  const [visible, setVisible] = useState<boolean>(false)
  const [exportConfig, setExportConfig] = useState<configT>({
    type: "all",
    currentHeader: [],
  })
  const [loading, setLoading] = useState<boolean>(false)
  const checkAll = useMemo(() => {
    return columns.length === exportConfig.currentHeader.length
  }, [exportConfig.currentHeader.length, columns.length])

  const indeterminate = useMemo(() => {
    return (
      exportConfig.currentHeader.length > 0 && exportConfig.currentHeader.length < columns.length
    )
  }, [exportConfig.currentHeader.length, columns.length])

  // 初始化
  const init = () => {
    if (!currentPageInfo?.columns) return
    let columns = formatColumns(currentPageInfo.columns)
    let _list: string[] = []
    // 默认选中
    if (defaultCheckedList?.length) {
      _list = defaultCheckedList
    }
    // 是否默认全选
    if (!isExportAll) {
      _list = columns.map((item) => item.value)
    }
    SetColumns(columns)
    setExportConfig({ ...exportConfig, currentHeader: _list })
  }
  const convertArrayValuesToStrings = (obj) => {
    // 遍历对象的所有键
    Object.keys(obj).forEach((key) => {
      // 检查当前键对应的值是否为数组
      if (Array.isArray(obj[key])) {
        // 如果是数组，则转换为逗号分隔的字符串并更新对象
        obj[key] = obj[key].join(",")
      }
    })
    return obj
  }
  // 确认
  const hanldeOk = async () => {
    // 下载参数
    const params: any = cloneDeep(exportConfig)
    params.fields = {}
    params.currentHeader = params.currentHeader.map((item: string) => {
      return columns.find((k: any) => k.value == item)
    })
    params.currentHeader.forEach((k: any) => {
      params.fields[k.value] = k.label
    })
    let propsRequestParams =
      typeof requestParams == "string" ? JSON.parse(requestParams || "{}") : requestParams
    let _requestParams = {
      ...propsRequestParams,
      ...JSON.parse(sessionStorage.getItem(saveKey) || "{}"),
      ...currentPageInfo?.apiParams,
    }
    _requestParams = { ..._requestParams, ...currentPageInfo?.apiParams }
    // 下载全部数据
    if (params.type != "current") {
      _requestParams = { ..._requestParams, current: 1, pageSize: 99999999 }
    }
    _requestParams.size = _requestParams.pageSize
    // 接口单独格式化字段
    if (currentPageInfo?.formatParams) {
      _requestParams = currentPageInfo?.formatParams(_requestParams)
    }
    const res: any = await utilOp.httpGet(
      currentPageInfo?.downUrl,
      convertArrayValuesToStrings(_requestParams),
      {
        fields: encodeURI(JSON.stringify(params?.fields)),
        fileName: encodeURI(fileName.replace("列表", "_") + moment().format("YYYY-MM-DD")),
        AsyncExport: true,
      }
    )
    setLoading(true)
    // eslint-disable-next-line no-debugger
    console.log(res, 5123131)
    if (!res) {
      handleCancel()
      message.warning("暂无数据")
      return
    }
    if (res && typeof res === "string") {
      const downCode = res
      // oss路径
      let ossUrl: any = await pollUntilConditionMet(`${currentPageInfo?.exportUrl}/${downCode}`)
      // 是否是失败结果
      console.log(ossUrl, 5612313)
      if (ossUrl?.success === "false") {
        message.warning(ossUrl?.content)
        handleCancel()
        return
      } else {
        ossUrl = ossUrl?.content
      }
      if (ossUrl && ossUrl.includes("upload")) {
        const urls = await getFileUrlById(ossUrl)
        const url = urls[0]
        if (!url) return
        download(url, fileName.replace("列表", "_") + moment().format("YYYY-MM-DD"))
        handleCancel()
      }
    } else {
      message.warning("获取文件失败")
    }
  }
  // 打开
  const handleOpen = () => {
    startPolling()
    setVisible(true)
  }
  // 取消, 初始化状态
  const handleCancel = () => {
    // 取消定时任务
    stopPolling()
    setLoading(false)
    setVisible(false)
    setExportConfig({
      type: "all",
      currentHeader: [],
    })
  }
  // 更改下载配置信息
  const handleChangeConfig = (e: RadioChangeEvent, type: string) => {
    setExportConfig({ ...exportConfig, [type]: e.target.value })
  }
  // 表头是否全选
  const onCheckAllChange: CheckboxProps["onChange"] = (e) => {
    const _list: string[] = e.target.checked ? columns?.map((k: any) => k.value) : []
    setExportConfig({ ...exportConfig, currentHeader: _list })
  }
  // 单选
  const handleChangeHeder = (list: any) => {
    setExportConfig({ ...exportConfig, currentHeader: list })
  }

  const formatColumns = (obj: any) => {
    return Object.keys(obj).map(function (k) {
      let val = obj[k]
      return { label: val, value: k }
    })
  }

  useEffect(() => {
    if (visible) init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  useEffect(() => {
    return () => {
      // 离开页面取消定时任务
      stopPolling()
    }
  }, [location])

  return (
    <>
      {currentPageInfo?.downUrl && (
        <>
          <Button onClick={handleOpen}>批量导出</Button>
          <Modal
            maskClosable={false}
            destroyOnClose
            open={visible}
            title={"数据导出"}
            onCancel={handleCancel}
            footer={
              <div style={{ padding: "12px 16px" }}>
                <Button onClick={handleCancel}>取消</Button>
                <Button
                  style={{ marginLeft: "12px" }}
                  loading={loading}
                  type="primary"
                  onClick={hanldeOk}
                >
                  {loading ? "下载中" : "确认"}
                </Button>
              </div>
            }
          >
            <div style={{ padding: "12px 16px" }}>
              <Radio.Group
                name="radiogroup"
                value={exportConfig.type}
                onChange={(e: RadioChangeEvent) => handleChangeConfig(e, "type")}
                style={{ marginBottom: "24px" }}
              >
                <Radio value={"current"}>导出当前查询分页数据</Radio>
                <Radio value={"all"}>导出当前查询全部数据</Radio>
              </Radio.Group>
              <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
              >
                导出表头选择
              </Checkbox>
              <Divider style={{ margin: "8px 0" }} />
              <CheckboxGroup
                style={{ marginBottom: "24px" }}
                options={columns}
                value={exportConfig.currentHeader}
                onChange={handleChangeHeder}
              />
            </div>
          </Modal>
        </>
      )}
    </>
  )
}
export default XZExport
