// 字段更新基础form表单信息
export const UPDATE_BASIC = "update_basic"
export const UPDATE_RULE = "update_rule"
export const RESET_FORM = "reset_form"
export const INIT_FORM = "init_form"
// 权限code
export const FUNCTION_CODE = "function_code"
// 存贮税区详情富文本数据
export const QUILL_TAX = "quill_tax"
// 更新新增跟进记录
export const UPDATE_FOLLOW = "update_follow"
// 更新通知用户组或者通知个人
export const UPDATE_NOTIFY = "update_notify"
// rolecodes
export const ROLE_CODE = "role_code"
export const ROLE_MEUS_LIST = "role_MEUS_LIST"
