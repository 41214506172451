import ProTable, { EditableFormInstance, EditableProTable, ProColumns } from "@ant-design/pro-table"
import React, { useEffect, useRef, useState } from "react"
import { Button, Col, message, Popconfirm, Row } from "antd"
import {
  createContractOrganizations,
  deleteContractOrganizations,
  getContractInfo,
} from "../../promises"
import { OrgTypeEnum } from "../../../../PlatformSetup/Tissue/constant"
import { useRequest } from "ahooks"
import { getTissueList } from "../../../promises"
const UsableTissue = (params) => {
  const { id } = params
  const editorFormRef = useRef<EditableFormInstance<DataSourceType>>()
  const [totalCount, setTotalCount] = useState<number>(0)
  const [editableKeys, setEditableRowKeys] = useState<React.Key[]>(() => [])
  const [dataSourceList, setDataSourceList] = useState([])

  const deleteContractOrganizationsService = useRequest(deleteContractOrganizations, {
    manual: true,
    onSuccess: () => {
      actionRef.current?.reload()
      message.success("操作成功")
    },
  })
  const tissueListService = useRequest(getTissueList, {
    manual: true,
    debounceWait: 600,
  })
  const actionRef = useRef<EditableFormInstance>()
  const columns: ProColumns[] = [
    {
      title: "组织ID",
      dataIndex: "id",
      width: 155,
      fieldProps: (form, config, b) => {
        return {
          disabled: true,
        }
      },
    },
    {
      title: "组织名称",
      dataIndex: "name",
      valueType: "select",
      formItemProps: () => {
        return {
          rules: [{ required: true, message: "此项为必填项" }],
        }
      },
      fieldProps: (form, config, b) => {
        return {
          placeholder: "组织名称或证件号搜索",
          allowClear: true,
          showSearch: true,
          optionFilterProp: "children",
          filterOption: false,
          fieldNames: { label: "name", value: "id" },
          onSearch: async (e) => {
            await tissueListService.run({ keywords: e })
          },
          options: tissueListService.data?.data?.records,
          onChange: (e, row) => {
            editorFormRef.current?.setRowData?.(config.rowKey[0]!, {
              type: row.type,
              id: row.value,
            })
          },
        }
      },
    },
    {
      title: "组织类型",
      valueEnum: OrgTypeEnum,
      width: 100,
      dataIndex: "type",
      formItemProps: () => {
        return {
          rules: [{ required: true, message: "此项为必填项" }],
        }
      },
      fieldProps: (form, config, b) => {
        return {
          disabled: true,
        }
      },
    },
    {
      title: "操作",
      valueType: "option",
      width: 60,
      render: (text, record, _, action) => [
        // eslint-disable-next-line react/jsx-key
        <Popconfirm
          title={"是否删除？"}
          onConfirm={async () => {
            await deleteContractOrganizationsService.run({
              id: id,
              refId: record.id,
            })
          }}
        >
          <a key="delete">移除</a>
        </Popconfirm>,
      ],
    },
  ]

  useEffect(() => {
    tissueListService.run()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <Row justify={"space-between"} style={{ padding: "10px 24px" }}>
        <Col style={{ fontSize: "16px", fontWeight: "500" }}>{`可用组织列表 / ${totalCount}`}</Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              actionRef.current?.addEditRecord?.({
                id: (Math.random() * 1000000).toFixed(0),
              })
            }}
          >
            添加一行
          </Button>
        </Col>
      </Row>
      <EditableProTable
        rowKey="id"
        search={false}
        recordCreatorProps={false}
        editableFormRef={editorFormRef}
        actionRef={actionRef}
        options={false}
        pagination={{
          pageSize: 10,
          showQuickJumper: true,
          showSizeChanger: false,
          showTotal(total, range) {
            return `总共${total}条`
          },
        }}
        request={async (params, sort, filter) => {
          const { pageSize, ...rest } = params
          const response = await getContractInfo(id)
          setTotalCount(response.data.organizationVos?.length || 0)
          setDataSourceList(() => {
            if (response.data.organizationVos) {
              return response.data.organizationVos
            } else {
              return []
            }
          })
          return {
            data: response.data.organizationVos,
            success: Boolean(response.data.successful),
            // eslint-disable-next-line no-extra-boolean-cast
            total: Boolean(response.data.organizationVos)
              ? response.data.organizationVos?.length
              : 0,
          }
        }}
        value={dataSourceList}
        onChange={setDataSourceList}
        editable={{
          editableKeys,
          onSave: async (rowKey, data, row) => {
            await createContractOrganizations({
              id: id,
              organizationId: data.id,
            })
            await message.success("添加成功")
            actionRef.current?.reload()
          },
          onChange: setEditableRowKeys,
        }}
        columns={columns}
      ></EditableProTable>
    </>
  )
}
export default UsableTissue
