/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { getIndividualCancelDetails } from "../promise"
import BasicInfo from "./BasicInfo"
import StepCom from "./StepCom"
import Logs from "./Logs"
import SubmitInfo from "./SubmitInfo"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import { Space } from "antd"
import { getOrderUploadByPath } from "../../service"
// 注销流程
const AgencyDetailsInfo = () => {
  // 角色code
  const roleList = useSelector((state: any) => state.roleCodes)
  const searchParams = useParams()
  const [details, setDetails] = useState<any>({})
  const [isSuc, setIsSuc] = useState(false)
  const local: any = localStorage.getItem("operation_tissue") || "{}"
  // 注销创建人
  const logOff = useMemo(() => {
    return details?.createUser == JSON.parse(local)?.userId
  }, [details])
  // 办税人权限
  const taxRole = useMemo(() => {
    return details?.taxOfficerId == JSON.parse(local)?.userId
  }, [details])
  // 执照办理人权限
  const licenseRole = useMemo(() => {
    return details?.licenseOfficeId == JSON.parse(local)?.userId
  }, [details])
  const [idCardUrl, setIdCardUrl] = useState("")
  const [idCardBackUrl, setIdCardBackUrl] = useState("")
  const [taxCleanFileOssUrl, setTaxCleanFileOssUrl] = useState("")
  const [licenseCancelFileOssUrl, setLicenseCancelFileOssUrl] = useState("")
  const getImage = (str: string, type: string) => {
    const obj = {
      zoom: false,
      filePaths: [str],
    }
    getOrderUploadByPath(obj).then((res: any) => {
      if (res.code == 200) {
        if (type === "is") {
          setIdCardUrl(res?.data?.files[0]?.downloadUrl)
        }
        if (type === "the") {
          setIdCardBackUrl(res?.data?.files[0]?.downloadUrl)
        }
        if (type === "taxCleanFileOssId") {
          setTaxCleanFileOssUrl(res?.data?.files[0]?.downloadUrl)
        }
        if (type === "licenseCancelFileOssId") {
          setLicenseCancelFileOssUrl(res?.data?.files[0]?.downloadUrl)
        }
      }
    })
  }
  // 获取详情
  const getDetailsInfo = (id?: any) => {
    setIsSuc(false)
    if (!id || id === "") return
    getIndividualCancelDetails(id).then((res: any) => {
      if (res.code === 200) {
        setIsSuc(true)
        setDetails(res.data)
        if (res?.data?.organizationVo?.idCardFront) {
          getImage(res?.data?.organizationVo?.idCardFront, "is")
        }
        if (res?.data?.organizationVo?.idCardBack) {
          getImage(res?.data?.organizationVo?.idCardBack, "the")
        }
        if (res?.data?.taxCleanFileOssId) {
          getImage(res?.data?.taxCleanFileOssId, "taxCleanFileOssId")
        }
        if (res?.data?.licenseCancelFileOssId) {
          getImage(res?.data?.licenseCancelFileOssId, "licenseCancelFileOssId")
        }
      }
    })
  }
  useEffect(() => {
    getDetailsInfo(searchParams?.id)
  }, [searchParams])
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <span>个体户注销申请详情</span>
          </Space>
        }
        breadCrumb={[
          { label: "业务代办", path: "" },
          { label: "工商税务开办", path: "" },
          { label: "个体户注销申请详情", path: "" },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <DetailPageContent>
        <div>
          <StepCom
            details={details}
            update={getDetailsInfo}
            logOff={logOff}
            taxRole={taxRole}
          ></StepCom>
          {/* 注销完成之前  需要提交各个环节注销参数 */}
          {details?.cancelStatus !== "CANCEL_COMPLETE" && details?.cancelStatus !== "REVOKE" ? (
            <SubmitInfo
              details={details}
              update={getDetailsInfo}
              logOff={logOff}
              taxRole={taxRole}
              licenseRole={licenseRole}
              isSuc={isSuc}
            ></SubmitInfo>
          ) : null}

          <BasicInfo
            details={details}
            idCardUrl={idCardUrl}
            idCardBackUrl={idCardBackUrl}
            taxCleanFileOssUrl={taxCleanFileOssUrl}
            licenseCancelFileOssUrl={licenseCancelFileOssUrl}
          ></BasicInfo>
          <Logs details={details}></Logs>
        </div>
      </DetailPageContent>
    </DetailPageContainer>
  )
}

export default AgencyDetailsInfo
