import React, { useEffect, useState } from "react"
import { message, Tabs } from "antd"
import type { TabsProps } from "antd"
import LogoPng from "../../assets/rscm-login.png"
import MessageInput from "./MessageInput/index"
import MessageInputPhone from "./MessageInput/indexPhone"
import QrInput from "./QrInput"
import "./index.scss"
import { useNavigate } from "react-router-dom"
import Util from "../../services/util"
import { ROLE_CODE } from "../../store/constants"
import { useDispatch } from "react-redux"
import { cross_env } from "../../services/serviceOp"

const util = Util.getInstance()

const Login: React.FC = () => {
  const dispatch = useDispatch()
  let [tabsKey, setTabsKey] = useState("message_tab")
  const nav = useNavigate()
  useEffect(() => {
    init()
    localStorage.removeItem("invoces")
    localStorage.removeItem("cacheInformation")
  }, []) // eslint-disable-line
  const init = async () => {
    const tokenObj = localStorage.getItem("operation_authorization") || "{}"
    if (JSON.parse(tokenObj).access_token) {
      localStorage.removeItem("operation_authorization")
    }
    const access_token = new URLSearchParams(window.location.search.substring(1)).get(
      "access_token"
    )
    if (access_token) {
      window.localStorage.setItem("operation_authorization", JSON.stringify({ access_token }))
      let redirect = new URLSearchParams(window.location.search.substring(1)).get("redirect")

      let result = (await getUserInfo()).data
      console.log(result, "result")
      result["access_token"] = access_token
      result["functionCode"] = result.authorities.join(",")
      result["userId"] = result?.id
      result["username"] = result?.name
      result["userName"] = result?.name
      localStorage.setItem("tokenMessage", JSON.stringify(result))
      localStorage.setItem("operation_authorization", JSON.stringify(result))
      dispatch({
        type: ROLE_CODE,
        payload: {
          codeList: result?.authorities,
          roleCodes: result?.roleCodes.split(","),
        },
      })
      if (redirect) {
        window.location.href = redirect
      } else {
        nav("/entity/transaction")
      }
      return
    }
    // const fail_login = new URLSearchParams(window.location.search.substring(1)).get("fail_login")
    // if (fail_login) {
    //   message.error("无登录权限，请联系管理员！")
    //   nav("/login")
    // }
  }
  const getUserInfo = async () => {
    return await util.httpGet(`${cross_env}/rscm/rscm-auth/oauth/token`)
  }
  const items: TabsProps["items"] = [
    {
      key: "message_tab",
      label: "密码登录",
      children: <MessageInput />,
    },
    {
      key: "message_tabs",
      label: "",
      children: <MessageInputPhone />,
    },
  ]
  return (
    <>
      <div className="login">
        <div className="sign-container">
          <div className="sign-container-text">
            <img src={LogoPng} alt="title" />
            <h2>运营中心</h2>
          </div>
          <div className="sign-container-input">
            <Tabs
              defaultActiveKey="message_tab"
              defaultValue={tabsKey}
              items={items}
              onChange={(key) => setTabsKey(key)}
            />
          </div>
        </div>
        <div className="login_footer">运营中心</div>
      </div>
    </>
  )
}
export default Login
