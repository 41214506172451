// 签章类型
export const typeEnum = new Map([["1", { text: "手机号" }]])

export const columnsPublic: any[] = [
  {
    title: "证件号（企业证件号、身份证号）",
    dataIndex: "id",
  },
  {
    title: "推送渠道",
    dataIndex: "decs",
    valueType: "select",
    valueEnum: typeEnum,
    formItemProps: () => {
      return {
        rules: [{ required: true, message: "此项为必填项" }],
      }
    },
  },

  {
    title: "推送地址",
    dataIndex: "title",
    formItemProps: () => {
      return {
        rules: [{ required: true, message: "此项为必填项" }],
      }
    },
  },
]
