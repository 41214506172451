import { OP_RESOURCE } from "../../api/config/servicePort"
import UtilOp from "../../services/serviceOp"
const utilOp = UtilOp.getInstance()

//直播接口
export const getIotLiveBroadcast = (params: any) => {
  return utilOp.httpGet(OP_RESOURCE + `/iot/live`, params)
}

//关闭直播接口
export const getIotLiveBroadcastOff = (params: any) => {
  return utilOp.httpGet(OP_RESOURCE + `/iot/close-live`, params)
}

//数据大屏接口
export const getDeviceLargeData = (params: any) => {
  return utilOp.httpGet(OP_RESOURCE + `/device/analyse`, params)
}
