const withAllowClear = {
  allowClear: true,
}
export default {
  type: "object",
  labelWidth: 76,
  properties: {
    bookCheckStatusEnum: {
      title: "状态",
      type: "string",
      widget: "select",
      enum: [
        "WAIT_SIGN",
        "WAIT_BANK_SIGN",
        "WAIT_CHECK",
        "WAIT_FACE_CHECK",
        "DONE",
        "CHECK_REJECT",
      ],
      enumNames: ["待签署", "待绑卡", "待审核", "待人脸审核", "建档成功", "审核拒绝"],
      placeholder: "请选择",
      props: withAllowClear,
    },
    timer: {
      bind: ["regQueryStartTime", "regQueryEndTime"],
      title: "准入时间",
      type: "data",
      widget: "XZDateRange",
      props: withAllowClear,
    },
    name: {
      title: "搜索",
      type: "string",
      widget: "input",
      placeholder: "供应商名称",
      props: withAllowClear,
    },
  },
}
