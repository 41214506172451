import React from "react"
import moment from "moment"
import { Badge } from "antd"
export default [
  {
    title: "组织",
    dataIndex: "orgName",
    fixed: "left",
  },
  {
    title: "设备名称",
    dataIndex: "name",
  },
  {
    title: "设备ID",
    dataIndex: "deviceCode",
  },
  {
    title: "在线状态",
    dataIndex: "onLine",
    render(_, record) {
      return <span>{record.onLine ? "在线" : "离线"}</span>
    },
  },
  {
    title: "离线时间",
    dataIndex: "onLineTime",
    render(_, record) {
      return <span>{record.onLine ? "-" : record.onLineTime || "-"}</span>
    },
  },
  {
    title: "部署位置",
    dataIndex: "area",
    render(_, record) {
      return <span>{(record.longitude, record.latitude, record.area)}</span>
    },
  },
]
