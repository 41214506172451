/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react"
import type { MenuProps } from "antd"
import { Menu } from "antd"
import { useNavigate, useLocation } from "react-router-dom"
import { EffectUrl } from "./constant"
import { saveKey } from "../SkTable/content"
import "./index.scss"

type type_menu_item = Required<MenuProps>["items"][number]
type type_key = {
  name: string
  path: string
  icon?: JSX.Element | any
  children?: type_key[]
  code?: string
  key?: string
}
type type_props = {
  lists: type_key[]
}

const { SubMenu } = Menu

const LeftNavbar: React.FC<type_props> = ({ lists }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [pathName, setPathName] = useState<string>("")
  const [items, setItems] = useState<type_menu_item[] | any>([])

  useEffect(() => {
    setItems(lists)
    const text: any = location.pathname.match(/(\/.*)/)
    const urlName = text[1]
    setPathName(urlName)
  }, [location, lists])
  const arrNode = useMemo(() => {
    return (
      <Menu
        selectedKeys={[pathName]}
        onClick={(e) => {
          navigate(e.key)
        }}
        mode="inline"
        theme="dark"
        multiple={false}
        inlineCollapsed={false}
        defaultOpenKeys={EffectUrl}
      >
        {items &&
          items.map((e: any, i: any) => {
            if (e.children) {
              return (
                <SubMenu
                  className="sub-menu"
                  key={e.key}
                  title={
                    <div className="meun-children">
                      <img className="menu-icon-image" src={e.icon} />
                      <Menu.Item
                        key={e.path}
                        onClick={() => {
                          sessionStorage.removeItem(saveKey())
                          navigate(e.key)
                        }}
                      >
                        <span>{e.name}</span>
                      </Menu.Item>
                    </div>
                  }
                >
                  {e.children.map((ee: any) => {
                    return (
                      <Menu.Item
                        className="meun-children"
                        key={ee.path}
                        onClick={() => {
                          sessionStorage.removeItem(saveKey())
                          navigate(ee.key)
                        }}
                      >
                        <span>{ee.name}</span>
                      </Menu.Item>
                    )
                  })}
                </SubMenu>
              )
            } else {
              return (
                <Menu.Item
                  className="meun-children"
                  key={e.path}
                  onClick={() => {
                    sessionStorage.removeItem(saveKey())
                    navigate(e.key)
                  }}
                >
                  {/* <i className={`menu-icon iconfont icon-${e.icon}`}></i> */}
                  <img className="menu-icon-image" src={e.icon} />
                  <span style={{ marginLeft: 30 }}>{e.name}</span>
                </Menu.Item>
              )
            }
          })}
      </Menu>
    )
  }, [pathName, items])
  return <div className="left-nav-bar">{arrNode}</div>
}
export default LeftNavbar
