// 后端微服务端口名
export const PORT1 = "/rscm/rscm-auth"
export const APIPrefix = "/consignor/recovery/admin/recovery-group"
export const USER = "/rscm/rscm-system/users"
export const OP_USER = "/rscm/rscm-system"
export const OP_SOURCE = "/rscm/rscm-resource"
export const OP_TRADE = "/rscm/rscm-trade"
export const OP_LOGISTIC = "/rscm/rscm-logistic"
export const OP_AGENCY = "/rscm/sinzetech-agency-processing"
export const OP_BI = "/rscm/rscm-bi"
export const OP_PUB_DATA = "/rscm/sk-pub-data"
export const OP_SIGN = "/rscm/rscm-sign"
export const OP_INVOICE = "/rscm/rscm-invoice"
export const OP_EVIDENCE = "/rscm/rscm-evidence"
export const OP_RESOURCE = "/rscm/rscm-resource"
export const OP_LOAN = "/rscm/rscm-loan"

export const APPLY = "/rscm/rscm-system/applications"
export const ACCESS_KEYS = "ACCESS_KEYS" // 权限
export const OPL_INVOICE = "/rscm/rscm-invoice"
