import { OP_SOURCE, OP_INVOICE } from "../../../../api/config/servicePort"
import UtilOp from "../../../../services/serviceOp"
// import { invoiceURL } from "@src/utils/invoiceURL"
const util = UtilOp.getInstance()
// const  = invoiceURL()
// 开票列表
interface Invoces {
  size: number
  curPage: number
  query?: string
  taxClerk?: string
  createTimeBegin?: string
  createTimeEnd?: string
  completeTimeBegin?: string
  completeTimeEnd?: string
  taskStatus?: string
}
export const getInvocesList = (params: Invoces) => {
  return util.httpGet(OP_INVOICE + "/invoicing-tasks", params)
}

// 开票详情
export const getInvocesInfo = (id: string | number) => {
  return util.httpGet(OP_INVOICE + `/invoicing-tasks/${id}`)
}

// 重开票
export const reInvoicing = (id: string | number) => {
  return util.httpGet(OP_INVOICE + `/invoices/create/${id}`)
}
// orc
export const getIdentifyInfoOCR = (params: any) => {
  return util.httpGet(OP_SOURCE + "/ocr/invoices", params)
}
// ocr识别
// export const getIdentifyInfoOCR = (id: string) => {
//   return util
//     .httpPost(`/invoices/ocr`, id, {
//       headers: {
//         "Content-Type": "text/plain",
//       },
//     })
//     .then((res) => res)
//     .catch((e) => e)
// }

// 根据开票任务  获取发票数据列表
export const getInvoices = (id: string) => {
  return util.httpGet(OP_INVOICE + `/invoices/${id}`)
}

// 发票预览
export const preview = (id: string) => {
  return util.httpGet(OP_INVOICE + `/invoices/preview/${id}`)
}
// 新建发票数据
interface createInvoces {
  fileId?: any
  vatInvoiceResponse?: any
}
export const createInvoces = (id: string) => {
  return util.httpPost(OP_INVOICE + `/invoices/create/${id}`)
}
//id换取文件路径
export const getOrderUploadByPath = async (params: any) => {
  return util.httpPut(OP_SOURCE + `/oss/endpoint`, params)
}

// 获取二维码
export const getInvoicesAccount = (params: any) => {
  return util.httpGet(OP_INVOICE + `/account/qrcode`, params)
}
// 发票拆分
export const getInvoicingSplit = (params: string) => {
  return util.httpGet(OP_INVOICE + `/invoicing-tasks/split`, params)
}
// 发票拆分确认
export const getInvoicingConfirm = (id: string, params: createInvoces) => {
  return util.httpPost(OP_INVOICE + `/invoicing-tasks/split-confirm/${id}`, params)
}
//选择任务项汇总接口
export const getCalculateSelect = (params: any) => {
  return util.httpGet(OP_INVOICE + "/invoicing-tasks/calculate-select", params)
}
