import * as XLSX from "xlsx"
import dayjs from "dayjs"
const exportToExcel = (list, title, columns) => {
  const workbook = XLSX.utils.book_new()
  const lists: any = []
  const fields = {}
  columns.map((item: any) => {
    if (item.dataIndex) {
      lists.push({
        label: item.title,
        value: item.dataIndex,
      })
      fields[item.dataIndex] = item.title
    }
  })
  const header = lists.map((item: any) => item.value)

  const data = [
    fields,
    ...list.map((item: any) => {
      let data = {}
      header.forEach((it: any) => {
        data[it] = item[it]
      })
      return data
    }),
  ]

  const ws = XLSX.utils.json_to_sheet(data, {
    header,
    skipHeader: true,
  })

  XLSX.utils.book_append_sheet(workbook, ws, "Sheet1")

  const dateStr = dayjs(new Date()).format("YYYY-MM-DD")
  const fileName = `${title}_${dateStr}.xlsx`
  XLSX.writeFile(workbook, fileName, {
    bookType: "xlsx",
  })
}
export default exportToExcel
