import { httpRequestProxy } from "../../services/serviceProxy"
import { OP_SIGN } from "../../api/config/servicePort"

export async function setOrganizeIdOfEsignForVendor(params: { vendorId: number }) {
  const { vendorId } = params
  return httpRequestProxy.httpPut(`/api/esign/account/org/${vendorId}`, {})
}

export async function fetchSignaturesOfVendor(params: { id: string }) {
  const { id } = params
  return httpRequestProxy.httpGet<
    { url: string; sealId: string; name: string; defaultFlag: boolean }[]
  >(OP_SIGN + `/accounts/org/${id}/seals`)
}

export async function createSignatureForVendor(params: {
  id: string
  base64: any
  filename: string
}) {
  const { id, base64 } = params
  return httpRequestProxy.httpPost(OP_SIGN + `/accounts/${id}/upload-seal`, base64, {
    headers: { "Content-Type": "text/plain;charset=UTF-8" },
  })
}

export async function setToDefaultSignatureOfVendor(params: { id: string; signatureId: string }) {
  const { id, signatureId } = params
  return httpRequestProxy.httpPut(OP_SIGN + `/accounts/org/${id}/default-seal/${signatureId}`, {})
}

export async function deleteSignatureOfVendor(params: { id: string; signatureId: string }) {
  const { id, signatureId } = params
  return httpRequestProxy.httpDeletel(OP_SIGN + `/accounts/org/${id}/remove-seal/${signatureId}`)
}
