import { httpRequestProxy } from "../serviceProxy"
import { OP_TRADE } from "../../api/config/servicePort"

export const orderApprovalApply = (orderIds: number[]) => {
  return httpRequestProxy.httpPost(`${OP_TRADE}/orders/approval`, orderIds)
}

export const orderApprovalStart = (orderId: number) => {
  return httpRequestProxy.httpPost(`${OP_TRADE}/orders/approval/start/${orderId}`)
}
export const orderApprovalPass = (orderId: number) => {
  return httpRequestProxy.httpPost(`${OP_TRADE}/orders/approval/pass/${orderId}`)
}
export const orderApprovalRefuse = (orderId: number, reason: string) => {
  return httpRequestProxy.httpPost(`${OP_TRADE}/orders/approval/refuse/${orderId}`, reason)
}
