/* eslint-disable react/no-children-prop */
import { DownloadFileButton } from "@/components/DownloadFileButton"
import {
  DownloadOutlined,
  LoadingOutlined,
  PlusOutlined,
  ReloadOutlined,
  SyncOutlined,
} from "@ant-design/icons"
import classNames from "classnames"
import React, { PropsWithChildren, useMemo } from "react"
import { EditableImage, EditableImageProps } from "../EditableImage"
import { EasyUpload, EasyUploadFileProps } from "../EasyUpload"
import "./index.scss"
import { cross_env } from "@src/services/util"
import { uuid } from "@src/utils"
import { cross_file_url_env } from "../../services/util"

const defaultProps = {}
type props = {
  src?: string
  editableImageProps?: EditableImageProps
  action?: string
  uploadFileProps?: EasyUploadFileProps
  updateFileProps?: EasyUploadFileProps
  createFileProps?: EasyUploadFileProps
  width?: number
  height?: number
  onSuccess?: EasyUploadFileProps["onSuccess"]
  onError?: EasyUploadFileProps["onError"]
  title?: string
  filename?: string
  isUpdate?: boolean // 是否更新
  isDownload?: boolean // 是否下载
  classFileName?: any
  tailor?: Boolean
}
const actionStyle = { color: "#fff", fontSize: 16 }

export type OperableImageProps = Partial<typeof defaultProps> & props

export const OperableImage: React.FC<PropsWithChildren<OperableImageProps>> = (props) => {
  const {
    src,
    children,
    editableImageProps,
    uploadFileProps,
    width,
    height,
    onSuccess,
    onError,
    title,
    filename,
    action,
    isUpdate = true,
    isDownload = true,
    classFileName = "",
    tailor = false,
  } = props
  const { updateFileProps = uploadFileProps, createFileProps = uploadFileProps } = props
  return (
    <div className="operableImage">
      <div className={classFileName} style={{ width, height }} title={title}>
        {src ? (
          <EditableImage
            title={title}
            width={width}
            height={height}
            src={src}
            classFileName={classFileName}
            {...editableImageProps}
            actions={[
              isUpdate && (
                <EasyUpload
                  onSuccess={onSuccess}
                  onError={onError}
                  action={action}
                  tailor={tailor}
                  method="PUT"
                  {...updateFileProps}
                >
                  <div style={actionStyle} title="更新图片">
                    <SyncOutlined />
                  </div>
                </EasyUpload>
              ),
              isDownload && (
                <DownloadFileButton href={src} filename={filename ? filename : title}>
                  {(props) => (
                    <div
                      style={actionStyle}
                      className="actionStyle"
                      onClick={props.onDownload}
                      title="下载图片"
                    >
                      {props.loading ? <LoadingOutlined /> : <DownloadOutlined />}
                    </div>
                  )}
                </DownloadFileButton>
              ),
            ]}
          />
        ) : (
          <EasyUpload
            onSuccess={onSuccess}
            onError={onError}
            listType="picture-card"
            action={action}
            tailor={tailor}
            method="PUT"
            {...createFileProps}
          >
            {(props) => (
              <EasyUpload.ImageTypeUI
                {...props}
                children={
                  <>
                    {classFileName !== "" ? null : (
                      <>
                        <PlusOutlined />
                        <span style={{ marginTop: 8 }}>{children}</span>
                      </>
                    )}
                  </>
                }
              />
            )}
          </EasyUpload>
        )}
      </div>
    </div>
  )
}

OperableImage.defaultProps = defaultProps
