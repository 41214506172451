import React, { useState, useEffect } from "react"
import { Upload, message, Modal, Progress, Image } from "antd"
import Util from "../../services/serviceOp"

const util = Util.getInstance()
// 组件上传文件

/**
 * @param{type}  image, video audio
 *
 * */

const uuid = () => {
  let date = new Date().valueOf() //获取时间戳
  let txt = "1234567890" //生成的随机机器码
  let len = 13 //机器码有多少位
  let pwd = "" //定义空变量用来接收机器码
  for (let i = 0; i < len; i++) {
    pwd += txt.charAt(Math.floor(Math.random() * txt.length)) //循环机器码位数随机填充
  }
  return date + pwd
}

const UploadCom = (props: any) => {
  const {
    type = "file",
    accept = "",
    maxSize,
    listType = "picture",
    maxCount = 1,
    name = "图片",
    cb,
    directory,
    value,
    multiple = false,
    ButtonText = `+上传默认${name}`,
    nullText = null,
    fileParams,
    filesValue = true,
  } = props
  const [fileList, setFileList] = useState<any>([])
  const [messageApi, contextHolder] = message.useMessage()
  const [visible, setVisible] = useState(false)
  const [visibleFile, setVisibleFile] = useState("")
  const beforeUpload = (file: any, fileList: any) => {
    const typeList = accept.split(",")
    console.log(typeList, "typeList")
    // const fileType = file.name.lastIndexOf(".")
    // let str = file.name.substring(fileType)
    // console.log(str, "file")

    // if (type !== "file") {
    //   if (typeList.indexOf(file.type) < 0) {
    //     messageApi.open({
    //       type: "error",
    //       content: `请选择正确格式的${name}`,
    //     })
    //     return false
    //   }
    // } else {
    //   if (typeList.indexOf(str) < 0) {
    //     messageApi.open({
    //       type: "error",
    //       content: `请选择正确格式的${name}`,
    //     })
    //     return false
    //   }
    // }
    if (maxSize && maxSize !== 0) {
      const currentSize = file.size / 1024 / 1024
      if (currentSize > maxSize) {
        messageApi.open({
          type: "error",
          content: `大小不能超过${maxSize}M`,
        })
        return false
      }
    }
    return file
  }
  const customRequest = (params: any) => {
    const { file, onSuccess, onError } = params
    const formData = new FormData()
    formData.append("file", file)
    util
      .httpUpload(
        `/rscm/rscm-resource/oss/endpoint?contentType=${file.type}&rename=${uuid()}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res: any) => {
        if (res.code === 200) {
          // onSuccess()
          let arr: any = []
          const fileType = file.name.lastIndexOf(".")
          let str = file.name.substring(fileType)
          arr.push({
            url: res.data.downloadLink,
            downloadLink: res.data.downloadLink,
            filePath: res.data.filePath,
            mimeType: str,
            fileName: file.name,
            size: file.size / 1024 / 1024,
            fileId: res.data.filePath,
            name: file.name,
            status: "done",
          })
          // console.log(arr, 'lllllllllllll')
          // const newArr  = fileList.filter((item:any) => {
          //   if(item.status === 'uploading' && item.name == file.name){
          //     item.status ="done"
          //     item.percent = '100',
          //     item.url =
          //   }
          //   return true
          // })
          onSuccess(
            {
              url: res.data.downloadLink,
              downloadLink: res.data.downloadLink,
              filePath: res.data.filePath,
              mimeType: str,
              fileName: file.name,
              size: file.size / 1024 / 1024,
              fileId: res.data.filePath,
              name: file.name,
              status: "done",
            },
            file
          )

          console.log(arr, "newArr")
          setFileList(arr)
          cb && cb(arr, file)
        } else {
          onError()
          messageApi.open({
            type: "error",
            content: res.message || "上传服务异常",
          })
        }
      })
  }
  const handleRemove = (file: any) => {
    if (!multiple) {
      setFileList([])
      cb && cb([], file)
    } else {
      const arr = fileList.filter((item: any) => {
        return item.url !== file.url
      })
      setFileList(arr)
      cb && cb(arr, file)
    }
  }
  useEffect(() => {
    if (!multiple) {
      if (value && value !== "") {
        const str = {
          url: value,
          ...fileParams,
        }
        console.log(str, "ssssssss")
        setFileList([str])
      }
    } else {
      if (value && value.length !== 0) {
        let arr = JSON.parse(JSON.stringify(value)) || []
        if (arr.length !== 0) {
          arr.map((item: any) => {
            // eslint-disable-next-line prettier/prettier
            ;(item.url = item.fileUrl), (item.name = item.fileName)
            return item
          })
        }

        setFileList(arr)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const handlePreview = (file: any) => {
    if (file.url) {
      if (filesValue) setVisible(true)
      setVisibleFile(file?.url)
    }
  }
  const handleChange = (info: any) => {
    console.log(info, "chaneg")
    // const list =[...fileList]

    setFileList(info.fileList)
  }
  return (
    <div>
      {contextHolder}
      <Upload
        listType={listType}
        accept={accept}
        fileList={fileList}
        maxCount={maxCount}
        multiple={multiple}
        onRemove={handleRemove}
        beforeUpload={beforeUpload}
        customRequest={customRequest}
        directory={directory}
        onPreview={handlePreview}
        onChange={handleChange}
        progress={{
          showInfo: true,
          strokeColor: {
            "0%": "#108ee9",
            "100%": "#87d068",
          },
          strokeWidth: 3,
          format: (percent: any) => {
            console.log(percent, "pppppp")
            return percent + "%"
            // return '80%'
          },
        }}
      >
        {fileList.length !== 0 && !multiple ? (
          nullText
        ) : (
          <div style={{ display: "flex" }}>{ButtonText}</div>
        )}
      </Upload>
      <Modal width={900} open={visible} onCancel={() => setVisible(false)} footer={null}>
        <img src={visibleFile} width={900} height={500} />
      </Modal>
    </div>
  )
}

export default UploadCom
