/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMount, useRequest } from "ahooks"
import { createContext, useEffect } from "react"
import { getEntityById, getOrderDetailById, getSupplyContracts, getLogisticsAPI } from "./service"
import { convertData } from "./utils"
import { useParams } from "react-router-dom"
import { OP_TRADE } from "../../../../../api/config/servicePort"

import {
  getOrderLogisticsItem,
  getOrderLogisticsList,
  getOrderLogisticsTrajectory,
  getTradingSchemeDetails,
} from "../../../promises"

export const useOrderDetail = () => {
  const params = useParams<{ id: string }>()
  const tradingSchemeDetailsService = useRequest(getTradingSchemeDetails, { manual: true })

  const orderDetailService = useRequest(getOrderDetailById, {
    // defaultParams: [params.id!],
    manual: true,
    onSuccess: (res) => {
      tradingSchemeDetailsService.run(res?.order?.tradeStrategyId)
      // tradingSchemeDetailsService.run(res.data.order)
      // if (res.data.sellerId && res.data.buyerId) {
      //   entitiesService.run({ sellerId: res.data.sellerId, buyerId: res.data.buyerId })
      //   supplyContractsService.run({
      //     sellerId: res.data.sellerId,
      //     buyerId: res.data.buyerId,
      //     effective: 1,
      //     current: 1,
      //     size: 999,
      //   })
      //   getLogistics.run({ orderNo: res?.data.buyerId })
      // }
    },
  })

  useMount(() => {
    const id = params.id
    if (!id) return
    orderDetailService.run(id)
    logisticsListService.run(id)
  })

  const logisticsListService = useRequest(getOrderLogisticsList, {
    manual: true,
    onSuccess: (res: any) => {
      if (!res?.[0]?.id) return
      logisticsDetailService.run(res?.[0]?.id)
    },
  })
  const logisticsDetailService = useRequest(getOrderLogisticsItem, {
    manual: true,
    onSuccess: (res) => {
      const logisticsId = logisticsDetailService.params[0]
      if (!logisticsId) return
      trajectoryService.run(logisticsId)
    },
  })
  const trajectoryService = useRequest(getOrderLogisticsTrajectory, { manual: true })
  // const logisticsListService = useRequest(
  //   (id: string) => {
  //     return http.get(`${OP_TRADE}/logistic/freight/order/${id}`)
  //   },
  //   {
  //     manual: true,
  //     onSuccess: (res: any) => {
  //       if (!res?.[0]?.id) return
  //       logisticsDetailService.run(res?.[0]?.id)
  //     },
  //   }
  // )
  const data = convertData(orderDetailService.data, logisticsListService.data)
  const loading = orderDetailService.loading
  const refresh = async () => {
    return await orderDetailService.refreshAsync()
  }
  return {
    data,
    logisticsDetailService,
    trajectoryService,
    tradingSchemeDetailsService,
    loading,
    refresh,
  }
}

export const OrderDetailContext = createContext<ReturnType<typeof useOrderDetail> | null>(null)
