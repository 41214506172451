/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo } from "react"
import { Button, Steps, message, Input } from "antd"
import { stepList } from "../../config"
import ModalCom from "@src/components/ModalCom"
import { getFileUrl } from "@src/utils/file.ts"

import {
  getIndividualCancelNote,
  getIndividualCancelReject,
  getIndividualCancellation,
} from "../../promise"
import { useNavigate } from "react-router-dom"

import "./index.scss"
import { getOrderUploadByPath } from "../../../service"
const { TextArea } = Input
interface Obj {
  id?: string
  file?: string
  rejectReason?: string
}
interface Prop {
  details?: any
  update?: (id: string | any) => void
  taxRole?: boolean
  logOff?: boolean
}

const StepCom = (props: Prop) => {
  const { details, update, taxRole, logOff } = props
  const nav = useNavigate()
  const [rejectOpen, setRejectOpen] = useState(false)
  const [rejectReason, setRejectReason] = useState("")

  // 下载申请书
  const downloadApplicationOperation = useMemo(() => {
    return [
      {
        role: true,
        operation: (
          <Button type="primary" onClick={() => handleDownload(details?.applyFileOssId)}>
            下载注销申请书，线下签署
          </Button>
        ),
      },
    ]
  }, [details])
  // 下载注销协议
  const downloadCancellationOperation = useMemo(() => {
    return [
      {
        role: true,
        operation: (
          <Button type="primary" onClick={() => handleDownload(details?.applyFileOssId)}>
            下载注销协议
          </Button>
        ),
      },
    ]
  }, [details])
  // 撤销申请
  const cancelationOperation = useMemo(() => {
    return [
      {
        role: true,
        operation: (
          <Button
            danger
            onClick={async () => {
              if (!details?.id) return
              const result: any = await getIndividualCancellation(details.id)
              if (result.code == 200) {
                message.success("撤销申请成功")
                handleUpdate()
              }
            }}
          >
            撤销申请
          </Button>
        ),
      },
    ]
  }, [details, taxRole])
  const operationObj = useMemo(() => {
    let stepDetails = {
      APPLY_FILE_WAIT_SIGN: {
        label: "申请书待签署",
        color: "warning",
        current: 0,
        list: [
          ...cancelationOperation,
          ...downloadApplicationOperation, // 下载申请书
          {
            role: logOff,
            operation: (
              <Button type="primary" onClick={() => handleSign()}>
                重发签署注销申请书短信
              </Button>
            ),
          },
        ],
        value: "APPLY_FILE_WAIT_SIGN", // 存储用于提交数据的状态参数
      },
      TAX_WAIT_CLEAN: {
        label: "待清税",
        color: "warning",
        current: 1,
        list: [
          {
            role: true, // taxRole
            operation: (
              <Button
                danger
                onClick={() => {
                  setRejectOpen(true)
                }}
              >
                无法清税，驳回申请
              </Button>
            ),
          },
          ...downloadCancellationOperation, // 下载注销协议
        ],
        value: "TAX_WAIT_CLEAN",
      },
      LICENSE_WAIT_CANCEL: {
        label: "执照待注销",
        color: "warning",
        current: 2,
        list: downloadCancellationOperation, // 下载注销协议
        value: "LICENSE_WAIT_CANCEL",
      },
      CANCEL_COMPLETE: {
        label: "已注销",
        color: "warning",
        current: 3,
        list: downloadCancellationOperation, // 下载注销协议
        value: "CANCEL_COMPLETE",
      },
      REVOKE: {
        label: "已撤销",
        color: "warning",
        value: "REVOKE",
      },
    }
    let type = details?.cancelStatus
    return stepDetails[type]
  }, [details, taxRole, logOff])
  // 更新
  const handleUpdate = () => {
    update && update(details?.id)
  }
  // 重发短信
  const handleSign = async () => {
    if (!details?.id) return
    const res: any = await getIndividualCancelNote(details?.id)
    if (res?.code === 200) {
      message.success("已重发，请联系个体户法人签署")
      handleUpdate()
    }
  }
  // 失败原因提交
  const handleOk = async () => {
    if (!rejectOpen || rejectReason.trim() === "") {
      message.error("请输入失败原因")
      return
    }
    const params = {
      id: details.id,
      rejectReason: rejectReason,
    }
    const result: any = await getIndividualCancelReject(params)
    if (result.code == 200) {
      message.success("申请驳回成功")
      setRejectOpen(false)
      setRejectReason("")
      handleUpdate()
    }
  }
  // 下载注销协议  或者申请书
  const handleDownload = async (value?: any) => {
    if (!value) {
      message.error("找不到文件id")
      return
    }
    let valueParse: any = ""
    let pattern = /^\s*{\s*".+":.+\s*}\s*$/
    if (pattern.test(value)) {
      valueParse = JSON.parse(value).fileId
      console.log(1)
    } else {
      valueParse = value
      console.log(2)
    }
    console.log(valueParse, "valueParse")
    getFileUrl(valueParse)
  }
  return (
    <div className="agency_step_container">
      <div className="agency_step_container_title">流程进度</div>
      <div className="agency_steps">
        <Steps items={stepList} current={operationObj?.current}></Steps>
      </div>
      <div className="agency_step_opera">
        <div className="agency_step_status">
          状态
          <div className={`default_bg ${operationObj?.color + "_bg"}`}>
            <span className={`default_color ${operationObj?.color}`}></span>
            {operationObj?.label}
          </div>
        </div>
        <div className="step_tax">办税人：{details?.taxOfficerName}</div>
        <div className="right_btn_operation">
          {operationObj?.list?.map((item: any) => {
            return item?.role ? <div className="mar_right">{item?.operation}</div> : null
          })}
        </div>
      </div>
      {/* 无法清税，驳回申请 */}
      <ModalCom
        open={rejectOpen}
        title="无法清税原因"
        onCancel={() => {
          setRejectOpen(false)
          setRejectReason("")
        }}
        className="reject_modal"
        width={500}
        onOk={handleOk}
      >
        <div className="reject_modal_container">
          <span className="reject_form_label">请输入失败原因：</span>
          <div className="reject_form_value">
            <TextArea
              rows={3}
              placeholder="请输入失败原因"
              maxLength={1000}
              onChange={(e: any) => {
                setRejectReason(e.target.value)
              }}
            ></TextArea>
          </div>
        </div>
      </ModalCom>
    </div>
  )
}

export default StepCom
