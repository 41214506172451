/* eslint-disable react/jsx-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from "react"
import React from "react"
import { Button, Space, Col, Radio, Form, Row, Table, Modal, message, Input } from "antd"
import "./index.scss"
import columns from "./columns"
import { getInvoicingSplit, getInvoicingConfirm } from "../promise"

export const AdjustInformation = (props: any) => {
  const [form] = Form.useForm()
  const { information, setInformation, reload } = props
  const [previewApply, setPreviewApply] = useState<any>([]) //申请开票预览
  const [loadingInfo, setLoadingInfo] = useState<any>(false)

  const handleBlur = async (type: any = true) => {
    let _getFields = form.getFieldsValue()
    if (type) {
      if (!_getFields.equalSplitAmount && !_getFields.fixedTicketAmount) {
        return
      }
    }
    setLoadingInfo(true)
    let params = {
      ..._getFields,
      id: information?.record?.id,
    }
    const result = await getInvoicingSplit(params)
    if (result.code == 200) {
      setLoadingInfo(false)
      setPreviewApply(result.data)
    } else {
      setLoadingInfo(false)
    }
  }
  const handInformation = async () => {
    let params = {
      ...form.getFieldsValue(),
    }
    const result: any = await getInvoicingConfirm(information?.record?.id, params)
    if (result.code == 200) {
      message.success("操作成功")
      setInformation({ open: false, record: [] })
      setPreviewApply([])
      form.resetFields()
      reload()
    }
  }
  useEffect(() => {
    if (information?.record?.id) handleBlur(false)
  }, [information])
  return (
    <div className="billing">
      <Modal
        title="全电开票"
        open={information?.open}
        width={900}
        onCancel={() => {
          setInformation({ open: false, record: [] })
          setPreviewApply([])
          form.resetFields()
        }}
        destroyOnClose={true}
        footer={null}
      >
        <div className="information-content">
          <div className="information-message">
            <div style={{ width: 400 }}>
              <div className="order-content">
                <div className="apply-billing">购买方信息</div>
              </div>
              <Row style={{ paddingTop: 16 }}>
                <Col span="15">
                  <div className={"info_items"}>
                    <span className={"label"}>名称:</span>
                    <span className={"value"}>{information?.record?.buyerName}</span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span="20">
                  <div className={"info_items"}>
                    <span className={"label"}>统一社会信用代码:</span>
                    <span className={"value"}>{information?.record?.buyerTaxNo}</span>
                  </div>
                </Col>
              </Row>
            </div>
            <div style={{ width: 400 }}>
              <div className="order-content">
                <div className="apply-billing">销售方信息</div>
              </div>
              <Row style={{ paddingTop: 16 }}>
                <Col span="15">
                  <div className={"info_items"}>
                    <span className={"label"}>名称:</span>
                    <span className={"value"}>{information?.record?.sellerName}</span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span="20">
                  <div className={"info_items"}>
                    <span className={"label"}>统一社会信用代码:</span>
                    <span className={"value"}>{information?.record?.sellerTaxNo}</span>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div>
            <div className="order-content">
              <div className="apply-billing" style={{ paddingTop: 16 }}>
                拆分发票
              </div>
              <div className="information-text">
                优先拆分一张固定票额的发票，剩余票额将按照均等拆金额进行拆分
              </div>
            </div>
            <div className="information-form">
              <Form form={form} style={{ display: "flex" }}>
                <Form.Item label="固定票额" name="fixedTicketAmount">
                  <Input
                    placeholder="请输入"
                    style={{ width: 250 }}
                    onBlur={handleBlur}
                    onPressEnter={handleBlur}
                  />
                </Form.Item>
                <Form.Item label="均等拆分" name="equalSplitAmount" style={{ marginLeft: 30 }}>
                  <Input
                    placeholder="请输入"
                    style={{ width: 250 }}
                    onBlur={handleBlur}
                    onPressEnter={handleBlur}
                  />
                </Form.Item>
              </Form>
            </div>

            <div className="table">
              <Table
                pagination={{
                  pageSize: 5,
                  showQuickJumper: true,
                  showTotal(total, range) {
                    return `总共${total}条`
                  },
                }}
                loading={loadingInfo}
                dataSource={previewApply}
                columns={columns}
                rowKey={(record: any) => record?.index}
              ></Table>
            </div>
          </div>
        </div>

        <Form.Item wrapperCol={{ span: 16 }} style={{ borderTop: "1px solid #f0f0f0" }}>
          <div
            className="form_footer"
            style={{ display: "flex", marginBottom: 10, paddingTop: 10 }}
          >
            <div style={{ display: "flex", flex: 1, textAlign: "right", marginLeft: "123%" }}>
              <Button
                style={{ marginRight: "12px" }}
                onClick={() => {
                  setInformation({ open: false, record: [] })
                  setPreviewApply([])
                  form.resetFields()
                }}
              >
                取消
              </Button>
              <Button type="primary" onClick={handInformation}>
                确定
              </Button>
            </div>
          </div>
        </Form.Item>
      </Modal>
    </div>
  )
}
export default AdjustInformation
