/* eslint-disable react-hooks/rules-of-hooks */
import { Row, Col } from "antd"
import React from "react"
import "./index.scss"
export const MakeOutAnInvoiceApply = (props: any) => {
  const { data } = props
  return (
    <>
      <Row>
        <Col span="12">
          <div className={"info_items"}>
            <span className={"label"}>购买方:</span>
            <span className={"value"}>{data?.name}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span="12">
          <div className={"info_items"}>
            <span className={"label"}>纳税人识别号:</span>
            <span className={"value"}>{data?.idNo}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span="12">
          <div className={"info_items"}>
            <span className={"label"}>地址、电话:</span>
            <span className={"value"}>{data?.invoiceAddress}</span>
            {data?.invoiceAddress && data?.invoicePhone ? "、" : ""}
            <span className={"value"}>{data?.invoicePhone}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span="12">
          <div className={"info_items"}>
            <span className={"label"}>开户行及账号:</span>
            <span className={"value"}>{data?.invoiceBankName}</span>
            {data?.invoiceBankName && data?.invoiceBankAccount ? "、" : ""}
            <span className={"value"}>{data.invoiceBankAccount}</span>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default MakeOutAnInvoiceApply
