import type { DataNode } from "antd/es/tree"

export const APIPrefix = "/consignor/recovery/admin/recovery-group"

export const dataSourceList = [
  { appName: "建龙运营端", appId: "130121241241" },
  { appName: "供应链平台", appId: "130121241244" },
]

export const treeData: DataNode[] = [
  {
    title: "parent 1",
    key: "0-0",
    children: [
      {
        title: "parent 1-0",
        key: "0-0-0",
        children: [
          {
            title: "leaf",
            key: "0-0-0-0",
          },
          {
            title: "leaf",
            key: "0-0-0-1",
          },
        ],
      },
      {
        title: "parent 1-1",
        key: "1-1-1",
        children: [
          {
            title: "leaf",
            key: "1-1-1-1",
          },
          {
            title: "leaf",
            key: "1-1-1-2",
          },
        ],
      },
    ],
  },
]
