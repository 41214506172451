/* eslint-disable react/display-name */
import { Button, Form, Space, Card, Upload, UploadFile, message } from "antd"
import React, { memo, useState } from "react"
import { namelessOptionEnum } from "../../../constants"
import { UploadOutlined, StarOutlined, FileOutlined } from "@ant-design/icons"
import { cross_env } from "../../../../../services/serviceOp"
import { getOrderUploadByPath } from "../../../service"
import { getFileUrl } from "../../../constants"

import "./index.scss"
const FormItem = Form.Item
export const ComponUpload = memo((props: any) => {
  const { form, title, formName, fileLists, setFileList, handTaxDistrict, rules, numInfo } = props

  return (
    <Form.Item
      rules={[{ required: rules, message: "请上传文件" }]}
      name={formName}
      label={title}
      className="FormItemUpload"
    >
      <div className="FormItemUpload-load">
        <Upload<Common.Res<{ fileKey: string }>>
          maxCount={numInfo}
          action={`${cross_env}/rscm/rscm-resource/oss/endpoint`}
          fileList={fileLists}
          // showUploadList={{
          //   removeIcon: <UploadOutlined  />,
          // }}
          method="put"
          onRemove={(file) => {
            // setFileList(file)
          }}
          headers={{
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("operation_authorization") || "{}"
            )?.access_token_op}`,
          }}
          onChange={({ fileList }) => {
            setFileList(fileList)
            // 全都完成了
            if (!fileList.some((file) => file.status !== "done")) {
              // const fileType = fileList.map((file) => file.response?.data)
              form?.setFieldValue(formName, fileList)
            }
          }}
        >
          <Space>
            <Button icon={<UploadOutlined />}>点击上传</Button>
            <span style={{ color: "#00000073" }}>最多上传{numInfo}个文件</span>
          </Space>
        </Upload>
        <div className="info-file">
          {handTaxDistrict ? (
            <a
              onClick={() => {
                if (handTaxDistrict?.flowConfig?.agreementExampleFileId) {
                  getFileUrl(handTaxDistrict?.flowConfig?.agreementExampleFileId)
                } else {
                  message.warning("当前税区,未找到模版")
                }
              }}
            >
              <FileOutlined />
              下载模版
            </a>
          ) : null}
        </div>
      </div>
    </Form.Item>
  )
})

export default ComponUpload
