const downloadFile = ({ filename, data }: { filename?: string; data: Blob | File | string }) => {
  const href = typeof data === "string" ? data : window.URL.createObjectURL(data)
  console.log(href, "href")
  const a = document.createElement("a")
  a.setAttribute("href", href)
  a.setAttribute("target", "_blank")
  a.setAttribute("download", filename || "文件") // 自定义文件名（有效）

  document.body.appendChild(a)
  a.click()
  try {
    document.body.removeChild(a)
  } catch (e) {
    window.console.log(e)
  }
  window.URL.revokeObjectURL(href)
}

export default downloadFile
