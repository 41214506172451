import React, { useEffect, useState } from "react"
import { Modal } from "antd"
import { Document, Page, pdfjs } from "react-pdf"
import "./index.scss"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const PdfView = ({ pdfSrc }: { pdfSrc: any }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [pdfConfig, setPdfConfig] = useState({
    pageNumber: 1,
    pageNumberInput: 1,
    pageNumberFocus: false,
    numPages: 1,
    pageWidth: 603,
    fullscreen: false,
  })

  const lastPage = () => {
    if (pdfConfig.pageNumber == 1) {
      return
    }
    const page = pdfConfig.pageNumber - 1
    document.getElementsByClassName("react-pdf__Document")[0].scrollTop = 0

    setPdfConfig({ ...pdfConfig, pageNumber: page, pageNumberInput: page })
  }
  const nextPage = () => {
    if (pdfConfig.pageNumber == pdfConfig.numPages) {
      return
    }
    const page = pdfConfig.pageNumber + 1
    document.getElementsByClassName("react-pdf__Document")[0].scrollTop = 0
    setPdfConfig({ ...pdfConfig, pageNumber: page, pageNumberInput: page })
  }
  const onPageNumberFocus = () => {
    setPdfConfig({ ...pdfConfig, pageNumberFocus: true })
  }
  const onPageNumberBlur = () => {
    setPdfConfig({ ...pdfConfig, pageNumberFocus: false, pageNumberInput: pdfConfig.pageNumber })
  }
  const onPageNumberChange = (e: any) => {
    let value = e.target.value
    value = value <= 0 ? 1 : value
    value = value >= pdfConfig.numPages ? pdfConfig.numPages : value
    setPdfConfig({ ...pdfConfig, pageNumberInput: value })
  }
  const toPage = (e: any) => {
    if (e.keyCode === 13) {
      setPdfConfig({ ...pdfConfig, pageNumber: Number(e.target.value) })
    }
  }
  // 下载文件
  const handleDownFile = () => {
    let download_a = document.createElement("a")
    download_a.setAttribute("href", pdfSrc)
    download_a.setAttribute("target", "_blank")
    download_a.setAttribute("id", "camnpr")
    document.body.appendChild(download_a)
    download_a.click()
    document.body.removeChild(download_a)
  }
  const onDocumentLoadSuccess = ({ numPages }: { numPages: any }) => {
    setPdfConfig({ ...pdfConfig, numPages: numPages })
  }

  useEffect(() => {
    setVisible(pdfSrc ? true : false)
  }, [pdfSrc])
  return (
    <Modal
      footer={false}
      wrapClassName="pdf-view-wrapper"
      width={720}
      visible={visible}
      onCancel={() => setVisible(false)}
    >
      <div className="pdf-view">
        <div className="container">
          <Document
            file={pdfSrc}
            onLoadSuccess={onDocumentLoadSuccess}
            options={{
              cMapUrl: "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.7.570/cmaps/",
              cMapPacked: true,
              disableWorker: true,
            }}
          >
            <Page
              pageNumber={pdfConfig.pageNumber}
              width={pdfConfig.pageWidth}
              loading={"加载中..."}
            />
          </Document>
        </div>
        <div className="page-tool">
          <div className="page-tool-item" onClick={lastPage}>
            {" "}
            上一页
          </div>
          <div className="page-tool-item" onClick={nextPage}>
            {" "}
            下一页
          </div>
          <div className="input">
            <input
              value={pdfConfig.pageNumberFocus ? pdfConfig.pageNumberInput : pdfConfig.pageNumber}
              onFocus={onPageNumberFocus}
              onBlur={onPageNumberBlur}
              onChange={onPageNumberChange}
              onKeyDown={toPage}
              type="number"
            />{" "}
            / {pdfConfig.numPages}
          </div>
          <div className="page-tool-item downbtn" onClick={handleDownFile}>
            {" "}
            下载
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default PdfView
