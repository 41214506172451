/* eslint-disable react-hooks/rules-of-hooks */
import { Form, Input, Row, Col, Select, Cascader, message } from "antd"
import React from "react"

export const applicationApply = (props: any) => {
  const { form } = props

  return (
    <>
      <Row>
        <Col>
          <Form.Item label="开票行名称" name="invoiceBankName">
            <Input placeholder={"请输入"} style={{ width: 306 }} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Item label="开票行账号" name="invoiceBankAccount">
            <Input placeholder={"请输入"} style={{ width: 306 }} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Item label="开票行电话" name="invoicePhone">
            <Input placeholder={"请输入"} style={{ width: 306 }} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Item label="开票地址" name="invoiceAddress">
            <Input placeholder={"请输入"} style={{ width: 306 }} />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export default applicationApply
