export const APIPrefix = "/consignor/recovery/admin/recovery-group"
export const USER = "/rscm/rscm-system/users"
export const APPLY = "/rscm/rscm-system/applications"
import { BadgeProps } from "antd"
import { getOrderUploadByPath } from "./service"

export const checkStatusEnumStatus: Record<string, BadgeProps> = {
  CREDIT_REJECT: {
    status: "error",
    text: "审核拒绝",
  },
  CREDIT_APPROVE: {
    status: "warning",
    text: "已申请",
  },
  UN_SIGN: {
    status: "warning",
    text: "协议待签署",
  },
  UN_REPAY: {
    status: "warning",
    text: "待还款",
  },
  PAYMENT_APPROVE: {
    status: "warning",
    text: "待放款",
  },
  PAYMENT_REJECT: {
    status: "error",
    text: "放款失败",
  },
  FINISHED: {
    status: "success",
    text: "还款完成",
  },
}
export const financingOrderStatusEnum: Record<string, BadgeProps> = {
  WAIT_SIGN: {
    status: "warning",
    text: "待签署",
  },
  WAIT_BANK_SIGN: {
    status: "warning",
    text: "待绑卡",
  },
  WAIT_CHECK: {
    status: "warning",
    text: "待审核",
  },
  WAIT_FACE_CHECK: {
    status: "warning",
    text: "待人脸审核",
  },
  DONE: {
    status: "success",
    text: "建档成功",
  },
  CHECK_REJECT: {
    status: "error",
    text: "审核拒绝",
  },
}
export const taxRegistrationStatusEnum: Record<string, BadgeProps> = {
  UNREGISTERED: {
    status: "warning",
    text: "待提交",
  },
  REGISTERED: {
    status: "warning",
    text: "待审核",
  },
  INVALID: {
    status: "success",
    text: "审核通过",
  },
}
export const repaymentMethodStatusEnum: any = {
  BULLET_REPAYMENT: "到期一次还本付息",
}
export const loanTermStatusEnum: any = {
  ORDER_COMPLETE: "按订单结算日期",
}
export const enableStatusEnum: Record<string, BadgeProps> = {
  0: {
    status: "error",
    text: "禁用",
  },
  1: {
    status: "success",
    text: "启用",
  },
}
export const OrderLogisticsStatusEnum: Record<string, BadgeProps> = {
  UNREGISTERED: {
    status: "success",
    text: "已申请",
  },
  REGISTERED: {
    status: "error",
    text: "审核拒绝",
  },
  INVALID: {
    status: "warning",
    text: "协议待签署",
  },
  INVALID1: {
    status: "warning",
    text: "待放款",
  },
  INVALID2: {
    status: "error",
    text: "放款失败",
  },
  INVALID3: {
    status: "warning",
    text: "待还款",
  },
  INVALID5: {
    status: "warning",
    text: "部分还款",
  },
  INVALID51: {
    status: "success",
    text: "还款完成",
  },
  INVALID52: {
    status: "error",
    text: "已逾期",
  },
}
export const getUrl = async (fileKey: string) => {
  let params = {
    filePaths: [fileKey],
  }
  const result: any = await getOrderUploadByPath(params)
  if (result.code == 200) {
    return result?.data?.files[0]?.downloadUrl
  }
}

export const loanCompanyList = (data) => {
  return [
    // {
    //   type: "营业执照",
    //   name: "营业执照.png",
    //   time: res?.data.legalIdCardFrontFileCreateTime,
    //   id: "1",
    //   fileId: res?.data.legalIdCardFrontFileId,
    // },
    {
      type: "身份证正面",
      name: "身份证正面.png",
      time: data?.legalIdCardFrontFileCreateTime,
      id: "2",
      fileId: data?.legalIdCardFrontFileId,
    },
    {
      type: "身份证反面",
      name: "身份证反面.png",
      time: data?.legalIdCardBackFileCreateTime,
      id: "3",
      fileId: data?.legalIdCardBackFileId,
    },
  ]
}
