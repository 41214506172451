import React, { FC, HTMLAttributes, ReactNode } from "react"
import { Button, ButtonProps } from "antd"
import { useLoading } from "@/hooks/useLoading"
import downloadFile from "@/utils/downloadFile"
import Util from "@src/services/util"
import axios, { AxiosRequestConfig } from "axios"
import dayjs from "dayjs"
import { isCrossOrigin } from "@src/utils/utils"
import { createImage } from "@src/utils/card/utils"
import {
  canvasToBlob,
  downloadCorsImage,
  imageToCanvas,
  getFileUrl,
} from "@src/utils/card/dataFormatConversion"

const defaultProps = {}

interface Props {
  href: string
  requestOptions?: AxiosRequestConfig
  buttonProps?: ButtonProps
  filename?: string
  children?:
    | ((props: { loading: boolean; onDownload: () => Promise<void> }) => ReactNode)
    | ReactNode
}

const downloadFileBlob = async (href: string, requestOptions: any) => {
  if (isCrossOrigin(href)) {
    // return await downloadCorsImage(href)
    getFileUrl(href)
  }
  const response = await axios.get(href, {
    ...requestOptions,
    responseType: "blob",
  })
  return response?.data
}
export type DownloadFileButtonProps = Partial<typeof defaultProps> & Props
export const DownloadFileButton: FC<DownloadFileButtonProps> = (props) => {
  const { buttonProps, filename, requestOptions, href, children } = { ...defaultProps, ...props }
  let _downloadFileHandle = async () => {
    const blob = await downloadFileBlob(href, requestOptions)
    // console.log(response.headers,response.headers['filename'],response.headers?.get('filename'),'response',response)
    // const encodedDefaultFilename = response.headers['filename']
    //   .get('content-disposition')
    //   ?.match(/filename=(.*)/)?.[0]
    //   .split('=')
    //   .reverse()[0];
    // const defaultFilename = encodedDefaultFilename ? decodeURI(encodedDefaultFilename) : undefined;
    downloadFile({
      data: blob,
      filename: filename ? filename : dayjs().format("YYYY-MM-DD HH.mm.ss"),
    })
  }
  const [downloadFileHandle, loading] = useLoading(_downloadFileHandle)
  return typeof children === "function" ? (
    <>{children({ loading, onDownload: downloadFileHandle })}</>
  ) : (
    <Button loading={loading} onClick={downloadFileHandle} type={"primary"} {...buttonProps}>
      {children}
    </Button>
  )
}
