import { BadgeProps } from "antd"
import dayjs from "dayjs"
import Decimal from "decimal.js"
import { RefObject } from "react"
import {
  DuplicateField,
  FormRef,
  RefType,
  RootFieldsValues,
  RootParams,
  SettlementPathsOneFields,
  TRefTypeArr,
} from "./interface"
// 获取 ref 数组
export const getRefs = (keyAndRefsArray: TRefTypeArr[] = []): RefObject<RefType | FormRef>[] => {
  let newArray: RefObject<RefType | FormRef>[] = []
  keyAndRefsArray.forEach((el: any) => {
    newArray.push(el.ref)
  })
  return newArray
}
// 获取 ref数组的 getValue的值
export const getRefsValues = (refArray: RefObject<RefType | FormRef>[] = []): any => {
  let values: any = {}
  refArray.forEach((ref: RefObject<RefType | any>) => {
    let valueObj: any = ref?.current?.getValue()
    console.log(valueObj, "valueObj")
    for (let k in valueObj) {
      values[k] = valueObj[k]
    }
  })
  return values
}
//判断结算设定输出是否相等
export function judge(arr: SettlementPathsOneFields | any) {
  for (let i = 0; i < arr.length; i++) {
    for (let j = 0; j < arr.length; j++) {
      let num = 0
      for (let key in arr[i]) {
        if (j != i && arr[j][key] == arr[i][key]) {
          num++
        }
      }
      if (num > 2) {
        return true
      }
    }
  }
  return false
}

//判断优先级
export function hasDuplicateField(array: DuplicateField | any, field: string) {
  const map = {}
  for (let i = 0; i < array.length; i++) {
    const value = array[i][field]
    if (map[value]) {
      return true
    }
    map[value] = true
  }
  return false
}
// 未使用，还导致报错，故隐藏
// //校验配置选项
// const handSettlementPaths = (values: RootFieldsValues) => {
//   return values?.settlementPaths?.length > 0
//     ? values?.settlementPaths
//     : values?.settlementPathsOne?.length > 0
//       ? values?.settlementPathsOne
//       : values?.settlementPathsTwo
// }
export const handLentConfig = (data) => {
  const resultData = data?.filter((ie: any) => {
    if (ie?.taxCompanyId) {
      ie.payeeRole = [ie.payeeRole, ie.taxCompanyId]
    }
    return ie
  })
  return resultData
}
//购消防
export const handSellerConfig = (value: any) => {
  const resultData =
    value &&
    value.map((ie) => {
      return {
        applyTo: ie.applyTo,
        intervalCount: ie.intervalCount + "",
        intervalType: ie.intervalType,
        limitAmount: ie.limitAmount + "",
        minAmount: ie.minAmount + "",
        priceType: ie.priceType,
      }
    })
  return resultData
}
const handSettlement = (value: any) => {
  const resultData =
    value &&
    value.map((ie, index) => {
      return {
        payOption: ie.payOption,
        payeeRole: ie?.payeeRole,
        payerRole: ie.payerRole,
        sort: index + 1,
        taxCompanyId: ie?.taxCompanyId,
      }
    })
  return resultData || []
}
const handCustomSettlement = (value: any) => {
  console.log(value, "value")
  const resultData =
    value &&
    value.map((ie, index) => {
      return {
        payOption: ie.payOption,
        payeeRole:
          Array.isArray(ie?.payeeRole) && ie?.payeeRole?.length == 2
            ? "TAX_COMPANY"
            : Array.isArray(ie?.payeeRole)
            ? ie?.payeeRole[0]
            : ie?.payeeRole,
        payerRole: ie.payerRole,
        sort: index + 1,
        taxCompanyId: Array.isArray(ie?.payeeRole) ? ie?.payeeRole[1] : null,
      }
    })
  console.log(resultData, "resultData")
  return resultData || []
}
//新增字段
export const getTradingScheme = (values: RootFieldsValues, newValue?: any) => {
  console.log(values, "values")
  let params: RootParams = {
    effectiveTime: dayjs(values?.time[0]).format("YYYY-MM-DD HH:mm:ss"),
    expirationTime: dayjs(values?.time[1]).format("YYYY-MM-DD HH:mm:ss"),
    name: values?.name,
    tradeType: {
      // purchase: {
      //   needSupplyAgreement: JSON.parse(values?.needSupplyAgreement || "false"),
      // },
      // difference: {
      // relatedOrderPaid: JSON.parse(values?.relatedOrderPaid || "false"),
      // relatedOrderInvoiced: JSON.parse(values?.relatedOrderInvoiced || "false"),
      // maxDifferenceRatio: Number(values?.maxDifferenceRatio) / 100,
      // },
      scope: values?.scope,
    },
    sellerConfig: {
      limitAmountConfig: values?.limitAmountConfig,
      // entityLevel: values?.sellerConfig,
      whiteList: values?.whiteList,
      blackList: values?.sellerBlackList,
      types: values?.sellerTypes,
      // taxRegion: values?.taxRegion,
      requiredInvoicingCreditFlag: values?.requiredInvoicingCreditFlag,
    },
    buyerConfig: {
      // entityLevel: values?.buyerConfig,
      whiteList: values?.whiteLists,
      blackList: values?.buyerBlackList,
      types: values?.buyerTypes,
    },
    rateConfig: {
      valueAddTaxCollectionRate: Number(
        new Decimal(values?.valueAddTaxCollectionRate).div(new Decimal("100"))
      ),
      valueAddTaxRate: Number(new Decimal(values?.valueAddTaxRate).div(new Decimal("100"))),
      withholdTaxRate: Number(new Decimal(values?.withholdTaxRate).div(new Decimal("100"))),
      incomeTaxRateReverse: Number(
        new Decimal(values?.incomeTaxRateReverse).div(new Decimal("100"))
      ),
    },
    settlementConfig: [
      {
        enable: values?.enable == 1 ? true : false,
        settlementPaths: handSettlement(newValue.settlementOptionsList),
      },
      {
        enable: values?.enable == 2 ? true : false,
        settlementPaths: handSettlement(newValue.settlementOptionsTwoList),
      },
      {
        enable: values?.enable == 3 ? true : false,
        settlementPaths: handCustomSettlement(values.settlementPaths),
      },
    ],
    contractConfig: {
      needPurchaseContract: values?.needPurchaseContract,
      purchaseContractWhiteList: values?.purchaseContractWhiteList,
      needSupplyContract: values?.needSupplyContract,
      supplyContractWhiteList: values?.supplyContractWhiteList,
    },
    // strategyContractEntityList: values?.strategyContractEntityList,
    applyInvoiceConfig: {
      type: values?.type,
      checkPayment: values?.checkPayment,
      checkAmount: values?.checkAmount,
      checkTaxPaid: values?.checkPayment,
      checkLogistic: values?.checkLogistic,
      checkBuyerSign: values?.checkBuyerSign,
      checkSellerSign: values?.checkSellerSign,
      approvalInvoice: values?.approvalInvoice,
      invoiceLimit: values?.invoiceLimit,
      drawer: values?.drawer?.length >= 2 ? "ALL" : values?.drawer[0],
    },
    operationAuth: values?.operationAuth,
  }
  return params
}
//详情英文转中文
export const getChangeType = (data: any, listType: any) => {
  let arrName: string[] = []
  listType &&
    listType.filter((item: any): void => {
      if (data && data.includes(item.value)) {
        arrName.push(item.label + " ")
      }
    })
  return arrName
}
export const getSettingFlag = (formList) => {
  return formList?.settlementConfig[0]?.enable ? 1 : formList?.settlementConfig[1]?.enable ? 2 : 3
}
export const contractStatusOptions = [
  {
    label: "未签署",
    value: "PENDING",
  },
  {
    label: "卖家已签署",
    value: "SELLER_COMPLETED",
  },
  {
    label: "买家已签署",
    value: "BUYER_COMPLETED",
  },
  {
    label: "已完成",
    value: "ALL_COMPLETED",
  },
  {
    label: "已撤销",
    value: "REVOKED",
  },
]
export const contractStatusColors = {
  PENDING: {
    label: "未签署",
    color: "default",
  },
  PROCESSING: {
    label: "签署中",
    color: "pedding",
  },
  ALL_COMPLETED: {
    label: "已完成",
    color: "success",
  },
  SELLER_COMPLETED: {
    label: "卖家已签署",
    color: "pedding",
  },
  BUYER_COMPLETED: {
    label: "买家已签署",
    color: "pedding",
  },
  REVOKED: {
    label: "已撤销",
    value: "default",
  },
}

// 开票状态
export const orderInvoiceStatusOptions = [
  {
    label: "待开票",
    value: "PENDING",
  },
  {
    label: "开票中",
    value: "PROCESSING",
  },
  {
    label: "已完成",
    value: "COMPLETED",
  },
]

// 订单类型
export const orderTypesOptions = [
  {
    label: "不限",
    value: "ALL",
  },
  {
    label: "采购订单",
    value: "PURCHASE_ORDER",
  },
  {
    label: "补差订单",
    value: "AWARD_ORDER",
  },
  // {
  //   label: "补税订单",
  //   value: "TAX_ORDER",
  // },
]
//申请开票方
export const orderDrawerOptions = [
  {
    label: "购买方",
    value: "BUYER",
  },
  {
    label: "销售方",
    value: "SELLER",
  },
]
//来源
export const channelOptions = [
  {
    label: "所有",
    value: "ALL",
  },
  {
    label: "EP",
    value: "EP",
  },
  {
    label: "回收小组",
    value: "RECOVERY",
  },
  {
    label: "运营中心",
    value: "ADMIN",
  },
]
//生成方式
export const contractCreateTypeOptions = [
  {
    label: "立即生成",
    value: "NOW",
  },
  {
    label: "按需生成",
    value: "AFTER",
  },
]
//购方签署方式
export const buyerSignTypeOptions = [
  {
    label: "无",
    value: "NO",
  },
  {
    label: "静默签署",
    value: "SILENCE",
  },
  {
    label: "手动签署",
    value: "MANUAL",
  },
]
//购方签署方式
export const invoiceLimitsOptions = [
  {
    label: "青岛胶州",
    value: "1",
  },
  {
    label: "青岛李沧",
    value: "2",
  },
  {
    label: "安徽宁国",
    value: "3",
  },
]
// 结算设定来源
export const settlementOptionsEnum: Record<API.Entity.Type, string> = {
  BUYER: "购买方",
  SELLER: "销售方",
  TAX_COMPANY: "代缴税公司",
  TAX_ORDER_AMOUNT: "订单价格(含税)",
  SELLER_NET_AMOUNT: "卖方净得价",
  TAX: "代扣税费合计",
  WITHHOLDING_FEES: "平台预扣费",
}

//结算设定1
export const settlementOptions = [
  {
    payerRole: "BUYER",
    payeeRole: "SELLER",
    payOption: "TAX_ORDER_AMOUNT",
    sort: "1",
  },
  {
    payerRole: "SELLER",
    payeeRole: "TAX_COMPANY",
    payOption: "TAX",
    sort: "2",
  },
]

//结算设定2
export const settlementOptionsTwo = [
  {
    payerRole: "BUYER",
    payeeRole: "TAX_COMPANY",
    payOption: "TAX",
    sort: "1",
  },
  {
    payerRole: "BUYER",
    payeeRole: "SELLER",
    payOption: "SELLER_NET_AMOUNT",
    sort: "2",
  },
]
//结算设定 支付方
export const settingOptionsPayment = [
  {
    label: "购买方",
    value: "BUYER",
  },
  {
    label: "销售方",
    value: "SELLER",
  },
]
//结算设定 收款
export const settingOptionsCompanyPayment = [
  {
    label: "购买方",
    value: "BUYER",
  },
  {
    label: "销售方",
    value: "SELLER",
  },
  {
    label: "代缴税公司",
    value: "TAX_COMPANY",
  },
]
//结算设定
export const settingOptions = [
  {
    label: "购买方",
    value: "BUYER",
  },
  {
    label: "销售方",
    value: "SELLER",
  },
]

//结算设定
export const handSettingOptions = [
  {
    label: "订单价格(含税)",
    value: "TAX_ORDER_AMOUNT",
  },
  {
    label: "卖方净得价",
    value: "SELLER_NET_AMOUNT",
  },
  {
    label: "代扣税费合计",
    value: "TAX",
  },
  {
    label: "平台预扣费",
    value: "WITHHOLDING_FEES",
  },
]
// 融资订单 状态options
export const financingStatusOptions = [
  {
    label: "已申请",
    value: "APPLIED",
  },
  {
    label: "审核拒绝",
    value: "APPROVAL_FAIL",
  },
  {
    label: "协议待签署",
    value: "CONTRACT_TO_BE_SIGNED",
  },
  {
    label: "待放款",
    value: "PENDING_LOAN",
  },
  {
    label: "放款失败",
    value: "LOAN_FAIL",
  },
  {
    label: "待还款",
    value: "PENDING_REPAYMENT",
  },
  {
    label: "部分还款",
    value: "PARTIAL_REPAYMENT",
  },
  {
    label: "还款完成",
    value: "REPAYMENT_COMPLETE",
  },
  {
    label: "已逾期",
    value: "OVERDUE",
  },
]

export const repaymentModeObj = {
  REPAID_ONCE_DUE: "到期一次还本付息",
}
//额度限定
export const settlementLimit = [
  {
    applyTo: "SELF",
    intervalType: "MONTH",
    intervalCount: 13,
    priceType: "EXCLUDE_TAX",
    limitAmount: 5000000,
    minAmount: 0,
  },
  {
    applyTo: "ASSOCIATE",
    intervalType: "YEAR",
    intervalCount: 1,
    priceType: "EXCLUDE_TAX",
    limitAmount: 5000000,
    minAmount: 0,
  },
  {
    applyTo: "SELF",
    intervalType: "YEAR",
    intervalCount: 1,
    priceType: "EXCLUDE_TAX",
    limitAmount: 4500000,
    minAmount: 0,
  },
]
// 订单类型
export const applyToOptions = [
  {
    label: "个体户",
    value: "SELF",
  },
  {
    label: "个人及其个体户",
    value: "ASSOCIATE",
  },
]
// 价格类型
export const priceTypeOptions = [
  {
    label: "含税价格",
    value: "INCLUDE_TAX",
  },
  {
    label: "不含税价格",
    value: "EXCLUDE_TAX",
  },
]

// 价格类型
export const intervalTypeOptions = [
  {
    label: "年",
    value: "YEAR",
  },
  {
    label: "季度",
    value: "QUARTER",
  },
  {
    label: "月",
    value: "MONTH",
  },
]

// 价格类型
export const EntityLevelOptions = [
  {
    label: "零散交易者",
    value: "SPORADIC_TRADER",
  },
  {
    label: "小规模交易者",
    value: "SMALL_TRADER",
  },
  {
    label: "中型交易者",
    value: "MIDSIZE_TRADER",
  },
  {
    label: "大规模交易者",
    value: "LARGE_TRADER",
  },
]

// 客户状态
export const customerStatusList = [
  {
    label: "失效",
    value: "失效",
    color: "rgba(245, 63, 63, 1)",
  },
  {
    label: "草稿",
    value: "草稿",
    color: "rgba(250, 171, 12, 1)",
  },
  {
    label: "有效",
    value: "有效",
    color: "#43cd43",
  },
  {
    label: "已签署",
    value: "已签署",
    color: "#43cd43",
  },
]

// 状态
export const tradingStatusEnum: Record<string, BadgeProps> = {
  有效: {
    status: "success",
    text: "有效",
  },
  失效: {
    status: "error",
    text: "失效",
  },
}
export const EntityTypes = [
  {
    label: "企业",
    value: "ENTERPRISE",
  },
  {
    label: "个体户",
    value: "INDIVIDUAL_BUSINESS",
  },
  {
    label: "自然人",
    value: "INDIVIDUAL",
  },
  {
    label: "其他",
    value: "OTHER",
  },
]
