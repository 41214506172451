import { OP_SOURCE, OP_USER, OP_LOAN } from "../../api/config/servicePort"
import UtilOp from "../../services/serviceOp"
const utilOp = UtilOp.getInstance()

//id换取文件路径
export const getOrderUploadByPath = async (params: any) => {
  return utilOp.httpPatch(OP_SOURCE + `/oss/endpoint`, params)
}
export const getUrlByFileId = (params: { zoom: boolean; fileIds: string }) => {
  return utilOp
    .httpGet(`${OP_SOURCE}/files/ossUrlZoom`, params)
    .then((res) => res as { data: string[]; code: number })
}
// 查询组织列表(翻页)
export const getTissueList = (params?: Tissue.ListParams) => {
  return utilOp.httpGet<Tissue.ListResponse>(OP_USER + "/organizations", params)
}
export const checkFile = async (fileIdList) => {
  let result = await getUrlByFileId({ zoom: false, fileIds: fileIdList.join(",") })?.then(
    (res) => res.data
  )
  return result
}
// 核心企业
export const getLoanCompany = (params?: any) => {
  return utilOp.httpGet(OP_LOAN + "/coreEnterprise", params)
}
// 核心企业新建
export const getLoanCompanyAdd = (params?: any) => {
  return utilOp.httpPost(OP_LOAN + "/coreEnterprise", params)
}
// 核心企业和供应商详情
export const getLoanCompanyDetails = (id: string) => {
  return utilOp.httpGet(OP_LOAN + `/coreEnterprise/${id}`)
}
// 供应商准入列表
export const getLoanSupplier = (params?: any) => {
  return utilOp.httpGet(OP_LOAN + "/supplier", params)
}
// 供应商准入详情
export const getLoanSupplierDetails = (id: string) => {
  return utilOp.httpGet(OP_LOAN + `/supplier/${id}`)
}
//融资产品列表
export const getFinanceProducts = (params?: any) => {
  return utilOp.httpGet(OP_LOAN + "/finance-products", params)
}
//融资产品列表详情
export const getFinanceProductsDetails = (id: string) => {
  return utilOp.httpGet(OP_LOAN + `/finance-products/${id}`)
}

// 融资产品新建
export const getFinanceProductsAdd = (params?: any) => {
  return utilOp.httpPost(OP_LOAN + "/finance-products", params)
}
// 融资产品更新
export const postCancelInvoiceUpdate = (params: { invoiceId: number | string }) => {
  return utilOp.httpPut(OP_LOAN + "/finance-products", params)
}
//(启用) 更新状态
export const postCancelInvoicePatch = async (id: any) => {
  return utilOp.httpPatch(OP_LOAN + `/finance-products/${id}`)
}
//融资订单
export const getFinanceList = (params?: any) => {
  return utilOp.httpGet(OP_LOAN + "/finance/operational", params)
}
//融资订单详情
export const getFinanceListDetails = (id?: any) => {
  return utilOp.httpGet(OP_LOAN + `/finance/${id}`)
}
//还款试算
export const getTrialPayment = (id: string, params: any) => {
  return utilOp.httpPost(OP_LOAN + `/finance/${id}/trial-payment`, params)
}
//还款申请
export const getTrialRepayApply = (params: any) => {
  return utilOp.httpPost(OP_LOAN + `/finance/${params.id}/repayApply`, params)
}
