/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable react/display-name */
import React, { memo, useContext, useState } from "react"
import { OrderDetailContext } from "../useOrderDetail"
import { Badge, Button, Card, Descriptions, Space, Table, message, Modal } from "antd"
import { ColumnsType } from "antd/es/table"
import moment from "moment"
import {
  getContractFileByNo,
  getFileUrl,
  getSignUrl,
  postResendContract,
  getOrderUploadContract,
  getOrderUploadDelete,
  getOrderUploadByPath,
  postContractsResend,
} from "../service"
import { useLoading } from "@/hooks/useLoading"
import { confirmToContinue, downloadFile } from "@/utils/utils"
import copy from "copy-to-clipboard"
import ModalForm from "@/components/ModalForm"
import axios from "axios"
import { OP_LOGISTIC } from "../../../../../../api/config/servicePort"
import {
  SupplyContractOfflineForm,
  SupplyContractOfflineFormValues,
} from "../../../../../../components/FormTemplate/supplyContractOffline/index"
import { SupplyContractEffectiveEnum, SupplyContractStatusEnum } from "@/constant/order"
import Access from "@src/components/Access"
import { AccessEnum } from "@src/contents/accessEnum"

export const OrderContractInfo: React.FC<{}> = memo(() => {
  const context = useContext(OrderDetailContext)
  const [deleteOpen, setDeleteOpen] = useState<any>({
    open: false,
  })
  const contractTableData = context?.data?.supplyContracts
  const columns = [
    { dataIndex: "typeName", title: "合同类型" },
    {
      dataIndex: "signStatus",
      title: "签署状态",
      render(value) {
        return <Badge {...SupplyContractStatusEnum[value]} />
      },
    },
    {
      dataIndex: "createdDate",
      title: "合同创建时间",
      render(text) {
        return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "-"
      },
    },
    {
      dataIndex: "actions",
      title: "操作",
      render(_, record) {
        return (
          <Space>
            {record.contractSource == "MANUAL" || record.type === "SUPPLY" ? (
              <a
                style={{ color: "#1890ff" }}
                onClick={() => setDeleteOpen({ open: true, record: record })}
              >
                删除
              </a>
            ) : undefined}
            {record.signStatus == "SIGNED" ? (
              <a style={{ color: "#1890ff" }} onClick={() => handContract(record)}>
                下载合同
              </a>
            ) : undefined}
          </Space>
        )
      },
    },
  ]
  const [resendContract] = useLoading(async () => {
    await confirmToContinue({ title: "提示", content: <>是否重发签署链接？</> })
    await postContractsResend({ contractNo: context?.data?.contractNo! })
    message.success("操作成功")
    context?.refresh()
  })
  const [deleteAndResendContract, deleteAndResendLoading] = useLoading(async () => {
    await confirmToContinue({ title: "提示", content: <>是否删除并重发合同？</> })
    await postResendContract([context?.data?.id])
    message.success("操作成功")
  })
  const [downloadContract, downloadLoading] = useLoading(async () => {
    const res = await getContractFileByNo(context?.data.contract?.contractNo!)
    downloadFile({
      filename: `${context?.data.id || ""}_${context?.data.contract?.contractNo || ""}`,
      data: res.data.fileDownUrl,
    })
  })
  const [signUrl] = useLoading(async (idNumber: string) => {
    const res = await getSignUrl({ contractNo: context?.data?.contractNo!, idNumber })
    if (!copy(res.data)) return
    message.success("复制成功")
  })
  //查看文件
  const handContract = async (record: any) => {
    let params = {
      filePaths: [record.filePath],
    }
    const result: any = await getOrderUploadByPath(params)
    if (result.code == 200) {
      window.open(result?.data?.files[0].downloadUrl, "_blank")
    } else {
      message.warning(result?.message || "查看失败")
    }
  }
  //删除
  const handDeleteParams = async () => {
    const result: any = await getOrderUploadDelete(deleteOpen?.record.id)
    if (result.code == 200) {
      message.success("操作成功")
      setDeleteOpen({ open: false, record: "" })
      await context?.refresh()
    } else {
      message.error(result.message || "操作失败")
    }
  }
  return (
    <Card
      title="合同签署"
      extra={
        <Space>
          <ModalForm<SupplyContractOfflineFormValues>
            title="合同上传"
            fieldProps={{
              labelCol: { span: 6 },
              wrapperCol: { span: 16 },
            }}
            modalProps={{ width: 600, destroyOnClose: true }}
            trigger={<Button type="primary">合同上传</Button>}
            onFinish={async (values) => {
              console.log(values, "values")
              let params = {
                filePath: values?.agreementUploadFiles[0]?.filePath,
                contractType: values.contractType,
              }
              let result: any = await getOrderUploadContract(context?.data.id, params)
              if (result.code == 200) {
                await context?.refresh()
                message.success("操作成功")
              }
            }}
          >
            <SupplyContractOfflineForm></SupplyContractOfflineForm>
          </ModalForm>
        </Space>
      }
    >
      {context?.data?.contractTemplateId && context?.data?.contractTemplateName && (
        <Descriptions
          column={3}
          title="订单合同"
          extra={
            <Space>
              <Button loading={deleteAndResendLoading} onClick={deleteAndResendContract}>
                删除重发
              </Button>
              {/* <Button
              hidden={context?.data.contract?.signStatus === "ALL_COMPLETED"}
              disabled={!context?.data.contract?.contractNo}
              type="primary"
              loading={downloadLoading}
              onClick={downloadContract}
            >
              下载合同
            </Button> */}
            </Space>
          }
        >
          <Descriptions.Item label="采购合同编号">{context?.data?.contractNo}</Descriptions.Item>
          <Descriptions.Item label="采购合同生成时间">
            {context?.data?.contractCreateDate
              ? moment(context?.data?.contractCreateDate).format("YYYY-MM-DD HH:mm:ss")
              : ""}
          </Descriptions.Item>
          <Descriptions.Item />
          <Descriptions.Item label="买方签署人">{context?.data?.buySigner?.name}</Descriptions.Item>
          <Descriptions.Item label="联系电话">{context?.data?.buySigner?.phone}</Descriptions.Item>
          <Descriptions.Item label="签署状态">
            <Space>
              {context?.data?.buySigner?.signed ? (
                <Badge color="#00d18b" text="已签署"></Badge>
              ) : (
                <Badge color="#f53f3f" text="未完成"></Badge>
              )}
              {context?.data?.buySigner?.signed === false && context?.data?.contractNo ? (
                <>
                  <a type="link" onClick={resendContract}>
                    重发合同短信
                  </a>
                  <a onClick={signUrl.bind(null, context?.data?.buySigner.idCard!)}>复制签署链接</a>
                </>
              ) : (
                <></>
              )}
            </Space>
          </Descriptions.Item>
          <Descriptions.Item label="卖方签署人">
            {context?.data?.sellSigner?.name}
          </Descriptions.Item>
          <Descriptions.Item label="联系电话">{context?.data?.sellSigner?.phone}</Descriptions.Item>
          <Descriptions.Item label="签署状态">
            <Space>
              {context?.data?.sellSigner?.signed ? (
                <Badge color="#00d18b" text="已签署"></Badge>
              ) : (
                <Badge color="#f53f3f" text="未完成"></Badge>
              )}
              {context?.data?.sellSigner?.signed === false && context?.data?.contractNo ? (
                <>
                  <a type="link" onClick={resendContract}>
                    重发合同短信
                  </a>
                  <a onClick={signUrl.bind(null, context?.data.sellSigner.idCard!)}>复制签署链接</a>
                </>
              ) : (
                <></>
              )}
            </Space>
          </Descriptions.Item>
        </Descriptions>
      )}
      <div style={{ fontWeight: "600", fontSize: "16px", marginBottom: "20px" }}>合同列表</div>
      <Table columns={columns} dataSource={contractTableData} />
      <br />
      <Modal
        maskClosable={false}
        onCancel={() => setDeleteOpen({ open: false })}
        title={"删除"}
        okText="确定"
        cancelText="取消"
        open={deleteOpen.open}
        onOk={handDeleteParams}
      >
        确定删除吗？
      </Modal>
      {/*
      删除重发：开票状态在未申请，已拒绝，已申请时显示
      下载合同：合同签署状态为已签署时显示
    */}
    </Card>
  )
})
