// 格式化日期时间
export const formatDateTime = (dateTime) => {
  const year = dateTime.getFullYear()
  const month = String(dateTime.getMonth() + 1).padStart(2, "0")
  const day = String(dateTime.getDate()).padStart(2, "0")
  const hours = String(dateTime.getHours()).padStart(2, "0")
  const minutes = String(dateTime.getMinutes()).padStart(2, "0")
  const seconds = String(dateTime.getSeconds()).padStart(2, "0")
  const daysOfWeek = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"]
  const dayOfWeek = daysOfWeek[dateTime.getDay()]

  return ` ${dayOfWeek} ${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

export const contractStatusColors = [
  {
    id: "1",
    color: "rgba(255, 119, 52, 0.5)",
    border: "rgba(255, 119, 52, 1)",
  },
  {
    id: "2",
    color: "rgba(250, 199, 0, 0.5)",
    border: "rgba(255, 195, 0, 1)",
  },
  {
    id: "3",
    color: "rgba(52, 255, 231, 0.5)",
    border: "rgba(52, 255, 206, 1)",
  },
]
export const handNewData = (data) => {
  // 获取当前时间的时间戳（毫秒）
  const currentTime = new Date().getTime()
  // 获取目标时间的时间戳（毫秒）
  const targetTime = new Date(data).getTime()
  const timeDifference = currentTime - targetTime + ""
  const positiveNumber = timeDifference.replace("-", "")
  return Number(positiveNumber)
}
