import Util from "../../services/util"
import { cross_url_env } from "@/services/util"
import {
  OP_USER,
  OP_TRADE,
  OP_SOURCE,
  OP_LOGISTIC,
  OPL_INVOICE,
} from "../../api/config/servicePort"
import UtilOp from "../../services/serviceOp"
import { httpRequestProxy } from "../../services/serviceProxy"
const utilOp = UtilOp.getInstance()
const util = Util.getInstance()
const currentDate = new Date()
const year = currentDate.getFullYear() // 获取年
const month = currentDate.getMonth() + 1 // 获取月份（注意要加1，因为月份是从0开始的）
const day = currentDate.getDate() // 获取日

export const getTaxRegions = (params?: any) => {
  return util.httpGet("/sinzetech-crm/corporealEntity/tax", params).then((res) => res)
}

export const updateEntity = (params?: any) => {
  return util.httpPut(`/sinzetech-crm/corporealEntity/${params.id}`, params).then((res) => res)
}
export const createEntity = (params?: any) => {
  const { ...rest } = params
  return util.httpPost(`/sinzetech-crm/corporealEntity`, rest).then((res) => res)
}

// 关联实体详情
export const customerRelatedEntityDetail = (id: any, value?: any) => {
  return util.httpGet(`/sinzetech-crm/corporealEntity/${id}`, value).then((res) => res)
}

// 业务负责人数据
export const getAllUsers = (values: any) => {
  return util.httpGet("/sinzetech-admin-user/users", values)
}

// 筛选办税人数据
export const getTaxUsers = (values: any) => {
  return util.httpGet<{ id: string; name: string }[]>("/sinzetech-admin-user/users/fill", values)
}

// 获取所有税区
export const getTaxs = (values: any) => {
  return util.httpGet("/sinzetech-tax/tax-region", values)
}

const tradeEntityPrefix = "/trade-entity"

// 获取实体详情
export const getEntityDetail = (id: string) => {
  return util
    .httpGet<API.Entity.Info>(tradeEntityPrefix + `/entity/one`, { id: id })
    .then((res) => res.data)
}

// 更新实体详情
export const updateEntityDetail = (data: Partial<API.Entity.Info>) => {
  return util.httpPut(tradeEntityPrefix + `/entity`, data)
}

// 获取税区列表
export const getTaxList = (values: any) => {
  return util
    .httpGet("/sinzetech-tax/tax-region", values)
    .then((res) => res?.data?.records as { id: number; name: string }[])
}

// 交易列表
interface Entry {
  current: string | number
  size: string | number
  level?: string | null
  sourceType?: string | null
  status?: string | null
  businessLeaderId?: string | null
  startTime?: string | null
  endTime?: string | null
  regionId?: string | null
  keyword?: string | number
  type?: string | null
  taxpayerId?: string | null
}
export const getEntryList = (params: Entry) => {
  return util.httpGet("/trade-entity/entity", params)
}

// 新增
export const addEntry = (params: any) => {
  return util.httpPost("/trade-entity/entity", params)
}

// ocr 识别  根据上传营业执照识别关键字段信息
export const getImageMsg = (values?: any) => {
  return util.httpGet("/sinzetech-ocr/ocr/business-license", values).then((res) => res)
}
// 获取客户
export const getCustomerInfo = (id?: any) => {
  return util.httpGet(`/sinzetech-crm/customer/${id}`).then((res) => res)
}
// // 获取税区列表
// export const getTaxList = (values:any) => {
//   return util.httpGet('/sinzetech-tax/tax-region', values)
//   .then(res => res)
// }

// 税收分类
export const getCategorySgort = () => {
  return util.httpGet(
    "/pub-data/invoice-configuration/invoice-category/short",
    {},
    true,
    cross_url_env
  )
}

// 发票项目数据
interface Cate {
  size: string | number
  current: string | number
  invoiceCategoryShort?: string
  keyword?: string
}
export const getCategoryTaxs = (params: Cate) => {
  return util.httpGet("/pub-data/invoice-configuration", params, true, cross_url_env)
}

// 修改业务负责人
interface EDITBUSINEESS {
  userId?: number
  userName?: string
  ids?: number[]
}
// 修改业务负责人
export const editBusinessUser = (params: EDITBUSINEESS) => {
  return util.httpPut("/trade-entity/entity/businessLeaderName", params)
}
// 修改办税人
export const editTaxUser = (params: EDITBUSINEESS) => {
  return util.httpPut("/trade-entity/entity/taxpayer", params)
}

// 获取业务负责人或者办税人
export const getBusinessAndTax = (type: string) => {
  return util.httpGet(`/trade-entity/entity/users/${type}`)
}

// 根据文件id获取，文件链接
export const getUrlByFileId = (params: { zoom: boolean; fileIds: string }) => {
  return utilOp
    .httpGet(`${OP_SOURCE}/files/ossUrlZoom`, params)
    .then((res) => res as { data: string[]; code: number })
}

export const checkFile = async (fileIdList) => {
  let result = await getUrlByFileId({ zoom: false, fileIds: fileIdList.join(",") })?.then(
    (res) => res.data
  )
  return result
}

export const postClosePaymentItem = (id: string) => {
  return utilOp.httpPut(`${OP_TRADE}/orders/payment/${id}/close`, {})
}
export const uploadOfflinePayment = (id, params) => {
  return httpRequestProxy.httpPost(`${OP_TRADE}/orders/${id}/offline`, params)
}

export const postCancelInvoice = (params: { invoiceId: number | string }) => {
  return utilOp.httpPut(`${OP_TRADE}/invoices/${params.invoiceId}/cancel`, {})
}

export const postDeleteInvoice = (params: { invoiceId: number | string }) => {
  return utilOp.httpDeletel(`${OP_TRADE}/invoices/${params.invoiceId}`, {})
}
export const uploadOfflineInvoice = (data) => {
  return httpRequestProxy.httpPost(`${OP_TRADE}/invoices/upload`, data)
}
export const getInvoiceInfoByOCR = (url) => {
  return utilOp.httpGet(`${OP_TRADE}/invoices/ocr`, {
    url: url,
  })
}
export const getOrderLogisticsList = (orderId: string) => {
  return utilOp.httpGet(`${OP_LOGISTIC}/logistic/freight/order/${orderId}`).then((res) => res.data)
}
export const getOrderLogisticsItem = (logisticsId: string) => {
  return utilOp
    .httpGet(`${OP_LOGISTIC}/logistic/freight/instorage/${logisticsId}`)
    .then((res) => res.data)
}

export const getOrderLogisticsTrajectory = (logisticsId: string) => {
  return utilOp.httpGet(`${OP_LOGISTIC}/logistic/freight/${logisticsId}/trajectory`)
}
export const postRevokeOrder = (orderId: string) => {
  return utilOp.httpPost(`${OP_TRADE}/orders/${orderId}/revoke`)
}

// 获取用户组数据
export const getUsersGroup = (params: any) => {
  return util.httpGet("/sinzetech-admin-user/user-groups", params)
}

// 获取用户组详情
export const getUserInfo = (id: string) => {
  return util.httpGet(`/sinzetech-admin-user/user-groups/${id}`)
}

// 采购订单
interface PRODUCTOR {
  size: string | number
  current: string | number
  keyword?: string
  startTime?: string
  endTime?: string
  timeType?: string
  keywordType?: string
  orderTypes?: string
  orderInvoiceStatus?: [string]
  contractStatus?: [string]
  platCatId: [string]
  taxRegionIds: [string]
  buyerIds: [string]
  sellerIds: [string]
}
export const productOrders = (params: PRODUCTOR) => {
  return util.httpGet("/sk-trade/orders", params)
}

// 关闭订单
export const closePructor = (id: string) => {
  return util.httpPutUrl(`/sk-trade/orders/close/${id}`)
}

// 品类数
interface SEARCHCATEGORY {
  keyword?: string | null
}
export const getCategoryTree = (params?: SEARCHCATEGORY) => {
  return utilOp.httpGet(OP_TRADE + "/categories/tree", params)
}

// 创建品类
interface ADDCATEGORY {
  name?: string | undefined
  ADDCATEGORY?: string | number
  descText?: string
  descPhoto?: string
  forTrade?: string | boolean
  forPriceIndex?: string | boolean
}
//创建品类
export const addCategoryTree = (params: ADDCATEGORY | any) => {
  return utilOp.httpPost(OP_TRADE + "/categories", params)
}

// 删除品类
export const deleteCategory = (id: string) => {
  return utilOp.httpDeletel(OP_TRADE + `/categories/${id}`)
}

// 编辑品类
export const editCategory = (id: string, params: ADDCATEGORY) => {
  return utilOp.httpPut(OP_TRADE + `/categories/${id}`, params)
}

// 编辑品类
export const getPurchaseOrders = (params: any) => {
  return utilOp
    .httpGet(`${OP_TRADE}/orders`, {
      ...params,
    })
    .then((data) => {
      if (data.code == 200) {
        return data.data
      } else {
        return Promise.reject(data)
      }
    })
}

//交易方案列表
export const getTradingScheme = (params: any) => {
  return utilOp.httpGet(OP_TRADE + `/trade-strategies`, params)
}
//立即发布
export const getTradeStrategies = (params?: any) => {
  return utilOp.httpPost(OP_TRADE + `/trade-strategies`, params).then((res) => res)
}
//修改
export const getTradeStrategiesEdit = (params?: any) => {
  return utilOp.httpPut(OP_TRADE + `/trade-strategies`, params).then((res) => res)
}
// 交易方案详情
export const getTradingSchemeDetails = (id: string) => {
  return utilOp.httpGet(OP_TRADE + `/trade-strategies/${id}`)
}
// 查询组织列表(翻页)
export const getTissueList = (params?: Tissue.ListParams) => {
  return utilOp.httpGet<Tissue.ListResponse>(OP_USER + "/organizations", params)
}
//查询所有的缴税公司列表
export const getCompanyList = (params: any) => {
  return utilOp.httpGet(OP_TRADE + `/tax-company/list`, params)
}

// 申请支付信息
export const getTradingSchemePayment = (params: string) => {
  return utilOp.httpGet(OP_TRADE + `/orders/payment/apply`, params)
}
//申请支付
export const getTradingSchemeApply = (params?: any) => {
  return utilOp.httpPost(OP_TRADE + `/orders/payment/apply`, params)
}
// 开票预览
export const getTradingPreview = (params: any) => {
  return utilOp.httpGet(OP_TRADE + `/orders/invoice-preview`, params)
}
// 确认开票
export const getTradingPreviewAdd = (params: any) => {
  return utilOp.httpPost(OP_TRADE + `/orders/confirm-issue`, params)
}
// 订单信息汇总
export const getTradingPreviewSummary = (params: any) => {
  return utilOp.httpGet(OP_TRADE + `/orders/summary`, params)
}
// 开票申请信息
export const getTradingApplyInfo = (params: any) => {
  return utilOp.httpGet(OP_TRADE + `/orders/invoice-apply-info`, params)
}
// 查询组织详情
export const getTissueInfo = (id) => {
  return utilOp.httpGet<any>(OP_USER + `/organizations/${id}`)
}
// 修改组织详情
export const editTissueInfo = (params: any) => {
  return utilOp.httpPut(OP_USER + `/organizations/${params.id}`, params.values)
}
// 查看不可开票订单
export const exportExcel = (key: any, header: any) => {
  return utilOp.httpPost(OP_TRADE + `/orders/invoice/fail/download/${key}`, {}, header)
}

// 在线冲红
export const inVoiceReverse = (params: any) => {
  return utilOp.httpPost(`${OP_TRADE}/invoices/${params.invoiceId}/reverse`)
}

// 获取订单发票列表

export const getInvoicesList = (params: any) => {
  return utilOp.httpGet(`${OP_TRADE}/invoices/${params.invoiceId}`)
}

// 开票预览
export const getTradingPreviewInvoice = (id: any) => {
  return utilOp.httpGet(OPL_INVOICE + `/invoices/invoice-preview/${id}`)
}
// 撤销抵扣
export const cancelPrepay = (id: any) => {
  return utilOp.httpPost(OP_TRADE + `/orders/cancel-prepay/${id}`)
}

//开票名称配置列表
export const getInvoiceCategory = (params?: any) => {
  return utilOp.httpGet(OP_TRADE + `/invoice-category-config`, params)
}
// 导出开票
export const getInvoices = (params: any) => {
  return utilOp.httpGet(OPL_INVOICE + "/invoices/export-file", params)
}
// 下载
export const exportDownloadExcel = (params: any) => {
  return utilOp
    .httpPost(OP_TRADE + `/download/contract`, params, {
      responseType: "blob", // 设置响应数据类型为二进制流
    })
    .then((response: any) => {
      const blob = new Blob([response.data], { type: "application/zip" })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.href = url
      link.setAttribute(
        "download",
        `${"订单合同" + "_" + year + "年" + month + "月" + day + "日"}.zip`
      )
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(url)
    })
}
