export const maxLineOption = {
  title: {
    text: "",
  },
  tooltip: {
    trigger: "axis",
  },
  legend: {
    data: ["企业", "个体户", "自然人", "其他"],
  },
  grid: {
    left: "3%",
    right: "6%",
    bottom: "0%",
    containLabel: true,
  },
  xAxis: {
    type: "category",
    boundaryGap: false,
    data: [],
    axisLabel: {
      margin: 16,
    },
  },
  yAxis: {
    type: "value",
  },
  series: [
    {
      name: "企业",
      type: "line",
      stack: "Total",
      data: [],
    },
    {
      name: "个体户",
      type: "line",
      stack: "Total",
      data: [],
    },
    {
      name: "自然人",
      type: "line",
      stack: "Total",
      data: [],
    },
    {
      name: "其他",
      type: "line",
      stack: "Total",
      data: [],
    },
  ],
}

export const barOption = {
  xAxis: {
    type: "category",
    data: [],
    axisLabel: {
      show: true,
      interval: 0,
      rotate: 30,
      margin: 16,
      // formatter: function (value) {
      //   return value.split("").join("\n")
      // },
    },
  },
  yAxis: {
    type: "value",
  },
  series: {
    data: [],
    type: "bar",
  },
  grid: {
    left: "3%",
    right: "0%",
    bottom: "0%",
    containLabel: true,
  },
  color: ["#1890FF"],
  tooltip: {
    trigger: "axis",
  },
}
export const lineOption = {
  xAxis: {
    type: "category",
    boundaryGap: false,
    data: [],
    axisLabel: {
      margin: 16,
    },
  },
  yAxis: {
    type: "value",
  },
  series: {
    data: [],
    type: "line",
    areaStyle: {
      opacity: "0.3",
    },
  },
  grid: {
    left: "3%",
    right: "6%",
    bottom: "0%",
    containLabel: true,
  },
  color: ["#1890FF"],
  tooltip: {
    trigger: "axis",
  },
}
export const pieOptions = {
  title: {
    text: "",
    subtext: "",
    left: "center",
  },
  tooltip: {
    trigger: "item",
  },
  legend: false,
  series: {
    type: "pie",
    radius: "70%",
    data: [],
    emphasis: {
      itemStyle: {
        shadowBlur: 10,
        shadowOffsetX: 0,
        shadowColor: "rgba(0, 0, 0, 0.5)",
      },
    },
    labelLine: {
      normal: {
        length: 5, //第一条线
        length2: 10, //第二条线
      },
    },
  },
}

export const minLine = {
  xAxis: {
    type: "category",
    boundaryGap: false,
    show: false,
    data: [],
  },
  yAxis: {
    type: "value",
    show: false,
  },
  series: {
    data: [],
    type: "line",
    symbol: "none",
    smooth: true,
    areaStyle: {
      opacity: "0.3",
    },
  },
  color: ["#1890FF"],
  tooltip: {
    trigger: "axis",
  },
}
