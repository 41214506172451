import axios from "axios"
import { OP_TRADE, OP_SOURCE, OP_SIGN, OP_LOGISTIC } from "../../../../../api/config/servicePort"
import UtilOp from "../../../../../services/serviceOp"
const utilOp = UtilOp.getInstance()
export const getOrderDetailById = async (id: string) => {
  return utilOp.httpGet(OP_TRADE + `/orders/${id}`).then((res) => res.data)
}

export const getEntityById = async (id: string) => {
  return axios
    .get<Common.ResponseData<API.Entity.Info>>(`/trade-entity/entity/one`, {
      params: {
        id,
      },
    })
    .then((res) => res.data)
}
export const getContractFileByNo = async (contractNo: string) => {
  return axios.get<{ fileId: string; fileDownUrl: string }>(
    `/sinzetech-sign/digital-contracts/${contractNo}/documents`
  )
}
export const postRevokeContract = async (contractNo: string) => {
  return axios.put(`/sinzetech-sign/digital-contracts/${contractNo}/revoke`)
}
//重发签署通知
export const postContractsResend = async (params) => {
  return utilOp.httpPost<API.Entity.Info>(OP_SIGN + `/digital-contracts/resend`, params)
}
export const postResendContract = async (params) => {
  return utilOp.httpPost<API.Entity.Info>(OP_TRADE + `/orders/contracts`, params)
}

// 获取签署链接
export const getSignUrl = async (params: { idNumber: string; contractNo: string }) => {
  return utilOp.httpGet<Common.ResponseData<string>>(
    OP_SIGN + `/digital-contracts/getSignUrl`,
    params
  )
}

export const getSupplyContracts = async (params: {
  sellerId: number
  buyerId: number
  effective: 0 | 1
  current: number
  size: number
}) => {
  return axios
    .get<Common.ResponseData<{ records: API.Schema.SupplyContractItem[] }>>(
      `/sk-trade/supply-contracts`,
      { params }
    )
    .then((res) => res.data)
}

export const getFileUrl = async (fileIds: string) => {
  return axios
    .patch(`/rscm-resource/oss/endpoint`, {
      filePaths: fileIds.split(","),
      zoom: false,
    })
    .then((res) => res.data.data.files)
}

export const getLogisticsAPI = async (orderNo: string | number) => {
  return axios.get(`/sk-trade/logistic/${orderNo}`).then((res) => res.data)
}
//新增上传
export const getOrderUploadContract = async (id, parasm: any) => {
  return utilOp.httpPut(OP_TRADE + `/orders/${id}/upload-contract`, parasm)
}
//删除合同
export const getOrderUploadDelete = async (id: any) => {
  return utilOp.httpDeletel(OP_TRADE + `/orders/remove-contract/${id}`)
}
//id换取文件路径
export const getOrderUploadByPath = async (params: any) => {
  return utilOp.httpPatch(OP_SOURCE + `/oss/endpoint`, params)
}
// 获取订单风险等级
export const getOrderCheck = (id) => {
  return utilOp.httpGet(OP_LOGISTIC + `/logistic/freight/riskCheck/` + id)
}
