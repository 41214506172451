/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { Select, Input } from "antd"
import "./index.less"

interface IProps {
  options: any[]
  placeholder: string
  selectPlaceholder: string
  value: any
  onChange: Function
  schema: any
}
/**
 * 搜索框自定义组件
 * @param props
 * @returns
 */
const InputAndSelect: React.FC<IProps> = (props) => {
  const { options = [], placeholder = "请输入", value, onChange, schema } = props
  const [iptVal, setIptVal] = useState()
  const [selectVal, setSelectVal] = useState()
  const change = (e: any, type: "input" | "select") => {
    if (type === "input") {
      setIptVal(e.target.value)
    } else if (type === "select") {
      setSelectVal(e)
    }
    onChange([iptVal, selectVal])
  }
  useEffect(() => {
    console.log(value)
    console.log(props)
    if (value.length === 2) {
      setIptVal(value[0])
      setSelectVal(value[1])
    }
  }, [])
  return (
    <div className="input_and_select">
      <Input
        value={iptVal}
        onInput={(e) => change(e, "input")}
        placeholder={placeholder}
        style={{ width: "50%" }}
      ></Input>
      <Select
        style={{ width: "50%" }}
        allowClear={false}
        showSearch
        value={selectVal}
        placeholder={schema?.selectPlaceholder || "请选择"}
        onChange={(e) => change(e, "select")}
        optionFilterProp="children"
        filterOption={(input, option) => (option?.label ?? "").includes(input)}
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
        }
        options={options}
      />
    </div>
  )
}

export default InputAndSelect
