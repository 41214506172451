import { OP_USER } from "../../../api/config/servicePort"
import UtilOp from "../../../services/serviceOp"

const utilOp = UtilOp.getInstance()
export const getCheckList = async (params: any) => {
  return utilOp.httpGet(`${OP_USER}/settlement-application`, params).then((res) => res.data)
}
export const getPassOrgs = async (params: any) => {
  return utilOp.httpPut(`${OP_USER}/settlement-application/approve`, params).then((res) => res)
}
export const putRejectOrgs = async (params: any) => {
  return utilOp.httpPut(`${OP_USER}/settlement-application/refuse`, params).then((res) => res)
}

export const delApply = async (params: any) => {
  return utilOp.httpDeletel(`${OP_USER}/settlement-application`, params).then((res) => res)
}
