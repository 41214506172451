import { getOrderUploadByPath } from "../pages/BusinessAgent/service"

export function dataURLtoFile(dataurl, filename = "file") {
  let arr = dataurl.split(",")
  let mime = arr[0].match(/:(.*?);/)[1]
  let suffix = mime.split("/")[1]
  let bstr = atob(arr[1])
  let n = bstr.length
  let u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], `${filename}.${suffix}`, {
    type: mime,
  })
}

export function base64ToBytes(base64String) {
  const binaryString = atob(base64String) // 将Base64字符串解码成二进制字符串
  const byteArray = new Uint8Array(binaryString.length) // 创建Uint8Array类型的空白数组

  for (let i = 0; i < binaryString.length; ++i) {
    byteArray[i] = binaryString.charCodeAt(i) // 逐个字节赋值到Byte数组中
  }

  return byteArray
}

export const getFileToBase64 = (file): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })
}

export const getFileUrl = async (fileKey: string) => {
  let params = {
    filePaths: [fileKey],
  }
  const result: any = await getOrderUploadByPath(params)
  if (result.code == 200) {
    let download_a = document.createElement("a")
    download_a.setAttribute("href", result?.data?.files[0]?.downloadUrl)
    download_a.setAttribute("target", "_blank")
    download_a.setAttribute("id", "camnpr")
    document.body.appendChild(download_a)
    download_a.click()
    document.body.removeChild(download_a)
  }
}
