import React from "react"
import { useEffect, useState } from "react"
import { Button, Space, Table, message } from "antd"
import FormItemCom from "../../../../components/FormItem"
import BreadCrumbBar from "../../../../components/BreadCrumbBar"
import { Link, useNavigate } from "react-router-dom"
import "./index.scss"
import { getEvidenceTemplateList } from "./Promise"
import TableCom from "../../../../components/TableCom"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"

const Template = (props: any) => {
  const [messageApi, contextHolder] = message.useMessage()
  const navigate = useNavigate()
  const [list, setList] = useState([])
  const [search, setSearchParams] = useState<any>({})
  // const tableRef = useRef<null | HTMLDivElement>(null);
  // const [scrollY, setScrollY] = useState<number>(0);
  const [pageOptions, setPageOptions] = useState({
    pageSize: 10,
    current: 1,
    total: 0,
  })

  const formColumns = [
    {
      label: "存证场景",
      dataIndex: "templateName",
      type: "Input",
      params: {
        placeholder: "请输入",
        allowClear: true,
        maxLength: 100,
        onChange: (e: any) => {
          const value = e.target.value.split("")
          if (value.length >= 100) {
            messageApi.open({
              type: "error",
              content: "存证场景最多100个字符",
            })
          }
        },
      },
    },
    {
      label: "配置人",
      dataIndex: "createUserName",
      type: "Input",
      params: {
        placeholder: "请输入",
        allowClear: true,
      },
    },
  ]
  const columns = [
    {
      title: "存证场景",
      dataIndex: "name",
    },
    {
      title: "配置人",
      dataIndex: "createUserName",
    },
    {
      title: "匹配企业数",
      dataIndex: "matchCustomer",
    },
    {
      title: "存证链阶段概述",
      dataIndex: "templateLinkNames",
    },
    {
      title: "操作",
      render: (record: any) => {
        // return <Link to={`/evidence/platform/template/add?id=${record?.id}`}>详情</Link>
        return (
          <Button
            type="link"
            onClick={() => {
              navigate("/evidence/platform/template/add", {
                state: {
                  id: record?.id,
                  info: true,
                  current: 1,
                  type: "edit",
                },
              })
            }}
          >
            详情
          </Button>
        )
      },
    },
  ]

  const getEvidenceTemplates = (object: any) => {
    const data = {
      size: object?.pageSize || 10,
      current: object?.current || 1,
      ...object,
      createUserName: object?.createUserName?.trim(),
      templateName: object?.templateName?.trim(),
    }
    getEvidenceTemplateList(data).then((res) => {
      if (res?.code === 200) {
        setList(res?.data?.records)
        setPageOptions({
          current: Number(res.data.current),
          pageSize: Number(res.data.size),
          total: res?.data?.total,
        })
      }
    })
  }

  const handleSearch = (values: any) => {
    let params = {
      ...values,
      size: 10,
      current: 1,
      templateName: values?.templateName?.trim(),
      createUser: values?.createUser?.trim(),
    }
    if (JSON.stringify(values) === "{}") {
      params = {}
    }
    setSearchParams(params)
    getEvidenceTemplates(params)
  }
  const handlePage = (values: any) => {
    console.log(values, "vv")
    const params = {
      ...search,
      size: values.pageSize,
      current: values.current,
    }
    getEvidenceTemplates(params)
  }

  useEffect((): void => {
    // const y = tableRef?.current?.clientHeight ?? 0;
    // console.log(y , '==')
    // setScrollY(y);
    getEvidenceTemplates({
      current: 1,
      size: 10,
    })
  }, [])
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <span>存证场景模板</span>
          </Space>
        }
        breadCrumb={[
          {
            label: "存证平台",
            path: "/evidence",
          },
          {
            path: "/evidence/platform",
            label: "平台配置",
          },
          {
            path: "",
            label: "存证场景模板",
          },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <DetailPageContent>
        {contextHolder}
        <div className="container table_content">
          <div className="query_header">
            <FormItemCom formParams={formColumns} onSearch={handleSearch}></FormItemCom>
          </div>
          <div className="table_content_list">
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 16 }}>
              <h2 className="table_title">存证场景模板</h2>
              <Button
                type="primary"
                onClick={() => {
                  navigate("/evidence/platform/template/add", {
                    state: {
                      type: "add",
                    },
                  })
                }}
              >
                新增模板
              </Button>
            </div>

            <TableCom
              rowKey={(record: any) => record?.id}
              columns={columns}
              dataSource={list}
              pagination={pageOptions}
              onChange={handlePage}
              scroll={{
                y: 300,
                // w:1200
              }}
            ></TableCom>
          </div>
        </div>
      </DetailPageContent>
    </DetailPageContainer>
  )
}
export default Template
