import React, { useEffect, useMemo } from "react"
import dayjs from "dayjs"
import { CustomRangePicker } from "../CustomRangePicker"

interface DateProps {
  value?: string[]
  onChange: any
}
const XZDateRange: React.FC<DateProps> = (props) => {
  const { value, onChange } = props

  const v = useMemo(
    () => value?.filter(Boolean)?.map((v) => (typeof v === "string" ? dayjs(v) : v)),
    [value]
  )
  return <CustomRangePicker style={{ width: "100%" }} value={v as any} onChange={onChange} />
}

export default XZDateRange
