const withAllowClear = {
  allowClear: true,
}
export default {
  type: "object",
  labelWidth: 86,
  properties: {
    businessLicenseStatus: {
      title: "营业执照",
      type: "string",
      widget: "select",
      enum: ["UNHANDLED", "HANDLED", "INVALID"],
      enumNames: ["未办理", "已办理", "已删除"],
      placeholder: "请选择",
      props: withAllowClear,
    },
    taxRegistrationStatus: {
      title: "税务登记",
      type: "string",
      widget: "select",
      enum: ["UNREGISTERED", "REGISTERED", "INVALID"],
      enumNames: ["未登记", "已登记", "已删除"],
      placeholder: "请选择",
      props: withAllowClear,
    },
    timer: {
      bind: ["createTimeStart", "createTimeEnd"],
      title: "申请时间",
      type: "data",
      widget: "XZDateRange",
      props: withAllowClear,
    },
    handleRegionId: {
      title: "办理区域",
      type: "string",
      widget: "select",
      enum: [],
      enumNames: [],
      placeholder: "请选择",
      props: withAllowClear,
    },
    checkStatus: {
      title: "审核状态",
      type: "string",
      widget: "select",
      enum: ["WAIT_CHECK", "UN_COMMIT", "CHECK_PASS", "COMMIT"],
      enumNames: ["待审核", "未提交", "审核通过", "已提交"],
      placeholder: "请选择",
      props: { ...withAllowClear },
    },
    signStatus: {
      title: "委托协议",
      type: "string",
      widget: "select",
      enum: ["SIGNED", "UN_SIGNED"],
      enumNames: ["已签署", "待签署"],
      placeholder: "请选择",
      props: { ...withAllowClear },
    },
    dualStatus: {
      title: "双录视频",
      type: "string",
      widget: "select",
      enum: ["DUAL_ED", "UN_DUAL"],
      enumNames: ["已录制", "待录制"],
      placeholder: "请选择",
      props: { ...withAllowClear },
    },
    keywords: {
      title: "搜索",
      type: "string",
      widget: "XZInput",
      placeholder: "申请编号、法人姓名、身份证号、联系电话搜索",
      props: withAllowClear,
    },
  },
}
