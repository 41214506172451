export const APIPrefix = "/consignor/recovery/admin/recovery-group"
export const USER = "/rscm/rscm-system/users"
export const APPLY = "/rscm/rscm-system/applications"
import { COMPANY_INFO } from "@/contents/companyInfo"
import { BadgeProps } from "antd"

export const userListStaus = [
  {
    label: "正常",
    value: "true",
  },
  {
    label: "停用",
    value: "false",
  },
]

export const OrderLogisticsStatusEnum: Record<string, BadgeProps> = {
  false: {
    status: "error",
    text: "停用",
  },
  true: {
    status: "success",
    text: "正常",
  },
}
// 税务登记
export const taxRegistrationStatusEnum: Record<string, BadgeProps> = {
  UNREGISTERED: {
    status: "warning",
    text: "未登记",
  },
  REGISTERED: {
    status: "success",
    text: "已登记",
  },
  INVALID: {
    status: "error",
    text: "已删除",
  },
}
// 运营标识
export const providerEnum: Record<string, BadgeProps> = {
  default: {
    text: COMPANY_INFO.company_name,
  },
  ly: {
    // status: "error",
    text: "绿易",
  },
  xyrscm: {
    // status: "success",
    text: "建龙",
  },
  sinzetech: {
    // status: "error",
    text: "新之",
  },
  nbcoop: {
    // status: "success",
    text: "供销",
  },
}
