function retArr() {
  let arr = JSON.parse(sessionStorage.getItem("ACCESS_KEYS") || "[]")

  return arr
}
// let arr = window?.["ACCESS_KEYS"] || []
export const AccessEnum = (function () {
  let obj = {}
  retArr().forEach((key) => {
    obj[key] = key
  })
  window.console.log(obj)
  return obj
})()
