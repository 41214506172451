/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { Row, Col, Button, Rate, Image } from "antd"
// import { OperableImage } from "@src/components/ImageOptions"
import { getStoreDetail, getUrlByFileId } from "./promises"
import wx_img from "@/assets/wx_official_account.jpg"
import wx_img_jl from "@/assets/wx_official_account_jl.jpg"
import wx_img_xz from "@/assets/wx_official_account_xz.jpg"
import Logos from "@/assets/rscm-top.png"
import businessImg from "@/assets/business_img.png"
import "./index.scss"

let current_env = window.location.href.split("/")[2]

const StorePage = () => {
  const history = useParams()
  const [open, setOpen] = useState<boolean>(false)
  const [detailInfo, setDetailInfo] = useState<any>({})
  const hrefList = ["test-rscm-op.saikul.com", "rscm-op.xyrscm.com"]
  const init = async () => {
    const res: any = await getStoreDetail(history?.id)
    if (res.code == 200) {
      // const _data = res.data
      // if (_data?.businessLicense) {
      //   let result = await getUrlByFileId({ zoom: false, fileIds: _data.businessLicense })
      //   _data.businessLicense = result[0]
      // }
      setDetailInfo(res.data)
    }
  }
  useEffect(() => {
    init()
  }, [])
  return (
    <div className="store-wrapper">
      <div className="page-header">
        <div className="logo">
          <img src={Logos} alt="logo" />
          <p>运营中心</p>
        </div>
        <div className="nav_list_content">店铺详情</div>
      </div>
      <div className="page-padding">
        <div className="store-header">
          <div className="store-header-t">
            <div className="page-title">店铺详情</div>
            <div className="store-status">
              平台认证状态
              <span style={detailInfo?.authStatus ? {} : { background: "red" }}></span>
              {detailInfo?.authStatus ? "已认证" : "未认证"}
            </div>
          </div>
          <div className="store-header-b">
            <div className="score">
              综合评分：
              <Rate
                disabled
                allowHalf
                defaultValue={detailInfo?.rating ? detailInfo?.rating / 2 : 4.5}
              />
              <span style={{ color: "#000", marginLeft: "8px" }}>
                {detailInfo?.rating ? detailInfo?.rating / 2 : 4.5}
              </span>
            </div>
            <div>
              <Button
                type="primary"
                onClick={() => {
                  setOpen(true)
                }}
              >
                入驻平台，查看更多数据
              </Button>
            </div>
          </div>
          {/* <div className="store-title">店铺详情</div>
        <div className="store-score">综合评分</div>
        <div className="store-status">
          平台认证状态：<span>已认证</span>
        </div> */}
        </div>
        <div className="store-content">
          <div className="store-content-title">基本信息</div>
          <Row>
            <Col span={8}>
              <span>经营者姓名</span>
              <div>{detailInfo?.operatorName}</div>
            </Col>
            <Col span={8}>
              <span>联系电话</span>
              <div>{detailInfo?.contact}</div>
            </Col>
            <Col span={8}>
              <span>身份证号</span>
              <div>{detailInfo?.idno}</div>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <span>名称</span>
              <div>{detailInfo?.name}</div>
            </Col>
            <Col span={8}>
              <span>统一社会信用代码</span>
              <div>{detailInfo?.uniformSocialCreditCode}</div>
            </Col>
            <Col span={8}>
              <span>入驻时间</span>
              <div>{detailInfo?.entryTime}</div>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="img-view">
              <span>营业执照</span>
              <div>{detailInfo?.businessLicense && <Image width={200} src={businessImg} />}</div>
            </Col>
          </Row>
        </div>
        {open && (
          <div className="modal">
            <div className="modal-title">关注公众号，完成注册</div>
            <img
              src={
                hrefList.indexOf(current_env) == -1
                  ? wx_img
                  : hrefList.indexOf(current_env) == 1
                  ? wx_img_jl
                  : wx_img_xz
              }
            />
            <Button
              type="primary"
              onClick={() => {
                setOpen(false)
              }}
            >
              关闭
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default StorePage
