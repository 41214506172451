import React from "react"
import { Badge } from "antd"
import { enableStatusEnum, loanTermStatusEnum, repaymentMethodStatusEnum } from "../constant"
import Decimal from "decimal.js"

export default [
  {
    title: "产品名称",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "借款周期",
    dataIndex: "loanTerm",
    key: "loanTerm",
    render: (value) => <span>{loanTermStatusEnum[value]}</span>,
  },
  {
    title: "还款模式",
    dataIndex: "repaymentMethod",
    key: "repaymentMethod",
    render: (value: any) => <span>{repaymentMethodStatusEnum[value]}</span>,
  },
  {
    title: "利率",
    dataIndex: "annualInterestRate",
    key: "annualInterestRate",
    render: (_, record) => {
      return `年利率${Number(new Decimal(_).mul(new Decimal("100")))}%`
    },
  },
  {
    title: "提前还款",
    dataIndex: "prepaymentAllowed",
    key: "prepaymentAllowed",
    render: (_, record) => {
      return _ ? "支持" : "不支持"
    },
  },
  {
    title: "融资公司",
    dataIndex: "financeCompany",
    key: "financeCompany",
  },
  {
    title: "核心企业",
    dataIndex: "companyNames",
    key: "companyNames",
  },
  {
    title: "状态",
    dataIndex: "status",
    key: "status",
    render: (value) => <Badge {...enableStatusEnum[value]} />,
  },
  {
    title: "添加人",
    dataIndex: "createUserName",
    key: "createUserName",
  },
  {
    title: "添加时间",
    dataIndex: "createTime",
    key: "createTime",
  },
]
