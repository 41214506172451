/* eslint-disable react-hooks/exhaustive-deps */
import { CaretDownOutlined, CaretRightOutlined, SearchOutlined } from "@ant-design/icons"
import Access from "@src/components/Access"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import SearchForm from "@src/components/FormItem"
import { AccessEnum } from "@src/contents/accessEnum"
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Select,
  Space,
  Table,
  Tag,
  message,
} from "antd"
import { uniqBy } from "lodash"
import React, { useEffect, useRef, useState } from "react"
import { getMenuBtnList } from "../constant"
import {
  addCategoryTree,
  deleteCategory,
  editCategory,
  getCategoryTree,
  getInvoiceCategory,
} from "../promises"
import "./index.scss"
const breads = [
  {
    label: "交易中心",
    path: "",
  },
  {
    label: "平台品类",
    path: "",
  },
]
const { Search } = Input
interface DELETE {
  open: boolean
  id: string
}
interface ADD extends DELETE {
  type: string
  name?: string
  rootId?: string | number
  deep?: string | number
  parentId?: string
}

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 16,
  },
}
const checkOptions = [
  {
    label: "交易品类",
    value: "forTrade",
  },
  {
    label: "商城搜索",
    value: "shopSearch",
  },
]
const Classification = () => {
  let listTimer = useRef<any>(null)
  const [loading, setLoading] = useState<Boolean>(false)
  const [totalList, setTotalList] = useState([])
  const [initInvoiceCategoryList, setInitInvoiceCategoryList] = useState<any>([])
  const [invoiceCategoryList, setInvoiceCategoryList] = useState<any>([])
  const [pageOptions, setPageOptions] = useState({
    pageSize: 10,
    current: 1,
    total: 0,
  })
  const [addParams, setAddParams] = useState<any>({
    type: "add",
    open: false,
    id: "",
    name: "",
    rootId: "",
    deep: 1,
    parentId: "",
    invoiceCategoryConfigId: "",
    invoiceCategoryConfigName: "",
  })
  const [form] = Form.useForm()
  const [listData, setList] = useState([])
  const [expandedRowKeys, setExpandedRowKeys] = useState<any>([])
  const [invoiceCategoryConfig, setInvoiceCategoryConfig] = useState<any>({})
  useEffect(() => {
    getLists()
  }, [])
  const getLists = async (obj?: any) => {
    let params: any = {
      ...obj,
      keywords: obj?.keywords?.trim(),
    }
    setLoading(true)
    // 获取配置开票名称列表
    const result: any = await getInvoiceCategory({ size: -1, current: -1 })
    if (result.code == 200) {
      let resultData = result.data.records.map((ie: any) => {
        return {
          label: ie.itemName,
          value: ie.id,
        }
      })
      setInvoiceCategoryList(resultData)
      // 原始品类
      setInitInvoiceCategoryList(resultData)
    }

    const res = await getCategoryTree(params)
    if (res.code == 200) {
      setTotalList(res?.data)
      if (obj?.keywords) {
        let menuList = []
        getMenuBtnList(res.data, menuList)
        setExpandedRowKeys(menuList)
      } else {
        setExpandedRowKeys([])
      }
      setLoading(false)
    }
    if (obj?.current && obj?.size) {
      const startIndex = (obj?.current - 1) * obj?.size
      const endIndex = obj.current * obj.size
      setList(res?.data.slice(startIndex, endIndex))
      setPageOptions({
        ...pageOptions,
        total: res?.data?.length,
        current: obj?.current,
        pageSize: obj?.size,
      })
    } else {
      const startIndex = (pageOptions.current - 1) * pageOptions.pageSize
      const endIndex = pageOptions.current * pageOptions.pageSize
      setList(res?.data?.slice(startIndex, endIndex))
      setPageOptions({
        ...pageOptions,
        total: res?.data?.length,
      })
    }
  }

  const onSearch = (value: string) => {
    if (listTimer.current) {
      clearTimeout(listTimer.current)
      listTimer.current = null
    }
    listTimer.current = setTimeout(() => {
      getLists(value)
    }, 700)
  }
  const formColumns = [
    {
      label: "搜索",
      dataIndex: "keywords",
      type: "Input",
      width: "264px",
      params: {
        suffix: <SearchOutlined style={{ color: "rgba(217, 217, 217, 1)" }} />,
        placeholder: "品类名称搜索",
      },
    },
  ]
  const handlePage = (object: any) => {
    const startIndex = (object?.current - 1) * object?.pageSize
    const endIndex = object.current * object.pageSize
    setList(totalList.slice(startIndex, endIndex))
    setPageOptions({
      ...pageOptions,
      current: object.current,
      pageSize: object.pageSize,
    })
  }
  // 删除
  const handleDelete = async () => {
    const res: any = await deleteCategory(addParams?.id)
    if (res?.code === 200) {
      message.success("操作成功")
      const len = Number(totalList.length)
      const size = Number(pageOptions.pageSize)
      const num = len % size
      let lastNum = len / size
      setAddParams({ name: "", open: false, id: "", deep: 0, type: "add" })
      form.resetFields()
      // const lastNum = parseInt(len/size)
      if (num < 2 && Number(pageOptions.current) === Math.ceil(lastNum)) {
        getLists({
          size: pageOptions.pageSize,
          current: pageOptions.current - 1,
        })
      } else {
        getLists({
          size: pageOptions.pageSize,
          current: pageOptions.current,
        })
      }
    } else {
      message.error(res.message || "删除失败")
    }
  }

  const flattenTree = (node, result) => {
    // 将当前节点的名称添加到结果数组中
    result.push(node)
    // 如果当前节点有子节点，则递归处理每个子节点
    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => {
        flattenTree(child, result)
      })
    }
  }
  const columns = [
    {
      title: "品类名称",
      dataIndex: "name",
      key: "name",
      width: "30%",
      fixed: "left",
    },
    {
      title: "品类标识",
      // dataIndex: "age",
      key: "isTrade",
      render: (record: any) => {
        return (
          <>
            {record?.isTrade && <Tag color="blue">交易品类</Tag>}
            {record?.isShopSearch && <Tag color="orange">商城搜索</Tag>}
          </>
        )
      },
    },
    {
      title: "开票名称",
      dataIndex: "invoiceCategoryConfigName",
      key: "invoiceCategoryConfigName",
    },
    {
      title: "排序",
      dataIndex: "sort",
      key: "sort",
    },
    {
      title: "操作",
      width: "20%",
      render: (record: any) => {
        return (
          <div style={{ marginLeft: -15 }}>
            {record?.deep < 5 && (
              <Access accessible={AccessEnum.op_entity_transaction_feature_add}>
                <Button type="link" onClick={() => handChildrenName(record)}>
                  添加子品类
                </Button>
              </Access>
            )}
            <Access accessible={AccessEnum.op_entity_transaction_feature_detail}>
              <Button
                type="link"
                style={{ marginLeft: -12 }}
                onClick={() => {
                  const flattenedArray = []
                  listData?.forEach((tree) => flattenTree(tree, flattenedArray))
                  let newFlattenedArray: any = flattenedArray?.filter(
                    (ie: any) => ie.id == record.parentId
                  )
                  let list: any = []
                  if (record?.isTrade) {
                    list.push("forTrade")
                  }
                  if (record?.isShopSearch) {
                    list.push("shopSearch")
                  }
                  const params = {
                    name: record?.name,
                    sort: record?.sort,
                    invoiceCategoryConfigId: record?.invoiceCategoryConfigId,
                    invoiceCategoryConfigName: record?.invoiceCategoryConfigName,
                    isTrade: list,
                  }
                  form.setFieldsValue(params)
                  console.log(record, 612313)
                  setAddParams({
                    ...addParams,
                    open: true,
                    rootId: record?.rootId,
                    deep: record?.deep,
                    parentId: record?.parentId,
                    name: newFlattenedArray?.length > 0 ? newFlattenedArray[0]?.name : "",
                    invoiceCategoryConfigId: record?.invoiceCategoryConfigId,
                    invoiceCategoryConfigName: record?.invoiceCategoryConfigName,
                    type: "edit",
                    id: record?.id,
                  })
                }}
              >
                详情
              </Button>
            </Access>
          </div>
        )
      },
    },
  ]

  const handChildrenName = (record: any) => {
    const flattenedArray = []
    console.log(record, 1111111111)
    listData?.forEach((tree) => flattenTree(tree, flattenedArray))
    // 携带父级品类
    let _options = [
      { label: record.invoiceCategoryConfigName, value: record.invoiceCategoryConfigId },
      ...initInvoiceCategoryList,
    ]
    _options.filter((item: any) => item.invoiceCategoryConfigId)
    setInvoiceCategoryList(_options)
    let newFlattenedArray: any = flattenedArray?.filter((ie: any) => ie.id == record.parentId)
    console.log(newFlattenedArray, "newFlattenedArray")
    const newFlattenedArrayName = newFlattenedArray?.length ? newFlattenedArray[0]?.name : ""
    setAddParams({
      ...addParams,
      open: true,
      rootId: record?.rootId,
      deep: record?.deep + 1,
      parentId: record?.id,
      name: addParams.type === "add" ? record.name : newFlattenedArrayName,
      type: "add",
    })
    let list: any = []
    if (record?.isTrade) {
      list.push("forTrade")
    }
    if (record?.isShopSearch) {
      list.push("shopSearch")
    }
    form.setFieldsValue({
      isTrade: list,
      invoiceCategoryConfigId: record.invoiceCategoryConfigId,
      invoiceCategoryConfigName: record.invoiceCategoryConfigName,
    })
  }
  console.log(expandedRowKeys, "expandedRowKeys")
  const expandable = {
    rowExpandable: (e: any) => {
      return true
    },
    onExpand: (expanded: Boolean, record: any) => {
      if (expanded) {
        let arr = expandedRowKeys
        arr.push(record.id)
        setExpandedRowKeys(arr)
      } else {
        let arr2 = []
        if (expandedRowKeys.length > 0 && record.id) {
          arr2 = expandedRowKeys.filter((item) => {
            return item !== record.id
          })
        }
        setExpandedRowKeys(arr2)
      }
    },
    expandedRowKeys: expandedRowKeys,

    expandIcon: ({
      expanded,
      onExpand,
      record,
      expandable,
    }: {
      expanded: boolean
      onExpand: (record: any, e: any) => void
      record: boolean
      expandable: any
    }) => {
      return expandable ? (
        expanded ? (
          <CaretDownOutlined onClick={(e) => onExpand(record, e)} />
        ) : (
          <CaretRightOutlined onClick={(e) => onExpand(record, e)} />
        )
      ) : null
    },
  }
  const handleOk = async () => {
    const res = await form.validateFields()
    const fileds = await form.getFieldsValue()
    let obj = {}
    if (res.checkList) {
      res.checkList.forEach((item: any) => {
        obj[item] = true
      })
    }
    if (addParams.type === "add") {
      // 当前是根节点新增的时候
      let params = {
        sort: res.sort,
        ...obj,
        name: res.name,
        isTrade: res?.isTrade?.includes("forTrade"),
        isShopSearch: res?.isTrade?.includes("shopSearch"),
        deep: addParams.deep,
        rootId: addParams.rootId,
        parentId: addParams?.parentId,
        invoiceCategoryConfigName: invoiceCategoryConfig.label,
        invoiceCategoryConfigId: invoiceCategoryConfig.value,
      }
      if (addParams.rootId === "") {
        delete params.rootId
        delete params.parentId
      }
      const response: any = await addCategoryTree(params)
      if (response?.code === 200) {
        setAddParams({
          name: "",
          open: false,
          id: "",
          type: "add",
        })
        form.resetFields()
        getLists({
          size: pageOptions.pageSize,
          current: pageOptions.current,
        })
      } else {
        message.error(res.message || "添加失败")
      }
    } else {
      // editCategory
      let params = {
        sort: res.sort,
        ...obj,
        name: res.name,
        isTrade: res?.isTrade?.includes("forTrade"),
        isShopSearch: res?.isTrade?.includes("shopSearch"),
        deep: addParams.deep,
        rootId: addParams.rootId,
        parentId: addParams?.parentId,
        invoiceCategoryConfigName: invoiceCategoryConfig.label,
        invoiceCategoryConfigId: invoiceCategoryConfig.value,
      }
      const response: any = await editCategory(addParams.id, params)
      if (response?.code === 200) {
        setAddParams({
          name: "",
          open: false,
          id: "",
          type: "add",
        })
        form.resetFields()
        getLists({
          size: pageOptions.pageSize,
          current: pageOptions.current,
        })
      } else {
        message.error(res.message || "修改失败")
      }
    }
  }
  console.log(addParams, 7123165, invoiceCategoryList, form.getFieldsValue())
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <span>平台品类</span>
          </Space>
        }
        breadCrumb={[
          { label: "交易中心", path: "" },
          { label: "平台品类", path: "" },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <DetailPageContent>
        <div className="user_serch">
          <SearchForm formParams={formColumns} onSearch={onSearch} optionsList={null}></SearchForm>
          <div className="system-user-add"></div>
        </div>
        <div className="PlatformSetup_user">
          <div className="PlatformSetup_user_basie">
            <div className="PlatformSetup_user_basie_display">
              <div className="PlatformSetup_user_basic_div">平台品类列表/{listData.length}</div>
              <Access accessible={AccessEnum.op_entity_transaction_feature_add}>
                <Button
                  type="primary"
                  // style={{
                  //   marginBottom: 10,
                  // }}
                  onClick={() => {
                    // 原始平台品类
                    setInvoiceCategoryList(initInvoiceCategoryList)
                    setAddParams({
                      open: true,
                      name: "",
                      id: "",
                      rootId: "",
                      deep: 1,
                      type: "add",
                    })
                  }}
                >
                  添加品类
                </Button>
              </Access>
            </div>
            <Table
              rowKey={(record: any) => record.id}
              style={{ paddingTop: 16 }}
              loading={loading}
              size="small"
              columns={columns}
              dataSource={listData}
              indentSize={24}
              pagination={{
                current: pageOptions.current,
                pageSize: pageOptions.pageSize,
                total: pageOptions.total,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal(total, range) {
                  return `总共${total}条`
                },
              }}
              onChange={handlePage}
              expandable={expandable}
              scroll={{ x: "max-content" }}
            />
            {/* 新增 */}
            <Modal
              title={addParams.type === "add" ? "新增品类" : "编辑品类"}
              open={addParams.open}
              destroyOnClose={true}
              onCancel={() => {
                setAddParams({ name: "", open: false, id: "", deep: 0, type: "add" })
                form.resetFields()
              }}
              footer={false}
            >
              <Form {...layout} form={form} className="formClass">
                <Form.Item label="上级品类">
                  <span>{addParams.name || "无"}</span>
                </Form.Item>
                <Form.Item
                  style={{ paddingTop: 11 }}
                  label="品类名称"
                  name="name"
                  rules={[{ required: true, message: "请输入品类名称" }]}
                >
                  <Input placeholder="请输入品类名称" showCount maxLength={15}></Input>
                </Form.Item>
                <Form.Item
                  label="排序"
                  name="sort"
                  style={{ paddingTop: 11 }}
                  rules={[
                    { required: true, message: "请输入排序" },
                    {
                      pattern: /^[0-9]*[1-9][0-9]*$/,
                      message: "只能输入整数字的排序",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="数字越小排序越靠前，最小为1"
                    min={1}
                    style={{ width: "100%" }}
                  ></InputNumber>
                </Form.Item>
                <Form.Item
                  label="开票名称"
                  name="invoiceCategoryConfigId"
                  style={{ paddingTop: 11 }}
                >
                  <Select
                    options={uniqBy(invoiceCategoryList, "value")}
                    placeholder="请选择"
                    onSelect={(_, option: any) => {
                      setInvoiceCategoryConfig(option)
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="品类标识"
                  name="isTrade"
                  style={{ paddingTop: 11, marginBottom: 24 }}
                >
                  <Checkbox.Group options={checkOptions} />
                </Form.Item>
                <Form.Item style={{ width: "108%", borderTop: "1px solid #f0f0f0" }}>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: 10,
                      paddingTop: 11,
                      paddingLeft: 20,
                    }}
                  >
                    {addParams?.type != "add" && (
                      <div style={{ width: "100px" }}>
                        <Access accessible={AccessEnum.op_entity_transaction_feature_detail_delete}>
                          <Popconfirm
                            title="删除"
                            style={{ width: 100 }}
                            description={`确认删除吗？删除后无法恢复！`}
                            onConfirm={handleDelete}
                            okText="确定"
                            cancelText="取消"
                          >
                            <Button danger>删除</Button>
                          </Popconfirm>
                        </Access>
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        textAlign: "right",
                        marginLeft: `${addParams?.type == "add" ? "350px" : "285px"}`,
                      }}
                    >
                      <Button
                        style={{ marginRight: "12px" }}
                        onClick={() => {
                          setAddParams({ name: "", open: false, id: "", deep: 0, type: "add" })
                          form.resetFields()
                        }}
                      >
                        取消
                      </Button>
                      {addParams.type === "add" ? (
                        <Button type="primary" onClick={handleOk}>
                          确定
                        </Button>
                      ) : (
                        <Access accessible={AccessEnum.op_entity_transaction_feature_detail_edit}>
                          <Button type="primary" onClick={handleOk}>
                            确定
                          </Button>
                        </Access>
                      )}
                    </div>
                  </div>
                </Form.Item>
              </Form>
            </Modal>
          </div>
        </div>
      </DetailPageContent>
    </DetailPageContainer>
  )
}

export default Classification
