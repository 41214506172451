export const statusList = {
  PENDING: {
    text: "待处理",
    status: "Warning",
  },
  PROCESSING: {
    text: "开票中",
    status: "Processing",
  },
  COMPLETED: {
    text: "开票完成",
    status: "Success",
  },
  PENDING_REVOKE: {
    text: "待撤销",
    status: "Warning",
  },
  REVOKED: {
    text: "已撤销",
    status: "Default",
  },
  PENDING_AWAIT_RED: {
    text: "待冲红",
    status: "Warning",
  },
  COMPLETED_RED: {
    text: "已冲红",
    status: "Default",
  },
}
export const makeStatusList = {
  YES: {
    text: "是",
    status: "success",
  },
  NO: {
    text: "否",
    status: "error",
  },
}
export const statusObjs = {
  PENDING: {
    label: "待处理",
    color: "warning",
  },
  PROCESSING: {
    label: "开票中",
    color: "primary",
  },
  COMPLETED: {
    label: "开票完成",
    color: "success",
  },
  PENDING_REVOKE: {
    label: "待撤销",
    color: "warning",
  },
  REVOKED: {
    label: "已撤销",
    color: "error",
  },
  PENDING_AWAIT_RED: {
    label: "待冲红",
    color: "primary",
  },
  COMPLETED_RED: {
    label: "已冲红",
    color: "success",
  },
}

export const taskSourceObjs = {
  TRADE: "交易系统",
}

// 发票模式
export const invoiceTypeObjs = {
  PAPER_SPECIAL: "纸质普票",
  PAPER_GENERAL: "纸质专票",
  ELECTRONIC_GENERAL: "电子普票",
  ELECTRONIC_SPECIAL: "电子专票",
  FULLY_ELECTRONIC_GENERAL: "全电普票",
  FULLY_ELECTRONIC_SPECIAL: "全电专票",
}

// 开票渠道
export const issueChannelObjs = {
  MANUAL: "手工",
  BW: "百旺",
  HX: "航信",
}
export const getBase64 = (url) => {
  return new Promise((resolve) => {
    const image = new Image()
    // 先设置图片跨域属性
    image.crossOrigin = "Anonymous"
    // 再给image赋值src属性，先后顺序不能颠倒
    image.src = url
    image.onload = function () {
      const canvas: any = document.createElement("CANVAS")
      // 设置canvas宽高等于图片实际宽高
      canvas.width = image.width
      canvas.height = image.height
      canvas.getContext("2d").drawImage(image, 0, 0)
      // toDataUrl可以接收2个参数，参数一：图片类型，参数二： 图片质量0-1（不传默认为0.92）
      const dataURL = canvas.toDataURL("image/jpeg")
      resolve(dataURL)
    }
    image.onerror = () => {
      resolve({ message: "相片处理失败" })
    }
  })
}
