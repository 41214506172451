import { RcFile } from "antd/lib/upload"
import { message } from "antd"

export const uploadFileBeforeLimit = (
  limitFns: ((file: RcFile, FileList: RcFile[]) => Promise<boolean>)[] = [],
  file: RcFile,
  FileList: RcFile[]
) => {
  return Promise.all(limitFns.map((fn) => fn(file, FileList)))
}

const limitType = (accept: string[] = ["*/*"], file: RcFile, FileList: RcFile[]) => {
  const isAccept =
    (accept?.filter((type) => {
      const targetType = type.split("/")
      const currentType = file.type.split("/")
      return (
        (targetType[0] === currentType[0] || targetType[0] === "*") &&
        (targetType[1] === currentType[1] || targetType[1] === "*")
      )
    }).length || 0) > 0
  return isAccept ? Promise.resolve(true) : Promise.reject("文件类型不合法")
}

const limitCommonType = async (file: RcFile, FileList: RcFile[]) => {
  limitType(["image/jpeg", "image/png"], file, FileList).catch((e) => {
    message.error(e)
    throw e
  })
  return Promise.resolve(true)
}
const limitSize = (size: number = 4, file: RcFile, FileList: RcFile[]) => {
  const isAccept = file.size / 1024 / 1024 < size
  return isAccept ? Promise.resolve(true) : Promise.reject(`文件大小限制 ${size} MB`)
}
const limitCommonSize = (file: RcFile, FileList: RcFile[]) => {
  limitSize(4, file, FileList).catch((e) => {
    message.error(e)
    throw e
  })
  return Promise.resolve(true)
}
export const limitMethods = {
  limitCommonType,
  limitCommonSize,
  limitSize,
  limitType,
}
export const uploadBeforeCommitLimit = uploadFileBeforeLimit.bind(null, [
  limitMethods.limitCommonSize,
  limitMethods.limitCommonType,
])
