import { OPL_INVOICE } from "../../../../api/config/servicePort"
import UtilOp from "../../../../services/serviceOp"
const util = UtilOp.getInstance()

export interface AuthenticationLoginParams {
  // 企业登录|企业登录发送验证码|企业登录选择责任人|企业登录验证验证码
  loginType?: "LOGIN" | "LOGIN_SEND_CODE" | "LOGIN_WITH_RESPONSE" | "LOGIN_WITH_CODE"
  /**
   * 纳税人识别号
   */
  nsrsbh?: string
  /**
   * 密码
   */
  password?: string
  /**
   * 短信验证码
   */
  smsCode?: string
  /**
   * 用户名
   */
  username?: string
  [property: string]: any
}
// 二次认证的验证码
export async function querySecondaryCode(params) {
  return util.httpGet(`/api/invoices/full/qrCodeQuery`, params)
}
// 二次认证状态查询
export async function querySecondaryCodeStatus(params) {
  return util.httpGet(OPL_INVOICE + "/HangXin/authStatus", params)
}

// 企业登录验证  发送验证码
// 之前的登陆接口，如：/authentication/1，统一到该接口
export async function AuthenticationLogin(params: AuthenticationLoginParams) {
  return util.httpPost(OPL_INVOICE + "/HangXin/login", params)
}

// 获取二次认证二维码
export async function getHangXinQrCode(params) {
  return util.httpGet(OPL_INVOICE + "/HangXin/qrCode", params)
}
