import * as actionTypes from "./constants"

const initialState = {
  // 模板的基本信息
  basicForm: {},
  // 模板的限制条件
  ruleForm: {
    limitInputWordQuantity: {
      maxValue: "",
      minValue: "",
    },
    limitInputValueRange: {
      minValue: "",
      maxValue: "",
    },
  },
  codeList: [],
  text: "",
  updateFollow: "",
  updateNotify: "",
  roleCodes: [],
  meunList: [],
}

function reducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.UPDATE_BASIC:
      return {
        ...state,
        basicForm: {
          ...state.basicForm,
          ...action.payload,
        },
      }
    case actionTypes.UPDATE_RULE:
      return {
        ...state,
        ruleForm: {
          ...state.ruleForm,
          ...action.payload,
        },
      }

    case actionTypes.RESET_FORM:
      return {
        ...state,
        ruleForm: {},
        basicForm: {},
      }
    case actionTypes.INIT_FORM:
      return {
        ...state,
        ...action.payload,
      }
    case actionTypes.FUNCTION_CODE:
      return {
        ...state,
        codeList: action.payload.codeList,
      }
    case actionTypes.QUILL_TAX:
      return {
        ...state,
        text: action.payload.text,
      }
    case actionTypes.UPDATE_FOLLOW:
      return {
        ...state,
        updateFollow: action.payload.value,
      }
    case actionTypes.UPDATE_NOTIFY:
      return {
        ...state,
        updateNotify: action.payload.value,
      }
    case actionTypes.ROLE_CODE:
      return {
        ...state,
        ...action.payload,
      }
    case actionTypes.ROLE_MEUS_LIST:
      return {
        ...state,
        meunList: action.payload.meunList,
      }
    default:
      return state
  }
}

export default reducer
