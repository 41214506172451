import { OP_TRADE, USER, OP_SOURCE, OP_USER, OP_SIGN } from "../../api/config/servicePort"
import axios from "axios"
import Util from "@src/services/util"
import UtilOp from "../../services/serviceOp"

const util = Util.getInstance()
const utilOp = UtilOp.getInstance()

// 获取所有税区
export const getTaxList = (values?: any) => {
  return util.httpGet("/sinzetech-tax/tax-region", values).then((res) => res)
}

// 获取所有用户
export const getUserLists = (values?: any) => {
  return utilOp.httpGet(OP_USER + "/users", values).then((res) => res)
}
// mock获取所有用户
// export const getUserLists = (values?: any) => {
//   return axios.get("http://localhost:8080/plat-oper-users", values).then((res) => res)
// }
//新增用户
export const getAddUserLists = (params: any) => {
  return utilOp.httpPost(OP_USER + `/users`, { ...params })
}
//修改用户
export const getPutUserListsUseoLists = (params: any) => {
  return utilOp.httpPut(OP_USER + `/users/${params.id}`, { ...params })
}
// 删除用户
export const getDeleteUseoLists = (id: string) => {
  return utilOp.httpDeletel(OP_USER + `/plat/oper/users/${id}`)
}
// 获取用户详情
export const getUserInfo = (id: string) => {
  return utilOp.httpGet(OP_USER + `/users/${id}`)
}

// 获取用户授权列表
export const getUserRolesLists = (values?: any) => {
  return utilOp.httpGet(OP_USER + "/authorisations", values).then((res) => res)
}
//新建用户授权
export const getAddUseoOrgs = (params: any) => {
  return utilOp.httpPost(OP_USER + `/authorisations`, {
    ...params,
  })
}
// 删除用户授权
export const getDeleteUseoOrgs = (id: any) => {
  return utilOp.httpDeletel(OP_USER + `/authorisations/${id}`)
}
//用户组织列表
export const getOrganizationList = (values?: any) => {
  // return utilOp.httpGet(OP_USER + "/authorisations/user-organizations", values).then((res) => res)
  return utilOp.httpGet(OP_USER + "/organizations", values).then((res) => res)
}
// 查询组织列表(翻页)
export const getTissueList = (params?: Tissue.ListParams) => {
  return utilOp.httpGet<Tissue.ListResponse>(OP_USER + "/organizations", params)
}
//查询应用管理列表
export const getOperAppsLists = (values?: any) => {
  // return utilOp.httpGet(OP_USER + "/applications", values).then((res) => res)
  return utilOp.httpGet(OP_USER + `/organizations/${values.id}`, values).then((res) => res)
}
//应用管理列表
export const getOperAppsTions = (values?: any) => {
  return utilOp.httpGet(OP_USER + "/applications", values).then((res) => res)
}

// //查询角色
export const getPlatCharactersList = (params: any) => {
  return utilOp.httpGet(OP_USER + `/applications/${params.id}/roles`)
}

// 组织管理用到——查询应用列表
export const getAppsList = (values?: any) => {
  return utilOp.httpGet(OP_USER + "/applications", values).then((res) => res)
}
//税区管理列表
export const getRegionsList = (params: any) => {
  return utilOp.httpGet(OP_USER + `/handle-regions`, params)
}
//办理区域详情
export const getOperRegionsDetails = (id?: any) => {
  return utilOp.httpGet(OP_USER + `/handle-regions/${id}`)
}
//新增用户
export const getAddRegionsList = (params: any) => {
  return utilOp.httpPost(OP_USER + `/handle-regions`, { ...params })
}
//修改用户
export const getPutRegionsList = (params: any) => {
  return utilOp.httpPut(OP_USER + `/handle-regions/${params.id}`, { ...params })
}

//开票名称配置列表
export const getInvoiceCategory = (params?: any) => {
  return utilOp.httpGet(OP_TRADE + `/invoice-category-config`, params)
}

//税收分类简称列表
export const getInvoiceCategoryTaxName = (params?: any) => {
  return utilOp.httpGet(OP_TRADE + `/invoice-category-config/tax-name-count`, params)
}
//新增开票名称配置
export const getInvoiceCategoryAdd = (params: any) => {
  return utilOp.httpPost(OP_TRADE + `/invoice-category-config`, { ...params })
}
// 删除开票名称分类设置
export const getInvoiceCategoryDelete = (id: any) => {
  return utilOp.httpDeletel(OP_TRADE + `/invoice-category-config/${id}`)
}
// 查询合同模版列表(翻页)
export const getContractList = (params: any) => {
  return utilOp.httpGet<any>(OP_SIGN + "/contract-templates", params)
}
//id换取文件路径
export const getOrderUploadByPath = async (params: any) => {
  return utilOp.httpPatch(OP_SOURCE + `/oss/endpoint`, params)
}
