const withAllowClear = {
  allowClear: true,
}
export default {
  type: "object",
  labelWidth: 126,
  properties: {
    name: {
      title: "交易方案名称",
      type: "string",
      widget: "input",
      placeholder: "请输入",
      props: withAllowClear,
    },
    timer: {
      bind: ["startTime", "endTime"],
      title: "交易方案有效期",
      type: "data",
      widget: "XZDateRange",
      props: withAllowClear,
    },
    status: {
      title: "状态",
      type: "string",
      widget: "select",
      enum: ["true", "false"],
      enumNames: ["有效", "失效"],
      placeholder: "请选择",
      props: withAllowClear,
    },
  },
}
