import { limitMethods, uploadFileBeforeLimit } from "@/utils/uploadFileBeforeLimit"
import { withDefaults } from "@/utils/withDefaults"
import { limitFileSize } from "@src/utils/card/utils"
import CropperModal, { imageSizeType } from "../../components/Cropper"

import { message, Upload, UploadProps } from "antd"
import { RcFile, UploadChangeParam, UploadFile } from "antd/lib/upload"
import React, { useState } from "react"
import { UploadFileStatus } from "antd/lib/upload/interface"
import { ProgressProps } from "antd/es/progress"
import { tableDrivenFactory } from "@/utils/tableDrivenFactory"
import { UploadImageTypeUI } from "./RenderUploadUI"
import { withGroup } from "@/utils/withGroup"
import { cross_file_url_env } from "../../services/util"

const defaultProps = {
  name: "file",
  showUploadList: false,
  beforeUploadLimitHandlers: [],
}
type UploadFileStatuses = UploadFileStatus | "block"
export type RenderUploadUIProps = {
  percent?: number
  status?: UploadFileStatuses
  loading: boolean
  errorMessage?: string
  currentFile?: RcFile
}

type props<T> = {
  beforeUploadLimitHandlers?: ((file: RcFile, FileList: RcFile[]) => Promise<boolean>)[]
  onError?: (e?: { message: string }, info?: UploadChangeParam<UploadFile<T>>) => boolean | void
  onSuccess?: (info: UploadChangeParam<UploadFile<T>>) => boolean | void
  maxSize?: number
  tailor?: Boolean
  children?: ((doms: RenderUploadUIProps) => React.ReactNode) | React.ReactNode
} & Omit<UploadProps<T>, "children">
export type EasyUploadFileProps<T = {}> = props<T>

// const getProgressStatus = tableDrivenFactory<UploadFileStatus,ProgressProps['status']>({
//   'done': 'success',
//   'error': 'exception',
//   'uploading': 'active',
//   'block': 'active',
//   'success':'success',
//   'OTHER':'normal'
// })

const Component: React.FC<EasyUploadFileProps> = <T = {},>(props: EasyUploadFileProps<T>) => {
  const {
    beforeUploadLimitHandlers,
    children,
    onSuccess,
    onError,
    maxSize,
    tailor,
    accept,
    beforeUpload: _beforeUpload,
    ...rest
  } = {
    ...defaultProps,
    ...props,
  }
  const [loading, setLoading] = useState(false)
  const [uploadPercent, setUploadPercent] = useState(0)
  const [uploadStatus, setUploadStatus] = useState<UploadFileStatuses>()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [currentFile, setCurrentFile] = useState<RcFile>()
  const reset = () => {
    setUploadPercent(0)
    setUploadStatus(undefined)
  }

  const onChange: UploadProps<T>["onChange"] = (info, ...rest) => {
    setUploadStatus(info.file.status)
    if (info.file.status === "uploading") {
      if (info.event) {
        setUploadPercent(info.event?.percent)
      }
      setLoading(true)
    }
    if (info.file.status === "done") {
      reset()
      setLoading(false)
      onSuccess?.(info)
    } else if (info.file.status === "error") {
      setLoading(false)
      reset()
      // @ts-ignore
      const message = info.file.response?.error || info.file.response?.message
      setErrorMessage(message)
      onError?.({ message }, info)
    }
    return props.onChange?.(info, ...rest)
  }
  const beforeUploadMb = (file: RcFile, e?: any) => {
    if (limitFileSize(file.size, 20)) {
      message.error("文件不可大于20MB")
      return false
    }
    console.log(file, "file")
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"
    if (!isJpgOrPng) {
      message.error("只支持图片上传")
      return false
    }
    return true
  }
  const beforeUpload: UploadProps<T>["beforeUpload"] = async (file, fileList) => {
    const isContinue = await _beforeUpload?.(file, fileList)
    if (isContinue === false) {
      return false
    }
    if (tailor) {
      const isJpgOrPng = beforeUploadMb(file)
      if (isJpgOrPng) {
        return new Promise((resolve, reject) => {
          CropperModal({
            title: "身份证正反面裁剪",
            file: file,
            submit: async (blob) => {
              const file = new File([blob], "image.jpeg")
              resolve(file)
            },
            cancel: () => {
              reject()
            },
            reupload: false,
          })
        })
      }
      return false
    } else {
      setCurrentFile(file)
      setUploadStatus("block")
      await uploadFileBeforeLimit(
        [
          limitMethods.limitType.bind(null, accept?.split(",")),
          limitMethods.limitSize.bind(null, maxSize),
          ...beforeUploadLimitHandlers,
        ],
        file,
        fileList
      ).catch((e) => {
        message.error(e)
        throw e
      })
      const result = await props.beforeUpload?.(file, fileList)
      return result
    }
  }
  const childrenObj = {
    percent: uploadPercent,
    status: uploadStatus,
    loading,
    errorMessage,
    currentFile,
  }
  return (
    <Upload
      accept={accept}
      {...rest}
      action={rest.action || `${cross_file_url_env}/rscm-resource/oss/endpoint`}
      onChange={onChange}
      beforeUpload={beforeUpload}
      disabled={loading}
      headers={{
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("operation_authorization") || "{}")
          ?.access_token_op}`,
        ...rest.headers,
      }}
    >
      {typeof children === "function" ? children?.(childrenObj) : children}
    </Upload>
  )
}

const EasyUpload = withGroup(withDefaults(Component, defaultProps), {
  ImageTypeUI: UploadImageTypeUI,
})
export { EasyUpload }
