import { MinusCircleOutlined, UploadOutlined } from "@ant-design/icons"
import { inputDefaultRexChineseRules, inputDefaultRules } from "@src/utils/RegExp"
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Upload,
} from "antd"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router"
import { cross_env } from "../../../../../services/serviceOp"
import { getContractList } from "../../../service"
import {
  dualPrefixEnum,
  dualPrefixShopEnum,
  handProcess,
  requiredEnum,
  taxRegistrationStatusEnum,
} from "../../constant"
import { FieldType } from "../../interface"
import "./index.scss"

const { Option } = Select

export const Registration = (props: any) => {
  const {
    individualPrefix,
    setIndividualPrefix,
    individualSuffix,
    setIndividualSuffix,
    supportAnonymousType,
    setSupportAnonymousType,
    form,
    fileList,
    setFileList,
    isNeedAgreement,
    setIsNeedAgreement,
    setIsNeedDual,
    isNeedDual,
    tissueList,
    tissueAuthList,
  } = props
  const history: any = useParams()
  const [ContractList, setContractList] = useState<any>([])
  const [logoutList, setLogoutList] = useState<any>([])
  const [shopSearchList, setShopSearchList] = useState<any>([])
  const [shopOperateList, setShopOperateList] = useState<any>([])
  const [processList, setProcessList] = useState<any>([])

  const handGetContractList = async () => {
    const result = await getContractList({
      size: -1,
      searchCount: true,
      current: 1,
      status: 1,
    })
    if (result.code == 200) {
      let res = result.data.records.map((ie: any) => {
        return {
          label: ie.name,
          value: ie.id,
          type: ie.type,
        }
      })
      setContractList(res.filter((ie) => ie.type == "DELEGATION"))
      setLogoutList(res.filter((ie) => ie.type == "LOGOUT_APPLY"))
      setShopOperateList(res.filter((ie) => ie.type == "PROOF_OPERATION"))
    }
  }
  useEffect(() => {
    handGetContractList()
    if (process.env.REACT_APP_ENV == "shop") {
      setShopSearchList(dualPrefixShopEnum)
    } else {
      setShopSearchList(dualPrefixEnum)
    }
    setProcessList(handProcess)
  }, [])
  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
  return (
    <div className="registration-tax">
      <Card title="流程配置" style={{ marginTop: 16 }}>
        <Form.Item<FieldType> name="isNeedDual" label="是否需要双录视频">
          <Radio.Group
            defaultValue={isNeedDual}
            onChange={(e: any) => {
              console.log(e, "e")
              setIsNeedDual(e?.target?.value)
            }}
          >
            <Radio value={true}>是</Radio>
            <Radio value={false}>否</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item<FieldType> name="isNeedAgreement" label="是否需要委托协议">
          <Radio.Group
            defaultValue={isNeedAgreement}
            onChange={(e: any) => {
              setIsNeedAgreement(e.target.value)
            }}
          >
            <Radio value={true}>是</Radio>
            <Radio value={false}>否</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="organizationName" hidden={true}></Form.Item>
        <Form.Item name={["operationAuth", "operationAuthType"]} hidden={true}></Form.Item>
        <Form.Item
          name={["operationAuth", "operationId"]}
          label="运营方"
          rules={[{ required: true, message: "请选择" }]}
        >
          <Select
            placeholder="选择运营方"
            mode="multiple"
            style={{ width: 434 }}
            showSearch
            maxTagCount={6}
            onChange={(e) => {}}
            filterOption={filterOption}
            options={tissueAuthList}
          />
        </Form.Item>
        <Form.Item
          name="organizationId"
          label="委托办理方"
          rules={[{ required: form?.getFieldValue("isNeedAgreement"), message: "请选择" }]}
        >
          <Select
            placeholder="选择委托办理方"
            style={{ width: 434 }}
            showSearch
            onChange={(e, option) => {
              console.log(e, option, "eeeeeeee")

              form?.setFieldValue("organizationName", option?.label)
            }}
            filterOption={filterOption}
            options={tissueList}
          />
        </Form.Item>
        {form.getFieldValue("isNeedAgreement") ? (
          <>
            <Form.Item
              name="agreementTemplateId"
              label="选择委托协议"
              rules={[{ required: true, message: "请选择" }]}
            >
              <Select
                placeholder="选择委托协议"
                style={{ width: 434 }}
                onChange={(e) => {}}
                options={ContractList}
              />
            </Form.Item>
            <Form.Item
              name="agreementExampleFileId"
              label="上传委托协议示例"
              rules={[{ required: true, message: "请选择" }]}
            >
              <Upload
                maxCount={1}
                accept=".xls, .xlsx, .doc, .docx, .pdf"
                action={`${cross_env}/rscm/rscm-resource/oss/endpoint`}
                fileList={fileList}
                method="put"
                headers={{
                  Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem("operation_authorization") || "{}"
                  )?.access_token_op}`,
                }}
                onChange={({ fileList }) => {
                  setFileList(fileList)
                  // 全都完成了
                  if (!fileList.some((file) => file.status !== "done")) {
                    form.setFieldValue("agreementExampleFileId", fileList)
                  }
                }}
              >
                <Space>
                  <Button icon={<UploadOutlined />}>点击上传</Button>
                  <span className="shop-text">线下签署委托协议时，下载模版供客户使用</span>
                </Space>
              </Upload>
            </Form.Item>
          </>
        ) : null}
        <div style={{ display: "flex" }}>
          <Form.Item
            name="shopOperateTemplateId"
            label="选择店铺经营证明模版"
            rules={[{ required: true, message: "请选择" }]}
          >
            <Select
              placeholder="选择店铺经营证明模版"
              style={{ width: 434, marginRight: 10 }}
              onChange={(e) => {}}
              options={shopOperateList}
            />
          </Form.Item>
          <div style={{ color: "rgba(0, 0, 0, 0.45)", paddingTop: 3 }}>
            营业执照办理时，需要出具店铺经营证明
          </div>
        </div>
        <Form.Item
          name="applyLogoutTemplateId"
          label="选择注销申请模版"
          rules={[{ required: true, message: "请选择" }]}
        >
          <Select
            placeholder="选择注销申请模版"
            style={{ width: 434 }}
            onChange={(e) => {}}
            options={logoutList}
          />
        </Form.Item>
        <Form.Item
          name={["flowConfigs", "siteShowType"]}
          label="店铺地址生成规则"
          rules={[{ required: true, message: "请选择" }]}
        >
          <Radio.Group disabled={history?.id !== "null"}>
            {processList?.map((ie) => {
              return (
                <>
                  <Radio value={ie.value}>{ie.label}</Radio>
                </>
              )
            })}
          </Radio.Group>
        </Form.Item>
      </Card>

      <Card title="个体户注册信息配置" style={{ marginTop: 16 }}>
        <Row>
          <Col>
            <Form.Item<FieldType>
              label="邮编"
              name="postcode"
              rules={[
                {
                  required: true,
                  message: "请输入邮编编号",
                },
                {
                  validator: async (rule: any, value: string) =>
                    inputDefaultRules(value, "邮编编号", 6, false, true), //特殊字符
                },
              ]}
            >
              <Input placeholder="请输入邮编编号，编号为6个数字" style={{ width: "434px" }} />
            </Form.Item>
            <Form.Item<FieldType>
              name="supportAnonymous"
              label="个体户办理能否采用不核名"
              className="registration-bottom"
            >
              <Radio.Group
                defaultValue={supportAnonymousType}
                onChange={(e: any) => {
                  setSupportAnonymousType(e.target.value)
                }}
              >
                <Radio value={true}>允许不核名</Radio>
                <Radio value={false}>不允许不核名</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item<FieldType>
              name="businessScope"
              label="经营范围配置"
              className="registration-bottom"
              rules={[
                {
                  required: true,
                  message: "请输入经营范围配置",
                },
                {
                  validator: async (rule: any, value: string) =>
                    inputDefaultRules(value, "经营范围配置", 500), //特殊字符
                },
              ]}
            >
              <Input.TextArea placeholder="请输入经营范围配置" style={{ width: 434, height: 88 }} />
            </Form.Item>
            <Form.Item<FieldType>
              label="个体户名称前缀"
              className="registration-bottom"
              name="individualBusinessPrefix"
              rules={[
                {
                  required: true,
                  message: "请输入",
                },
                {
                  validator: async (rule: any, value: string) =>
                    inputDefaultRexChineseRules(value, "个体户名称前缀", 10, true),
                },
              ]}
            >
              <Input
                placeholder="请输入个体户名称前缀"
                style={{ width: "434px" }}
                showCount
                maxLength={10}
                onChange={(e) => {
                  setIndividualPrefix(e.target.value)
                }}
              />
            </Form.Item>
            <Form.Item<FieldType>
              label="个体户名称后缀"
              className="registration-bottom"
              name="individualBusinessSuffix"
              rules={[
                {
                  required: true,
                  message: "请输入",
                },
                {
                  validator: async (rule: any, value: string) =>
                    inputDefaultRexChineseRules(value, "个体户名称后缀", 10, true),
                },
              ]}
            >
              <Input
                placeholder="请输入个体户名称后缀"
                style={{ width: "434px" }}
                showCount
                maxLength={10}
                onChange={(e) => {
                  setIndividualSuffix(e.target.value)
                }}
              />
            </Form.Item>
            <Form.Item
              label="预览企业/个体户名称"
              name="name4"
              style={{ width: 655 }}
              className="registration-bottom"
            >
              <Input
                addonBefore={individualPrefix}
                addonAfter={individualSuffix}
                disabled={true}
                defaultValue="企业名称"
              />
            </Form.Item>
            <Form.Item<FieldType> label="额外上传文件配置" name="extraFileConfigs"></Form.Item>
            <Form.Item<FieldType> label=" ">
              <Form.List name={["extraFileConfigs"]}>
                {(subFields, subOpt) => (
                  <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
                    {subFields.map((subField) => (
                      <Space key={subField.key}>
                        <Form.Item
                          name={[subField.name, "fileType"]}
                          label=""
                          rules={[{ required: true, message: "请选择" }]}
                        >
                          <Select
                            placeholder="选择文件类型"
                            style={{ width: 150 }}
                            onChange={(e) => {
                              console.log(e)
                            }}
                            options={taxRegistrationStatusEnum}
                          />
                        </Form.Item>
                        <Form.Item
                          name={[subField.name, "allowEmpty"]}
                          label=""
                          rules={[{ required: true, message: "请选择" }]}
                        >
                          <Select
                            placeholder="是否必填"
                            style={{ width: 150 }}
                            onChange={(e) => {
                              console.log(e)
                            }}
                            options={requiredEnum}
                          />
                        </Form.Item>
                        <Form.Item
                          name={[subField.name, "fileNumbers"]}
                          label=""
                          rules={[
                            { required: true, message: "请输入" },
                            {
                              validator: async (rule: any, value: string) =>
                                inputDefaultRules(value, "上传数量", 50, false, true), //特殊字符
                            },
                          ]}
                        >
                          <InputNumber
                            style={{ width: 150 }}
                            placeholder="上传数量"
                            max={10}
                            min={1}
                            step={1}
                          />
                        </Form.Item>
                        <Form.Item
                          name={[subField.name, "fileRequire"]}
                          rules={[
                            { required: true, message: "请输入" },
                            {
                              validator: async (rule: any, value: string) =>
                                inputDefaultRules(value, "要求说明", 50), //特殊字符
                            },
                          ]}
                        >
                          <Input style={{ width: 150 }} placeholder="要求说明，最多50个字" />
                        </Form.Item>
                        <Form.Item
                          name={[subField.name, "fileName"]}
                          rules={[
                            { required: true, message: "请输入" },
                            {
                              validator: async (rule: any, value: string) =>
                                inputDefaultRules(value, "文件名称", 10), //特殊字符
                            },
                          ]}
                        >
                          <Input style={{ width: 150 }} placeholder="文件名称" />
                        </Form.Item>
                        <div style={{ marginTop: "-10px" }}>
                          <MinusCircleOutlined
                            onClick={() => {
                              subOpt.remove(subField.name)
                            }}
                          />
                        </div>
                      </Space>
                    ))}
                    <Button
                      type="dashed"
                      onClick={() => subOpt.add()}
                      block
                      style={{
                        maxWidth: 646,
                        color: "rgba(24, 144, 255, 1)",
                        height: 40,
                        display: subFields.length >= 10 ? "none" : "",
                      }}
                    >
                      + 新增配置
                    </Button>
                  </div>
                )}
              </Form.List>
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Card title="移动端文案提示" style={{ marginTop: 16 }}>
        <Form.Item<FieldType>
          name="handlingPrompt"
          className="registration-bottom"
          label="移动端提交办理申请后提示"
          rules={[
            {
              required: true,
              message: "请输入移动端提交办理申请后提示",
            },
            {
              validator: async (rule: any, value: string) =>
                inputDefaultRules(value, "移动端提交办理申请后提示", 50), //特殊字符
            },
          ]}
        >
          <Input.TextArea placeholder="请输入" style={{ width: 434, height: 88 }} />
        </Form.Item>
        <Form.Item<FieldType>
          name="completedPrompt"
          className="registration-bottom"
          label="移动端营业执照办理成功后提示"
          rules={[
            {
              required: true,
              message: "请输入移动端营业执照办理成功后提示",
            },
            {
              validator: async (rule: any, value: string) =>
                inputDefaultRules(value, "移动端营业执照办理成功后提示", 50), //特殊字符
            },
          ]}
        >
          <Input.TextArea placeholder="请输入" style={{ width: 434, height: 88 }} />
        </Form.Item>
        <Form.Item<FieldType>
          name="registeredPrompt"
          className="registration-bottom"
          label="移动端税务登记成功后提示"
          rules={[
            {
              required: true,
              message: "请输入移动端税务登记成功后提示",
            },
            {
              validator: async (rule: any, value: string) =>
                inputDefaultRules(value, "移动端税务登记成功后提示", 50), //特殊字符
            },
          ]}
        >
          <Input.TextArea placeholder="请输入" style={{ width: 434, height: 88 }} />
        </Form.Item>
        <Form.Item<FieldType>
          name="checkCompletePrompt"
          className="registration-bottom"
          label="移动端审核成功后提示"
          rules={[
            {
              required: true,
              message: "请输入移动端审核成功后提示",
            },
            {
              validator: async (rule: any, value: string) =>
                inputDefaultRules(value, "移动端审核成功后提示", 50), //特殊字符
            },
          ]}
        >
          <Input.TextArea placeholder="请输入" style={{ width: 434, height: 88 }} />
        </Form.Item>
      </Card>
      <Card title="个体户代办企业微信通知配置" style={{ marginTop: 16 }}>
        <div>
          <span>提交办理申请后</span>
          <Form.Item<FieldType>
            name="submitUrl"
            className="registration-bottom"
            label="机器人webhookurl"
            rules={[
              {
                required: false,
                message: "请输入",
              },
            ]}
          >
            <Input placeholder="请输入" style={{ width: 434 }} />
          </Form.Item>
          <Form.Item<FieldType>
            name="submitContent"
            className="registration-bottom"
            label="文案内容"
            rules={[
              {
                required: false,
                message: "请输入",
              },
              {
                validator: async (rule: any, value: string) =>
                  inputDefaultRules(value, "提交办理申请后", 500), //特殊字符
              },
            ]}
          >
            <Input.TextArea placeholder="请输入" style={{ width: 434, height: 88 }} />
          </Form.Item>
        </div>
        <div>
          <span>审核成功后</span>
          <Form.Item<FieldType>
            name="checkUrl"
            className="registration-bottom"
            label="机器人webhookurl"
            rules={[
              {
                required: false,
                message: "请输入",
              },
            ]}
          >
            <Input placeholder="请输入" style={{ width: 434 }} />
          </Form.Item>
          <Form.Item<FieldType>
            name="checkContent"
            className="registration-bottom"
            label="文案内容"
            rules={[
              {
                required: false,
                message: "请输入",
              },
              {
                validator: async (rule: any, value: string) =>
                  inputDefaultRules(value, "审核成功后", 500), //特殊字符
              },
            ]}
          >
            <Input.TextArea placeholder="请输入" style={{ width: 434, height: 88 }} />
          </Form.Item>
        </div>
        <div>
          <span>营业执照办理成功后</span>
          <Form.Item<FieldType>
            name="licenseUrl"
            className="registration-bottom"
            label="机器人webhookurl"
            rules={[
              {
                required: false,
                message: "请输入",
              },
            ]}
          >
            <Input placeholder="请输入" style={{ width: 434 }} />
          </Form.Item>
          <Form.Item<FieldType>
            name="licenseContent"
            className="registration-bottom"
            label="文案内容"
            rules={[
              {
                required: false,
                message: "请输入",
              },
              {
                validator: async (rule: any, value: string) =>
                  inputDefaultRules(value, "营业执照办理成功后", 500), //特殊字符
              },
            ]}
          >
            <Input.TextArea placeholder="请输入" style={{ width: 434, height: 88 }} />
          </Form.Item>
        </div>
        <div>
          <span>通知办税人清税</span>
          <Form.Item<FieldType>
            name="clearanceUrl"
            className="registration-bottom"
            label="机器人webhookurl"
            rules={[
              {
                required: false,
                message: "请输入",
              },
            ]}
          >
            <Input placeholder="请输入" style={{ width: 434 }} />
          </Form.Item>
          <Form.Item<FieldType>
            name="clearanceContent"
            className="registration-bottom"
            label="文案内容"
            rules={[
              {
                required: false,
                message: "请输入",
              },
              {
                validator: async (rule: any, value: string) =>
                  inputDefaultRules(value, "通知办税人清税", 500), //特殊字符
              },
            ]}
          >
            <Input.TextArea placeholder="请输入" style={{ width: 434, height: 88 }} />
          </Form.Item>
        </div>
        <div>
          <span>通知营业执照办理人注销</span>
          <Form.Item<FieldType>
            name="logoutUrl"
            className="registration-bottom"
            label="机器人webhookurl"
            rules={[
              {
                required: false,
                message: "请输入",
              },
            ]}
          >
            <Input placeholder="请输入" style={{ width: 434 }} />
          </Form.Item>
          <Form.Item<FieldType>
            name="logoutContent"
            className="registration-bottom"
            label="文案内容"
            rules={[
              {
                required: false,
                message: "请输入",
              },
              {
                validator: async (rule: any, value: string) =>
                  inputDefaultRules(value, "通知营业执照办理人注销", 500), //特殊字符
              },
            ]}
          >
            <Input.TextArea placeholder="请输入" style={{ width: 434, height: 88 }} />
          </Form.Item>
        </div>
      </Card>
      <Card title="个体户代办运营方标识" style={{ marginTop: 16, marginBottom: 50 }}>
        <Form.Item
          name="dualPrefix"
          label="双录模版标识"
          rules={[{ required: false, message: "请选择" }]}
        >
          <Select
            placeholder="请输入"
            style={{ width: 434 }}
            onChange={(e) => {}}
            options={shopSearchList}
          />
        </Form.Item>
      </Card>
    </div>
  )
}

export default Registration
