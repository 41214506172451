import http from "@/services/serviceOp"
const utilOp = http.getInstance()
// 假设这是你的API调用函数，返回一个Promise
async function fetchDataFromApi(url: string) {
  // 这里替换成实际的API调用，比如使用fetch、axios等
  // 为了演示，这里使用Promise.resolve模拟成功响应
  const res = await utilOp.httpGet(url, {}, { headers: { noLoading: true } })
  return res?.data
}

// 判断函数，根据返回数据判断是否满足停止轮询的条件
function shouldStopPolling(data: any) {
  // 修改这里的条件以匹配你期望的停止条件
  // 示例：当数据中包含"完成"时停止
  return data?.content
}

// 控制是否继续轮询的标志
let continuePolling = true

// 手动结束轮询的函数
export function stopPolling() {
  continuePolling = false
}
// 手动开始轮询的函数
export function startPolling() {
  continuePolling = true
}
// 主函数，定期调用API直到满足shouldStopPolling条件
export async function pollUntilConditionMet(url: string, interval = 2000, maxAttempts = Infinity) {
  let attempts = 0
  // eslint-disable-next-line no-constant-condition
  while (continuePolling) {
    try {
      const data: any = await fetchDataFromApi(url)
      console.log("当前API响应:", data)
      if (shouldStopPolling(data)) {
        console.log("已满足条件，停止轮询。")
        return data
      }
    } catch (error) {
      console.error("调用API时出错:", error)
      // 根据实际情况决定是否继续或中断循环
    }

    attempts++
    if (attempts >= maxAttempts) {
      console.warn("达到最大尝试次数，停止轮询。")
      break
    }

    // 等待指定时间后再次尝试
    await new Promise((resolve) => setTimeout(resolve, interval))
  }
}

// 文件流导出
export async function download(downloadUrl: string, downloadFileName: string) {
  const blob = await getBlob(downloadUrl)
  saveAs(blob, downloadFileName)
}

function getBlob(url: string) {
  return new Promise<Blob>((resolve, reject) => {
    const xhr = new XMLHttpRequest()

    xhr.open("GET", url, true)
    xhr.responseType = "blob"
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response)
      } else {
        reject(new Error(`Request failed with status ${xhr.status}`))
      }
    }
    xhr.onerror = () => {
      reject(new Error("Request failed"))
    }

    xhr.send()
  })
}

function saveAs(blob: Blob, filename: string) {
  const link = document.createElement("a")
  const body = document.body

  link.href = window.URL.createObjectURL(blob)
  link.download = filename

  // hide the link
  link.style.display = "none"
  body.appendChild(link)

  link.click()
  body.removeChild(link)

  window.URL.revokeObjectURL(link.href)
}
