import { OP_TRADE } from "../../../api/config/servicePort"
import UtilOp from "../../../services/serviceOp"

const utilOp = UtilOp.getInstance()

// 同步新再生网订单
export const syncOrder = (values?: any) => {
  return utilOp.httpPost(OP_TRADE + "/orders/sync-order", values).then((res) => res)
}

export const getOrderStatus = (values?: any) => {
  return utilOp.httpGet(OP_TRADE + "/orders/sync-order", values).then((res) => res)
}
