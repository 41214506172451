import Util from "../../../../services/util"
import axios from "axios"
import UtilOp from "../../../../services/serviceOp"
import { OP_USER } from "../../../../api/config/servicePort"

const util = Util.getInstance()
const utilOp = UtilOp.getInstance()

// 品类数
interface SEARCHCATEGORY {
  keyword?: string | null
}
export const getCategoryTree = (params?: SEARCHCATEGORY) => {
  return util.httpGet("/sk-trade/categories/tree/search", params)
}

// 创建品类
interface ADDCATEGORY {
  name?: string | undefined
  ADDCATEGORY?: string | number
  descText?: string
  descPhoto?: string
  forTrade?: string | boolean
  forPriceIndex?: string | boolean
}
export const addCategoryTree = (params: ADDCATEGORY) => {
  return util.httpPost("/sk-trade/categories", params)
}

// 删除品类
export const deleteCategory = (id: string) => {
  return util.httpDeletel(`/sk-trade/categories/${id}`)
}

// 编辑品类
export const editCategory = (id: string, params: ADDCATEGORY) => {
  return util.httpPut(`/sk-trade/categories/${id}`, params)
}
//查询功能点
export const getPlatOperList = (applicationId, params?: any) => {
  return utilOp.httpGet(OP_USER + `/applications/${applicationId}/functions`, params)
}
//创建功能点
export const getAddPlatOperList = (params: any) => {
  return utilOp.httpPost(OP_USER + `/applications/${params.applicationId}/functions`, params)
}
//修改功能点
export const getEditPlatOperList = (id: string, params: any) => {
  return utilOp.httpPut(OP_USER + `/applications/${params.applicationId}/functions/${id}`, params)
}
//删除功能点
export const getDelePlatOperList = (params: any) => {
  return utilOp.httpDeletel(
    OP_USER + `/applications/${params.applicationId}/functions/${params.id}`
  )
}
//功能点详情
export const getDelePlatOperDatil = (params: any) => {
  return utilOp.httpDeletel(
    OP_USER + `/applications/${params.applicationId}/functions/${params.id}`
  )
}
//查询组件树
export const getPlatComponentsListTree = (params?: any) => {
  return utilOp.httpGet(OP_USER + `/applications/${params.id}/components/tree`, params)
}
//查询组件
export const getPlatComponentsList = (params?: any) => {
  return utilOp.httpGet(OP_USER + `/applications/${params.id}/components`, params)
}
//创建组件
export const getAddPlatComponentsList = (params: any, applicationId: string) => {
  return utilOp.httpPost(OP_USER + `/applications/${applicationId}/components`, params)
}
//组件详情
export const getEditPlatComponentsDetil = (params: any) => {
  return utilOp.httpGet(OP_USER + `/applications/${params.applicationId}/components/${params.id}`)
}
//修改组件
export const getEditPlatComponentsList = (id: string, params: any) => {
  return utilOp.httpPut(OP_USER + `/applications/${params.applicationId}/components/${id}`, params)
}
//删除组件
export const getDelePlatComponentsList = (params: any) => {
  return utilOp.httpDeletel(
    OP_USER + `/applications/${params.applicationId}/components/${params.id}`
  )
}
// //查询角色
export const getPlatCharactersList = (id: string, params?: any) => {
  return utilOp.httpGet(OP_USER + `/applications/${id}/roles`, params)
}
// //mock查询角色
// export const getPlatCharactersList = (params?: any) => {
//   return axios.get("http://localhost:8080/plat-oper-roles", params)
// }
//创建角色
export const getAddPlatCharactersList = (params: any) => {
  return utilOp.httpPost(OP_USER + `/applications/${params.applicationId}/roles`, params)
}
//角色详情
export const getDelePlatCharactersDetail = (params: any) => {
  return utilOp.httpGet(OP_USER + `/applications/${params.applicationId}/roles/${params.id}`)
}
//修改角色
export const getEditPlatCharactersList = (id: string, params: any) => {
  return utilOp.httpPut(OP_USER + `/applications/${params.applicationId}/roles/${id}`, params)
}
//删除角色
export const getDelePlatCharactersList = (params: any) => {
  return utilOp.httpDeletel(OP_USER + `/applications/${params.applicationId}/roles/${params.id}`)
}

// //查询角色
export const getApplicationsDetail = (id?: any) => {
  return utilOp.httpGet(OP_USER + `/applications/${id}`)
}
