/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { Button, Form, message, Modal, Col, Radio, Select, Row } from "antd"
import React, { memo, useEffect, useMemo, useState } from "react"
import { getTasksTaxpayer } from "../promises"
import { DeclarationDescEnum } from "../constant"
import { getTissueInfo } from "../promises"
import "./index.scss"

export const MakeOutAnInvoice = (props: any) => {
  const { data, getTaxMin, editTissueInfoService } = props

  const [taxationForm] = Form.useForm()
  const [taxationOpen, setTaxationOpen] = useState<any>(false) //税务登记

  useEffect(() => {
    if (data) {
      taxationForm.setFieldsValue({
        declarationCycle: data?.declarationCycle ? data?.declarationCycle : "MONTH",
        invoiceClerk: Array.isArray(data?.invoiceClerk)
          ? data?.invoiceClerk[0]
          : data?.invoiceClerk
          ? data?.invoiceClerk
          : getTaxMin && getTaxMin[0],
      })
    }
  }, [data])
  const handLiberal = () => {
    if (data?.taxRegistrationStatus == "UNREGISTERED") {
      message.warning("请先完成税务登记")
      return
    } else {
      setTaxationOpen(true)
    }
  }

  //完成税务登记
  const onTaxManFinish = () => {
    let values = taxationForm.getFieldsValue()
    let _invoiceClerk =
      typeof values.invoiceClerk == "string" ? values?.invoiceClerk : values?.invoiceClerk?.value
    let params: any = {
      taxpayerName: data.name,
      taxpayerNo: data.idNo,
      taxFilingPeriod: values.declarationCycle,
      taxClerk: _invoiceClerk,
    }
    taxationForm
      .validateFields()
      .then(async (res) => {
        const resultData: any = await getTasksTaxpayer(params)
        if (resultData.code == 200) {
          message.success("申请成功")
          editTissueInfoService.run({
            values: { ...data, ...values, invoiceClerk: [_invoiceClerk] },
            id: data.id,
          })
          setTaxationOpen(false)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const handForm = useMemo(() => {
    return (
      <>
        <Form
          form={taxationForm}
          className="details-form"
          // initialValues={{
          //   declarationCycle: infoList?.declarationCycle ? infoList?.declarationCycle : "MONTH",
          //   invoiceClerk: Array.isArray(infoList?.invoiceClerk)
          //     ? infoList?.invoiceClerk[0]
          //     : infoList?.invoiceClerk,
          // }}
        >
          <Col>
            <Form.Item name="declarationCycle" label="申报周期" rules={[{ required: true }]}>
              <Radio.Group>
                <Radio value="MONTH"> 按月申报 </Radio>
                <Radio value="QUARTER"> 按季度申报 </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="invoiceClerk"
              label="办税人/开票员"
              rules={[{ required: true, message: "请选择" }]}
            >
              <Select
                // defaultValue={data?.invoiceClerk ? data?.invoiceClerk : getTaxMin && getTaxMin[0]}
                placeholder="请选择"
                style={{ width: 320 }}
                onChange={(e) => {
                  console.log(e)
                }}
                options={getTaxMin}
              />
            </Form.Item>
          </Col>
        </Form>
      </>
    )
  }, [getTaxMin])
  return (
    <>
      <div className="info_bank">
        <Row className="onlyRead_left">
          <Col className="only_read">开票行名称：</Col>
          <Col>{data?.invoiceBankName}</Col>
        </Row>
        <Row className="onlyRead_left">
          <Col className="only_read">开票行账户：</Col>
          <Col>{data?.invoiceBankAccount}</Col>
        </Row>
        <Row className="onlyRead_left">
          <Col className="only_read">开票行预留手机号：</Col>
          <Col>{data?.invoicePhone}</Col>
        </Row>
      </div>
      <div className="info_bank bank_top">
        <Row className="onlyRead_left">
          <Col className="only_read">开票地址：</Col>
          <Col>{data?.invoiceAddress}</Col>
        </Row>
        {data?.type == "ENTERPRISE" || data?.type == "INDIVIDUAL_BUSINESS" ? (
          <Row className="onlyRead_left">
            <Col className="only_read">开票渠道：{DeclarationDescEnum[data?.applyStatusEnum]}</Col>
            {data.applyStatusEnum == "UN_OPEN" ? (
              <a onClick={handLiberal} style={{ paddingLeft: 10 }}>
                开通
              </a>
            ) : null}
          </Row>
        ) : null}
        <Row className="onlyRead_left">
          <Col className="only_read"></Col>
          <Col></Col>
        </Row>
      </div>
      {/* 完成税务登记 */}
      <Modal
        title="开票渠道开通"
        open={taxationOpen}
        footer={null}
        width={600}
        onCancel={() => {
          // taxationForm.resetFields()
          setTaxationOpen(false)
        }}
      >
        {handForm}
        <div style={{ width: "100%", borderTop: "1px solid #f0f0f0" }}>
          <div className="check-modal">
            <i></i>
            <div style={{ marginBottom: 10 }}>
              <Button
                className="check-modal-button"
                onClick={() => {
                  // taxationForm.resetFields()
                  setTaxationOpen(false)
                }}
              >
                取消
              </Button>
              <Button type="primary" onClick={onTaxManFinish}>
                完成
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default MakeOutAnInvoice
