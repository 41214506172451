const withAllowClear = {
  allowClear: true,
}
export default {
  type: "object",
  labelWidth: 106,
  properties: {
    taskStatus: {
      title: "状态",
      type: "string",
      widget: "select",
      enum: ["PENDING", "COMPLETED"],
      enumNames: ["待处理", "已完成"],
      placeholder: "请选择",
      props: withAllowClear,
    },
    createTime: {
      bind: ["createTimeBegin", "createTimeEnd"],
      title: "创建时间",
      type: "range",
      format: "date",
      props: withAllowClear,
    },
    completeTime: {
      bind: ["completeTimeBegin", "completeTimeEnd"],
      title: "完成时间",
      type: "range",
      format: "date",
      props: withAllowClear,
    },
    taxClerk: {
      title: "办税人",
      type: "string",
      widget: "XZInput",
      placeholder: "请输入",
      props: withAllowClear,
    },
    query: {
      title: "关键字搜索",
      type: "string",
      widget: "input",
      placeholder: "请输入任务编号、开票方、税号",
      props: withAllowClear,
    },
  },
}
