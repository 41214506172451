/* eslint-disable react/display-name */
import { Card } from "antd"
import React, { memo, useState, useEffect, useMemo } from "react"
import ProDescriptions, { ProDescriptionsItemProps } from "@ant-design/pro-descriptions"
import { getTissueCityTreeList } from "../../../service"
import "./index.scss"
export const BankInfo = memo((props: any) => {
  const { list, registrationList } = props
  const [detailList, setDetailList] = useState<any>({})

  const flatArray = (list: any) => {
    let _list: any = []
    for (let i in list) {
      _list.push(list[i])
      if (list[i]?.children?.length) {
        _list.push(...flatArray(list[i]?.children))
      }
    }
    return _list
  }

  const init = async () => {
    if (JSON.stringify(list) !== "{}") {
      const res = await getTissueCityTreeList({ maxDeep: 2 })
      const bankInfo: any = list.bankInfoDto
      if (res.code == 200) {
        let cityList = flatArray(res.data)
        let nameArr: any = []
        for (let i in cityList) {
          if (bankInfo?.provinceCode?.includes(String(cityList[i].code))) {
            nameArr.push(cityList[i].name)
          }
        }
        if (nameArr?.length) bankInfo.area = nameArr?.join("-")
      }

      setDetailList({
        ...bankInfo,
        ...list,
      })
    }
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list])
  const headerBankDescColumns: ProDescriptionsItemProps<Tissue.Info>[] = [
    {
      title: "银行卡号",
      key: "bankAccount",
      dataIndex: "bankAccount",
      copyable: true,
    },
    {
      title: "开户行名称",
      key: "bankName",
      dataIndex: "bankName",
      copyable: true,
    },
    {
      title: "开户支行地址",
      key: "area",
      dataIndex: "area",
      copyable: true,
    },
    {
      title: "支行名称",
      key: "bankBranchName",
      dataIndex: "bankBranchName",
      copyable: true,
    },
    {
      title: "开户行行号",
      key: "bankBranchCode",
      dataIndex: "bankBranchCode",
      copyable: true,
    },
    {
      title: "银行预留手机号",
      key: "phone",
      dataIndex: "phone",
    },
  ]
  const headerSupplyDescColumns: ProDescriptionsItemProps<Tissue.Info>[] = [
    {
      title: "客户",
      key: "linkCustomerName",
      dataIndex: "linkCustomerName",
      copyable: true,
    },
  ]
  return (
    <>
      <Card title="银行信息" className="BankInfo">
        <ProDescriptions
          column={4}
          columns={headerBankDescColumns}
          dataSource={detailList}
        ></ProDescriptions>
      </Card>
      <Card title="关联客户" className="RelatedCustomers">
        <ProDescriptions
          column={4}
          columns={headerSupplyDescColumns}
          dataSource={detailList}
        ></ProDescriptions>
      </Card>
    </>
  )
})

export default BankInfo
