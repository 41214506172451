//驳回原因
export const TurnDownColumns: any = [
  {
    title: "驳回时间",
    dataIndex: "createTime",
  },
  {
    title: "审核人",
    dataIndex: "operationUserName",
  },
  {
    title: "驳回记录",
    dataIndex: "operationDesc",
  },
]
export const OrgTypesEnum = [
  { label: "企业", value: "ENTERPRISE" },
  { label: "个人", value: "INDIVIDUAL" },
  { label: "个体工商户", value: "INDIVIDUAL_BUSINESS" },
  { label: "其他", value: "OTHER" },
]
export const OrgTypeStatusList = {
  false: "禁用",
  true: "正常",
}
export const OrgTypeOutStatusList = {
  CANCEL_APPLY: "申请注销",
  CANCEL_COMPLETE: "已注销",
}
