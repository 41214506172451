import React, { memo, useContext } from "react"
import { OrderDetailContext } from "../../useOrderDetail"
import { Table } from "antd"
import { formatAmount } from "../../../../../../../utils/utils"
const defaultProps = {}
type props = {}
export type PurchasePlanProps = Required<typeof defaultProps> &
  props &
  React.HTMLAttributes<HTMLDivElement>
export const PurchasePlan: React.FC<React.PropsWithChildren<PurchasePlanProps>> = memo((props) => {
  const context = useContext(OrderDetailContext)
  return (
    <>
      <Table
        // @ts-ignore
        dataSource={
          context?.logisticsDetailService.data?.plans
            ? context?.logisticsDetailService.data?.plans
            : []
        }
        columns={[
          { title: "买方", dataIndex: "buyerName" },
          { title: "卖方", dataIndex: "sellerName" },
          { title: "单价", dataIndex: "unitPrice", render: formatAmount },
          { title: "供货协议", dataIndex: "agreementNames" },
          { title: "采购计划", dataIndex: "logisticNo" },
          { title: "源采购计划", dataIndex: "parentLogisticNo" },
        ]}
      ></Table>
    </>
  )
})
PurchasePlan.displayName = "物流关联采购计划"
PurchasePlan.defaultProps = defaultProps
