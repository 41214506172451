export default [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "发票项目名称",
    dataIndex: "itemName",
  },
  {
    title: "税收分类编码",
    dataIndex: "taxCategoryCode",
  },
]
