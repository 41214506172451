/* eslint-disable react/display-name */
import { Card } from "antd"
import React, { memo, useState, useEffect } from "react"
import TableCom from "@src/components/TableCom"

export const OperationLog = memo((props: any) => {
  const { list } = props
  const contractColumns = [
    {
      title: "操作时间",
      dataIndex: "createTime",
    },
    {
      title: "操作人",
      dataIndex: "operationUserName",
    },
    {
      title: "操作内容",
      dataIndex: "operationType",
    },
    {
      title: "详情",
      dataIndex: "operationDesc",
    },
  ]
  return (
    <Card title="操作日志" className="DetailsInformation">
      <TableCom
        columns={contractColumns}
        dataSource={list?.operationLogVos}
        total={0}
        pagination={false}
      ></TableCom>
    </Card>
  )
})

export default OperationLog
