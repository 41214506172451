import React from "react"
import { Badge } from "antd"
import { checkStatusEnumStatus } from "../constant"
import Decimal from "decimal.js"

export default [
  {
    title: "融资单号",
    dataIndex: "id",
    key: "id",
    fixed: "left",
  },
  {
    title: "融资产品",
    dataIndex: "financeProductName",
    key: "financeProductName",
  },
  {
    title: "融资公司",
    dataIndex: "financeCompany",
    key: "financeCompany",
  },
  {
    title: "借款人",
    dataIndex: "custName",
    key: "custName",
  },
  {
    title: "核心企业",
    dataIndex: "companyName",
    key: "companyName",
  },
  {
    title: "利率",
    dataIndex: "appRate",
    key: "appRate",
    render: (value) => {
      return value ? Number(new Decimal(value).mul(new Decimal("100"))) + "%" : "-"
    },
  },
  {
    title: "借款周期",
    dataIndex: "loanTerm",
    key: "loanTerm",
  },
  {
    title: "本金",
    dataIndex: "appAmt",
    key: "appAmt",
  },
  {
    title: "利息",
    dataIndex: "loanInterest",
    key: "loanInterest",
  },
  // {
  //   title: "本息总额",
  //   dataIndex: "phone",
  //   key: "phone",
  // },
  // {
  //   title: "还款模式",
  //   dataIndex: "phone",
  //   key: "phone",
  // },
  // {
  //   title: "罚息",
  //   dataIndex: "phone",
  //   key: "phone",
  // },
  // {
  //   title: "已还金额",
  //   dataIndex: "phone",
  //   key: "phone",
  // },
  // {
  //   title: "剩余应还",
  //   dataIndex: "phone",
  //   key: "phone",
  // },
  {
    title: "状态",
    dataIndex: "financeStatus",
    key: "financeStatus",
    render: (value) => <Badge {...checkStatusEnumStatus[value]} />,
  },
  {
    title: "备注",
    dataIndex: "remark",
    key: "remark",
    render: (value) => {
      return value ? value : "-"
    },
  },
  {
    title: "申请时间",
    dataIndex: "createTime",
    key: "createTime",
  },
  {
    title: "放款时间",
    dataIndex: "tranDate",
    key: "tranDate",
  },
  // {
  //   title: "还款时间",
  //   dataIndex: "phone",
  //   key: "phone",
  // },
  {
    title: "关联订单号",
    dataIndex: "orderId",
    key: "orderId",
    fixed: "right",
  },
]
