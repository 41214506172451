import { useRequest } from "ahooks"
import { Modal, Result, Spin, message } from "antd"
import QRCode from "qrcode.react"
import React, { useEffect, useRef, useState } from "react"
import QuanDianModalLogin from "../QuanDian"
import "../index.scss"
import { getHangXinQrCode, querySecondaryCodeStatus } from "../promise"

interface Props {
  onCancel: () => void
  visible: boolean
  invoiceInfo: any
  nsrsbh: string
}

const SecondaryAuthentication: React.FC<Props> = (props) => {
  const { visible, onCancel, invoiceInfo, nsrsbh } = props
  const [src, setSrc] = useState<string>("")
  const [visibleQuanDianModalLogin, setVisibleQuanDianModalLogin] = useState<boolean>(false)
  // 使用 useRequest 创建 HandleSecondaryCode
  const {
    data: handleSecondaryCodeData,
    loading: handleSecondaryCodeLoading,
    run: handleSecondaryCodeRun,
  } = useRequest(getHangXinQrCode, {
    manual: true,
    onSuccess: async (data) => {
      setSrc(data?.data?.ewm)
    },
  })
  // 使用 useRef 保存 handleSecondaryCodeRun 的引用
  const handleSecondaryCodeRef = useRef(handleSecondaryCodeRun)
  useEffect(() => {
    // 调用 ref.current 来访问 handleSecondaryCodeRun
    handleSecondaryCodeRef.current({
      taxNo: invoiceInfo?.taxNo,
      taxUserName: invoiceInfo?.username,
      channel: "HX",
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceInfo?.taxNo, invoiceInfo?.username])
  // 判断是否扫码成功
  const HandleSecondaryCodeStatus = useRequest(querySecondaryCodeStatus, {
    manual: true,
    onSuccess: (data) => {
      console.log(data, "data----")

      if (data?.acceptState == 1) {
        message.warning("人脸核验失败，请重新扫码")
        handleSecondaryCodeRef.current({
          taxNo: invoiceInfo?.taxNo,
          taxUserName: invoiceInfo?.username,
          channel: "HX",
        })
      } else if (data?.acceptState == 2) {
        message.success("人脸核验成功，请点击开票")
        onCancel()
        return true
      } else if (data?.acceptState == 3) {
        message.error("人脸核验失败，请重新扫码")
        handleSecondaryCodeRef.current({
          taxNo: invoiceInfo?.taxNo,
          taxUserName: invoiceInfo?.username,
          channel: "HX",
        })
      }
    },
  })
  const handleOk = () => {
    HandleSecondaryCodeStatus.run({
      taxNo: nsrsbh,
      authId: handleSecondaryCodeData?.data?.authId,
    })
  }
  console.log(handleSecondaryCodeData, "handleSecondaryCodeData")
  return (
    <>
      <Modal
        title={"请打开电子税务局app，并扫描二维码"}
        visible={visible}
        onOk={handleOk}
        onCancel={() => onCancel()}
        className="quanDianModal"
        bodyStyle={{ textAlign: "center" }}
      >
        <Spin spinning={handleSecondaryCodeLoading}>
          {handleSecondaryCodeData?.data?.qrCode ? (
            <QRCode
              id="qrCode"
              value={handleSecondaryCodeData?.data?.qrCode}
              size={200} // 二维码的大小
              fgColor="#000000" // 二维码的颜色
              style={{ margin: "auto" }}
            />
          ) : (
            <Result status="warning" title={"出错了"} />
          )}
        </Spin>
      </Modal>
      {visibleQuanDianModalLogin && (
        <QuanDianModalLogin
          visible={visibleQuanDianModalLogin}
          onCancel={() => setVisibleQuanDianModalLogin(false)}
        />
      )}
    </>
  )
}
export default SecondaryAuthentication
