/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { load } from "@amap/amap-jsapi-loader"
import "./index.scss"
import { Helmet } from "react-helmet"

const CustomMap = ({ markersData }) => {
  const [mapData, setMap] = useState<any>(null)

  useEffect(() => {
    load({
      key: "d5cae8631e7df2a26af307aa94cf70bb",
      version: "1.4.15",
      plugins: [],
    }).then((AMap) => {
      const mapInstance = new AMap.Map("container", {
        resizeEnable: true,
        // backgroundColor: "#f0f0f0", // 设置地图背景颜色
        zoom: 6.4,
        center: [114.521, 38.0481],
        mapStyle: "amap://styles/b9e6ba0f286a722dcb426d8c32c1a940", // 设置地图主题颜色为深蓝色
      })
      setMap(mapInstance)
    })
  }, [])

  useEffect(() => {
    if (mapData) {
      // 清除之前的标记
      mapData.clearMap()
      markersData?.forEach((camera) => {
        console.log(camera, "camera")
        const marker = new AMap.Marker({
          position: [camera.longitude, camera.latitude],
          title: camera.city,
          map: mapData,
          content: camera.onLine
            ? '<div class="marker-online"></div>'
            : '<div class="marker-off"></div>',
        })

        // 添加点击事件，可以添加一些自定义操作
        marker.on("click", () => {
          console.log(`点击了摄像头 ${camera.city}`)
        })
      })
    }
  }, [mapData])

  return (
    <>
      <div
        id="container"
        className="map-container"
        style={{ width: "92%", height: "520px", marginLeft: 14, marginTop: 50 }}
      >
        <div className="map-charts">
          <div className="map-charts-online">
            <div className="marker-online"></div> <span>在线</span>
          </div>
          <div className="map-charts-off">
            <div className="marker-off"></div> <span>离线</span>
          </div>
        </div>
      </div>
      <Helmet>
        <script>
          {`
            window._AMapSecurityConfig = {
              securityJsCode: 'c619200d969af31f75fd611201ffe297'
            };
          `}
        </script>
      </Helmet>
    </>
  )
}

export default CustomMap
