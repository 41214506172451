/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useMemo } from "react"
import { Button, Input, Table, Modal, Form, Tree, Popconfirm, Tag, message } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import {
  getPlatCharactersList,
  getAddPlatCharactersList,
  getEditPlatCharactersList,
  getDelePlatCharactersList,
  getPlatComponentsListTree,
  getDelePlatCharactersDetail,
} from "../promises"
import { useParams } from "react-router-dom"
import SearchForm from "@src/components/FormItem"
import type { DataNode, TreeProps } from "antd/es/tree"
import { treeData } from "../../constant"
import Access from "@src/components/Access"
import { AccessEnum } from "@src/contents/accessEnum"
import "./index.scss"
import { getChildrenIdsInParents } from "../constant"

const layout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 16,
  },
}
const TreeNode = Tree.TreeNode
const DetailCharacters = (props: any) => {
  const { info } = props
  let listTimer = useRef<any>(null)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<Boolean>(false)
  const [pageOptions, setPageOptions] = useState({ pageSize: 10, current: 1, total: 0 })
  const [addParams, setAddParams] = useState<Common.DetailModuleInter>({
    type: "add",
    open: false,
    record: "",
  })
  const [treeList, setTreeList] = useState<any>([])
  const [characList, setCharacList] = useState<any>([])
  const [characTreeList, setCharacTreeList] = useState<any>([])
  useEffect(() => {
    getLists()
  }, [])

  const getLists = async (data?: any) => {
    console.log(data, "data")
    setLoading(true)
    let parasm = {
      size: 10,
      current: 1,
      ...data,
    }
    const res = await getPlatCharactersList(info?.id, parasm)
    // eslint-disable-next-line no-constant-condition
    if ((res.code = 200)) {
      setCharacList(res.data.records)
      setPageOptions({
        pageSize: res.data?.size,
        current: res.data?.current,
        total: res.data?.total,
      })
      handGetPlatComponentsListTree()
      setLoading(false)
    } else {
      message.error("请求失败")
    }
  }
  const onChange = (params: any) => {
    setPageOptions({
      ...pageOptions,
      ...params,
    })
    getLists({
      size: params.pageSize,
      current: params.current,
    })
  }
  //获取组织树
  const handGetPlatComponentsListTree = async () => {
    const result = await getPlatComponentsListTree({
      id: info?.id,
      size: 50,
      current: 1,
    })
    if (result.code == 200) {
      setCharacTreeList(result.data)
    } else {
      message.error("组织树获取失败")
    }
  }
  const onSearch = (value: string) => {
    if (listTimer.current) {
      clearTimeout(listTimer.current)
      listTimer.current = null
    }
    listTimer.current = setTimeout(() => {
      getLists(value)
    }, 700)
  }
  const formColumns = [
    {
      label: "搜索",
      dataIndex: "keywords",
      type: "Input",
      width: "264px",
      params: {
        suffix: <SearchOutlined style={{ color: "rgba(217, 217, 217, 1)" }} />,
        placeholder: "角色名称",
      },
    },
  ]

  const onCheck: TreeProps["onCheck"] = (checkedKeys: any, info: any) => {
    let _checkedKeys
    if (info?.halfCheckedKeys?.length > 0) {
      _checkedKeys = checkedKeys.concat(info.halfCheckedKeys)
      setTreeList(_checkedKeys)
    } else {
      setTreeList(checkedKeys)
    }
  }

  // 删除
  const handleDelete = async () => {
    let params = {
      applicationId: info?.id,
      id: addParams?.record?.id,
    }
    const result: any = await getDelePlatCharactersList(params)
    if (result.code == 200) {
      message.success("删除成功")
      getLists({
        size: pageOptions.pageSize,
        current: pageOptions.current,
      })
      setAddParams({ open: false, type: "add" })
    } else {
      message.error(result.message || "删除失败")
    }
  }
  //详情
  const handCharactersDatil = async (record: any) => {
    let params = {
      applicationId: info?.id,
      id: record.id,
    }
    const result: any = await getDelePlatCharactersDetail(params)
    if (result.code == 200) {
      const _componentIds = getChildrenIdsInParents(characTreeList, result.data.componentIds)
      setTreeList(_componentIds)
    }
    setAddParams({ ...addParams, open: true, type: "edit", record: record })
    form.setFieldsValue({ ...record })
  }
  const columns = [
    {
      title: "角色名称",
      dataIndex: "name",
      key: "roleName",
      width: "20%",
    },
    {
      title: "操作",
      width: "30%",
      align: "left",
      render: (record: any) => {
        return (
          <div style={{ marginLeft: -15 }}>
            {!record.isSAdmin ? (
              <>
                <Access accessible={AccessEnum.op_platform_apply_feature_editCharac}>
                  <Button
                    type="link"
                    onClick={() => {
                      handCharactersDatil(record)
                    }}
                  >
                    详情
                  </Button>
                </Access>
              </>
            ) : (
              ""
            )}
          </div>
        )
      },
    },
  ]
  const handleOk = async () => {
    const res = await form.validateFields()
    if (addParams.type === "add") {
      let params = {
        applicationId: info.id,
        name: res.name,
        componentIds: treeList,
      }
      const response: any = await getAddPlatCharactersList(params)
      if (response?.code === 200) {
        setAddParams({ open: false, type: "add" })
        form.resetFields()
        getLists({
          size: pageOptions.pageSize,
          current: pageOptions.current,
        })
        message.success("添加成功")
      } else {
        message.success("添加失败")
      }
    } else {
      let params = {
        applicationId: info.id,
        name: res.name,
        componentIds: treeList,
      }
      const response: any = await getEditPlatCharactersList(addParams?.record?.id, params)
      if (response?.code === 200) {
        setAddParams({ open: false, type: "add" })
        form.resetFields()
        getLists({
          size: pageOptions.pageSize,
          current: pageOptions.current,
        })
        message.success("修改成功")
      } else {
        message.error("修改失败")
      }
    }
  }
  const handAddCharters = () => {
    setTreeList([])
    setAddParams({ open: true, type: "add" })
  }
  // 递归渲染树形菜单方法
  const renderTree = (data) => {
    return (
      data &&
      data.map((item) => {
        if (!item.children) {
          return <TreeNode title={item.name} key={item.id}></TreeNode>
        } else {
          return (
            <TreeNode title={item.name} key={item.id}>
              {renderTree(item.children)}
            </TreeNode>
          )
        }
      })
    )
  }
  const TreeHandList = useMemo(() => {
    return (
      <Form {...layout} form={form} className="formClass">
        <Form.Item
          label="角色名"
          name="name"
          rules={[{ required: true, message: "请输入角色名,不超过20字符", max: 20 }]}
        >
          <Input placeholder="请输入角色名"></Input>
        </Form.Item>

        <Form.Item label="权限设置" name="componentIds">
          <Tree
            checkable
            defaultExpandedKeys={treeList}
            defaultSelectedKeys={treeList}
            defaultCheckedKeys={treeList}
            onCheck={onCheck}
          >
            {renderTree(characTreeList)}
          </Tree>
        </Form.Item>
        <Form.Item style={{ width: "108%", borderTop: "1px solid #f0f0f0" }}>
          <div
            style={{
              display: "flex",
              marginBottom: 10,
              paddingTop: 10,
              paddingLeft: 20,
            }}
          >
            {addParams?.type != "add" && (
              <div style={{ width: "100px" }}>
                <Access accessible={AccessEnum.op_platform_apply_feature_deleteCharac}>
                  <Popconfirm
                    title="删除"
                    description="删除角色后，取消与用户的关联关系? "
                    onConfirm={handleDelete}
                    okText="确定"
                    cancelText="取消"
                  >
                    <Button danger>删除</Button>
                  </Popconfirm>
                </Access>
              </div>
            )}
            <div
              style={{
                display: "flex",
                flex: 1,
                textAlign: "right",
                marginLeft: `${addParams?.type == "add" ? "350px" : "285px"}`,
              }}
            >
              <Button
                style={{ marginRight: "12px" }}
                onClick={() => {
                  setAddParams({ open: false, type: "add" })
                  form.resetFields()
                }}
              >
                取消
              </Button>
              {addParams.type === "add" ? (
                <Button type="primary" onClick={handleOk}>
                  确定
                </Button>
              ) : (
                <Access accessible={AccessEnum.op_platform_apply_feature_editCharac}>
                  <Button type="primary" onClick={handleOk}>
                    确定
                  </Button>
                </Access>
              )}
            </div>
          </div>
        </Form.Item>
      </Form>
    )
  }, [treeList, addParams])
  return (
    <>
      <div className="DetailModule_list_keysWrod">
        <SearchForm formParams={formColumns} onSearch={onSearch} optionsList={null}></SearchForm>
      </div>
      <div className="DetailModule">
        <div className="DetailModule_list">
          <div className="DetailModule_list_title">
            <div>角色列表/{characList.length}</div>
            <div>
              <Access accessible={AccessEnum.op_platform_apply_feature_addCharac}>
                <Button type="primary" onClick={handAddCharters}>
                  新建角色
                </Button>
              </Access>
            </div>
          </div>
          <Table
            rowKey={(record: any) => record.id}
            loading={loading}
            size="small"
            columns={columns}
            dataSource={characList}
            pagination={{
              current: Number(pageOptions.current),
              pageSize: Number(pageOptions.pageSize),
              total: pageOptions.total,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal(total, range) {
                return `总共${total}条`
              },
            }}
            onChange={onChange}
          />
        </div>
        {/* 新增 */}
        <Modal
          title={addParams.type === "add" ? "新增角色" : "角色详情"}
          open={addParams.open}
          destroyOnClose={true}
          footer={false}
          onCancel={() => {
            setAddParams({ open: false, type: "add" })
            form.resetFields()
          }}
        >
          {TreeHandList}
        </Modal>
      </div>
    </>
  )
}
export default DetailCharacters
