const withAllowClear = {
  allowClear: true,
}
export default {
  type: "object",
  labelWidth: 106,
  properties: {
    invoiceStatus: {
      title: "开票状态",
      type: "string",
      widget: "select",
      enum: ["PEDDING", "ISSUED", "CANCELED", "ERROR", "NEGATIVE_ISSUED"],
      enumNames: ["待开具", "正数发票已开具", "已冲红", "开票失败", "负数发票已开具"],
      placeholder: "请选择",
      props: withAllowClear,
    },
    buyerName: {
      title: "买方名称",
      type: "string",
      widget: "input",
      placeholder: "请输入",
      props: withAllowClear,
    },
    sellerName: {
      title: "开票方",
      type: "string",
      widget: "input",
      placeholder: "请输入",
      props: withAllowClear,
    },
    invoiceType: {
      title: "发票类型",
      type: "string",
      widget: "select",
      enum: [
        "PAPER_SPECIAL",
        "PAPER_GENERAL",
        "ELECTRONIC_GENERAL",
        "ELECTRONIC_SPECIAL",
        "FULLY_ELECTRONIC_GENERAL",
        "FULLY_ELECTRONIC_SPECIAL",
      ],
      enumNames: ["纸质普票", "纸质专票", "电子普票", "电子专票", "全电普票", "全电专票"],
      placeholder: "请选择",
      props: withAllowClear,
    },
    deregisterStatus: {
      bind: ["invoiceDateStartDate", "invoiceDateEndDate"],
      title: "开票日期",
      type: "range",
      format: "date",
      props: withAllowClear,
    },
    invoiceIssuer: {
      title: "开票人",
      type: "string",
      widget: "input",
      placeholder: "请输入",
      props: withAllowClear,
    },
    keyword: {
      title: "关键字搜索",
      type: "string",
      widget: "input",
      placeholder: "请搜索发票号、订单ID...",
      props: withAllowClear,
    },
  },
}
