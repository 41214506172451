import Util from "../../../services/util"
import { OP_USER, OP_SOURCE } from "../../../api/config/servicePort"
import UtilOp from "../../../services/serviceOp"
const utilOp = UtilOp.getInstance()

export const getStoreDetail = (id: any) => {
  return utilOp.httpGet(OP_USER + `/sites/${id}`)
}
// 根据文件id获取，文件链接
export const getUrlByFileId = (params: { zoom: boolean; fileIds: string }) => {
  return utilOp.httpGet(`${OP_SOURCE}/files/ossUrlZoom`, params)
}
