export const EntityTypeEnum: Record<API.Entity.Type, string> = {
  ENTERPRISE: "企业",
  INDIVIDUAL: "自然人",
  INDIVIDUAL_BUSINESS: "个体户",
  OTHER: "其他",
}

export const EntityTypeIdNoEnum: Record<API.Entity.Type, string> = {
  ENTERPRISE: "统一社会信用代码",
  INDIVIDUAL: "身份证号码",
  INDIVIDUAL_BUSINESS: "统一社会信用代码",
}

export const PORT1 = "/rscm/rscm-auth"
