import React from "react"

export const OrgTypeStatusEnum = new Map([
  [false, { text: "禁用", status: "Error" }],
  [true, { text: "正常", status: "Success" }],
])
export const OrgTypeOutStatusEnum = new Map([
  ["CANCEL_APPLY", { text: "申请注销", status: "Success" }],
  ["CANCEL_COMPLETE", { text: "已注销", status: "Error" }],
])
export const OrgTypeStatusList = {
  false: "禁用",
  true: "正常",
}
export const OrgTypeOutStatusList = {
  CANCEL_APPLY: "申请注销",
  CANCEL_COMPLETE: "已注销",
}
export const LineTypeEnum = new Map([
  ["UNAPPROVAL", { text: "不需要审核" }],
  ["APPROVAL", { text: "需要审核" }],
])

export const OrgTypeAuthStatusEnum = new Map([
  ["false", { text: "未认证", status: "Error" }],
  ["true", { text: "已认证", status: "Success" }],
])

export const OrgTypeEnum = new Map([
  ["INDIVIDUAL", { text: "自然人" }],
  ["ENTERPRISE", { text: "企业" }],
  ["INDIVIDUAL_BUSINESS", { text: "个体户" }],
  ["OTHER", { text: "其他" }],
])

export const OrgTypeRegistrationEnum = new Map([
  ["REGISTERED", { text: "已登记" }],
  ["UNREGISTERED", { text: "未登记" }],
  ["INVALID", { text: "已删除" }],
])
export const DeclarationCycleList = {
  MONTH: "月度总授信额度",
  QUARTER: "季度总授信额度",
}
// 税务登记
export const DeclarationCycleEnum = {
  MONTH: "按月申报",
  QUARTER: "按季度申报",
}
// 开票渠道
export const DeclarationDescEnum = {
  UN_OPEN: "未开通",
  APPLY: "已申请",
  OPENED: "已开通",
}
// 税务登记
export const DeclarationCycleListEnum = [
  {
    value: "MONTH",
    label: "按月申报",
  },
  {
    value: "QUARTER",
    label: "按季度申报",
  },
]
// 税务登记
export const taxRegistrationStatusEnum = [
  {
    value: "UNREGISTERED",
    label: "未登记",
  },
  {
    value: "REGISTERED",
    label: "已登记",
  },
]

export const handleData = (start: any[]) => {
  const arr: any = start?.map((item) => {
    if (item?.children?.length) {
      return {
        value: item.code,
        label: item.name,
        children: handleData(item.children),
      }
    } else {
      return {
        value: item.code,
        label: item.name,
        children: [],
      }
    }
  })
  return arr
}
//超群提供的代码
export function convert(privinceCode: string, cityCode: string, areaCode: string) {
  return [Number(privinceCode), Number(cityCode), Number(areaCode)]
}
export const applyToEnum: any = {
  ASSOCIATE: "个人及其个体户",
  SELF: "个体户",
}
export const intervalTypeEnum: any = {
  YEAR: "年",
  QUARTER: "季",
  MONTH: "月",
}
export const priceTypeEnum: any = {
  INCLUDE_TAX: "含税",
  EXCLUDE_TAX: "不含税",
}
