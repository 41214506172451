/* eslint-disable no-debugger */
// import { OP_USER } from "../../../../api/config/servicePort"
import { OP_USER } from "../../../api/config/servicePort"
import dayjs from "dayjs"

export const downConfig = {
  工商税务开办列表: {
    columns: {
      applyNo: "申请编号",
      taxName: "办理区域",
      legalName: "法人姓名",
      legalPhone: "法人联系电话",
      legalIdno: "身份证号",
      createTime: "申请时间",
      applySourceName: "申请来源",
      applyOrgName: "申请组织",
      applyUserName: "申请人",
      signStatusName: "委托协议",
      dualStatusName: "双录视频",
      checkStatusName: "审核状态",
      licenseStatusName: "营业执照",
      taxStatusName: "税务登记",
    },
    downUrl: `${OP_USER}/business-agencies`,
    exportUrl: `${OP_USER}/excel/export`,
    formatParams: (obj: any) => {
      if (obj.createTimeStart) {
        obj.createTimeStart =
          dayjs(obj.createTimeStart).format("YYYY-MM-DD HH:mm:ss").split(" ")[0] + " 00:00:00"
      }
      if (obj.createTimeEnd) {
        obj.createTimeEnd =
          dayjs(obj.createTimeEnd).format("YYYY-MM-DD HH:mm:ss").split(" ")[0] + " 23:59:59"
      }
      return obj
    },
  },
}
