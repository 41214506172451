import React, { memo, useContext } from "react"
import { OrderDetailContext } from "../useOrderDetail"
import { Card, Progress, Space, Table, Tabs, Image, Spin, TabsProps } from "antd"
import { ColumnsType } from "antd/lib/table"
// import { OperableImage } from "@src/components/OperableImage"
import { TransactionInfo } from "./transactionInfo"
import { TraceInfo } from "./traceInfo"
import { BasicInfo } from "./basicInfo"
import { PurchasePlan } from "./puarhcasePlan"
import numeral from "numeral"
import { transDigitByUnit } from "../../../../../../utils/transDigitByUnit"
import { divide } from "lodash"
import OrderStatus from "./orderStatus"
const items: TabsProps["items"] = [
  {
    key: "0",
    label: "风险校验",
    children: <OrderStatus></OrderStatus>,
  },
  {
    key: "1",
    label: "货单动态",
    children: <TransactionInfo></TransactionInfo>,
  },
  {
    key: "2",
    label: "物流轨迹",
    children: <TraceInfo></TraceInfo>,
  },
  {
    key: "3",
    label: "货物信息",
    children: <BasicInfo></BasicInfo>,
  },
  {
    key: "4",
    label: "关联计划",
    children: <PurchasePlan></PurchasePlan>,
  },
]

export const LogisticsInfo: React.FC<any> = memo(() => {
  const context = useContext(OrderDetailContext)

  const columns: ColumnsType<Schema.Order.Logistics> = [
    { dataIndex: "carriageTypeName", title: "运输方式" },
    { dataIndex: "vehicleNo", title: "车牌号" },
    { dataIndex: "netWeight", title: "数量" },
    { dataIndex: "measureUnit", title: "单位" },
    { dataIndex: "loadingDate", title: "出发时间" },
    { dataIndex: "finishDeliveryDate", title: "送达时间" },
    { dataIndex: "loadingLocation", title: "起始地" },
    {
      dataIndex: "receiveAddress",
      title: "目的地",
      render: (text) => {
        return text?.split("/").join(",").split(",").join("")
      },
    },
    {
      dataIndex: "distance",
      title: "运输距离",
      render: (text) => `${text ? divide(Number(text), 1000) : 0}km`,
    },
  ]
  const arriveQuantity =
    context?.data?.logistics?.reduce(
      (q, item) =>
        numeral(q || 0)
          .add(
            transDigitByUnit(
              item.netWeight,
              item.unitOfMeasure,
              context?.data.unitOfMeasure || "吨"
            ) || 0
          )
          .value() || 0,
      0
    ) || 0

  return (
    <Card
      title="物流信息"
      extra={
        <Space>
          物流完成度：
          <Progress
            style={{ width: 200, margin: 0, marginRight: "-2em" }}
            percent={context?.data.quantity ? (arriveQuantity / context?.data.quantity) * 100 : 0}
            format={() => ""}
          />
          <span style={{ marginLeft: "2em" }}>
            {arriveQuantity || 0}/{context?.data.quantity || 0}吨
          </span>
        </Space>
      }
    >
      <Table
        dataSource={context?.data?.logistics}
        // @ts-ignore
        columns={columns}
        onRow={(record) => {
          return {
            style: { cursor: "pointer" },
            className:
              record.id === context?.logisticsDetailService?.params?.[0]
                ? "table-row-line-height"
                : "",
            onClick: () => {
              context?.logisticsDetailService?.run(record.id)
            },
          }
        }}
      ></Table>
      <br />
      {context?.data?.logistics?.length ? (
        <Spin spinning={context?.logisticsDetailService?.loading}>
          <Tabs items={items}></Tabs>
        </Spin>
      ) : (
        ""
      )}
    </Card>
  )
})

LogisticsInfo.displayName = "订单物流模块"
