import { OP_EVIDENCE } from "@/api/config/servicePort"
import UtilOp from "../../../../services/serviceOp"
const util = UtilOp.getInstance()

type get_field_list = {
  name?: string
  createUser?: string | number
  type?: number
  size?: number | string
  current?: number | string
}

const getFieldList: (values: get_field_list) => Promise<any> = (values: get_field_list) => {
  return util.httpGet(OP_EVIDENCE + "/fields", values).then((res) => res)
}

const addFiled = (values: any) => {
  return util.httpPost(OP_EVIDENCE + "/fields", values).then((res) => res)
}

const getFiledInfo = (id: string) => {
  return util.httpGet(`${OP_EVIDENCE}/fields/${id}`).then((res) => res)
  // .catch(err => err)
}

const getImageUrl = (params: any) => {
  return util.httpGetDomain(`${OP_EVIDENCE}/files/ossUrlZoom`, params).then((res) => res)
}

// 新增场景关联模板字段
const addTemplateFiled = (params: any) => {
  return util
    .httpPost(OP_EVIDENCE + "/secure-evidence-templates/links/fields", params)
    .then((res) => res)
}

// 获取关联字段列表

const associatedFields = (id: string) => {
  return util
    .httpGet(`${OP_EVIDENCE}/secure-evidence-templates/links/${id}/fields`)
    .then((res) => res)
}

export { getFieldList, addFiled, getFiledInfo, getImageUrl, addTemplateFiled, associatedFields }
