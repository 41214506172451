/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
import { Button, Form, Space, Card, Table, UploadFile } from "antd"
import React, { memo, useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import moment from "moment"
import { EntityTypeEnum, taxRegisTypeEnum } from "../../../constants"
import { OrgTypeOutStatusList, OrgTypeStatusList } from "../constants"
const FormItem = Form.Item
export const Organizational = memo((props: any) => {
  const { list } = props
  const columns: any = [
    {
      title: "组织名称",
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "证件号",
      dataIndex: "idNo",
      key: "idNo",
    },
    {
      title: "组织类型",
      dataIndex: "type",
      key: "type",
      render: (text) => {
        return <span>{EntityTypeEnum[text]}</span>
      },
    },
    {
      title: "税务登记状态",
      dataIndex: "taxRegistrationStatus",
      key: "taxRegistrationStatus",
      render: (text) => {
        return <span>{taxRegisTypeEnum[text]}</span>
      },
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      render: (text: any, record: any) => {
        return record?.cancelStatus == "NORMAL"
          ? OrgTypeStatusList[record.status]
          : OrgTypeOutStatusList[record.cancelStatus]
      },
    },
    {
      dataIndex: "createTime",
      title: "创建时间",
    },
    {
      title: "操作",
      dataIndex: "operation",
      fixed: "right",
      render: (text: any, record: any) => {
        return (
          <div className="table-operate">
            <Link to={`/platformSetup/tissue/info/${record.id}`}>详情</Link>
          </div>
        )
      },
    },
  ]
  const useTableMemo = useMemo(() => {
    return (
      <Table
        scroll={{ x: "max-content" }}
        dataSource={list && [list]}
        columns={columns}
        pagination={false}
      />
    )
  }, [list])
  return (
    <Card title="组织信息">
      {/* <Table dataSource={[list] && []} columns={columns} pagination={false} /> */}
      {useTableMemo}
    </Card>
  )
})

export default Organizational
