import React, { useMemo } from "react"
import { Row, Col, Image } from "antd"
import { OperableImage } from "@src/components/ImageOptions"
import "./index.scss"
interface BasicDetailsProps {
  details: any
  idCardUrl?: string
  idCardBackUrl?: string
  taxCleanFileOssUrl?: string
  licenseCancelFileOssUrl?: string
}

const BasicDetails = (props: BasicDetailsProps) => {
  const { details, idCardUrl, idCardBackUrl, taxCleanFileOssUrl, licenseCancelFileOssUrl } = props

  return (
    <div className="agency_info_details_container">
      <div className="h4_title border_btm">主体基本信息</div>
      <div className="basic_form_container">
        <Row>
          <Col span={8}>
            <div className="form_item">
              <span className="form_item_label">企业/个体户名称:</span>
              <div>{details?.organizationVo?.name}</div>
            </div>
          </Col>
          <Col span={8}>
            <div className="form_item">
              <span className="form_item_label">法人姓名:</span>
              <div>{details?.organizationVo?.operatorLegal}</div>
            </div>
          </Col>
          <Col span={8}>
            <div className="form_item">
              <span className="form_item_label">联系电话:</span>
              <div>{details?.organizationVo?.contact}</div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="h4_title">证照信息</div>
      <div className="certificate_container">
        <div className="flex_phone_c">
          <OperableImage width={120} height={120} src={idCardUrl || "string"} noRenew={true} />
          <span className="phone_desc">身份证正面</span>
        </div>
        <div className="flex_phone_c">
          <OperableImage width={120} height={120} src={idCardBackUrl || "string"} noRenew={true} />
          <span className="phone_desc">身份证反面</span>
        </div>
        {details?.taxCleanFileOssId && details?.taxCleanFileOssId !== "" ? (
          <div className="flex_phone_c">
            <OperableImage
              width={120}
              height={120}
              src={taxCleanFileOssUrl || "string"}
              noRenew={true}
            />
            <span className="phone_desc">清税证明</span>
          </div>
        ) : null}
        {details?.licenseCancelFileOssId && details?.licenseCancelFileOssId !== "" ? (
          <div className="flex_phone_c">
            <OperableImage
              width={120}
              height={120}
              noRenew={true}
              src={licenseCancelFileOssUrl || "string"}
            />
            <span className="phone_desc">执照注销证明</span>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default BasicDetails
