import React from "react"
import { Badge } from "antd"
import { OrderLogisticsStatusEnum, providerEnum } from "../constant"

export default [
  {
    title: "姓名",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "账号",
    dataIndex: "account",
    key: "account",
  },
  {
    title: "手机号",
    dataIndex: "phone",
    key: "phone",
  },

  {
    title: "状态",
    dataIndex: "status",
    key: "status",
    render: (value) => <Badge {...OrderLogisticsStatusEnum[value]} />,
  },
  {
    title: "运营标识",
    dataIndex: "provider",
    key: "provider",
    render: (value) => {
      if (!value) value = "default"
      return providerEnum[value]?.text
    },
  },
]
