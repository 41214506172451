/* eslint-disable react-hooks/rules-of-hooks */
import React from "react"
import { useAccessList } from "../../hooks/useAccess"
import { AccessEnum } from "../../contents/accessEnum"
interface AccessInterFace {
  accessible?: keyof typeof AccessEnum
  fallback?: React.ReactNode
}
/**
 * 权限显示组件
 * @param props
 * @returns
 */
export const handAccess = (accessible) => {
  const accessList = useAccessList() as string[]
  return accessList.includes(accessible) ? true : false
}
