import { BadgeProps } from "antd"
import { getOrderUploadByPath } from "./service"

// 营业执照
export const businessLicenseStatusEnum: Record<string, BadgeProps> = {
  UNHANDLED: {
    status: "warning",
    text: "未办理",
  },
  HANDLED: {
    status: "success",
    text: "已办理",
  },
  INVALID: {
    status: "error",
    text: "已删除",
  },
}
export const taxRegisTypeEnum: Record<API.Entity.Type, string> = {
  UNREGISTERED: "未登记",
  REGISTERED: "已登记",
  INVALID: "已删除",
}
// 税务登记
export const taxRegistrationStatusEnum: Record<string, BadgeProps> = {
  UNREGISTERED: {
    status: "warning",
    text: "未登记",
  },
  REGISTERED: {
    status: "success",
    text: "已登记",
  },
  INVALID: {
    status: "error",
    text: "已删除",
  },
}
// 申请来源
export const applicationSourceStatusEnum: Record<API.Entity.Type, string> = {
  MOBILE_INDIVIDUAL_BUSINESS_REGISTRATION: "移动端个体户注册",
  OP: "运营端",
}
/**
 * 创建代办个体户类型
 */

export const radioOptionEnum = [
  {
    label: "个体户办理",
    value: "INDIVIDUAL_BUSINESS",
  },
  // {
  //   label: "企业办理",
  //   value: "ENTERPRISE",
  // },
]

/**
 * 创建无名个体户
 */

export const namelessOptionEnum = [
  {
    label: "不需要名称",
    value: false,
  },
  {
    label: "需要名称",
    value: true,
  },
]

// 政治面貌
export const legalPoliticalAffiliationList = [
  {
    value: "CPC_MEMBER",
    label: "中共党员",
  },
  {
    value: "CPC_PROBATIONARY_MEMBER",
    label: "中共预备党员",
  },
  {
    value: "CYL_MEMBER",
    label: "共青团员",
  },
  {
    value: "DAB_MEMBER",
    label: "民革党员",
  },
  {
    value: "CDL_MEMBER",
    label: "民盟盟员",
  },
  {
    value: "CNDCA_MEMBER",
    label: "民建会员",
  },
  {
    value: "CZGP_MEMBER",
    label: "民进会员",
  },
  {
    value: "PWDP_MEMBE",
    label: "农工党党员",
  },
  {
    value: "CIPU_MEMBER",
    label: "致公党党员",
  },
  {
    value: "CAPD_MEMBER",
    label: "三九学社社员",
  },
  {
    value: "TMDA_MEMBER",
    label: "台盟盟员",
  },
  {
    value: "NON_PARTISAN",
    label: "无党派人士",
  },
  {
    value: "MASSES",
    label: "群众",
  },
]

export const removeField = (str, field) => {
  let regex = new RegExp(field)
  return str?.replace(regex, "") || ""
}

// export const getNewAffiliationList = (data) => {
//   if (data) {
//     let params = {}
//     return params
//   }
// }

export const EntityTypeEnum: Record<API.Entity.Type, string> = {
  ENTERPRISE: "企业",
  INDIVIDUAL: "个人",
  INDIVIDUAL_BUSINESS: "个体户",
  OTHER: "其他",
}

//审核状态
export const examineStatusEnum: Record<string, BadgeProps> = {
  WAIT_CHECK: {
    status: "warning",
    text: "待审核",
  },
  CHECK_PASS: {
    status: "success",
    text: "审核通过",
  },
  UN_COMMIT: {
    status: "error",
    text: "未提交",
  },
  COMMIT: {
    status: "warning",
    text: "已提交",
  },
}
// 注销状态
export const outDelegationStatusEnum: Record<string, BadgeProps> = {
  APPLY_FILE_WAIT_SIGN: {
    status: "warning",
    text: "申请书待签署",
  },
  TAX_WAIT_CLEAN: {
    status: "warning",
    text: "待清税",
  },
  LICENSE_WAIT_CANCEL: {
    status: "warning",
    text: "执照待注销",
  },
  REVOKE: {
    status: "error",
    text: "已撤销",
  },
  CANCEL_COMPLETE: {
    status: "success",
    text: "已注销",
  },
}
// 委托协议
export const delegationAgreementStatusEnum: Record<string, BadgeProps> = {
  UN_SIGNED: {
    status: "warning",
    text: "待签署",
  },
  SIGNED: {
    status: "success",
    text: "已签署",
  },
}
// 双录视频
export const bleRecordingStatusEnum: Record<string, BadgeProps> = {
  UN_DUAL: {
    status: "warning",
    text: "待录制",
  },
  DUAL_ED: {
    status: "success",
    text: "已录制",
  },
}
//民族汇总
export const nation_data = [
  { label: "汉族", value: "汉族" },
  { label: "蒙古族", value: "蒙古族" },
  { label: "回族", value: "回族" },
  { label: "藏族", value: "藏族" },
  { label: "维吾尔族", value: "维吾尔族" },
  { label: "苗族", value: "苗族" },
  { label: "彝族", value: "彝族" },
  { label: "壮族", value: "壮族" },
  { label: "布依族", value: "布依族" },
  { label: "朝鲜族", value: "朝鲜族" },
  { label: "满族", value: "满族" },
  { label: "侗族", value: "侗族" },
  { label: "瑶族", value: "瑶族" },
  { label: "白族", value: "白族" },
  { label: "土家族", value: "土家族" },
  { label: "哈尼族", value: "哈尼族" },
  { label: "哈萨克族", value: "哈萨克族" },
  { label: "傣族", value: "傣族" },
  { label: "黎族", value: "黎族" },
  { label: "傈僳族", value: "傈僳族" },
  { label: "佤族", value: "佤族" },
  { label: "畲族", value: "畲族" },
  { label: "高山族", value: "高山族" },
  { label: "拉祜族", value: "拉祜族" },
  { label: "水族", value: "水族" },
  { label: "东乡族", value: "东乡族" },
  { label: "纳西族", value: "纳西族" },
  { label: "景颇族", value: "景颇族" },
  { label: "柯尔克孜族", value: "柯尔克孜族" },
  { label: "土族", value: "土族" },
  { label: "达翰尔族", value: "达翰尔族" },
  { label: "么佬族", value: "么佬族" },
  { label: "羌族", value: "羌族" },
  { label: "布朗族", value: "布朗族" },
  { label: "撒拉族", value: "撒拉族" },
  { label: "毛南族", value: "毛南族" },
  { label: "仡佬族", value: "仡佬族" },
  { label: "锡伯族", value: "锡伯族" },
  { label: "阿昌族", value: "阿昌族" },
  { label: "普米族", value: "普米族" },
  { label: "塔吉克族", value: "塔吉克族" },
  { label: "怒族", value: "怒族" },
  { label: "乌孜别克族", value: "乌孜别克族" },
  { label: "俄罗斯族", value: "俄罗斯族" },
  { label: "鄂温克族", value: "鄂温克族" },
  { label: "德昂族", value: "德昂族" },
  { label: "保安族", value: "保安族" },
  { label: "裕固族", value: "裕固族" },
  { label: "京族", value: "京族" },
  { label: "塔塔尔族", value: "塔塔尔族" },
  { label: "独龙族", value: "独龙族" },
  { label: "鄂伦春族", value: "鄂伦春族" },
  { label: "赫哲族", value: "赫哲族" },
  { label: "门巴族", value: "门巴族" },
  { label: "珞巴族", value: "珞巴族" },
  { label: "基诺族", value: "基诺族" },
]

// 防抖函数
export const debounce = (callback: Function, time: number = 500) => {
  let timer: null | any = null
  return (...args: any) => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      callback.apply(this, args)
    }, time)
  }
}

export const handDataReorganization = (data) => {
  console.log(data, "data")
  let arr: any = []
  if (data.length > 0) {
    data?.map((ie) => {
      let params: any = {
        name: ie[0]?.data?.fileName || "",
        comment: ie[0]?.data?.fileRequire || "",
        num: ie[0]?.data?.fileNumbers || "",
        allowEmpty: ie[0]?.data?.allowEmpty,
        fileType: ie[0].data?.fileType || "",
        files: [],
        newFiles: [],
      }
      ie.map((item) => {
        params.files.push({
          name: item?.name,
          path: item?.response?.data?.filePath || item?.path,
        })
      })
      arr.push(params)
    })
    return arr
  }
}
/**
 * 获取文件信息，下载文件
 * @param fileKey
 */
export const getFileUrl = async (fileKey: string) => {
  let params = {
    filePaths: [fileKey],
  }
  const result: any = await getOrderUploadByPath(params)
  if (result.code == 200) {
    let download_a = document.createElement("a")
    download_a.setAttribute("href", result?.data?.files[0]?.downloadUrl)
    download_a.setAttribute("target", "_blank")
    download_a.setAttribute("id", "camnpr")
    document.body.appendChild(download_a)
    download_a.click()
    document.body.removeChild(download_a)
  }
}

/**
 * ，下载办理资料
 * @param fileKey
 */
export const getFileUrlDetails = async (fileKey: string) => {
  let download_a = document.createElement("a")
  download_a.setAttribute("href", fileKey)
  download_a.setAttribute("target", "_blank")
  download_a.setAttribute("id", "camnpr")
  document.body.appendChild(download_a)
  download_a.click()
  document.body.removeChild(download_a)
}
