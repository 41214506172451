/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
import { Input, Form, Select, Card, Row, Col, message, Radio, Cascader } from "antd"
import { useWatch } from "antd/es/form/Form"
import React, { memo, useState, useEffect } from "react"
import { namelessOptionEnum, nation_data } from "../../../constants"
import { OperableImage } from "@src/components/ImageOptions"
import {
  getOcrIdCardFront,
  getTissueBankList,
  getTissueCityTreeList,
  getTissueCorrespondentList,
  getOrgs,
} from "../../../service"
import { legalPoliticalAffiliationList } from "../../../constants"
import { cross_env } from "@src/services/serviceOp"
import { handleData } from "./content"
import "./index.scss"
const colProps = {
  span: 8,
}
const FormItem = Form.Item

export const BasicInformation = memo((props: any) => {
  const {
    form,
    nameLessFlag,
    individualPrefix,
    individualSuffix,
    idCardL,
    idCardR,
    setIdCardL,
    setIdCardR,
    detailInfo,
    setNamelessType,
    setNameLessFlag,
    detailsCard,
    namelessType,
    handGetOperRegionsRecommend,
    setAdvancedInfo,
    advancedInfo,
    setFormationDisabled,
    formationDisabled,
  } = props

  const provinceCode = useWatch("provinceCode", form)
  const bankCode = useWatch("bankCode", form)

  const [bankList, setBankList] = useState<any>([]) //开户行
  const [cityTreeList, setCityTreeList] = useState<any>([]) //省市区
  const [correspondent, setCorrespondent] = useState<any>([]) //联行号
  const [orgsList, setOrgsList] = useState<any>([]) // 组织列表

  const init = async () => {
    const [resultBankList, resultCityTreeList, resultOrgList]: any = await Promise.allSettled([
      getTissueBankList({ size: -1 }),
      getTissueCityTreeList({ maxDeep: 2 }),
      getOrgs({ searchCount: true, size: 9999, current: 1 }),
    ])
    if (resultBankList.status == "fulfilled") {
      setBankList(resultBankList?.value?.data?.records)
    }
    if (resultCityTreeList.status == "fulfilled") {
      setCityTreeList(handleData(resultCityTreeList?.value?.data))
    }
    console.log(resultOrgList, 6123131)
    setOrgsList(
      resultOrgList?.value?.data?.records?.map((item) => {
        return {
          label: item.name,
          value: item.id,
        }
      })
    )
  }

  const handAearchHandle = async () => {
    if (bankCode && provinceCode) {
      let resultBranchAddress = provinceCode?.slice(-1)
      const params = {
        bankCode: bankCode,
        cityCode: resultBranchAddress[0],
        size: -1,
      }
      const result: any = await getTissueCorrespondentList(params)
      let resultData = result?.data?.records?.map((item: any) => {
        return {
          value: item.cnaps,
          label: item.branchName,
          id: item.cnaps,
          // TEST_CODE
          codeOfBranch: item.bankCode,
          branchName: item.branchName,
        }
      })
      setCorrespondent(resultData)
    }
  }
  const handleSearchOrg = async (e) => {
    let result: any = []
    // 超过4个汉字搜索
    let searchValue = e.replaceAll("有限", "").replaceAll("公司", "")
    const res = await getOrgs({
      searchCount: true,
      size: 9999,
      current: 1,
      keywords: searchValue,
    })
    if (res.code == 200) {
      result = res.data.records.map((item) => {
        return {
          label: item.name,
          value: item.id,
        }
      })
    }

    setOrgsList(result)
  }

  // // 查询组织列表
  // useEffect(() => {
  //   console.log(orgName, 612313)
  // }, [orgName])
  // 查询开户支行
  useEffect(() => {
    handAearchHandle()
  }, [provinceCode, bankCode])

  useEffect(() => {
    init()
  }, [])

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())

  return (
    <>
      <Card title="身份信息">
        <Row>
          <Col {...colProps}>
            <Form.Item
              label="法人身份证"
              name="legal"
              rules={[{ required: true, message: "请上传身份证", max: 100 }]}
            >
              <div style={{ display: "flex" }}>
                <OperableImage
                  width={120}
                  height={120}
                  src={idCardL[0]?.downloadLink || detailsCard[1]}
                  action={`${cross_env}/rscm/rscm-resource/oss/endpoint`}
                  uploadFileProps={{
                    name: "file",
                    onChange: async ({ file }) => {
                      if (file.response?.data?.downloadLink) {
                        console.log(file, "file")
                        setIdCardL([file.response?.data])
                        form.setFieldValue("legal", "身份证")
                        message.loading("身份图像信息识别中...", 1.5)
                        const result: any = await getOcrIdCardFront({
                          url: file.response?.data?.downloadLink,
                        })
                        if (result.code == 200) {
                          console.log(result, "result")
                          form.setFieldsValue({
                            legalName: result?.data?.name,
                            legalIdno: result?.data?.idNum,
                            legalAddress: result?.data?.address,
                            legalNation: result?.data?.nation,
                            sex: result.data.sex,
                          })
                          setAdvancedInfo(JSON.parse(result.data.advancedInfo))
                          setFormationDisabled(false)
                          handGetOperRegionsRecommend()
                        } else {
                          setAdvancedInfo([])
                          setFormationDisabled(true)
                          message.error(result?.response?.data?.msg || "识别失败")
                        }
                      }
                    },
                  }}
                >
                  身份证正面
                </OperableImage>
                <div style={{ marginLeft: 10 }}>
                  <OperableImage
                    width={120}
                    height={120}
                    action={`${cross_env}/rscm/rscm-resource/oss/endpoint`}
                    src={idCardR[0]?.downloadLink || detailsCard[0]}
                    uploadFileProps={{
                      name: "file",
                      onChange: async ({ file }) => {
                        if (file.response?.data?.downloadLink) {
                          setIdCardR([file.response?.data])
                        }
                      },
                    }}
                  >
                    身份证反面
                  </OperableImage>
                </div>
              </div>
              <p className="id_desc">支持png jpg jpeg文件上传,最大50MB</p>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          {!formationDisabled ? (
            <Col {...colProps}>
              <Form.Item
                label="证件照"
                name="idCardHead"
                // rules={[{ required: true, message: "系统自动上传", max: 100 }]}
              >
                <img src={`data:image/png;base64,${advancedInfo?.Portrait}`} width="120"></img>
                <p className="id_desc">系统自动获取身份证上的照片</p>
              </Form.Item>
            </Col>
          ) : null}
        </Row>
        <Row>
          <Col {...colProps}>
            <Form.Item
              label="姓名"
              name="legalName"
              rules={[{ required: true, message: "请填写姓名", max: 100 }]}
            >
              <Input disabled={formationDisabled} placeholder={"自动识别，请核对姓名"} />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item
              label="身份证号"
              name="legalIdno"
              rules={[
                { required: true, message: "请输入个体户身份证" },
                {
                  pattern:
                    /^[1-9]\d{5}(19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
                  message: "请输入正确的身份证号",
                },
              ]}
            >
              <Input disabled={formationDisabled} placeholder={"自动识别，请核对身份证"} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="sex" label="性别">
              <Radio.Group disabled={formationDisabled} onChange={(e: any) => {}}>
                <Radio value={"男"}>男</Radio>
                <Radio value={"女"}>女</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col {...colProps}>
            <Form.Item
              label="住宅"
              name="legalAddress"
              rules={[
                {
                  required: true,
                  message: "请输入200个字符内的住址信息",
                  max: 200,
                },
              ]}
            >
              <Input disabled={formationDisabled} placeholder={"自动识别，请核对详细地址"} />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item
              label="民族"
              name="legalNation"
              rules={[{ required: true, message: "请输入民族" }]}
            >
              <Select
                disabled={formationDisabled}
                placeholder={"自动识别，请核对民族"}
                options={nation_data}
              ></Select>
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item
              label="手机号"
              name="legalPhone"
              rules={[
                {
                  pattern: /^1[0-9]{10}/,
                  message: "请输入正确的手机号",
                },
                { max: 11, message: "请输入正确的手机号" },
                { required: true, message: "请输入手机号" },
              ]}
            >
              <Input placeholder={"请输入手机号"} />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item
              label="邮箱"
              name="legalMail"
              rules={[
                { required: true, message: "请输入个体户联系邮箱" },
                { max: 100, message: "请输入100个字符内" },
                {
                  pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
                  message: "邮箱格式不正确",
                },
              ]}
            >
              <Input placeholder={"请输入个体户联系邮箱"} />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item
              label="政治面貌"
              name="legalPoliticalAffiliation"
              rules={[{ required: true, message: "请选择政治面貌" }]}
              initialValue={"MASSES"}
            >
              <Select options={legalPoliticalAffiliationList} defaultValue={"MASSES"}></Select>
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Card title="银行信息">
        <Row>
          <Col {...colProps}>
            <Form.Item
              label="银行账号"
              name="bankAccount"
              rules={[{ required: namelessType, message: "请输入银行卡号" }]}
            >
              <Input style={{ width: "98%" }} placeholder="请输入银行卡号" />
            </Form.Item>
          </Col>
          <Col>
            <div
              style={{ color: "rgba(250, 130, 20, 0.85)", paddingTop: "4px", marginLeft: "12px" }}
            >
              建议使用一类卡作为收款账号，因各银行限制，二类卡一天只能收1万元，超额打款会导致收款失败!
            </div>
          </Col>
        </Row>

        <Row>
          <Col {...colProps}>
            <Form.Item
              label="开户行查询"
              name="paymentAgency"
              rules={[{ required: namelessType, message: "请选择银行" }]}
            >
              <Select
                placeholder="请选择银行"
                options={bankList?.map((item: any) => ({
                  value: item.code,
                  label: item.name,
                  id: item.code,
                }))}
                showSearch
                filterOption={filterOption}
                onSelect={(_, option: any) => {
                  form.setFieldValue("bankCode", option.id)
                  form.setFieldValue("bankNameApi", option.label)
                }}
              ></Select>
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item
              label=""
              name="provinceCode"
              rules={[{ required: namelessType, message: "请选择省份" }]}
            >
              <Cascader
                style={{ width: "98%", marginLeft: "5px" }}
                options={cityTreeList}
                placeholder="请选择省份"
                allowClear
                showSearch
              />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item
              label=""
              name="bankBranchName"
              style={{ width: 430 }}
              rules={[{ required: namelessType, message: "请输入开户行关键词" }]}
            >
              <Select
                placeholder="请搜索或选择开户支行"
                allowClear
                showSearch
                filterOption={filterOption}
                onFocus={() => {
                  const { bankCode, provinceCode } = form.getFieldsValue()
                  if (!bankCode && !provinceCode) {
                    message.error("请选择开户机构和省份")
                  }
                }}
                options={correspondent}
                onSelect={(_, option: any) => {
                  form.setFieldsValue({
                    bankName: option.label,
                    bankBranchCode: option.id,
                    codeOfBranch: option.codeOfBranch,
                    bankBranchName: option.branchName,
                  })
                }}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item hidden label="" name="bankNameApi"></Form.Item>
        <Form.Item hidden label="" name="bankCode"></Form.Item>
        <Form.Item hidden label="" name="codeOfBranch"></Form.Item>
        <Row>
          <Col {...colProps}>
            <Form.Item
              label="开户行名称"
              name="bankBranchName"
              rules={[{ required: namelessType, message: "开户行名称" }]}
            >
              <Input disabled placeholder="查询后自动输入" />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item
              label="开户行行号"
              name="bankBranchCode"
              rules={[{ required: namelessType, message: "开户行行号" }]}
            >
              <Input disabled placeholder="查询后自动输入" />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item
              label="银行预留电话"
              name="phone"
              style={{ width: 430 }}
              rules={[
                {
                  pattern: /^1[0-9]{10}/,
                  message: "请输入正确银行预留电话",
                },
                { max: 11, message: "请输入正确银行预留电话" },
                { required: true, message: "请输入银行预留电话" },
              ]}
            >
              <Input placeholder="银行预留电话" style={{ width: "98%" }} />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Card title="关联客户">
        <Row>
          <Col {...colProps}>
            <Form.Item label="组织名称" name="linkCustomerId">
              <Select
                placeholder="请输入关键词查询"
                allowClear
                showSearch
                onSearch={handleSearchOrg}
                filterOption={filterOption}
                onClear={() => setOrgsList([])}
                options={orgsList}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Card title="名称申报">
        <Row>
          <div className="needName-form">
            <FormItem label="创建无名称个体户" name="needName">
              <Radio.Group
                defaultValue={namelessType}
                onChange={(e: any) => {
                  setNamelessType(e.target.value)
                  setNameLessFlag(e.target.value)
                }}
              >
                {namelessOptionEnum.map((item: any) => {
                  return (
                    <Radio key={item.value} value={item.value}>
                      {item.label}
                    </Radio>
                  )
                })}
              </Radio.Group>
            </FormItem>
            <p>若无需名称，个体户营业执照名称为空</p>
          </div>
        </Row>
        <Row style={{ display: namelessType ? "block" : "none" }}>
          <Col {...colProps}>
            <Form.Item
              label="企业/个体户名称"
              name="name"
              style={{ width: 430 }}
              rules={[{ required: namelessType, message: "请输入企业名称" }]}
            >
              <Input addonBefore={individualPrefix} addonAfter={individualSuffix} />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ display: namelessType ? "block" : "none" }}>
          <Col {...colProps}>
            <Form.Item
              label="备选1"
              name="name1"
              style={{ width: 430 }}
              rules={[{ required: namelessType, message: "请输入企业名称" }]}
            >
              <Input addonBefore={individualPrefix} addonAfter={individualSuffix} />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ display: namelessType ? "block" : "none" }}>
          <Col {...colProps}>
            <Form.Item
              label="备选2"
              name="name2"
              style={{ width: 430 }}
              rules={[{ required: namelessType, message: "请输入企业名称" }]}
            >
              <Input addonBefore={individualPrefix} addonAfter={individualSuffix} />
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </>
  )
})

export default BasicInformation
