import { createImage } from "./utils"
export const blobToUrl = (blob: Blob) => {
  return URL.createObjectURL(blob)
}

export const imageToCanvas = (
  image: HTMLImageElement | HTMLCanvasElement,
  setStyle?: (context: CanvasRenderingContext2D | null) => void
) => {
  const canvas = document.createElement("canvas")
  canvas.height = image.height
  canvas.width = image.width
  const context = canvas.getContext("2d")
  setStyle?.(context)
  context?.drawImage(image, 0, 0, image.width, image.height)
  return canvas
}

export const blobToCanvas = async (blob: any) => {
  const url: any = blobToUrl(blob)
  const image = await createImage(url)
  const canvas = imageToCanvas(image, (context) => {
    // 背景设置为白色
    if (!context) return
    context.fillStyle = "#fff"
    context.fillRect(0, 0, context.canvas.width, context.canvas.height)
  })
  canvas.dataset.filename = blob.name || "image" + blob.type.split("/").splice(-1)
  URL.revokeObjectURL(url)
  return canvas
}

export const canvasToBlob = async (canvas: HTMLCanvasElement, type?: string, quality?: number) => {
  return new Promise<Blob>((resolve, reject) => {
    canvas.toBlob(
      (blob: any) => {
        if (blob) {
          blob.name = canvas.dataset.filename
          resolve(blob)
        } else {
          reject(blob)
        }
      },
      type || "image/jpeg",
      quality
    )
  })
}

export const downloadCorsImage = async (href: string) => {
  const image = await createImage(href)
  const canvas = await imageToCanvas(image)
  const noCorsCanvas = await imageToCanvas(canvas)
  return await canvasToBlob(noCorsCanvas)
}

export const getFileUrl = async (url: string) => {
  let download_a = document.createElement("a")
  download_a.setAttribute("href", url)
  download_a.setAttribute("target", "_blank")
  download_a.setAttribute("id", "camnpr")
  document.body.appendChild(download_a)
  download_a.click()
  document.body.removeChild(download_a)
}
