/* eslint-disable @typescript-eslint/no-this-alias */
// @ts-nocheck
import React, { Component } from "react"
import { Modal, Checkbox, message, Radio, Spin, Button } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import Axios from "axios"
const CheckboxGroup = Checkbox.Group
import "./storeList.scss"
import { RouteProps } from "react-router-dom"
import UtilOp from "../../services/serviceOp"
const util = UtilOp.getInstance()
interface AsyncUrls {
  url: string
  taskUrl: string
}

interface Props {
  asyncUrls?: AsyncUrls
  columnsKey: () => any[]
  closeExportStoreList: Function
  serchObj: Object
  size: number
  current: number
  total: number
  url?: string
  pageKey?: string
  headers?: { [key: string]: any }
  name?: string
  exportName?: string
}

interface State {
  isLoading: boolean
  columnsKey: any[]
  selectList: any[]
  isAllChecked: boolean
  exportType: number
  exportExcelModel: boolean
  isExcelLoading: boolean
  disabledBtn: boolean
  antIcon: any
  taskNo: string
  fileId: string
  exportExcelHref: string
  originalName: string
}

class ExportStoreList extends Component<Props, State> {
  state: State = {
    isLoading: false,
    columnsKey: [],
    selectList: [],
    isAllChecked: true,
    exportType: 2,
    exportExcelModel: false,
    isExcelLoading: true,
    disabledBtn: true,
    taskNo: "",
    fileId: "",
    exportExcelHref: "",
    originalName: "",
    antIcon: <LoadingOutlined style={{ fontSize: 24 }} spin />,
  }
  timor: NodeJS.Timeout
  UNSAFE_componentWillMount() {
    console.log(this.props, "dsfds")
    // let settings: { [key: string]: any } | null = localStorage.getItem(`${window.EStorage.getItem('tenantId')}_PageExportSetting`) && JSON.parse(localStorage.getItem(`${window.EStorage.getItem('tenantId')}_PageExportSetting`))
    let initColumnsKey = []
    this.props.columnsKey().forEach((item) => {
      if (item.dataIndex != "operation") {
        initColumnsKey.push({
          label: item.title,
          value: item.dataIndex,
        })
      }
    })
    let pageKey = this.props?.pageKey || location.pathname
    let initSelectList = initColumnsKey.map((item) => item.value)
    this.setState({
      columnsKey: initColumnsKey,
      selectList: initSelectList,
      isAllChecked: initSelectList.length === initColumnsKey.length,
    })
  }
  // 导出
  exportStoreList = () => {
    let { selectList, columnsKey } = this.state
    console.log(this.props, "0000")
    let list = []
    selectList.forEach((item) => {
      columnsKey.forEach((it, ix) => {
        if (item == it.value) {
          list.push(it)
        }
      })
    })
    if (list.length == 0) {
      message.error("至少选择一列导出！")
      return
    }
    let fields = {},
      newKey = []
    list.forEach((item) => {
      fields[item.value] = item.label
      newKey.push(item.value)
    })
    // let settings: { [key: string]: any } | null = localStorage.getItem(`${window.EStorage.getItem('tenantId')}_PageExportSetting`) && JSON.parse(localStorage.getItem(`${window.EStorage.getItem('tenantId')}_PageExportSetting`)) || {}
    // let pageKey = this.props?.pageKey || location.pathname
    // settings[pageKey] = newKey
    // console.log(window.EStorage.getItem('tenantId'), 'localStorage.getItem("tenantId")')
    // localStorage.setItem(`${window.EStorage.getItem('tenantId')}_PageExportSetting`, JSON.stringify(settings))
    if (this.props.asyncUrls) {
      console.log("订单管理-采购订单")
      this.setState({ exportExcelModel: true })
      util
        .httpGet(this.props.asyncUrls.url, {
          params: {
            ...this.props.serchObj,
            size: this.state.exportType == 1 ? this.props.size : this.props.total,
            current: this.state.exportType == 1 ? this.props.current : 1,
          },
          headers: {
            isExport: true,
            fields: encodeURI(JSON.stringify(fields)),
            ...this.props.headers,
          },
          // responseType: 'blob',
        })
        .then((res) => {
          // const url = `/sinzetech-order/jlorder/excel/export/${res.data.data.taskNo}?fileId=${res.data.data.fileId}`
          const url = `${this.props.asyncUrls.taskUrl}/${res.data.data.taskNo}?fileId=${res.data.data.fileId}`
          this.getExportData(url)
        })
        .catch((err) => {
          this.setState({
            disabledBtn: false,
          })
        })
    } else {
      console.log(fields, "fields")
      let params = {
        ...this.props.serchObj,
        exportFields: JSON.stringify(fields),
        size: this.state.exportType == 1 ? this.props.size : this.props.total,
        current: this.state.exportType == 1 ? this.props.current : 1,
      }
      util
        .httpGet(this.props.url, params)
        .then((res) => {
          window.open(res.data)
          // let that = this
          // this.setState({ isLoading: false })
          // let blob = new Blob([res.data])
          // let reader = new FileReader()
          // reader.readAsDataURL(blob)
          // reader.onload = (e) => {
          //   let a = document.createElement("a")
          //   // a.download = '导出数据' + '.xlsx';
          //   a.download =
          //     that.props.exportName ||
          //     that.props.name ||
          //     decodeURI(res.headers["content-disposition"].split(";")[1].split(`=`)[1])
          //   a.href = URL.createObjectURL(blob)
          //   document.querySelector("body").append(a)
          //   a.click()
          // }
        })
        .catch((err) => {
          this.setState({ isLoading: false })
        })
      this.props.closeExportStoreList()
    }
  }
  // 轮询导出
  getExportData = (url) => {
    Axios.get(url, {}).then((res) => {
      if (res.data.data) {
        this.setState({
          disabledBtn: false,
          exportExcelHref: res.data.data.downloadUrl,
          originalName: res.data.data.originalName,
        })
      } else {
        this.timor = setTimeout(() => {
          this.getExportData(url)
        }, 1000 * 2)
      }
    })
  }
  // 单个选择
  childrenChange = (list: any[]) => {
    let { columnsKey, isAllChecked } = this.state
    columnsKey.length === list.length ? (isAllChecked = true) : (isAllChecked = false)
    this.setState({
      selectList: list,
      isAllChecked,
    })
  }
  // 全选/取消全选
  onCheckAllChange = (e: { target: { checked: any } }) => {
    let { columnsKey, selectList } = this.state
    e.target.checked ? (selectList = columnsKey.map((item) => item.value)) : (selectList = [])
    this.setState({ isAllChecked: e.target.checked, selectList })
  }
  // 下载
  exportExcel = () => {
    this.downlad(this.state.exportExcelHref, this.state.originalName)
    message.success("下载成功")
    this.props.closeExportStoreList()
  }
  // 下载文件
  downlad = (urls: any, fileName: any) => {
    const x = new window.XMLHttpRequest()
    x.open("GET", urls, true)
    x.responseType = "blob"
    x.onload = () => {
      const url = window.URL.createObjectURL(x.response)
      const a = document.createElement("a")
      a.href = url
      a.target = "_blank"
      a.download = fileName
      a.style.display = "none"
      document.body.append(a)
      a.click()
    }
    x.send()
  }
  // 取消
  closeExportExcel = () => {
    this.setState({
      exportExcelModel: false,
      disabledBtn: true,
    })
  }
  componentWillUnmount(): void {
    clearTimeout(this.timor)
  }
  render() {
    let { selectList, columnsKey, isAllChecked, exportExcelModel, disabledBtn, antIcon } =
      this.state
    return (
      <div id="inStorage_modal">
        {this.state.isLoading ? <>loading</> : null}
        <Modal
          className="export_inStorage_modal"
          title="数据导出"
          visible={true}
          // onOk={this.exportStoreList}
          onCancel={() => {
            this.props.closeExportStoreList()
          }}
          // cancelText="取消"
          // okText="确定"
          footer={null}
        >
          <div className="exportType">
            <Radio.Group
              name="radiogroup"
              value={this.state.exportType}
              onChange={(e) => {
                this.setState({ exportType: e.target.value })
              }}
            >
              <Radio value={1}>导出当前查询分页数据</Radio>
              <Radio value={2}>导出当前查询全部数据</Radio>
            </Radio.Group>
          </div>
          <div className="CheckAll" style={{ borderBottom: "1px solid #e9e9e9", marginBottom: 8 }}>
            <Checkbox
              onChange={(e) => {
                this.onCheckAllChange(e)
              }}
              checked={isAllChecked}
            >
              导出表头选择
            </Checkbox>
          </div>
          <div className="Check-list">
            <CheckboxGroup
              options={columnsKey}
              value={selectList}
              onChange={(list) => {
                this.childrenChange(list)
              }}
            />
          </div>
          <div style={{ width: "100%", borderTop: "1px solid #f0f0f0" }}>
            <div className="check-modal">
              <i></i>
              <div style={{ marginBottom: 10 }}>
                <Button
                  className="check-modal-button"
                  onClick={() => {
                    this.props.closeExportStoreList()
                  }}
                >
                  取消
                </Button>
                <Button type="primary" onClick={this.exportStoreList}>
                  确定
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          className="export_excel_modal"
          title="文件下载"
          visible={exportExcelModel}
          onOk={this.exportExcel}
          okButtonProps={{ disabled: disabledBtn }}
          onCancel={this.closeExportExcel}
          cancelText="取消"
          okText="下载"
        >
          <div className="export_excel_box">
            <p>{disabledBtn ? "文件生成中，请勿关闭网页" : "文件已生成"}</p>
            {this.state.disabledBtn ? <Spin indicator={antIcon} /> : null}
          </div>
        </Modal>
      </div>
    )
  }
}

export default ExportStoreList
