import React, { useEffect, useState } from "react"
import { Modal, Form, Button, message, Input } from "antd"
import { putRejectOrgs } from "../service"
// import "./index.scss"

const { TextArea } = Input
type type_modal_operator = {
  (a: boolean): void
}

type Reject_T = {
  rejectStatus: boolean
  addModal: type_modal_operator
  cb: () => void
  ids: string[]
}
const Reject: React.FC<Reject_T> = ({ rejectStatus, ids, addModal, cb }) => {
  const [form] = Form.useForm()
  const handleCancel = () => {
    form.resetFields()
    addModal(false)
  }
  const [textVal, setTextVal] = useState("")
  const onFinish = async () => {
    const res: any = await putRejectOrgs({ ids, refuseReason: textVal })
    if (res.code === 200) {
      message.success("驳回成功")
      handleCancel()
      cb()
    }
  }

  return (
    <>
      <Modal
        open={rejectStatus}
        title="审核驳回"
        onCancel={handleCancel}
        centered
        width={500}
        footer={null}
      >
        <Form
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 500 }}
          onFinish={onFinish}
          className="reject_content"
        >
          <Form.Item
            name="refuseReason"
            label="请输入驳回原因"
            rules={[
              {
                required: true,
                message: "请输入驳回原因",
              },
            ]}
            style={{ marginRight: 20, padding: "10px 0" }}
            wrapperCol={{ span: 16 }}
          >
            <TextArea
              value={textVal}
              onChange={(e) => setTextVal(e.target.value)}
              placeholder="请输入驳回原因"
            />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 16 }} style={{ borderTop: "1px solid #e8e8e8" }}>
            <div
              className="form_footer"
              style={{
                display: "flex",
                marginBottom: 10,
                paddingTop: 10,
                paddingRight: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  textAlign: "right",
                  marginLeft: "110%",
                }}
              >
                <Button
                  style={{ marginRight: "12px" }}
                  onClick={(e) => {
                    handleCancel()
                  }}
                >
                  取消
                </Button>
                <Button type="primary" htmlType="submit">
                  确定
                </Button>
              </div>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
export default Reject
