import { Modal, ModalFuncProps, message } from "antd"
import dayjs from "dayjs"
import numeral from "numeral"

export const confirmToContinue = (params?: ModalFuncProps) => {
  return new Promise((resolve, reject) => {
    Modal.confirm({
      ...params,
      okText: "确认",
      cancelText: "取消",
      onCancel: () => {
        reject()
      },
      onOk: () => {
        resolve(null)
      },
    })
  }).catch((error) => {
    console.log(error)
    return Promise.reject(error)
  })
}
export const checkValueToContinue = (value: any, msg: string = "缺少值") => {
  if (value) {
    return Promise.resolve(null)
  } else {
    message.warning(msg)
    return Promise.reject(msg)
  }
}
export const whileLoading = async <T>(request: () => Promise<T>, msg: string = "处理中") => {
  const destroy = message.loading(msg, 0)
  const response = await request().finally(() => {
    destroy()
  })
  return response
}

export function isCrossOrigin(url: string) {
  const currentOrigin = new URL(window.location.href)
  const requestOrigin = new URL(url)
  return (
    currentOrigin.hostname !== requestOrigin.hostname ||
    currentOrigin.protocol !== requestOrigin.protocol ||
    currentOrigin.port !== requestOrigin.port
  )
}

export const dateWithFallback = (input?: dayjs.ConfigType) => {
  return input ? dayjs(input) : undefined
}

export const formatAmount = (amount: string | number | null = 0) => {
  return numeral(amount || 0).format("0,0.00[0000]")
}

export const downloadFile = ({
  filename,
  data,
}: {
  filename?: string
  data: Blob | File | string
}) => {
  const href = typeof data === "string" ? data : window.URL.createObjectURL(data)
  const a = document.createElement("a")
  a.setAttribute("href", href)
  a.setAttribute("target", "_blank")
  a.setAttribute("download", filename || "文件") // 自定义文件名（有效）

  document.body.appendChild(a)
  a.click()
  try {
    document.body.removeChild(a)
  } catch (e) {
    window.console.log(e)
  }
  window.URL.revokeObjectURL(href)
}

// Map 类型转为 Option 数组类型
export const transMapToOptions = (map: Record<string, any>) => {
  return Object.keys(map).reduce(
    (list, key) => list.concat({ value: key, label: map[key] }),
    [] as { value: string; label: string }[]
  )
}

// Option 数组类型 转为 Map 类型
export const transOptionsToMap = (options: { label: any; value: string; [key: string]: any }[]) => {
  return options.reduce(
    (map, item) => ({ ...map, [item.value]: item.label }),
    {} as Record<string, any>
  )
}
