import React, {
  CSSProperties,
  FC,
  HTMLAttributes,
  PropsWithChildren,
  useEffect,
  useState,
} from "react"
import { Image, ImageProps } from "antd"
import { EyeOutlined, ToTopOutlined } from "@ant-design/icons"
import classNames from "classnames"
import "./index.scss"
interface Props extends ImageProps {
  actions?: React.ReactNode[]
  customActions?: (previewButton: React.ReactNode) => React.ReactNode
  classFileName?: any
}

export type EditableImageProps = Props & HTMLAttributes<any>
export const EditableImage: FC<PropsWithChildren<EditableImageProps>> = (props) => {
  const { actions, customActions, src, classFileName, width, height, ...rest } = props
  const [useSrc, setUseSrc] = useState(src)
  useEffect(() => {
    setUseSrc(src)
  }, [src])
  const [visible, setVisible] = useState(false)
  const onVisibleChange = (visible: boolean, prevVisible: boolean) => {
    // 只接受关闭功能
    if (visible === false) setVisible(visible)
  }
  const resultClassName = classFileName + "edit"
  const previewButton = <EyeOutlined title="预览" onClick={() => setVisible(true)} />
  return (
    <div>
      <Image
        onError={() => {
          setUseSrc("/fallback-image.jpg")
        }}
        src={useSrc}
        preview={{
          onVisibleChange,
          visible,
          mask: customActions ? (
            customActions(previewButton)
          ) : (
            <div style={{ display: "flex", gap: 12, fontSize: 16 }}>
              <div onClick={() => setVisible(true)} title="预览图片" className={resultClassName}>
                {previewButton}
              </div>
              {actions}
            </div>
          ),
        }}
        {...rest}
        style={{
          objectFit: "contain",
          width,
          height,
          ...rest.style,
        }}
      />
      <div>
        <span>{props.title}</span>
      </div>
    </div>
  )
}
