import { DownloadFileButton } from "../DownloadFileButton"
import { DownloadOutlined, LoadingOutlined, PlusOutlined, SyncOutlined } from "@ant-design/icons"
import classNames from "classnames"
import React, { PropsWithChildren } from "react"
import { EditableImage, EditableImageProps } from "../EditableImage"
import { EasyUpload, EasyUploadFileProps } from "../EasyUpload"
import styles from "./index.module.less"
import { uuid } from "@/utils/util"
import { BASE_URL_AUTH, BASE_URL_SOURCE } from "@/api/config/servicePort"

const defaultProps = {}
type props = {
  noRenew?: boolean
  src?: string
  editableImageProps?: EditableImageProps
  action?: string
  uploadFileProps?: EasyUploadFileProps
  updateFileProps?: EasyUploadFileProps
  createFileProps?: EasyUploadFileProps
  width?: number
  height?: number
  onSuccess?: EasyUploadFileProps["onSuccess"]
  onError?: EasyUploadFileProps["onError"]
  title?: string
  filename?: string
}
const actionStyle = { color: "#fff", fontSize: 16 }
export type OperableImageProps = Partial<typeof defaultProps> & props

export const OperableImage: React.FC<PropsWithChildren<OperableImageProps>> = (props) => {
  const {
    noRenew,
    src,
    children,
    editableImageProps,
    uploadFileProps,
    width,
    height,
    onSuccess,
    onError,
    title,
    filename,
    action,
  } = props
  const { updateFileProps = uploadFileProps, createFileProps = uploadFileProps } = props

  const renderEditableIamge = () => {
    let _actions = [
      <DownloadFileButton
        key="download"
        href={src ? src : ""}
        filename={filename ? filename : title}
      >
        {(props) => (
          <div style={actionStyle} onClick={props.onDownload} title="下载图片">
            {props.loading ? <LoadingOutlined /> : <DownloadOutlined />}
          </div>
        )}
      </DownloadFileButton>,
    ]
    if (!noRenew) {
      _actions = [
        <EasyUpload
          key="reload"
          onSuccess={onSuccess}
          onError={onError}
          action={action}
          method="PUT"
          {...updateFileProps}
        >
          <div style={actionStyle} title="更新图片">
            <SyncOutlined />
          </div>
        </EasyUpload>,
        ..._actions,
      ]
    }
    return (
      <EditableImage
        key={1}
        title={title}
        width={width}
        height={height}
        src={src}
        {...editableImageProps}
        actions={_actions}
      />
    )
  }
  return (
    <>
      <div className={classNames(styles.container)} style={{ width, height }} title={title}>
        {src ? (
          renderEditableIamge()
        ) : (
          <EasyUpload
            key={2}
            onSuccess={onSuccess}
            onError={onError}
            listType="picture-card"
            action={action}
            method="PUT"
            {...createFileProps}
          >
            {/* @ts-ignore */}
            {(props) => (
              <EasyUpload.ImageTypeUI {...props}>
                <>
                  <PlusOutlined />
                  <span style={{ marginTop: 8 }}>{children}</span>
                </>
              </EasyUpload.ImageTypeUI>
            )}
          </EasyUpload>
        )}
      </div>
    </>
  )
}

OperableImage.defaultProps = defaultProps
