import React, { useEffect, useState, useImperativeHandle, forwardRef } from "react"
import { Form, Input, Radio, Row, Col, Button } from "antd"
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import { useDispatch } from "react-redux"
import { UPDATE_BASIC } from "../../../../store/constants"
import "./index.scss"
const FormItem = Form.Item

/**
 * @param{type} string 区别是限制条件或者基本类型数据
 * */
const layout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 24,
  },
}
const radioList = [
  {
    label: "读写",
    value: "WRITE",
  },
  {
    label: "只读",
    value: "READ",
  },
  {
    label: "隐藏",
    value: "HIDE",
  },
]

const uuid = () => {
  let date = new Date().valueOf() //获取时间戳
  let txt = "1234567890" //生成的随机机器码
  let len = 13 //机器码有多少位
  let pwd = "" //定义空变量用来接收机器码
  for (let i = 0; i < len; i++) {
    pwd += txt.charAt(Math.floor(Math.random() * txt.length)) //循环机器码位数随机填充
  }
  return date + pwd
}
// 动态创建表单
const FormCreate = (props: any) => {
  const { name, label, cb, rules, defaultValue, isInfo, type, formCreateFlag } = props
  const [list, setList] = useState([
    {
      value: "",
      id: uuid(),
    },
  ])
  const handleCreate = () => {
    let arr = JSON.parse(JSON.stringify(list))
    arr.push({
      value: "",
      id: uuid(),
    })
    setList(arr)
  }
  const handleReset = () => {
    let arr: any = []
    arr.push({
      value: "",
      id: uuid(),
    })
    setList(arr)
  }
  const remove = (object: any) => {
    const arr = JSON.parse(JSON.stringify(list))
    const res = arr.filter((item: any) => item.id !== object.id)
    setList(res)
  }
  const handleInput = (e: any, object: any) => {
    const id = e.target.id
    const value = e.target.value
    const arr = JSON.parse(JSON.stringify(list))
    const res = arr.map((item: any) => {
      if (item.id === id) {
        item.value = value
      }
      return item
    })
    setList(res)
  }
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    cb && cb(list)
  }, [list]) // eslint-disable-line
  useEffect(() => {
    if (defaultValue?.option && defaultValue?.option.length !== 0) {
      if (type === "select") {
        const res = defaultValue.option.map((item: any) => {
          return {
            value: item.value,
            id: uuid(),
          }
        })
        setList(res)
      } else {
        const res = defaultValue.option.map((item: any) => {
          return {
            value: item,
            id: uuid(),
          }
        })
        setList(res)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue])
  return (
    <Form.Item name={name} rules={rules}>
      <div className="select_ipt">
        <p style={{ width: formCreateFlag ? 120 : 150, marginLeft: formCreateFlag ? 28 : 0 }}>
          {label}：
        </p>
        <div className="select-add">
          {list.map((item, index) => {
            return (
              <>
                <div key={index}>
                  <Input
                    disabled={isInfo}
                    key={index}
                    placeholder="请输入"
                    style={{ width: "400px" }}
                    onChange={(e) => handleInput(e, item)}
                    id={item.id}
                    value={item.value}
                  ></Input>
                  {list.length > 1 && !isInfo ? (
                    <Button
                      type="text"
                      icon={<MinusCircleOutlined style={{ color: "red" }} />}
                      onClick={() => remove(item)}
                    ></Button>
                  ) : null}
                  {!isInfo && (
                    <>
                      <Button
                        onClick={handleCreate}
                        style={{ marginLeft: 16 }}
                        type="primary"
                        ghost
                        icon={<PlusOutlined />}
                      >
                        添加选项
                      </Button>
                      <Button style={{ marginLeft: 16 }} danger onClick={handleReset}>
                        重置
                      </Button>
                    </>
                  )}
                </div>
              </>
            )
          })}
        </div>
      </div>
    </Form.Item>
  )
}

const SelectTemplate = (props: any, ref: any) => {
  const { infoType = "basicInfo", infoData, isInfo } = props
  const dispatch = useDispatch<any>()
  const [formBacis] = Form.useForm()
  const formValidate = () => {
    formBacis.validateFields()
  }
  const updateState = (type: string, value: any) => {
    if (infoType === "basicInfo") {
      setBasic({
        ...basic,
        [type]: value,
      })
      dispatch({
        type: UPDATE_BASIC,
        payload: {
          [type]: value,
        },
      })
    }
  }
  const reset = () => {
    formBacis.resetFields()
  }
  const [basic, setBasic] = useState<any>({})
  useImperativeHandle(ref, () => ({
    reset,
    formValidate,
  }))
  useEffect(() => {
    if (infoData?.name && infoData?.name !== "") {
      formBacis.setFieldValue("name", infoData.name)
    }
  }, [infoData]) // eslint-disable-line
  if (infoType === "basicInfo") {
    return (
      <div>
        <Form {...layout} form={formBacis}>
          <FormItem
            name="name"
            label="字段名称"
            rules={[
              {
                required: true,
                message: "请输入字段名称",
              },
            ]}
          >
            <Input
              maxLength={10}
              disabled={isInfo}
              placeholder="请输入"
              defaultValue={infoData?.name}
              className="ipt"
              onChange={(e) => {
                updateState("name", e.target.value)
                formBacis.setFieldValue("name", e.target.value)
              }}
            ></Input>
            <span className="span_color">不超过10个字，支持中文，英文，数字，字母组合</span>
          </FormItem>
          <FormItem label="默认值">
            <Input
              disabled={isInfo}
              defaultValue={infoData?.defaultValue?.dataValue}
              placeholder="请输入"
              className="ipt"
              onChange={(e) => {
                updateState("dataValue", e.target.value)
              }}
            ></Input>
            <span className="span_color">按照限制条件展示，若与限制条件不符，则不展示</span>
          </FormItem>
          <Row>
            <Col span="24">
              <FormCreate
                defaultValue={infoData?.rules?.textPrefixOption}
                name="textPrefixOption"
                label="文本前置下拉配置"
                cb={(values: any) => {
                  const res = values.map((item: any) => item.value)
                  updateState("textPrefixOption", {
                    option: res,
                  })
                }}
                isInfo={isInfo}
              ></FormCreate>
            </Col>
            <Col span="24">
              <FormCreate
                defaultValue={infoData?.rules?.textSuffixOption}
                name="textSuffixOption"
                label="文本后置下拉配置"
                cb={(values: any) => {
                  const res = values.map((item: any) => item.value)
                  updateState("textSuffixOption", {
                    option: res,
                  })
                }}
                isInfo={isInfo}
              ></FormCreate>
            </Col>
          </Row>
          <FormItem label="输入正则表达式" name="regularExpression">
            <Input
              disabled={isInfo}
              defaultValue={infoData?.rules?.regularExpression}
              className="ipt"
              placeholder="请输入默认字段"
              onChange={(e: any) => {
                updateState("regularExpression", e.target.value)
              }}
            ></Input>
          </FormItem>
          <FormItem label="用户权限" name="accessType" className="user-crud">
            <Radio.Group
              disabled={isInfo}
              defaultValue={infoData?.accessType}
              onChange={(e) => {
                updateState("accessType", e.target.value)
              }}
            >
              {radioList.map((item: any) => (
                <Radio key={item.value} value={item.value}>
                  {item.label}
                </Radio>
              ))}
            </Radio.Group>
            <span className="user-crud-span span_color">
              读写:允许用户查看和编辑该字段;只读:用户只能查看该字段;隐藏:用户无法查看该字段
            </span>
          </FormItem>
          <FormItem label="占位内容" name="placeholder">
            <Input
              disabled={isInfo}
              defaultValue={infoData?.placeholder}
              placeholder="请输入"
              className="ipt"
              onChange={(e) => {
                updateState("placeholder", e.target.value)
              }}
            ></Input>
            <span className="span_color">
              占位内容,显示在输入框中每用户点击输入框或有内容输入后消失
            </span>
          </FormItem>
          <FormItem label="字段说明" name="comment">
            <Input
              disabled={isInfo}
              placeholder="请输入"
              className="ipt"
              defaultValue={infoData?.comment}
              onChange={(e) => {
                updateState("comment", e.target.value)
              }}
            ></Input>
            <span className="span_color">
              字段说明,显示在字段输入框之后(移动端在输入框下方),用于提示输入框及规则
            </span>
          </FormItem>
          <FormItem label="选项类型">
            <Radio.Group
              disabled={isInfo}
              defaultValue={infoData?.rules?.isMultiple}
              onChange={(e) => {
                updateState("isMultiple", e.target.value)
              }}
            >
              {[
                { label: "单选", value: 0 },
                { label: "复选", value: 1 },
              ].map((item: any) => (
                <Radio key={item.value} value={item.value}>
                  {item.label}
                </Radio>
              ))}
            </Radio.Group>
          </FormItem>
          {/* <FormItem label='选项数据来源'>
          <Radio.Group>
            {
              [{label:'静态数据', value:1}, {label:'外部数据库', value:2},{label:'远端数据', value:3}].map((item:any) => <Radio key={item.value} value={item.value}>{item.label}</Radio>)
            }
          </Radio.Group>
        </FormItem> */}
          <FormCreate
            type="select"
            defaultValue={infoData?.rules?.optionData}
            formCreateFlag={true}
            name="next"
            label="选项数据配置"
            cb={(values: any) => {
              // const res = values.map((item:any) => item.value)
              updateState("optionData", {
                option: values,
              })
            }}
            isInfo={isInfo}
          ></FormCreate>
        </Form>
      </div>
    )
  } else {
    return null
  }
}

export default forwardRef(SelectTemplate)
