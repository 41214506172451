import { Descriptions, DescriptionsProps } from "antd"
import { DescriptionsItemProps } from "antd/lib/descriptions/Item"
import React, { memo, useContext } from "react"
import { OrderDetailContext } from "../../useOrderDetail"
import { formatAmount } from "../../../../../../../utils/utils"
const defaultProps = {}
type props = {}
export type BasicInfoProps = Required<typeof defaultProps> &
  props &
  React.HTMLAttributes<HTMLDivElement>
export const BasicInfo: React.FC<React.PropsWithChildren<BasicInfoProps>> = memo((props) => {
  const context = useContext(OrderDetailContext)
  const Desc: (Omit<DescriptionsItemProps, "children"> & {
    render?: (value: any, T?: API.Logistics.Detail) => React.ReactNode
    children: keyof API.Logistics.Detail | string
  })[][] = [
    [
      { label: "原发品类", children: "originalCategoryName" },
      { label: "质检品类", children: "categoryName" },
    ],
    [
      {
        label: "原发毛重",
        children: "originalGrossWeight",
        render: (text, record) => (text || 0) + record?.originalQuantityUnitName,
      },
      { label: "原发毛重时间", children: "" },
      {
        label: "原发皮重",
        children: "originalTareWeight",
        render: (text, record) => (text || 0) + record?.originalQuantityUnitName,
      },
      { label: "原发皮重时间", children: "" },
      {
        label: "原发净重",
        children: "originalNetWeight",
        render: (text, record) => (text || 0) + record?.originalQuantityUnitName,
      },
    ],
    [
      {
        label: "到厂毛重",
        children: "grossWeight",
        render: (text, record) => (text || 0) + record?.measureUnit,
      },
      { label: "到厂毛重时间", children: "grossWeightTime" },
      {
        label: "到厂皮重",
        children: "tareWeight",
        render: (text, record) => (text || 0) + record?.measureUnit,
      },
      { label: "到厂皮重时间", children: "tareWeightTime" },
      {
        label: "到厂净重",
        children: "netWeight",
        render: (text, record) => (text || 0) + record?.measureUnit,
      },
    ],
    [
      {
        label: "扣杂",
        children: "deduction",
        render: (text, record) => (text || 0) + record?.measureUnit,
      },
      { label: "磅单号", children: "manifestNo" },
    ],
    [
      { label: "发货时间", children: "loadingDate" },
      { label: "完成送货时间", children: "finishDeliveryDate" },
    ],
  ]
  return (
    <>
      {Desc.map((descRow, index) => (
        <Descriptions column={5} key={index}>
          {descRow.map(({ children, ...rest }, index) => (
            <Descriptions.Item key={index} {...rest}>
              {/* @ts-ignore */}
              {rest.render
                ? rest.render(
                    context?.logisticsDetailService?.data?.[children],
                    context?.logisticsDetailService.data
                  )
                : context?.logisticsDetailService?.data?.[children]}
            </Descriptions.Item>
          ))}
        </Descriptions>
      ))}
    </>
  )
})
BasicInfo.displayName = "物流基本信息"
BasicInfo.defaultProps = defaultProps
