import React, { useMemo, useState } from "react"
import ReactECharts from "echarts-for-react"

export const CameraCharts = ({ list }) => {
  const [newList, setNewList] = useState<any>([])
  useMemo(() => {
    let names = list?.map(function (item) {
      return item.cameraCount
    })
    setNewList(names)
  }, [list])
  // 渲染图表选项
  const option = {
    title: {
      // text: "摄像头示例图",
      textStyle: {
        color: "#fff", // 图例字体颜色
      },
    },
    tooltip: {
      trigger: "axis",
    },
    legend: {
      data: list?.map((item) => item.name),
    },
    xAxis: {
      type: "category",
      data: list?.map((item) => item.name),
      axisLabel: {
        textStyle: {
          color: "#fff",
        },
      },
      axisLine: {
        lineStyle: {
          color: "rgba(2, 140, 240, 1)", // y 轴颜色
          width: 2, // X 轴直线宽度
          type: "line", // X 轴直线类型
        },
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        textStyle: {
          color: "#fff",
        },
      },
    },
    series: [
      {
        // name: series.name,
        type: "line",
        data: newList,
        itemStyle: {
          normal: {
            color: "rgba(0, 198, 186, 1)", // 折线颜色
          },
        },
        lineStyle: {
          color: "rgba(0, 255, 224, 1)", // 线条颜色
        },
        areaStyle: {
          color: "rgba(0, 198, 186, 1)", // 阴影颜色
          opacity: 0.5, // 阴影透明度
          shadowBlur: 10, // 阴影模糊度
          shadowColor: "rgba(0, 136, 198, 0)", // 阴影颜色
        },
      },
    ],
  }
  return <ReactECharts option={option} style={{ height: "300px", width: 500 }} />
}

export default CameraCharts
