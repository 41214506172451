import { Modal, Select, message, Button } from "antd"
import React, { useEffect, useState } from "react"
import { getupDataManualCredit } from "../promise"
interface CompleteProps {
  cancelModal: (a?: boolean) => void
  id: string
}
const Complete = ({ cancelModal, id }: CompleteProps) => {
  useEffect(() => {}, [])
  const [channel, setchannel] = useState<string>()
  const submit = async () => {
    if (!channel) {
      return message.error("请选择开票渠道")
    }
    let params: any = {
      id: id,
      channel: channel,
    }
    const res: any = await getupDataManualCredit(params)
    if (res.code == 200) {
      message.success("操作成功")
      cancelModal(true)
    }
  }
  return (
    <div className="commonality">
      <Modal
        title="确认当前开票方已完成开通任务"
        onCancel={() => {
          cancelModal()
        }}
        open={true}
        footer={null}
      >
        <div style={{ padding: 24 }}>
          <span style={{ marginRight: 14 }}>
            <span style={{ color: "red" }}>*</span>
            开票渠道
          </span>

          <Select
            value={channel || "请选择"}
            style={{ width: 200 }}
            onChange={(value) => {
              setchannel(value)
            }}
          >
            <Select.Option value="BW">百望云</Select.Option>
            <Select.Option value="MANUAL">电子税务局</Select.Option>
            <Select.Option value="HX">航信</Select.Option>
          </Select>
        </div>
        <div style={{ width: "100%", borderTop: "1px solid #f0f0f0" }}>
          <div className="check-modal">
            <i></i>
            <div style={{ marginBottom: 10 }}>
              <Button
                className="check-modal-button"
                onClick={() => {
                  cancelModal()
                }}
              >
                取消
              </Button>
              <Button type="primary" onClick={submit}>
                完成
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Complete
