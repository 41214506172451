import React from "react"
import { Select } from "antd"
interface IProps {
  options: any[]
  placeholder: string
  value: any
  onChange: Function
}
/**
 * 搜索框自定义组件
 * @param props
 * @returns
 */
const QuerySelect: React.FC<IProps> = (props) => {
  const { options = [], placeholder = "请输入", value, onChange } = props
  const change = (e: string) => {
    onChange(e)
  }
  return (
    <Select
      allowClear
      showSearch
      value={value}
      placeholder={placeholder}
      onChange={change}
      optionFilterProp="children"
      filterOption={(input, option) => (option?.label ?? "").includes(input)}
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
      }
      options={options}
    />
  )
}

export default QuerySelect
