const withAllowClear = {
  allowClear: true,
}
export default {
  type: "object",
  labelWidth: 126,
  properties: {
    keyword: {
      title: "搜索",
      type: "string",
      widget: "input",
      placeholder: "组织、设备名称、部署区域、搜索",
      props: withAllowClear,
    },
    deviceCode: {
      title: "设备ID",
      type: "string",
      widget: "input",
      placeholder: "设备ID",
      props: withAllowClear,
    },
    online: {
      title: "状态",
      type: "string",
      widget: "select",
      enum: ["true", "false"],
      enumNames: ["在线", "离线"],
      placeholder: "请选择",
      props: withAllowClear,
    },
  },
}
