import { useState } from "react"
/**
 * 设置table标题title
 * @param title
 * @returns
 */
function useTableTitle(title: string) {
  const [total, setTotal] = useState<number>()
  return {
    setTotal,
    tableTitle: title + (total ? ` / ${total}` : ""),
  }
}

export default useTableTitle
