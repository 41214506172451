import Util from "../../../services/util"
import UtilOp from "../../../services/serviceOp"
import { OP_SIGN } from "../../../api/config/servicePort"
import { rest } from "lodash"
const utilOp = UtilOp.getInstance()
const util = Util.getInstance()

// 根据文件id获取，文件链接
export const getUrlByFileId = (params: { zoom: boolean; fileIds: string }) => {
  return util
    .httpGetDomain(`/rscm/rscm-resource/files/ossUrlZoom`, params)
    .then((res) => res as { data: string[]; code: number })
}

// 新增合同模版
export const createContract = (params: Contract.Info) => {
  return utilOp.httpPost(OP_SIGN + "/contract-templates", params)
}
// 查询合同模版列表(翻页)
export const getContractList = (params: Contract.ListParams) => {
  return utilOp.httpGet<Contract.ListResponse>(OP_SIGN + "/contract-templates", params)
}

// 模板详情
export const getContractInfo = (id) => {
  return utilOp.httpGet<Contract.Info>(OP_SIGN + `/contract-templates/${id}`)
}
// 编辑合同模版
export const editContractInfo = (params: Contract.Info) => {
  return utilOp.httpPut(OP_SIGN + `/contract-templates/${params?.id}`, params)
}

// 更改合同模版有效状态
export const editContractStatus = (params) => {
  return utilOp.httpPut(OP_SIGN + `/contract-templates/${params.id}/status?status=${params.status}`)
}
// 查看合同模版关联的组织列表(翻页)
export const getContractOrganizationsList = (params) => {
  return utilOp.httpGet<Contract.OrganizationsList>(
    OP_SIGN + `/contract-templates/${params.id}/organizations`
  )
}
// 删除合同模版关联的组织
export const deleteContractOrganizations = (params) => {
  return utilOp.httpDeletel(
    OP_SIGN + `/contract-templates/${params.id}/organizations/${params.refId}`
  )
}
// 新增合同模版关联的组织
export const createContractOrganizations = (params) => {
  const { id, organizationId, ...rest } = params
  return utilOp.httpPost(
    OP_SIGN + `/contract-templates/${id}/organizations/${organizationId}`,
    rest
  )
}
