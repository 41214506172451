/* eslint-disable react-hooks/exhaustive-deps */

import { eraseBgColor } from "@/utils/canvas/eraseBgColor"
import {
  Button,
  message,
  Image,
  Space,
  Slider,
  Row,
  Col,
  Spin,
  Divider,
  Empty,
  Tooltip,
  Popconfirm,
  Alert,
  UploadProps,
} from "antd"
import ImgCrop from "antd-img-crop"
import React, { useEffect, useRef, useState, useMemo } from "react"
import "./contractSignaturesSetting.scss"
import {
  DeleteOutlined,
  InboxOutlined,
  QuestionCircleOutlined,
  StarFilled,
} from "@ant-design/icons"
import Dragger from "antd/lib/upload/Dragger"
import { EditableImage } from "@/components/EditableImage"
import { SliderSingleProps } from "antd/lib/slider"
import { RcFile } from "antd/lib/upload"
import { useRequest, useThrottleFn } from "ahooks"
import {
  createSignatureForVendor,
  deleteSignatureOfVendor,
  fetchSignaturesOfVendor,
  setOrganizeIdOfEsignForVendor,
  setToDefaultSignatureOfVendor,
} from "./service"
type Vendor = any
interface SignatureSettingProps {
  vendor: Vendor
  onRefreshVendorData?: (success: (response: Vendor) => void) => void
}
const defaultProps: Partial<SignatureSettingProps> = {}
const calcTolerance = (range: [number, number], rate: number) => {
  const [min, max] = range
  return min + (max - min) * rate
}
export const SignatureSetting: React.FC<SignatureSettingProps> = (props) => {
  const { vendor, onRefreshVendorData } = props
  const { orgId, id: vendorId } = vendor
  const [originSignatureFile, setOriginSignatureFile] = useState<File>()
  const slidingSizeRef = useRef(100)
  const [resultCanvas, setResultCanvas] = useState<HTMLCanvasElement>()
  const previewUrl = useMemo(() => resultCanvas?.toDataURL(), [resultCanvas])
  const signatureFileListService = useRequest(fetchSignaturesOfVendor, {
    manual: true,
  })
  const resetSource = () => {
    setOriginSignatureFile(undefined)
    setResultCanvas(undefined)
  }
  const onRemove = async (signatureId: string) => {
    await deleteSignatureOfVendor({ id: orgId!, signatureId })
    message.success("操作成功")
    await signatureFileListService.refresh()
  }
  const beforeCrop = async (file: RcFile) => {
    if (!["image/jpeg", "image/png", "image/jpg"].includes(file.type)) {
      message.warning("只允许上传png、jpg、jpeg类型")
      return Promise.reject()
    }
    return Promise.resolve(true)
  }
  const beforeUpload: UploadProps<unknown>["beforeUpload"] = async (file) => {
    const newFile = await eraseBgColor(
      { tolerance: calcTolerance([10, 60], slidingSizeRef.current / 100) },
      file
    )
    setOriginSignatureFile(file)
    setResultCanvas(newFile)
    return false
  }
  const setToDefault = async (signatureId: string, isDefault: boolean) => {
    if (isDefault === true) {
      message.warning("避免重复设置")
      return
    }

    await setToDefaultSignatureOfVendor({ id: orgId!, signatureId })
    await signatureFileListService.refresh()
    message.success("设置成功")
  }
  const onChangeToleranceRate: SliderSingleProps["onChange"] = (value) => {
    slidingSizeRef.current = value
    updateToleranceForImage.run(value)
  }
  const updateToleranceForImage = useThrottleFn(
    async (value) => {
      if (!originSignatureFile) return
      const newFile = await eraseBgColor(
        { tolerance: calcTolerance([10, 60], value / 100) },
        originSignatureFile!
      )
      setResultCanvas(newFile)
    },
    { wait: 32.22 }
  )
  const onUploadSignature = async () => {
    if (!originSignatureFile || !orgId || !originSignatureFile) {
      return
    }
    await createSignatureForVendor({
      id: orgId,
      base64: resultCanvas?.toDataURL()?.replace(/.*base64,/, "")!,
      filename: originSignatureFile.name.split(".")[0],
    })
    resetSource()
    message.success("上传成功")
    signatureFileListService.refresh()
  }
  const setOrganizeIdOfEsignForVendorService = useRequest(setOrganizeIdOfEsignForVendor, {
    manual: true,
  })

  useEffect(() => {
    if (vendor && !orgId && vendorId) {
      !(async () => {
        await setOrganizeIdOfEsignForVendorService.run({ vendorId })
        onRefreshVendorData?.((response) => {
          if (response.orgId) signatureFileListService.run({ id: response.orgId })
        })
      })()
    } else if (orgId) {
      signatureFileListService.run({ id: orgId })
    }
  }, [orgId, vendorId])

  useEffect(() => {
    return () => {
      updateToleranceForImage.cancel()
    }
  }, [])
  return (
    <Spin spinning={setOrganizeIdOfEsignForVendorService.loading}>
      {!orgId && vendor.id && (
        <>
          <Alert message="一般纳税人e签宝账号未获取，无法上传" type="warning" closable />
          <br />
        </>
      )}
      <Row gutter={[16, 16]}>
        <Col span={24} md={12} lg={12} xl={9} xxl={6}>
          <div className={"uploadDraggerContainer"}>
            <ImgCrop
              beforeCrop={beforeCrop}
              modalOk="确认"
              modalCancel="取消"
              modalTitle="截取签章"
              maxZoom={5}
            >
              <Dragger
                disabled={!orgId}
                beforeUpload={beforeUpload}
                className={"uploadDragger"}
                method="POST"
                accept="image/*"
                showUploadList={false}
                multiple={false}
              >
                {previewUrl ? (
                  <div className={"drawingBoard"}>
                    <Image preview={false} src={previewUrl}></Image>
                  </div>
                ) : (
                  <>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">+ 点击或者拖拽上传印章</p>
                    <p className="ant-upload-hint">支持上传图片：png、jpg、jpeg</p>
                  </>
                )}
              </Dragger>
            </ImgCrop>
          </div>
          <b>
            调整背景{" "}
            <Tooltip
              overlayStyle={{ minWidth: 350 }}
              title={
                <>
                  调整背景以保证背景透明、印章清晰。
                  <br />
                  建议：印章截取部分背景色应避免存在较大色差
                </>
              }
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </b>
          <Slider
            disabled={!orgId}
            defaultValue={100}
            style={{ width: 300, marginLeft: 0 }}
            marks={{ 0: "0%", 100: "100%" }}
            onChange={onChangeToleranceRate}
          />
          <Button
            style={{ width: 300 }}
            type="primary"
            disabled={!originSignatureFile}
            onClick={onUploadSignature}
          >
            确认上传
          </Button>
        </Col>
        <Col span={0} md={1}>
          <Divider type="vertical" style={{ height: "100%" }} />
        </Col>
        <Col span={24} md={11} lg={11} xl={14} xxl={17}>
          <Spin spinning={signatureFileListService.loading}>
            {signatureFileListService.data?.data?.length ? (
              <Row justify={"start"} gutter={[16, 16]}>
                {signatureFileListService.data?.data?.map((file) => (
                  <Col key={file.sealId}>
                    <div key={file.sealId} className={"imageItemWrapper"}>
                      <EditableImage
                        customActions={(previewButton) => (
                          <>
                            <Space>
                              {previewButton}
                              <Popconfirm
                                title="是否删除印章？"
                                onConfirm={async () => onRemove(file.sealId)}
                                okText="是"
                                cancelText="否"
                              >
                                <DeleteOutlined title={"删除"} />
                              </Popconfirm>
                            </Space>
                          </>
                        )}
                        height={120}
                        src={file.url}
                      />
                      <div className={"actionsBar"}>
                        <Popconfirm
                          title="是否设置为默认印章？"
                          onConfirm={async () => setToDefault(file.sealId, file.defaultFlag)}
                          okText="是"
                          cancelText="否"
                        >
                          <Tooltip title="设为默认">
                            <StarFilled
                              title="设为默认"
                              className={"markSty"}
                              style={{ color: file.defaultFlag ? "#ffc069" : "#fff" }}
                            />
                          </Tooltip>
                        </Popconfirm>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            ) : (
              <Row justify="center" align="middle">
                <Empty description="暂无印章" />
              </Row>
            )}
          </Spin>
        </Col>
      </Row>
    </Spin>
  )
}

SignatureSetting.defaultProps = defaultProps
