import numeral from "numeral"

//格式化数字
export const transformDateType = (num: any, opt: any) => {
  if (num || num === 0 || num === "0") {
    return formatNumber(num, opt)
  } else {
    return ""
  }
}
/**
 * @description 转化数值单位
 * @param {String | Number} num 数值
 * @param {String} type 转化目标单位
 * @param {Number} bit_number 小数位
 * @return string
 */
export const unitTransformation = (num: any, type: any, bit_number = 1) => {
  if (num === 0 || num === "0") return "0.0"
  if (!num || !type) return
  switch (type) {
    case "十":
      return (num = transformDateType(num, { divide: 10, bit_number }))
    case "百":
      return (num = transformDateType(num, { divide: 100, bit_number }))
    case "千":
      return (num = transformDateType(num, { divide: 1000, bit_number, thousands: true }))
    case "万":
      return (num = transformDateType(num, { divide: 10000, bit_number, thousands: true }))
    case "百万":
      return (num = transformDateType(num, { divide: 1000000, bit_number, thousands: true }))
    case "千万":
      return (num = transformDateType(num, { divide: 10000000, bit_number, thousands: true }))
    case "亿":
      return (num = transformDateType(num, { divide: 100000000, bit_number, thousands: true }))
    default:
      return ""
  }
}
/**
 * @description 转化数值单位
 * @param {String | Number} num 数值
 * @param {Object} option 转化目标单位
 * {
 *    type: number | percent;   数值 | 百分比
 *    bit_number: number;       小数后位数
 *    thousands: boolean;       是否增加千分位
 * }
 * @return string
 */
export const formatNumber = (num: any = 0, option: any = null) => {
  //如果option没有配置对数据的处理，默认返回数据
  if (!option) return num

  //返回需要保留的小数位
  function getNumBit(bit: any = 2) {
    bit = parseInt(bit)
    let str = ""
    //是否增加千分位
    if (option.thousands) str = "0,0."
    else str = "0."
    for (let i = 0; i < bit; i++) {
      str += "0"
    }
    return str
  }

  let bit_number = getNumBit(option && option.bit_number ? option.bit_number : 2)
  //进位
  if (option.divide) return numeral(num).divide(option.divide).format(bit_number)

  if (!option.type) option.type = "number"
  try {
    //格式化数据
    switch (option.type) {
      // 整数
      case "number":
        return numeral(num).format(bit_number)
      //代表百分数,percent
      case "percent":
        return numeral(num).format(bit_number + "%")
      default:
        return num
    }
  } catch (error) {
    console.log(error)
  }
}
// 加法
export const accuracyAdd = (a: any, b: any) => {
  const precision = Math.max(getPricision(a), getPricision(b))
  const multiplier = Math.pow(10, precision)
  return (Math.round(a * multiplier) + Math.round(b * multiplier)) / multiplier
}
// 减法
export const accuracySubtract = (a: any, b: any) => {
  return accuracyAdd(a, -b)
}
const getPricision = (num: any) => {
  const str = String(num)
  const decimalIndex = str.indexOf(".")
  return decimalIndex === -1 ? 0 : str.length - decimalIndex - 1
}
