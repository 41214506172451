/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
type useTableHeightType = {
  h: number
}

// 返回table高度
export function useTableHeight({ h }: useTableHeightType) {
  const [height, setHeight] = useState(0)

  const change = () => {
    const winHeight = window.innerHeight
    setHeight(winHeight - h)
  }
  useEffect(() => {
    change()
    window.addEventListener("resize", change)
    return () => {
      window.removeEventListener("resize", change)
    }
  }, [])
  return {
    h: height,
  }
}
