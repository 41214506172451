/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { Button, Space, Collapse, Radio, Form, Input, Table, Modal, message } from "antd"
import React, { memo, forwardRef, useImperativeHandle, useState, useEffect, useMemo } from "react"
import { debounce, inputDefaultRules } from "../../../../../../../src/utils/RegExp"
import { getTradingSchemePayment, getTradingSchemeApply } from "../../../../promises"
import "./index.scss"
interface DataType {
  key: React.Key
  name: string
  age: string
  address: string
}
export const procureOrderApply = (props: any) => {
  const {
    selectedRowKeys,
    setIsModalOpen,
    isModalOpen,
    handleRefresh,
    setSelectedRowKeys,
    setSelectedRowKeysList,
  } = props
  const [form] = Form.useForm()
  const [dataSourceList, setDataSourceList] = useState<any>([])
  const [radioList, setRadioList] = useState<string>("all")
  const [amountALl, setAmountALl] = useState<any>({
    applied: "",
  })
  const [tax, setTax] = useState<any>(0)
  const [paramsList, setParamsList] = useState<any>([])

  const handArrList = (arrList) => {
    let newTax = 0
    const _list = arrList?.map((ie: any) => {
      if (Number(ie?.appliedAmount) > Number(ie.tax)) {
        ie.tax = 0
      } else {
        if (Number(ie.applyAmount) + Number(ie?.appliedAmount) >= Number(ie?.tax)) {
          ie.tax = Number(ie.tax) - Number(ie?.appliedAmount)
        } else {
          ie.tax = Number(ie.applyAmount)
        }
      }
      newTax += Number(ie.tax)
      return ie
    })
    setParamsList(_list)
    setTax(newTax)
  }
  //初始化计算
  useMemo(async () => {
    if (selectedRowKeys.length > 0 && isModalOpen) {
      let params: any = {
        orderIds: selectedRowKeys.map((ie: any) => ie.id).join(","),
      }
      const result: any = await getTradingSchemePayment(params)

      let amountData: any = ""
      //模拟数据 momentData 接口调通 切换 result.data
      const arrList: any = result?.data?.map((ie: any, index: number) => {
        if (radioList == "all") {
          amountData = ie.receivable - ie.appliedAmount
        } else if (radioList == "min") {
          amountData = ie.tax
        } else {
          amountData = ((ie.receivable - ie.appliedAmount) * (Number(radioList) / 100)).toFixed(2)
        }
        form.setFieldValue(`${index + "applyAmount"}`, amountData)
        return {
          ...ie,
          index: index,
          applyAmount: amountData,
        }
      })
      setDataSourceList(arrList)
      if (arrList.length > 0) {
        handArrList(arrList)
      }
    }
  }, [selectedRowKeys, radioList])

  const columnsOne = [
    {
      title: "订单号",
      dataIndex: "orderId",
    },
    {
      title: "销售方",
      dataIndex: "sellerName",
      key: "sellerName",
    },
    {
      title: "税款",
      dataIndex: "tax",
      key: "tax",
    },
    {
      title: "货款",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "应付总额",
      dataIndex: "receivable",
      key: "receivable",
    },
    {
      title: "已申请金额",
      dataIndex: "appliedAmount",
      key: "appliedAmount",
    },
    {
      title: "申请金额",
      dataIndex: "applyAmount",
      key: "applyAmount",
      width: 250,
      render: (text: any, record: any) => {
        return (
          <Form form={form} onValuesChange={onValuesChange}>
            <Form.Item
              name={[`${record.index + "applyAmount"}`]}
              rules={[
                {
                  required: false,
                  message: "申请金额不满足最小开票金额",
                },
                {
                  validator: async (rule: any, value: string) => {
                    if (Number(value) < Number(record.tax)) {
                      return Promise.reject("申请金额不满足最小开票金额")
                    }
                  },
                },
              ]}
            >
              <Input
                placeholder="请输入"
                onChange={debounce((e) => {
                  let num = record.receivable - record.appliedAmount
                  if (Number(e.target.value) > num) {
                    record.applyAmount = num
                    setTimeout(() => {
                      form.setFieldValue(`${record.index + "applyAmount"}`, num)
                    }, 500)
                  } else {
                    record.applyAmount = e.target.value
                  }
                }, 300)}
              />
            </Form.Item>
          </Form>
        )
      },
    },
  ]
  //计算
  const handNewCount = (dataSourceList) => {
    console.log(dataSourceList, "dataSourceList")
    handArrList(dataSourceList)
    const newCount =
      dataSourceList?.reduce((a: any, b: any) => (a += Number(b.applyAmount)), 0) || 0
    setAmountALl({ ...amountALl, applied: newCount.toFixed(2) })
  }
  const onValuesChange = debounce((changedValue: any) => {
    handNewCount(dataSourceList)
  }, 500)
  // 点击确定只需要传 dataSourceList
  const handleOk = async () => {
    const _dataSourceList = paramsList.map((item: any) => {
      const { orderId, tax, applyAmount } = item
      return {
        orderId,
        tax,
        applyAmount,
      }
    })
    const result: any = await getTradingSchemeApply(_dataSourceList)
    if (result?.code == 200) {
      message.success("支付成功")
      setIsModalOpen(false)
      handleRefresh()
      setSelectedRowKeys([])
      setSelectedRowKeysList([])
    }
    console.log(dataSourceList, "dataSourceList")
  }
  const handTable = useMemo(() => {
    handNewCount(dataSourceList)
    return (
      <>
        <Table
          style={{ paddingTop: 10 }}
          dataSource={dataSourceList}
          columns={columnsOne}
          pagination={false}
        />
      </>
    )
  }, [dataSourceList])

  const handInformation = useMemo(() => {
    return (
      <>
        <div className="procure-order-information">
          <span>申请支付金额：{amountALl?.applied}</span>
          <span>
            <span className="procure-span">货款：{(amountALl?.applied - tax).toFixed(2)}</span>
            <span className="procure-span">税款：{tax}</span>
          </span>
        </div>
      </>
    )
  }, [amountALl, tax])
  return (
    <div className="billing">
      <Modal
        title="申请付款"
        open={isModalOpen}
        width={900}
        onCancel={() => {
          setIsModalOpen(false)
        }}
        destroyOnClose={true}
        footer={null}
      >
        <div className="ant-content">
          {handTable}
          <div className="billing-add">付款方案：请选择以下应付总额比例进行付款申请</div>
          <div className="billing-add">
            <Radio.Group
              defaultValue={radioList}
              onChange={(e: any) => {
                setRadioList(e.target.value)
              }}
            >
              <Radio value={"all"}>应付全部</Radio>
              <Radio value={"80"}>80%</Radio>
              <Radio value={"50"}>50%</Radio>
              <Radio value={"30"}>30%</Radio>
              <Radio value={"min"}>最小开票金额</Radio>
            </Radio.Group>
          </div>
          {handInformation}
        </div>
        <Form.Item wrapperCol={{ span: 16 }} style={{ borderTop: "1px solid #f0f0f0" }}>
          <div
            className="form_footer"
            style={{ display: "flex", marginBottom: 10, paddingTop: 10 }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                textAlign: "right",
                marginLeft: "123%",
              }}
            >
              <Button
                style={{ marginRight: "12px" }}
                onClick={() => {
                  setIsModalOpen(false)
                }}
              >
                取消
              </Button>
              <Button type="primary" onClick={handleOk}>
                确定
              </Button>
            </div>
          </div>
        </Form.Item>
      </Modal>
    </div>
  )
}

export default procureOrderApply
