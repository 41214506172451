/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Result } from "@src/services/util"
import { useRequest } from "ahooks"
import { message } from "antd"
import { UploadChangeParam, UploadFile } from "antd/es/upload"
import { createContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { getUrlByFileId } from "../../../Entity/promises"
import {
  getOcrBusinessLicense,
  getOcrIdCardFront,
  getOrgs,
  getRegionsList,
  getTissueInfo,
} from "../promises"

export const uploadFileAndGettingRes = async (file: UploadFile) => {
  return new Promise<
    | Result<{ downloadLink: string; downloadLinkExpirationTime: string; filePath: string }>
    | undefined
  >((resolve, reject) => {
    if (file.status === "done") {
      resolve(file.response)
    } else if (file.status === "error") {
      message.success("更新失败")
      reject(file.error)
    }
  })
}

export const usePlatformSetupTissue = () => {
  const [codePhotos, setCodePhotos] = useState<any>([])
  const [resultOcrPhotos, setResultOcrPhotos] = useState<any>([])
  const [resultOcrPhotosFlag, setResultOcrPhotosFlag] = useState<any>(true)
  const [tissueAuthList, setTissueAuthList] = useState<any>([]) //组织当前列表
  const [taxRegionsList, setTaxRegionsList] = useState<any>([]) //组织当前列表
  const params = useParams<{ id: string }>()
  console.log(params, "params")
  const tissueInfoService = useRequest(getTissueInfo, {
    manual: true,
    defaultParams: [params.id!],
    refreshDeps: [params.id],
  })
  //组织当前列表
  const tissueAuthInit = async () => {
    const response = await getOrgs()
    if (response.code == 200) {
      const result = response.data?.map((ie) => {
        return {
          label: ie.name,
          value: ie.id,
        }
      })
      setTissueAuthList(result)
    }
  }
  //税区当前列表
  const taxRegionsListInit = async (value?: any) => {
    const response = await getRegionsList(value)
    if (response.code == 200) {
      const result = response.data?.records?.map((ie) => {
        return {
          label: ie.name,
          value: ie.id,
        }
      })
      setTaxRegionsList(result)
    }
  }
  useEffect(() => {
    tissueAuthInit()
    taxRegionsListInit({
      status: 1,
    })
  }, [])
  const onSaveByNewData = async (values: Tissue.Info) => {
    const keys = Object.keys(values)
    console.log(keys, values, "keys, values, oldValues")
    setCodePhotos(values)
    // await onSave(keys, values, oldValues)
  }
  const onUploadFileChange = async (key: string, { file }: UploadChangeParam<UploadFile<{}>>) => {
    const response = await uploadFileAndGettingRes(file)
    if (response.code == 200) {
      if (key == "businessLicense") {
        message.loading("图像信息识别中...", 1)
        const result: any = await getOcrBusinessLicense({ url: response?.data.downloadLink })
        setResultOcrPhotosFlag(false)
        if (result.code == 200) {
          setResultOcrPhotos(result)
        } else {
          message.error(result?.response?.data?.msg || "识别失败")
        }
      } else {
        if (key == "idCardFront") {
          message.loading("身份图像信息识别中...", 1.5)
          const result: any = await getOcrIdCardFront({ url: response?.data.downloadLink })
          setResultOcrPhotosFlag(false)
          if (result.code == 200) {
            setResultOcrPhotos(result)
          } else {
            message.error(result?.response?.data?.msg || "识别失败")
          }
        }
      }
    }
    const newCodePhotos = {
      fileType: key,
      fileId: response?.data.filePath,
      fileUrl: response?.data.downloadLink,
    }
    console.log(response, "response")
    setCodePhotos((prevCodePhotos) => {
      // 查找是否已存在相同的 fileType
      const existingIndex = prevCodePhotos.findIndex(
        (photo) => photo.fileType === newCodePhotos.fileType
      )
      if (existingIndex !== -1) {
        // 如果已存在相同的 fileType，替换它
        return prevCodePhotos.map((photo, index) =>
          index === existingIndex ? newCodePhotos : photo
        )
      } else {
        // 否则添加新的 newCodePhotos
        return [...prevCodePhotos, newCodePhotos]
      }
    })
    // await onSaveByNewData({ [key]: response?.data.filePath })
  }

  const fileUrlService = useRequest(async (fileIdList: string[]) => {
    return getUrlByFileId({ zoom: false, fileIds: fileIdList.join(",") })?.then((res) =>
      res.data.map((item, index) => (fileIdList[index] ? item : undefined))
    )
    // getUrlByFileId({zoom:false,fileIds: fileIdList})
    // return (await Promise.allSettled(fileIdList.map((fileId)=>getUrlByFileId({zoom:false,fileIds: fileIdList.join(',')})))).map((result)=>(result.status==='fulfilled'?result.value:null))
  })

  return {
    id: params.id,
    onUploadFileChange,
    fileUrlService,
    codePhotos,
    setCodePhotos,
    tissueInfoService,
    resultOcrPhotos,
    resultOcrPhotosFlag,
    setResultOcrPhotos,
    setResultOcrPhotosFlag,
    tissueAuthList,
    taxRegionsList,
    taxRegionsListInit,
  }
}
export const PlatformSetupTissuePropsContext = createContext(null)
