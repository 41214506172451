export const RexChinesePhone = /^1[3|4|5|6|7|8|9][0-9]{9}$|^0\d{2,3}-?\d{7,8}$/
export const RexIdNo =
  /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/
export const RexChineseName = /^(?:[\u4e00-\u9fa5·]{2,25})$/
export const RexNumber = /^\d+$/
// 电子邮箱
export const RexALEmail = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
// 统一社会信用代码
export const RexSocialCreditCode = /^[0-9A-Za-z]{18}$/
// 个体户类型，统一社会信用代码限制 92 开头，18 位
export const RexIndividualCode = /^92.{16}$/
export const RexInvoice = /\*[\u4e00-\u9fa50-9]+?\*[\u4e00-\u9fa50-9]+/

// 邮政编码
export const RexPostalCode = /^[1-9]\d{5}$/
