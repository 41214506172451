import React from "react"
import classNames from "classnames"
import "./index.scss"
interface CardInfoP {
  type?: string
  title: string
  value: string
  changeValue: string
  children: React.ReactNode
}
const CardInfo = (props: CardInfoP) => {
  const { type, title, value, changeValue, children } = props
  return (
    <div className="card-info">
      <div className="content">
        <div className="left">
          <div className="label">{title}</div>
          <div className="value">{value}</div>
        </div>
        {type !== "pie" && <div className="title">近7日</div>}
        <div className="right">{children}</div>
      </div>

      <div className="bottom">
        今日新增 {changeValue}{" "}
        <span
          className={classNames({
            "add-status": changeValue == "0" ? false : true,
            "decline-status": false,
          })}
        ></span>
      </div>
    </div>
  )
}
export default CardInfo
