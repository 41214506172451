const withAllowClear = {
  allowClear: true,
}
export default {
  type: "object",
  labelWidth: 76,
  properties: {
    keywords: {
      title: "搜索",
      type: "string",
      widget: "input",
      placeholder: "产品名称/融资公司",
      props: withAllowClear,
    },
    timer: {
      bind: ["startDate", "endDate"],
      title: "添加时间",
      type: "data",
      widget: "XZDateRange",
      props: withAllowClear,
    },
  },
}
