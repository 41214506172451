import ReactEcharts from "echarts-for-react"
import React from "react"
const toroidalCharts = ({ list }) => {
  const _list = list?.map((item) => {
    return {
      name: item.name,
      value: item.count,
    }
  })
  // 过滤掉值为0的数据项
  const filteredData = _list?.filter((item) => item?.value !== 0)
  // 配置选项
  const option = {
    tooltip: {
      trigger: "item",
    },
    series: [
      {
        type: "pie",
        radius: ["50%", "70%"],
        data: filteredData,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        label: {
          fontSize: "16",
          formatter: "{b}: {d}%",
          fontWeight: 500,
          textStyle: {
            color: "#fff", // 字体颜色为白色，适用于深色背景
          },
        },
      },
    ],
  }
  return (
    <ReactEcharts
      option={option}
      style={{ height: "250px", width: "100%" }}
      className="ring-chart"
    />
  )
}

export default toroidalCharts
