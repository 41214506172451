import { Form, Input } from "antd"
import React from "react"
import { FieldType, FromProps } from "../../interface"
import { inputDefaultRules } from "@src/utils/RegExp"
import "./index.scss"

export const BasicInformation = (props: FromProps) => {
  return (
    <>
      <Form.Item<FieldType>
        label="税区名称"
        name="name"
        style={{ marginTop: 10 }}
        rules={[
          {
            required: true,
            message: "请输入税区名称",
          },
          {
            validator: async (rule: any, value: string) =>
              inputDefaultRules(value, "税区名称", 100), //特殊字符
          },
        ]}
      >
        <Input placeholder="请输入税区名称" style={{ width: "434px" }} showCount maxLength={100} />
      </Form.Item>
    </>
  )
}

export default BasicInformation
