import React, { useEffect, useState } from "react"
import { Table, Modal, Form, Button, message, Input, Checkbox } from "antd"
import { getPassOrgs } from "../service"
type type_modal_operator = {
  (a: boolean): void
}

type Pass_T = {
  passStatus: boolean
  addModal: type_modal_operator
  cb: () => void
  rows: any[]
}
const Pass: React.FC<Pass_T> = ({ passStatus, rows, addModal, cb }) => {
  const [form] = Form.useForm()
  const handleCancel = () => {
    form.resetFields()
    addModal(false)
  }

  const [tableData, setTableData] = useState<any[]>([])
  const [reqData, setReqData] = useState<any>({})
  const checkboxData = [
    {
      label: "采购商城",
      value: "5",
    },
    {
      label: "供应链平台",
      value: "2",
    },
  ]
  useEffect(() => {
    const obj = {}
    const arr = rows.map((row) => {
      obj[row.id] = [5, 2]
      return {
        ...row,
      }
    })
    setReqData(obj)
    setTableData(arr)
  }, [rows])
  const columns = [
    {
      title: "入驻组织",
      dataIndex: "orgName",
      key: "orgName",
    },
    {
      title: "组织类型",
      dataIndex: "orgTypeDesc",
      key: "orgTypeDesc",
    },
    {
      title: "分类应用",
      dataIndex: "org",
      render: (value, row) => {
        return (
          <Checkbox.Group
            options={checkboxData}
            defaultValue={["5", "2"]}
            onChange={(val) => {
              const obj = JSON.parse(JSON.stringify(reqData))
              obj[row.id] = val
              setReqData(obj)
            }}
          />
        )
      },
    },
  ]
  const onFinish = async () => {
    const res: any = await getPassOrgs(reqData)
    if (res.code === 200) {
      message.success("审核成功")
      handleCancel()
      cb()
    }
  }

  return (
    <>
      <Modal
        open={passStatus}
        title="分配权限"
        onCancel={handleCancel}
        centered
        width={550}
        footer={null}
      >
        <Form
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ width: 550 }}
          onFinish={onFinish}
          className="reject_content"
        >
          <Table columns={columns} dataSource={tableData} pagination={false}></Table>
          <Form.Item wrapperCol={{ span: 16 }} style={{ borderTop: "1px solid #f0f0f0" }}>
            <div
              className="form_footer"
              style={{ display: "flex", marginBottom: 10, paddingTop: 10 }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  textAlign: "right",
                  marginLeft: "110%",
                }}
              >
                <Button
                  style={{ marginRight: "12px" }}
                  onClick={(e) => {
                    handleCancel()
                  }}
                >
                  取消
                </Button>
                <Button type="primary" htmlType="submit">
                  确定
                </Button>
              </div>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
export default Pass
