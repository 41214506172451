import React, { useState, useEffect } from "react"
import { ACCESS_KEYS } from "../contents/contentKeys"

export const AccessContext = React.createContext([])
/**
 * 使用权限表
 * @returns
 */
export function useAccess() {
  return React.useContext(AccessContext)
}

export function useAccessList() {
  const [accessList, setAccessList] = useState([])
  const getAccessList = async () => {
    try {
      const list = JSON.parse(sessionStorage.getItem(ACCESS_KEYS) || "[]")
      setAccessList(list)
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    getAccessList()
  }, [])
  return accessList
}
