import { Collapse, DatePicker, Form, Input, Select } from "antd"
import React, { useEffect, useState } from "react"
import { getOrgs } from "../../../../../api/service"
import { CustomRangePicker } from "../../../../../components/CustomRangePicker"
import { FieldType, FromProps } from "../../interface"
import "./index.scss"
const { Panel } = Collapse
const { RangePicker } = DatePicker
type ExpandIconPosition = "start" | "end"

export const BasicInformation = (props: FromProps) => {
  const [tissueAuthList, setTissueAuthList] = useState<any>([]) //组织当前列表

  //组织当前列表
  const tissueAuthInit = async () => {
    const response = await getOrgs()
    if (response.code == 200) {
      const result = response.data?.map((ie) => {
        return {
          label: ie.name,
          value: ie.id,
        }
      })
      setTissueAuthList(result)
    }
  }
  useEffect(() => {
    tissueAuthInit()
  }, [])
  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
  return (
    <>
      <Form.Item<FieldType>
        label="交易方案名称"
        name="name"
        rules={[
          {
            required: true,
            message: "请输入交易方案名称",
          },
        ]}
      >
        <Input placeholder="请输入交易方案名称" style={{ width: "264px" }} />
      </Form.Item>
      <Form.Item<FieldType>
        label="交易方案有效期"
        name="time"
        className="NewTradingScheme_Collapse_time"
        rules={[
          {
            required: true,
            message: "请选择交易方案有效期",
          },
        ]}
      >
        <CustomRangePicker style={{ maxWidth: "264px", marginLeft: 7 }} />
      </Form.Item>
      <span className="NewTradingScheme_Collapse_text">超过有效期后,交易方案不可用</span>
      <Form.Item name={["operationAuth", "operationAuthType"]} hidden={true}></Form.Item>
      <Form.Item
        name={["operationAuth", "operationId"]}
        label="运营方"
        rules={[{ required: true, message: "请选择" }]}
      >
        <Select
          placeholder="选择运营方"
          mode="multiple"
          style={{ width: "264px" }}
          showSearch
          maxTagCount={6}
          onChange={(e) => {}}
          filterOption={filterOption}
          options={tissueAuthList}
        />
      </Form.Item>
    </>
  )
}

export default BasicInformation
