/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */

import { useTableHeight } from "@/hooks/useTableHeight"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import SearchForm from "@src/components/FormItem"
import ModalCom from "@src/components/ModalCom"
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tooltip,
  Typography,
} from "antd"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import SecondaryAuthentication from "../../components/QRCodeSecondaryAuthentication"
import QuanDianModalLogin from "../../components/QuanDian"
import useInvoiceHandler from "../../components/useInvoiceHandler"
import {
  apiUpdateCredit,
  getInvoicingList,
  getTaxpayersInfoEdit,
  upDataCredit,
} from "../../promise"
import { ISSUE_CHANNEL, queryData, TAX_FILLING_PERIOD, TAX_MAKE_PERIOD } from "./content"
import "./index.scss"
const { Option } = Select
const EditableCell: React.FC<any> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  element,
  message,
  valuePropName,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          valuePropName={valuePropName}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `${message}`,
            },
          ]}
        >
          {element}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}
const colProps = {
  span: 8,
}
const TIPS = "查询失败，保证百望云数电链接已登录"
/**
 * 开票方列表
 * @returns
 */
function InvoicingParty() {
  const [modalForm] = Form.useForm()
  const [editingKey, setEditingKey] = useState("")
  let userName = JSON.parse(localStorage.getItem("operation_tissue") || "{}")?.name
  const [pageOptions, setPageOptions] = useState({
    pageSize: 10,
    current: 1,
  })
  const [total, setTotal] = useState(0)
  const [list, setList] = useState([])
  const [isOpen, setOpen] = useState(false)
  const [form] = Form.useForm()
  const [search, setSearch] = useState({})
  const { h } = useTableHeight({ h: 440 })
  const isEditing = (record: any) => record.key === editingKey
  const refreshFunction = () => {
    getData()
  }
  const {
    visibleQuanDianModalLogin,
    visibleSecondaryAuthentication,
    invoiceInfo,
    invoiceCode,
    nsrsbh,
    setNsrsbh,
    handleInvoiceResponse,
    closeQuanDianModalLogin,
  } = useInvoiceHandler(refreshFunction)
  const edit = (record: any & { key: React.Key }) => {
    modalForm.setFieldsValue({
      ...record,
      reverseInvoicing: record.reverseInvoicing == "YES" ? record.reverseInvoicing : null,
    })
    setEditingKey(record.key)
  }
  const cancel = () => {
    setEditingKey("")
  }
  const save = async (key: React.Key) => {
    try {
      const row: any = modalForm.getFieldsValue()
      let params: any = {
        id: key,
        taxFilingPeriod: row.taxFilingPeriod,
        issueChannel: row.issueChannel,
        reverseInvoicing: row.reverseInvoicing ? "YES" : "NO",
      }
      const resultData: any = await getTaxpayersInfoEdit(params)
      if (resultData.code == 200) {
        message.success("编辑成功")
        setEditingKey("")
        getData(search)
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo)
    }
  }
  /**
   * 列表查询
   * @param values 查询的参数
   */
  const onSearch = (values: any) => {
    const createTimeBegin = values.createTime
      ? dayjs(values.createTime[0]).format("YYYY-MM-DD") + " 00:00:00"
      : ""
    const createTimeEnd = values.createTime
      ? dayjs(values.createTime[1]).format("YYYY-MM-DD") + " 23:59:59"
      : ""
    const lastUpdateDateBegin = values.lastUpdateDate
      ? dayjs(values.lastUpdateDate[0]).format("YYYY-MM-DD") + " 00:00:00"
      : ""
    const lastUpdateDateEnd = values.lastUpdateDate
      ? dayjs(values.lastUpdateDate[1]).format("YYYY-MM-DD") + " 23:59:59"
      : ""

    const params: Api.InvoicingPartyType.QueryData = {
      createTimeBegin, // 创建时间开始
      createTimeEnd, // 创建时间结束
      lastUpdateDateBegin, // 额度更新时间开始
      lastUpdateDateEnd, // 额度更新时间结束
      taxClerk: values?.taxClerk || "",
      reverseInvoicing: values?.reverseInvoicing || "",
      query: values?.query || "",
    }
    setSearch(params)
    getData(params)
  }
  const handleChange = (params?: any) => {
    setPageOptions({
      current: params.current,
      pageSize: params.pageSize,
    })
    getData({
      current: params.current,
      pageSize: params.pageSize,
    })
  }
  const getData = async (params = {}) => {
    const reqData = {
      current: pageOptions.current,
      size: pageOptions.pageSize,
      ...params,
    }
    const res = await getInvoicingList(reqData)
    if (res.code === 200) {
      setList(res.data.records)
      setTotal(res?.data?.total)
    }
  }
  /**
   * 自动更新
   */
  const upDate = async (row: any) => {
    setNsrsbh(row.taxpayerNo)
    const res: any = await apiUpdateCredit(row.id)
    console.log(res, "res")
    handleInvoiceResponse(res?.data)
    if (res?.code === 200) {
      message.info(res.message)
      setOpen(false)
      getData()
    }
  }
  const columns = [
    {
      title: "开票方主体",
      dataIndex: "taxpayerName",
      width: 150,
    },
    {
      title: "纳税人识别号",
      dataIndex: "taxpayerNo",
      width: 150,
    },
    {
      title: "报税周期",
      dataIndex: "taxFilingPeriod",
      width: 150,
      editable: true,
      element: (
        <Select style={{ width: 130 }}>
          <Option value="MONTH">月</Option>
          <Option value="QUARTER">季</Option>
        </Select>
      ),
      message: "请选择报税周期",
      render: (record?: any) => {
        return <span>{TAX_FILLING_PERIOD[record]}</span>
      },
    },
    {
      title: "办税人",
      dataIndex: "taxClerk",
      width: 150,
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      width: 150,
    },
    {
      title: "开票渠道",
      dataIndex: "issueChannel",
      width: 150,
      editable: true,
      element: (
        <Select style={{ width: 130 }}>
          <Option value="BW">百望云</Option>
          <Option value="HX">航信</Option>
          <Option value="MANUAL">电子税务局</Option>
          <Option value="CLIENT_HANDLE">客户自开</Option>
        </Select>
      ),
      message: "请选择开票渠道",
      render: (record?: any) => {
        return <span>{ISSUE_CHANNEL[record]}</span>
      },
    },
    {
      title: "反向开票",
      dataIndex: "reverseInvoicing",
      width: 150,
      editable: true,
      valuePropName: "checked",
      element: <Switch checkedChildren="是" unCheckedChildren="否" />,
      render: (text?: any) => {
        return <span>{TAX_MAKE_PERIOD[text]}</span>
      },
    },
    {
      title: "总授信额度",
      width: 150,
      render: (record?: any) => {
        return (
          <div>
            {record?.lastUpdateStatus === "SUCCESS" ? (
              <span>{record?.totalCredit}</span>
            ) : (
              <div className="credit_fail_box">
                <span className="fail_red">{record?.totalCredit || "---"}</span>
                <Tooltip title={TIPS}>
                  <img src={require("@/assets/tips_icon.png")} alt="" />
                </Tooltip>
              </div>
            )}
          </div>
        )
      },
    },
    {
      title: "可用授信额度",
      width: 150,
      render: (record?: any) => {
        return (
          <div>
            {record?.lastUpdateStatus === "SUCCESS" ? (
              <span>{record?.availableCredit}</span>
            ) : (
              <div className="credit_fail_box">
                <span className="fail_red">{record?.availableCredit || "---"}</span>
                {/* <Tooltip title={TIPS}> */}
                <img src={require("@/assets/tips_icon.png")} alt="" />
                {/* </Tooltip> */}
              </div>
            )}
          </div>
        )
      },
    },
    {
      title: "额度更新时间",
      dataIndex: "lastUpdateDate",
      width: 150,
    },
    {
      title: "操作",
      width: 230,
      fixed: "right",
      align: "center",
      render: (_: any, record?: any) => {
        record.key = record.id
        const editable = isEditing(record)

        return (
          <div className="update_control">
            {record?.issueChannel === "MANUAL" ? null : (
              <span onClick={() => upDate(record)}>自动更新</span>
            )}
            <span onClick={() => openToastUpData(record)}>手动更新</span>
            {editable ? (
              <span>
                <Typography.Link style={{ marginRight: 8 }} onClick={() => save(record.key)}>
                  保存
                </Typography.Link>
                <a onClick={cancel}>取消</a>
              </span>
            ) : (
              <Typography.Link onClick={() => edit(record)}>编辑</Typography.Link>
            )}
          </div>
        )
      },
    },
  ]
  const openToastUpData = (row: any) => {
    form.setFieldsValue({
      totalCredit:
        typeof row?.totalCredit === "number" ? row?.totalCredit.toString() : row?.totalCredit || "",
      availableCredit:
        typeof row?.availableCredit === "number"
          ? row?.availableCredit.toString()
          : row?.availableCredit || "",
      id: row?.id,
    })
    setOpen(true)
  }
  /**
   * 手动更新
   */
  const handleUpData = async () => {
    try {
      await form.validateFields()
      const params = form.getFieldsValue()
      const res: any = await upDataCredit(params)
      if (res?.code === 200) {
        message.info(res.message)
        setOpen(false)
        getData()
      } else {
        message.error(res.message)
      }
    } catch (e) {
      console.log(e)
    }
  }
  const mergedColumns = columns.map((col: any) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: col.dataIndex,
        valuePropName: col.valuePropName,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        element: col.element,
        message: col.message,
      }),
    }
  })
  useEffect(() => {
    getData()
  }, [pageOptions.current, pageOptions.pageSize])
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <span>开票方列表</span>
          </Space>
        }
        breadCrumb={[
          { label: "税票系统", path: "" },
          { label: "开票方列表", path: "" },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <DetailPageContent>
        <div className="container table_content">
          <div className="query_header">
            <SearchForm formParams={queryData} onSearch={onSearch} />
          </div>
          <div className="table_content_list">
            <h2 className="table_title">开票方列表</h2>
            <Form form={modalForm} component={false}>
              <Table
                rowKey={(record: any) => record.id}
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                rowClassName="editable-row"
                columns={mergedColumns}
                dataSource={list}
                scroll={{
                  y: h + "px",
                  x: 1400,
                }}
                pagination={{
                  ...pageOptions,
                  total: total,
                }}
                onChange={handleChange}
              ></Table>
            </Form>
          </div>
        </div>
        <ModalCom
          open={isOpen}
          title="手动上传电子税务额度"
          onOk={upDate}
          onCancel={() => setOpen(false)}
          centered
          width={700}
          footer={null}
        >
          <Form form={form} style={{ padding: 20 }}>
            <Form.Item hidden name="id"></Form.Item>
            <Row>
              <Col {...colProps}>
                <Form.Item
                  label="总授信额度"
                  name="totalCredit"
                  rules={[
                    { max: 18, message: "请输入长度为18的整数或者小数" },
                    {
                      pattern: /^(([1-9][0-9]*)|((\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/,
                      message: "请输入整数或小数点保留两位",
                    },
                    { required: true, message: "请输入总授信额度" },
                  ]}
                >
                  <Input placeholder={"请输入"} style={{ width: 240 }} />
                </Form.Item>
              </Col>
              <Col {...colProps}></Col>
            </Row>
            <Row>
              <Col {...colProps}>
                <Form.Item
                  label="可用授信额度"
                  name="availableCredit"
                  rules={[
                    { max: 18, message: "请输入长度为18的整数或者小数" },
                    {
                      pattern: /^(([1-9][0-9]*)|((\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/,
                      message: "请输入整数或小数点保留两位",
                    },

                    { required: true, message: "请输入可用授信额度" },
                  ]}
                >
                  <Input placeholder={"请输入"} style={{ width: 240 }} />
                </Form.Item>
              </Col>
              <Col {...colProps}></Col>
            </Row>
          </Form>
          <div style={{ width: "100%", borderTop: "1px solid #f0f0f0" }}>
            <div className="check-modal">
              <i></i>
              <div style={{ marginBottom: 10 }}>
                <Button className="check-modal-button" onClick={() => setOpen(false)}>
                  取消
                </Button>
                <Button type="primary" onClick={handleUpData}>
                  完成
                </Button>
              </div>
            </div>
          </div>
        </ModalCom>
        {visibleQuanDianModalLogin && (
          <QuanDianModalLogin
            visible={visibleQuanDianModalLogin}
            onCancel={closeQuanDianModalLogin}
            invoiceCode={invoiceCode}
            nsrsbh={nsrsbh}
          />
        )}
        {visibleSecondaryAuthentication && (
          <SecondaryAuthentication
            visible={visibleSecondaryAuthentication}
            onCancel={closeQuanDianModalLogin}
            invoiceInfo={invoiceInfo}
            nsrsbh={nsrsbh}
          />
        )}
      </DetailPageContent>
    </DetailPageContainer>
  )
}

export default InvoicingParty
