/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
import { RollbackOutlined } from "@ant-design/icons"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import { Button, Card, Col, Form, Row, Space, message } from "antd"
import React, { forwardRef, memo, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { getOrgs } from "../../../../api/service"
import {
  getAddRegionsList,
  getOperRegionsDetails,
  getPutRegionsList,
  getTissueList,
} from "../../service"
import { FinishResult } from "../interface"
import BasicInforMation from "./BasicInformation/index" //基础信息
import Registration from "./Registration" //个体户注册信息配置
import "./index.scss"

export const NewTaxDistrict = memo(() => {
  //form实例
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const history: any = useParams()
  const [individualPrefix, setIndividualPrefix] = useState<any>("") //前缀
  const [individualSuffix, setIndividualSuffix] = useState<any>("") //后缀
  const [tissueList, setTissueList] = useState<any>([]) //组织列表
  const [tissueAuthList, setTissueAuthList] = useState<any>([]) //组织当前列表
  const [isNeedDual, setIsNeedDual] = useState<any>(true)
  const [isNeedAgreement, setIsNeedAgreement] = useState<any>(true)
  const [supportAnonymousType, setSupportAnonymousType] = useState<any>(true) // 无名代办
  const [fileList, setFileList] = useState<any>([])
  const handFilePath = (file) => {
    let options = file?.map((ie) => {
      return {
        name: ie?.name,
        path: ie?.response?.data?.filePath || ie?.url,
      }
    })
    return options
  }
  //组织列表
  const tissueInit = async () => {
    const response = await getTissueList({ size: -1, searchCount: true })
    if (response.code == 200) {
      const result = response.data.records.map((ie) => {
        return {
          label: ie.name,
          value: ie.id,
        }
      })
      setTissueList(result)
    }
  }
  //组织当前列表
  const tissueAuthInit = async () => {
    const response = await getOrgs()
    if (response.code == 200) {
      const result = response.data?.map((ie) => {
        return {
          label: ie.name,
          value: ie.id,
        }
      })
      setTissueAuthList(result)
    }
  }
  //保存校验
  const onFinish = async (values: any) => {
    console.log(values, "values")
    let params: any = {
      ...values,
      flowConfigs: {
        ...values.flowConfigs,
        isNeedDual: values.isNeedDual,
        isNeedAgreement: values.isNeedAgreement,
        agreementTemplateId: values.agreementTemplateId,
        agreementExampleName: values?.agreementExampleFileId
          ? handFilePath(values?.agreementExampleFileId)[0]?.name
          : "",
        agreementExampleFileId: values?.agreementExampleFileId
          ? handFilePath(values?.agreementExampleFileId)[0]?.path
          : "",
        shopOperateTemplateId: values.shopOperateTemplateId,
        applyLogoutTemplateId: values.applyLogoutTemplateId,
      },
      organizationId: values?.organizationId,
      operatorIdentificationConfig: {
        dualPrefix: values.dualPrefix,
      },
      notifyConfig: {
        SUBMIT_APPLY: {
          webhookUrl: values?.submitUrl,
          context: values?.submitContent,
        },
        CHECK_COMPLETE: {
          webhookUrl: values?.checkUrl,
          context: values?.checkContent,
        },
        LICENSE_COMPLETE: {
          webhookUrl: values?.licenseUrl,
          context: values?.licenseContent,
        },
        CLEARANCE_TAX: {
          webhookUrl: values?.clearanceUrl,
          context: values?.clearanceContent,
        },
        LOGOUT: {
          webhookUrl: values?.logoutUrl,
          context: values?.logoutContent,
        },
      },
      supportAnonymous: supportAnonymousType,
    }
    console.log(params, "params")
    if (history?.id == "null") {
      const result: FinishResult | any = await getAddRegionsList({ ...params })
      if (result.code == 200) {
        message.success("发布成功")
        navigate(`/platformSetup/district`)
      }
    } else {
      params.id = history?.id
      const result: any = await getPutRegionsList(params)
      if (result.code == 200) {
        message.success("修改成功")
        navigate(`/platformSetup/district`)
      } else {
        message.error(result.message || "修改失败")
      }
    }
  }
  //错误验证
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo)
  }

  const init = async () => {
    if (history?.id == "null") return
    const result = await getOperRegionsDetails(history?.id)
    if (result.code == 200) {
      const formList = result.data
      console.log(formList, "formList")
      form.setFieldsValue({
        ...formList,
        postcode: formList.postcode + "",
        isNeedDual: formList?.flowConfig?.isNeedDual,
        isNeedAgreement: formList?.flowConfig?.isNeedAgreement,
        agreementTemplateId: formList?.flowConfig?.agreementTemplateId,
        agreementExample: formList?.flowConfig?.agreementExample,
        shopOperateTemplateId: formList?.flowConfig?.shopOperateTemplateId,
        applyLogoutTemplateId: formList?.flowConfig?.applyLogoutTemplateId,
        submitUrl: formList?.notifyConfig?.SUBMIT_APPLY?.webhookUrl,
        submitContent: formList?.notifyConfig?.SUBMIT_APPLY?.context,
        checkUrl: formList?.notifyConfig?.CHECK_COMPLETE?.webhookUrl,
        checkContent: formList?.notifyConfig?.CHECK_COMPLETE?.context,
        licenseUrl: formList?.notifyConfig?.LICENSE_COMPLETE?.webhookUrl,
        licenseContent: formList?.notifyConfig?.LICENSE_COMPLETE?.context,
        clearanceUrl: formList?.notifyConfig?.CLEARANCE_TAX?.webhookUrl,
        clearanceContent: formList?.notifyConfig?.CLEARANCE_TAX?.context,
        logoutUrl: formList?.notifyConfig?.LOGOUT?.webhookUrl,
        logoutContent: formList?.notifyConfig?.LOGOUT?.context,
        organizationId: formList?.organizationId,
        dualPrefix: formList?.operatorIdentificationConfig?.dualPrefix,
        agreementExampleFileId: [
          {
            name: formList?.flowConfig?.agreementExampleName,
            url: formList?.flowConfig?.agreementExampleFileId,
          },
        ],
        operationAuth: {
          operationId: formList.operationAuthList?.map((item) => item.operationId),
        },
        flowConfigs: {
          ...formList.flowConfig,
        },
      })
      setFileList([
        {
          name: formList?.flowConfig?.agreementExampleName,
        },
      ])
      setSupportAnonymousType(formList.supportAnonymous)
      setIndividualPrefix(formList.individualBusinessPrefix)
      setIndividualSuffix(formList.individualBusinessSuffix)
      setIsNeedDual(formList?.flowConfigs?.isNeedDual)
      setIsNeedAgreement(formList?.flowConfigs?.isNeedAgreement)
    }
  }

  useEffect(() => {
    tissueInit()
    tissueAuthInit()
    init()
  }, [])

  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <Button
              onClick={() => navigate("/platformSetup/district")}
              icon={<RollbackOutlined />}
              style={{ color: "#1890FF" }}
            >
              返回
            </Button>
            <span style={{ paddingLeft: 4 }}>
              {" "}
              {history?.id == "null" ? "新建税区" : "编辑税区"}
            </span>
          </Space>
        }
        breadCrumb={[
          { label: "平台设置", path: "" },
          { label: "税区管理", path: "" },
          { label: "新建税区", path: "" },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <DetailPageContent>
        <div className="NewTradingScheme_Content">
          <Form
            labelWrap
            form={form}
            name="basic"
            colon={false}
            initialValues={{
              remember: true,
              isNeedDual: true,
              isNeedAgreement: true,
              dualPrefix: "newGeneral1",
              operationAuth: {
                operationId: [
                  JSON.parse(
                    localStorage.getItem("operation_tissue") || "{}"
                  )?.organizationId?.toString(),
                ], //组织id,
                operationAuthType: "HANDLEREGION",
              },
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Card title="新增税区">
              <Row style={{ justifyContent: "center", marginLeft: -200 }}>
                <Col span="8">
                  <BasicInforMation form={form} />
                </Col>
              </Row>
            </Card>

            <Registration
              form={form}
              individualPrefix={individualPrefix}
              setIndividualPrefix={setIndividualPrefix}
              individualSuffix={individualSuffix}
              setIndividualSuffix={setIndividualSuffix}
              supportAnonymousType={supportAnonymousType}
              setSupportAnonymousType={setSupportAnonymousType}
              fileList={fileList}
              setFileList={setFileList}
              setIsNeedDual={setIsNeedDual}
              isNeedDual={isNeedDual}
              setIsNeedAgreement={setIsNeedAgreement}
              isNeedAgreement={isNeedAgreement}
              tissueList={tissueList}
              tissueAuthList={tissueAuthList}
            />

            <div className="NewTradingScheme_Content_button_save">
              <Button
                className="NewTradingScheme_Content_button_save_left"
                onClick={() => navigate(`/platformSetup/district`)}
              >
                取消
              </Button>
              <Button type="primary" htmlType="submit">
                保存
              </Button>
            </div>
          </Form>
        </div>
      </DetailPageContent>
    </DetailPageContainer>
  )
})

export default memo(
  forwardRef((props: any, ref: any) => <NewTaxDistrict {...props} refInstance={ref} />)
)
