/* eslint-disable react/jsx-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { Button, Space, Col, Radio, Form, Row, Table, Modal, message } from "antd"
import React, { useState, useCallback, useMemo, useEffect } from "react"
import {
  getTradingPreview,
  getTradingPreviewAdd,
  getTissueInfo,
  editTissueInfo,
  exportExcel,
} from "../../../../promises"
import "./index.scss"
import MakeOutAnInvoice from "./components/makeOutInfoAnInvoice"
import MakeOutEditAnInvoice from "./components/makeOutEditAnInvoice"
import { formatNumber } from "@/utils/formatNumber"
import columns from "./columns"
import moment from "moment"
import { saveAs } from "file-saver"

export const ProcureApplyBilling = (props: any) => {
  const [form] = Form.useForm()
  const {
    selectedRowKeys,
    setIsModalOpen,
    isModalOpen,
    handleRefresh,
    setSelectedRowKeys,
    setSelectedRowKeysList,
  } = props
  const currentTenantId = JSON.parse(localStorage.getItem("operation_tissue") || "{}")
    ?.organizationId //组织id
  console.log(JSON.parse(localStorage.getItem("operation_tissue") || "{}"), currentTenantId, 123123)
  const [radioList, setRadioList] = useState<string>("SINGLE") //开票方式默认按订单开票
  const [makeOutAnInvoiceFlag, setMakeOutAnInvoiceFlag] = useState(false) //发票抬头控制
  const [previewApply, setPreviewApply] = useState<any>([]) //申请开票预览
  const [currentTenantList, setCurrentTenantList] = useState<any>([]) //发票抬头信息
  const _columns: any = columns
  const handleRequestData = async (_invoiceType?: any) => {
    try {
      const res: any = await getTradingPreview({
        orderIds: selectedRowKeys.map((ie: any) => ie.id).join(","),
        applyOrgId: currentTenantId,
        invoiceType: _invoiceType || radioList,
      })
      res.data.invoiceInfoList = res.data.invoiceInfoList?.map((item: any, index: number) => {
        return { ...item, index: index + 1 }
      })
      setPreviewApply(res?.data)
      return {
        data: res?.data,
        total: res?.data.total,
      }
    } catch (error) {
      console.log(error)
    }
  }
  //修改发票抬头
  const handApplicationBank = async () => {
    let params = {
      values: form.getFieldsValue(),
      id: currentTenantId,
    }
    const result: any = await editTissueInfo(params)
    if (result.code == 200) {
      message.success("修改成功")
      handGetTissueInfo()
      setMakeOutAnInvoiceFlag(false)
    } else {
      message.error(result.message)
    }
  }
  //确认申请
  const handleOk = async () => {
    if (!previewApply?.invoiceInfoList?.length) {
      message.error("无可开票信息")
      return
    }
    let params = {
      issueMode: radioList,
      applyOrgId: currentTenantId,
      invoicePreviewDetails: previewApply.invoiceInfoList,
    }
    const result: any = await getTradingPreviewAdd(params)
    if (result.code == 200) {
      if (!result?.data?.failure) message.success("申请成功")
      Modal.info({
        title: `开票申请`,
        okText: "我已知晓",
        content: (
          <div>
            <div style={{ margin: "3px 0" }}>
              申请成功{result?.data?.success}条，失败{result?.data?.failure}条
            </div>
            {result?.data?.message?.length ? (
              <>
                <div>失败原因：</div>
                <>
                  {result?.data?.message?.map((item: string, index: number) => {
                    return (
                      <div>
                        {index + 1}、{item}
                      </div>
                    )
                  })}
                </>
              </>
            ) : null}
          </div>
        ),
        onOk: () => {
          handleRefresh()
          setIsModalOpen(false)
          typeof setSelectedRowKeys == "function" && setSelectedRowKeys([])
          typeof setSelectedRowKeysList == "function" && setSelectedRowKeysList([])
        },
        onCancel: () => {
          handleRefresh()
          setIsModalOpen(false)
          typeof setSelectedRowKeys == "function" && setSelectedRowKeys([])
          typeof setSelectedRowKeysList == "function" && setSelectedRowKeysList([])
        },
      })
    }
  }
  //获取发票抬头信息
  const handGetTissueInfo = async () => {
    const result = await getTissueInfo(currentTenantId)
    if (result.code == 200) {
      setCurrentTenantList(result.data)
    }
  }
  useMemo(() => {
    if (isModalOpen) {
      handGetTissueInfo()
      handleRequestData()
    }
  }, [isModalOpen])
  const applicationBankButton = useCallback(
    (flag: any) => {
      return (
        <>
          <Button
            className="apply-billing-button"
            style={{ display: flag ? "none" : "" }}
            onClick={() => {
              form.setFieldsValue({ ...currentTenantList })
              setMakeOutAnInvoiceFlag(true)
            }}
          >
            编辑
          </Button>
          {flag
            ? [
                <div className="apply-billing-button">
                  <Button type="primary" onClick={handApplicationBank}>
                    保存
                  </Button>
                  <Button
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      setMakeOutAnInvoiceFlag(false)
                    }}
                  >
                    取消
                  </Button>
                </div>,
              ]
            : null}
        </>
      )
    },
    [currentTenantList]
  )
  //查看不可开票订单
  const handExportToExcel = async () => {
    const currentTime = moment().format("YYYY-MM-DD")
    const res: any = await exportExcel(previewApply?.excelKey, {
      responseType: "arraybuffer",
    })
    let blob = new Blob([res.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
    saveAs(blob, `不可开票订单_${currentTime}${decodeURI(".xlsx")}`)
  }
  return (
    <div className="billing">
      <Modal
        title="申请开票"
        open={isModalOpen}
        width={900}
        onCancel={() => {
          setIsModalOpen(false)
        }}
        destroyOnClose={true}
        footer={null}
      >
        <div className="ant-content">
          <div className="apply-radio">
            <div className="apply-billing">开票方式</div>
            <div className="apply-content">
              <Radio.Group
                defaultValue={radioList}
                onChange={(e: any) => {
                  setRadioList(e.target.value)
                  handleRequestData(e.target.value)
                }}
              >
                <Radio value={"SINGLE"}>按订单开票</Radio>
                <Radio value={"MERGE"}>合并订单开票</Radio>
              </Radio.Group>
            </div>
          </div>
          <div className="apply-radio">
            <div className="order-content">
              <div className="apply-billing" style={{ paddingTop: 16 }}>
                订单
              </div>
              <Button
                className="apply-billing-button"
                disabled={!previewApply?.excelKey}
                style={{ color: !previewApply?.excelKey ? "#ccc" : "" }}
                onClick={handExportToExcel}
              >
                查看不可开票订单
              </Button>
            </div>

            <Row className="apply-billing-row">
              <Col span="8">
                <div className={"info_items"}>
                  <span className={"label"}>开票总订单:</span>
                  <span className={"value"}>{previewApply?.invoiceOrderCount}</span>
                </div>
              </Col>
              <Col span="8">
                <div className={"info_items"}>
                  <span className={"label"}>总金额:</span>
                  <span className={"value"}>
                    {formatNumber(previewApply?.totalAmount, {
                      type: "number",
                      bit_number: 2,
                      thousands: true,
                    })}
                  </span>
                </div>
              </Col>
              <Col span="8">
                <div className={"info_items"}>
                  <span className={"label"}>可开票订单数:</span>
                  <span className={"value"}>{previewApply?.availableInvoiceOrderCount}</span>
                </div>
              </Col>
              <Col span="8">
                <div className={"info_items"}>
                  <span className={"label"}>开票数:</span>
                  <span className={"value"}>
                    <span className={"value"}>{previewApply?.availableInvoiceCount}</span>
                  </span>
                </div>
              </Col>
              <Col span="8">
                <div className={"info_items"}>
                  <span className={"label"}>开票金额:</span>
                  <span className={"value"}>
                    <span className={"value"}>
                      {formatNumber(previewApply?.invoiceOrderAmount, {
                        type: "number",
                        bit_number: 2,
                        thousands: true,
                      })}
                    </span>
                  </span>
                </div>
              </Col>
            </Row>
          </div>
          <div className="apply-radio">
            <div className="order-content">
              <div className="apply-billing" style={{ paddingTop: 16 }}>
                开票预览
              </div>
              <div className="text">
                如订单所属开票方不同，品类发票项目名称，规格型号不同，或单笔订单超过开票金额限额则会出现发票拆分的情况
              </div>
            </div>
            <div className="table">
              <Table
                pagination={{
                  pageSize: 5,
                  showQuickJumper: true,
                  showTotal(total, range) {
                    return `总共${total}条`
                  },
                }}
                dataSource={previewApply?.invoiceInfoList}
                columns={_columns}
                rowKey={(record: any) => record?.index}
                expandable={{
                  expandedRowRender: (record: any) => (
                    <>
                      {record?.orders?.map((item: any) => (
                        <p style={{ margin: 0, marginLeft: "38px" }}>
                          订单：{item?.orderId}
                          <span style={{ margin: "0 12px" }}>
                            不含税金额：
                            {formatNumber(item?.totalPrice, {
                              bit_number: 2,
                              thousands: true,
                            })}
                          </span>
                          <span style={{ margin: "0 12px" }}>
                            增值税：
                            {formatNumber(item?.taxAmount, {
                              bit_number: 2,
                              thousands: true,
                            })}
                          </span>
                          <span style={{ margin: "0 12px" }}>
                            价税合计：
                            {formatNumber(item?.amountWithTax, {
                              bit_number: 2,
                              thousands: true,
                            })}
                          </span>
                        </p>
                      ))}
                    </>
                  ),
                  rowExpandable: (record: any) => record?.orders?.length,
                }}
                indentSize={5}
              ></Table>
            </div>
          </div>
          <div>
            <div className="order-content">
              <div className="apply-billing" style={{ paddingTop: 16 }}>
                发票抬头信息
              </div>
              {applicationBankButton(makeOutAnInvoiceFlag)}
            </div>
            <div className="apply-content">
              <Form form={form} name="basic" initialValues={{ remember: true }} autoComplete="off">
                {makeOutAnInvoiceFlag ? (
                  <MakeOutEditAnInvoice form={form} />
                ) : (
                  <MakeOutAnInvoice data={currentTenantList} />
                )}
              </Form>
            </div>
          </div>
        </div>

        <Form.Item wrapperCol={{ span: 16 }} style={{ borderTop: "1px solid #f0f0f0" }}>
          <div
            className="form_footer"
            style={{ display: "flex", marginBottom: 10, paddingTop: 10 }}
          >
            <div style={{ display: "flex", flex: 1, textAlign: "right", marginLeft: "123%" }}>
              <Button
                style={{ marginRight: "12px" }}
                onClick={() => {
                  setIsModalOpen(false)
                }}
              >
                取消
              </Button>
              <Button type="primary" onClick={handleOk}>
                确定
              </Button>
            </div>
          </div>
        </Form.Item>
      </Modal>
    </div>
  )
}
export default ProcureApplyBilling
