/* eslint-disable react/display-name */
import { Card, message } from "antd"
import React, { memo, useState, useEffect, useMemo } from "react"
import ProDescriptions, { ProDescriptionsItemProps } from "@ant-design/pro-descriptions"
import { OperableImage } from "@src/components/ImageOptions"
import { cross_env } from "@src/services/serviceOp"
import { legalPoliticalAffiliationList } from "../../../constants"
import { getOcrIdCardFront, getOperAgenciessAdd, getOperAgenciessEdit } from "../../../service"
import { cloneDeep } from "lodash"
import "./index.scss"
export const DetailsInformation = memo((props: any) => {
  const { list, registrationList } = props
  const [detailList, setDetailList] = useState<any>({})
  const [currentImages, setCurrentImages] = useState<any>(registrationList)
  useEffect(() => {
    if (JSON.stringify(list) !== "{}") {
      setDetailList({
        ...list,
        postcode: list?.handleRegion?.postcode,
      })
    }
    if (registrationList?.length) {
      setCurrentImages(registrationList)
    }
  }, [list, registrationList])
  //头像
  const basicInfoLegalIdNoColumns: any = {
    dataIndex: "legalIdNo",
    title: "证件照",
    editable: false,
    render: () => {
      return <img src={`data:image/png;base64,${detailList?.idCardHead}`} width="120"></img>
    },
  }
  const headerDescImages: ProDescriptionsItemProps<Tissue.Info>[] = [
    {
      dataIndex: "idCardFront",
      title: "法人身份证",
      className: "descriptionsName",
      editable: false,
      render: (dom, record) => (
        <>
          <OperableImage
            width={120}
            height={120}
            src={currentImages[1] || "string"}
            action={`${cross_env}/rscm/rscm-resource/oss/endpoint`}
            uploadFileProps={{
              name: "file",
              onChange: async ({ file }) => {
                if (list.businessLicenseStatus == "UNHANDLED") {
                  if (file.response?.data?.downloadLink) {
                    // 身份证正面
                    message.loading("身份图像信息识别中...")
                    setCurrentImages([currentImages[0], file.response?.data?.downloadLink])
                    // 获取证件照
                    const result: any = await getOcrIdCardFront({
                      url: file.response?.data?.downloadLink,
                    })
                    const Portrait = JSON.parse(result?.data?.advancedInfo || "{}")?.Portrait
                    // 证件照
                    setDetailList({ ...detailList, idCardHead: Portrait })
                    updateImage(1, file.response?.data?.filePath, Portrait)
                  }
                }
              },
            }}
          />
          <div style={{ marginLeft: 10 }}>
            <OperableImage
              width={120}
              height={120}
              src={currentImages[0] || "string"}
              action={`${cross_env}/rscm/rscm-resource/oss/endpoint`}
              uploadFileProps={{
                name: "file",
                onChange: async ({ file }) => {
                  if (list.businessLicenseStatus == "UNHANDLED") {
                    if (file.response?.data?.downloadLink) {
                      // 身份证反面
                      message.loading("身份图像信息识别中...")
                      setCurrentImages([file.response?.data?.downloadLink, currentImages[1]])
                      updateImage(2, file.response?.data?.filePath)
                    }
                  }
                },
              }}
            />
          </div>
        </>
      ),
    },
    detailList?.idCardHead ? basicInfoLegalIdNoColumns : null,
  ]
  const headerDescColumns: ProDescriptionsItemProps<Tissue.Info>[] = [
    {
      title: "姓名",
      key: "legalName",
      dataIndex: "legalName",
      ellipsis: true,
      copyable: true,
    },
    {
      title: "身份证号",
      key: "legalIdno",
      dataIndex: "legalIdno",
      copyable: true,
    },
    {
      title: "政治面貌",
      key: "legalPoliticalAffiliation",
      dataIndex: "legalPoliticalAffiliation",
      render(value, record) {
        return legalPoliticalAffiliationList.map((ie) => {
          if (ie.value == record?.legalPoliticalAffiliation) {
            // eslint-disable-next-line react/jsx-key
            return <span>{ie.label}</span>
          }
        })
      },
    },
    {
      title: "性别",
      key: "sex",
      dataIndex: "sex",
      copyable: true,
    },
    {
      title: "住址",
      key: "legalAddress",
      dataIndex: "legalAddress",
      copyable: true,
    },
    {
      title: "手机号",
      key: "legalPhone",
      dataIndex: "legalPhone",
      copyable: true,
    },
    {
      title: "邮政编码",
      key: "postcode",
      dataIndex: "postcode",
      copyable: true,
    },
    {
      title: "邮箱",
      key: "legalMail",
      dataIndex: "legalMail",
      copyable: true,
    },
    {
      title: "民族",
      key: "legalNation",
      dataIndex: "legalNation",
    },
  ]
  const updateImage = async (type, idcard, idHeader?: any) => {
    const params: any = {
      id: detailList?.id,
    }
    if (type == 1) {
      //身份证正面 证件照
      params.idCardFront = idcard
      params.idCardHead = idHeader
    } else {
      params.idCardBack = idcard
    }
    const result: any = await getOperAgenciessEdit(params)
    if (result?.code) {
      message.success("更新成功")
    }
  }
  return (
    <Card title="基本信息" className="DetailsInformation">
      <ProDescriptions column={4} columns={headerDescImages}></ProDescriptions>
      <ProDescriptions
        column={4}
        columns={headerDescColumns}
        dataSource={detailList}
      ></ProDescriptions>
    </Card>
  )
})

export default DetailsInformation
