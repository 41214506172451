/* eslint-disable prettier/prettier */
type COMPANY_TYPE = {
  company_name: string
  mark: {
    label: string
    value: string
  }[]
}
type COMPANY = {
  xyrscm: COMPANY_TYPE // 建龙
  sinzetech: COMPANY_TYPE // 新之
  nbcoop: COMPANY_TYPE // 供销
  ly: COMPANY_TYPE // 绿易
}

type URL_TYPE = {
  "TEST-RSCM-OP.SAIKUL.COM": "sinzetech"
  "RSCM-OP.SAIKUL.COM": "sinzetech"
  "RSCM-OP.XYRSCM.COM": "xyrscm"
  "RSCM-OP.NBCOOP.COM.CN": "nbcoop"
  "WWW.NBCOOP.COM.CN": "nbcoop"
  "NBCOOP.COM.CN": "nbcoop"
  "WWW.LVYINET.COM": "ly"
  "LY.SAIKUL.COM": "ly"
  "WASTEFREE.SAIKUL.COM": "wufei"
  "LOCALHOST:3000": "nbcoop"
}
const ORIGINURL: URL_TYPE = {
  "TEST-RSCM-OP.SAIKUL.COM": "sinzetech",
  "RSCM-OP.SAIKUL.COM": "sinzetech",
  "RSCM-OP.XYRSCM.COM": "xyrscm",
  "RSCM-OP.NBCOOP.COM.CN": "nbcoop",
  "WWW.NBCOOP.COM.CN": "nbcoop",
  "NBCOOP.COM.CN": "nbcoop",
  "WWW.LVYINET.COM": "ly",
  "LY.SAIKUL.COM": "ly",
  "WASTEFREE.SAIKUL.COM": "wufei",
  "LOCALHOST:3000": "nbcoop",
}

const company_info = {
  xyrscm: {
    company_name: "建龙",
    mark: [{ label: "建龙", value: "default" }],
  },
  sinzetech: {
    company_name: "新之",
    mark: [
      { label: "新之", value: "default" },
      { label: "绿易", value: "ly" },
    ],
  },
  nbcoop: {
    company_name: "供销",
    mark: [{ label: "供销", value: "default" }],
  },
  ly: {
    company_name: "绿易",
    mark: [{ label: "绿易", value: "default" }],
  },
}

/**
 * 生成域名配置地址
 * @param config
 * @returns
 */
const createWebnfig = (config: COMPANY) => {
  const origin = window.location.origin.split("//")[1].toLocaleUpperCase() as "RSCM-EP.SAIKUL.COM"
  if (ORIGINURL[origin]) {
    return config[ORIGINURL[origin]]
  } else {
    return config.sinzetech
  }
}

export const COMPANY_INFO = createWebnfig(company_info)
