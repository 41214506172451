import React, { useState, useRef, useEffect } from "react"
import { Input, Button, message } from "antd"
import { UserOutlined, LockOutlined } from "@ant-design/icons"

import { useNavigate } from "react-router-dom"
import Util from "../../../services/util"
import { FUNCTION_CODE, ROLE_CODE } from "../../../store/constants"
import { useDispatch } from "react-redux"
import "./index.scss"
const MessageInput: React.FC = () => {
  const dispatch = useDispatch<any>()
  const [phone, setPhone] = useState<string>("")
  const [sms, setSms] = useState<string>("")
  const [validateId, setValidateId] = useState<string>("")
  const [messageApi, contextHolder] = message.useMessage()
  const [tr, setTr] = useState<number>(0)
  const [canSubmit, setCanSubmit] = useState(false)
  const timer: any = useRef(null)
  const util = Util.getInstance()
  const navigate = useNavigate()
  const [redirect, setRedirect] = useState<any>("")

  useEffect(() => {
    if (tr === 60) timer.current = setInterval(() => setTr((tr) => --tr), 1000)
    else if (tr === 0) timer.current && clearInterval(timer.current)
  }, [tr])
  useEffect(() => {
    let redirect_url = new URLSearchParams(window.location.search.substring(1)).get("redirect")
    setRedirect(redirect_url)
    return () => {
      setRedirect("")
    }
  }, [])

  const putMessage = () => {
    // var reg = /^[1][3,4,5,7,8][0-9]{9}$/;
    if (!phone || phone === "") {
      messageApi.open({
        type: "error",
        content: "请输入手机号",
      })
      return
    }
    let reg = /^1[0-9]{10}/
    if (reg.test(phone)) {
      setTr(60)
      util
        .httpPostParams("/rscm-resource/sms/endpoint/send-validate", {
          phone,
        })
        .then((data: any) => {
          setValidateId(data?.data?.id)
          messageApi.open({
            type: "success",
            content: "验证码已发送，请您查收。",
          })
        })
        .catch(() => {
          messageApi.open({
            type: "error",
            content: "验证码发送失败，请稍后！",
          })
        })
    } else {
      messageApi.open({
        type: "error",
        content: "手机号错误，请您检查后重新输入。",
      })
    }
  }
  const login = () => {
    if (!sms || sms === "") {
      messageApi.open({
        type: "error",
        content: "请输入验证码",
      })
      return
    }
    util
      .httpPostParams("/sinzetech-admin-auth/oauth/token", {
        grant_type: "validate_code",
        client_id: "client",
        client_secret: "client",
        validateId: validateId,
        validateCode: sms,
        phone,
      })
      .then((data: any) => {
        const tokenMessage = {
          userId: data.userId,
          account: data.account,
          phone: data.phone,
          access_token: data.access_token,
          token_type: data.token_type,
          userName: data.username,
          wxAccount: data.wxAccount,
          functionCode: data.functionCode,
        }
        // // functionCode
        // dispatch({
        //   type: FUNCTION_CODE,
        //   payload: {
        //     codeList: data.functionCode.split(","),
        //   },
        // });
        util.setSessiontoken(JSON.stringify(tokenMessage))
        // navigate("/system");
        getUserInfo(tokenMessage)
      })
      .catch((err) => {
        messageApi.open({
          type: "error",
          content: err.response?.data?.message || "登录失败！",
        })
      })
  }
  const getUserInfo = async (tokenMessage: any) => {
    const res = await util.httpGet(`/sinzetech-admin-user/users/current`)
    if (res?.code === 200) {
      // functionCode
      dispatch({
        type: ROLE_CODE,
        payload: {
          codeList: res?.data.authorities,
          roleCodes: res?.data?.roleCodes.split(","),
        },
      })
      const params = {
        ...tokenMessage,
        roleCodes: res?.data?.roleCodes,
      }
      util.setSessiontoken(JSON.stringify(params))
      if (redirect) {
        window.location.href = redirect
      } else {
        navigate("/entity/transaction/enterprise")
      }
    }
  }
  return (
    <div className="sign-container-input-by-message">
      {contextHolder}
      <div className="sign-container-input-by-message-phone">
        <Input
          placeholder="手机号"
          prefix={<UserOutlined />}
          value={phone}
          onChange={(e) => {
            let value = e.target.value.replace(/[^\d]/g, "")
            setPhone(value)
            if (phone !== "" && sms !== "") {
              setCanSubmit(true)
            }
          }}
          maxLength={11}
        />
        <Button size="small" onClick={putMessage} disabled={tr === 0 ? false : true}>
          {tr > 0 ? `${tr}秒后重新点击` : "获取验证码"}
        </Button>
      </div>
      <div className="sign-container-input-by-message-sms">
        <Input
          placeholder="短信验证码"
          prefix={<LockOutlined />}
          value={sms}
          onChange={(e) => {
            let value = e.target.value.replace(/[^\d]/g, "")
            setSms(value)
            if (phone !== "" && sms !== "") {
              setCanSubmit(true)
            }
          }}
          maxLength={6}
        />
      </div>
      <Button
        type="primary"
        onClick={login}
        className="sign-container-input-by-message-submit"
        disabled={!canSubmit}
      >
        登录
      </Button>
    </div>
  )
}
export default MessageInput
