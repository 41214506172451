/* eslint-disable react/jsx-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
import { Button, Form, Row, Card, Col, UploadFile, Tag, Image, Upload } from "antd"
import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import { namelessOptionEnum, getFileUrl } from "../../../constants"
import { PaperClipOutlined, UploadOutlined } from "@ant-design/icons"
import ComponUpload from "../../components/componUpload/index"
import downloadFile from "../../../../../utils/downloadFile"
import { OperableImage } from "@src/components/OperableImage"

import "./index.scss"
import { getUrlByFileId } from "../../../service"
const FormItem = Form.Item
export const MaterialUploadDetails = memo((props: any) => {
  const { list, videoList, fileImgData, setFileImgData, submitOpen } = props
  const [fileListOther, setFileListOther] = useState<UploadFile[]>([]) // 其他补充材料上传
  const [delegationAgreement, setDelegationAgreement] = useState<UploadFile[]>([]) // 其他补充材料上传
  const [imageDataFlag, setImageDataFlag] = useState<any>(true)
  const handFileDataList = () => {
    let newArr: any = list.extendsFiles
    newArr?.map((item: any, index) => {
      if (item?.fileType == "IMAGE") {
        if (imageDataFlag) {
          item?.files.map(async (fileItem) => {
            item.newFiles = []
            let newFileItem = await handGetUrlByFileId(fileItem?.path)
            item.newFiles.push(...newFileItem)
          })
        }
      } else {
        item.newFiles = []
      }
    })
    setTimeout(() => {
      setFileImgData(newArr)
      setImageDataFlag(false)
    }, 1000)
  }
  const handGetUrlByFileId = async (fileIdList) => {
    let result = await getUrlByFileId({ zoom: false, fileIds: fileIdList })?.then((res) => {
      return res.data
    })
    return result
  }
  useMemo(() => {
    handFileDataList()
  }, [list, imageDataFlag])
  useEffect(() => {
    setFileListOther(list?.otherFile)
    setDelegationAgreement(list?.delegatedAgreement?.files)
    // setFileDataLists(list?.extendsFiles)
  }, [list])
  const fileDomeCile = (fileList, bool, ie?) => {
    return (
      <div className="fileDomeCile">
        <span> {ie?.name}：</span>
        <div className="fileDomeCile-file">
          {bool ? (
            <Tag color={list.signStatus == "SIGNED" ? "green" : "orange"} style={{ width: 50 }}>
              {list.signStatus == "SIGNED" ? "已签署" : "待签署"}
            </Tag>
          ) : null}
          {fileList?.map((item, index) => {
            return (
              <>
                <span
                  className="fileDomeCile-file-span"
                  key={index}
                  onClick={() => {
                    getFileUrl(item.path)
                  }}
                >
                  <span style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                    <PaperClipOutlined />
                  </span>
                  <span style={{ paddingLeft: 5 }}>{item.name || "委托协议"}</span>
                  <span className="fileDomeCile-icon" style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                    <UploadOutlined />
                  </span>
                </span>
              </>
            )
          })}
        </div>
      </div>
    )
  }

  const handImageStatus: any = useMemo(() => {
    console.log(fileImgData, "fileImgData")
    let arrExtraFileConfigs = fileImgData?.map((ie: any, index: number) => {
      if (ie?.fileType == "IMAGE") {
        return (
          <div style={{ display: "flex" }}>
            <p>{ie?.name + "："}</p>
            {ie?.newFiles.map((item, ImgIndex) => {
              return (
                <div className="details-img">
                  <OperableImage
                    width={120}
                    height={100}
                    isUpdate={false}
                    src={item}
                  ></OperableImage>
                  <span>{ie?.comment + (ImgIndex + 1)}</span>
                </div>
              )
            })}
          </div>
        )
      } else {
        return (
          <Col>
            <div style={{ display: "flex" }} className="details-file">
              <div>{fileDomeCile(ie?.files, false, ie)}</div>
            </div>
          </Col>
        )
      }
    })
    return arrExtraFileConfigs
  }, [imageDataFlag, fileImgData])
  return (
    <Card title="材料上传">
      <Row className="materialUploadDetails_row">
        <Col>
          <div>{handImageStatus}</div>
        </Col>
      </Row>
      {list.handleRegion?.flowConfig?.isNeedAgreement ? (
        <Row className="materialUploadDetails_row">
          <Col>
            <div style={{ display: "flex" }}>
              <span>委托协议上传</span>
              {fileDomeCile(delegationAgreement, true)}
            </div>
          </Col>
        </Row>
      ) : null}
      {list.handleRegion?.flowConfig?.isNeedDual ? (
        <Row className="materialUploadDetails_row">
          <Col>
            <div style={{ display: "flex" }}>
              <span>双录视频：</span>
              <div>
                <Tag
                  color={list.dualStatus == "DUAL_ED" ? "green" : "orange"}
                  style={{ width: 50 }}
                >
                  {list.dualStatus == "DUAL_ED" ? "已录制" : "未录制"}
                </Tag>
                {list?.dualFiles ? (
                  <div className="flex_phone">
                    {videoList.map((ie) => {
                      return (
                        <>
                          <video
                            src={ie}
                            style={{
                              width: "104px",
                              height: "104px",
                              marginLeft: 5,
                            }}
                            controls
                            // autoPlay
                            // loop
                          ></video>
                        </>
                      )
                    })}

                    {/* <span className="image_desc">双录视频</span> */}
                  </div>
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
      ) : null}
      <Row className="materialUploadDetails_row">
        <Col>
          <div style={{ display: "flex" }}>
            <span>其他补充材料上传</span>
            {fileDomeCile(fileListOther, false)}
          </div>
        </Col>
      </Row>
    </Card>
  )
})

export default MaterialUploadDetails
