/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react"
import { Button, Modal, Form, Col, Row, Input, Select, message } from "antd"
import {
  getAddUseoOrgs,
  getOrganizationList,
  getOperAppsLists,
  getPlatCharactersList,
} from "../../../../service"

import "./index.scss"
import { RexIdNo } from "@/utils/RegExp"

type type_modal_operator = {
  (a: boolean): void
}

type type_user_modal = {
  isAdd: boolean
  addModal?: type_modal_operator | any
  id: string
  cb: () => void
}
const UserModal: React.FC<type_user_modal> = ({ isAdd, addModal, id, cb }) => {
  const [messageApi, contextHolder] = message.useMessage()
  const [getOrganization, setGetOrganization] = useState<any>([]) //组织列表
  const [getUserList, setGetUserList] = useState<any>([]) //应用列表
  const [getPlatCharacters, setGetPlatCharacters] = useState<any>([])
  const [form] = Form.useForm()
  useEffect(() => {
    handGetOrganizationList()
  }, [])

  const handGetOrganizationList = async () => {
    let params = {
      size: -1,
    }
    const result = await getOrganizationList(params)
    if (result.code == 200) {
      let resultData = result.data.records.map((ie: any) => {
        return {
          label: ie.name,
          value: ie.id,
        }
      })
      setGetOrganization(resultData)
    }
  }
  const handGtOperAppsLists = async (e) => {
    let params = {
      id: e,
    }
    const result = await getOperAppsLists(params)
    if (result.code == 200) {
      let resultData = result.data.applications.map((ie: any) => {
        return {
          label: ie.name,
          value: ie.id,
        }
      })
      setGetUserList(resultData)
    }
  }
  const getPlatCharactersLists = async (e) => {
    let params = {
      id: e,
    }
    const result: any = await getPlatCharactersList(params)
    if (result.code == 200) {
      let resultData = result.data.records.map((ie: any) => {
        return {
          label: ie.name,
          value: ie.id,
        }
      })

      setGetPlatCharacters(resultData)
    }
  }
  const handleCancel = () => {
    form.resetFields()
    addModal(false)
  }
  const handGetChangeLists = (e) => {
    handGtOperAppsLists(e)
    form.setFieldsValue({
      appId: "",
      roleId: "",
    })
  }
  const handChanges = (e) => {
    getPlatCharactersLists(e)
    form.setFieldsValue({
      roleId: "",
    })
  }
  const onFinish = async (values: any) => {
    let params = {
      organizationId: values.orgId,
      userId: id,
      applicationId: values.appId,
      roleId: values.roleId,
    }
    const result: any = await getAddUseoOrgs(params)
    if (result.code == 200) {
      messageApi.open({
        type: "success",
        content: "新增成功",
      })
      handleCancel()
      cb()
    }
  }
  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
  return (
    <>
      <Modal
        open={isAdd}
        title="新建授权"
        onCancel={handleCancel}
        centered
        width={600}
        footer={null}
      >
        {contextHolder}
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600, paddingTop: 15 }}
          // initialValues={value}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row>
            <Col span={20}>
              <Form.Item
                label="选择所属组织"
                name="orgId"
                rules={[{ required: true, message: "请选择" }]}
              >
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch
                  options={getOrganization}
                  onChange={(e) => handGetChangeLists(e)}
                  filterOption={filterOption}
                />
              </Form.Item>
              <Form.Item
                label="选择应用"
                name="appId"
                style={{ paddingTop: 3 }}
                rules={[{ required: true, message: "请选择" }]}
              >
                <Select
                  placeholder="请选择"
                  allowClear
                  options={getUserList}
                  onChange={(e) => handChanges(e)}
                />
              </Form.Item>
              <Form.Item
                label="选择角色"
                style={{ paddingTop: 3, marginBottom: 24 }}
                name="roleId"
                rules={[{ required: true, message: "请选择" }]}
              >
                <Select placeholder="请选择" allowClear options={getPlatCharacters} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item wrapperCol={{ span: 16 }} style={{ borderTop: "1px solid #f0f0f0" }}>
            <div
              className="form_footer"
              style={{ display: "flex", marginBottom: 10, paddingTop: 10 }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  textAlign: "right",
                  marginLeft: "110%",
                }}
              >
                <Button
                  style={{ marginRight: "12px" }}
                  onClick={(e) => {
                    handleCancel()
                  }}
                >
                  取消
                </Button>
                <Button type="primary" htmlType="submit">
                  确定
                </Button>
              </div>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
export default UserModal
