/* eslint-disable no-async-promise-executor */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
import { Button, Form, Space, Card, Radio, message, Popconfirm } from "antd"
import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import { TagCustomStyle } from "@src/components/TagCustomStyle"
import LocationInformation from "./LocationInformation" //办理地信息
import BasicInformation from "./BasicInformation" //基本信息
import MaterialUpload from "./MaterialUpload" //材料上传
import { radioOptionEnum, removeField, debounce, handDataReorganization } from "../../constants"
import { useParams, useNavigate } from "react-router-dom"
import {
  getOperAgenciessDetails,
  getOperAgenciessAdd,
  getOperAgenciessEdit,
  getUrlByFileId,
  getOperRegionsRecommend,
  getOperRegionsDetails,
} from "../../service"
import "./index.scss"
import { RollbackOutlined } from "@ant-design/icons"

const defaultProps = {}
type props = {}
const FormItem = Form.Item

const prefix = "new-order-detail-page"
const classWithPrefix = (classname: string) => {
  return [prefix, classname].join("-")
}
let handTaxList: any = []
export type NewPurchaseOrderProps = props & React.HTMLAttributes<HTMLDivElement>
export const NewApplication: React.FC<React.PropsWithChildren<NewPurchaseOrderProps>> = memo(() => {
  const [form] = Form.useForm()
  const history: any = useParams()
  const navigate = useNavigate()
  const [applyType, setApplyType] = useState<any>("INDIVIDUAL_BUSINESS") // 代办
  const [namelessType, setNamelessType] = useState<any>(true) // 无名代办
  const [detailInfo, setDetailInfo] = useState<any>({}) //编辑回显
  const [nameLessFlag, setNameLessFlag] = useState<boolean>(false)
  const [individualPrefix, setIndividualPrefix] = useState<any>("前缀") //前缀
  const [individualSuffix, setIndividualSuffix] = useState<any>("后缀") //后缀
  const [idCardL, setIdCardL] = useState<any>([]) //身份证正面
  const [idCardR, setIdCardR] = useState<any>([]) //身份证反面
  const [detailsCard, setDetailsIdCardL] = useState<any>([]) //回显正面
  const [handTaxDistrict, setHandTaxDistrict] = useState<any>([]) //税区详情
  const [taxArrNum, setTaxArrNum] = useState<any>([]) //税税通截图
  const [taxPassNumUpload, setTaxPassNumUpload] = useState<any>([]) //税税通文档
  const [advancedInfo, setAdvancedInfo] = useState<any>("") //头像截图
  const [formationDisabled, setFormationDisabled] = useState<any>(true)
  const [fileDataListLink, setFileDataListLink] = useState<any>([])
  const [loadings, setLoadings] = useState<boolean>(false)
  const [saveLoadings, setSaveLoadings] = useState<boolean>(false)
  const handFilePath = (file) => {
    let options =
      file &&
      file?.map((ie) => {
        return {
          name: ie?.name,
          path: ie?.response?.data?.filePath || ie?.url,
        }
      })
    return options
  }
  const handLoading = () => {
    setLoadings(false)
    setSaveLoadings(false)
  }
  const onSaveFinish = async (values, doCode) => {
    let fileDataTaxSAve: any = []
    let resultDataResFlag = false
    if (idCardR?.length == 0) {
      message.error("请上传完整身份证信息")
      return
    }
    // if (history?.id == "null") {
    //   fileDataTaxSAve = taxPassNumUpload
    // } else {
    //   taxPassNumUpload?.map((fileItem) => {
    //     fileDataTaxSAve?.push(fileItem?.filter((item, index) => item?.data?.fileType == "FILE"))
    //   })
    // }

    //税税通截图
    let recoverData = handDataReorganization(taxArrNum)
    //文档截图
    let taxPassData = handDataReorganization(taxPassNumUpload)
    let resultDataRes: any = []
    if (recoverData?.length > 0) {
      resultDataRes = recoverData.concat(taxPassData).filter((item) => item !== undefined)
    }
    console.log(resultDataRes, "resultDataRes111")
    // 银行信息
    // 银行账号，开户行名字，支行名字，开户行行号, 预留电话号，城市code, 开户行code
    const {
      bankAccount,
      bankNameApi,
      bankBranchName,
      bankBranchCode,
      phone,
      provinceCode,
      bankCode,
    } = values
    let params = {
      ...values,
      idCardFront: idCardL[0]?.filePath,
      idCardBack: idCardR[0]?.filePath,
      otherFile: values?.otherFile ? handFilePath(values?.otherFile) : [],
      applySource: "OP",
      needName: namelessType,
      doCode: doCode,
      extendsFiles: resultDataRes || [],
      idCardHead: advancedInfo?.Portrait,
      delegatedAgreement: {
        type: values.type,
        files: handFilePath(values?.contracts),
      },
      bankInfoDto: {
        bankAccount,
        bankBranchName: bankBranchName,
        bankBranchCode: bankBranchCode,
        bankName: bankNameApi,
        bankCode,
        phone,
        provinceCode,
      },
    }
    delete params.bankAccount
    delete params.bankName
    delete params.bankBranchName
    delete params.bankBranchCode
    delete params.phone
    delete params.provinceCode
    delete params.bankCode
    if (namelessType) {
      params.name = individualPrefix + values.name + individualSuffix
      params.name1 = individualPrefix + values.name1 + individualSuffix
      params.name2 = individualPrefix + values.name2 + individualSuffix
    }
    if (history?.id == "null") {
      resultDataRes.forEach((item: any) => {
        if (item?.allowEmpty) {
          if (item?.num !== item?.files?.length) {
            console.log(item, "item")
            if (item?.name !== "") {
              resultDataResFlag = true
            } else {
              resultDataResFlag = false
            }
            message.error(item?.name + " 请完善上传")
          }
        }
      })
      if (resultDataResFlag) {
        return
      }
      console.log(params, "params")
      const result: any = await getOperAgenciessAdd(params)
      if (result.code == 200) {
        message.success("申请成功")
        navigate(`/agencyBusiness/setup`)
      } else {
        handLoading()
      }
    } else {
      params.id = history?.id
      const result: any = await getOperAgenciessAdd(params)
      if (result.code == 200) {
        message.success("编辑成功")
        navigate(`/agencyBusiness/setup`)
      } else {
        handLoading()
      }
    }
  }

  //保存校验
  const save = async () => {
    let values = form.getFieldsValue()
    form
      .validateFields()
      .then((res) => {
        setSaveLoadings(true)
        onSaveFinish(values, 0)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  //提交校验
  const onHandFinish = async () => {
    let values = form.getFieldsValue()
    form
      .validateFields()
      .then((res) => {
        setLoadings(true)
        onSaveFinish(values, 1)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  //错误验证
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo)
  }
  const handPresuffix = (str, prefix, suffix) => {
    let region = removeField(str, prefix)
    let result = removeField(region, suffix)
    return result || ""
  }

  const handFileDataLists = (fileData) => {
    let newArr: any = fileData
    newArr?.map((item: any, index) => {
      if (item?.fileType == "IMAGE") {
        item?.files.map(async (fileItem) => {
          let newFileItem: any = await handImageList(fileItem?.path)
          fileItem.newFile = newFileItem[0]
          item.newFiles?.push(...newFileItem)
        })
      }
    })
    setTimeout(() => {
      handFileDataList(newArr)
    }, 500)
  }
  const handFileDataList = (actionList) => {
    let newActionList = actionList.filter((ie) => handTaxList?.includes(ie.name))
    let imgData: any = []
    let fileData: any = []
    let fileImageAll: any = []
    let newFileDataList = newActionList?.map((item, index) => {
      let fileItem = item?.files?.map((ie, index) => {
        return {
          data: {
            fileName: item?.name,
            allowEmpty: item?.allowEmpty,
            fileNumbers: item?.num,
            fileRequire: item?.comment,
            fileType: item?.fileType,
          },
          name: ie?.name,
          path: ie?.path,
          newFile: ie?.newFile,
          status: "done",
          uid: index + 1,
        }
      })
      fileImageAll.push(fileItem)
    })
    fileImageAll.map((fileItem) => {
      imgData.push(fileItem.filter((item, index) => item.data.fileType == "IMAGE"))
      fileData.push(fileItem.filter((item, index) => item.data.fileType == "FILE"))
    })
    console.log(fileImageAll, "fileImageAll")
    setTaxArrNum(imgData.filter((item) => Array.isArray(item) && item.length > 0))
    setTaxPassNumUpload(fileData.filter((item) => Array.isArray(item) && item.length > 0))
    return newFileDataList
  }
  const handImageList = async (fileIdList) => {
    let result = await getUrlByFileId({ zoom: false, fileIds: fileIdList })?.then((res) => {
      return res.data
    })
    return result
  }
  const init = async () => {
    if (history?.id == "null") return
    const result = await getOperAgenciessDetails(history.id)
    if (result?.code == 200) {
      setFormationDisabled(false)
      setDetailInfo(result?.data)
      console.log(result?.data, "result?.data")
      handGetUrlByFileId([result.data.idCardBack, result.data.idCardFront])
      setNamelessType(result?.data?.needName)
      form.setFieldsValue({
        ...result?.data,
        taxRegionId: result?.data?.handleRegion?.id,
        name: handPresuffix(
          result.data.name,
          result?.data?.handleRegion?.individualBusinessPrefix,
          result?.data?.handleRegion?.individualBusinessSuffix
        ),
        name1: handPresuffix(
          result.data.name1,
          result?.data?.handleRegion?.individualBusinessPrefix,
          result?.data?.handleRegion?.individualBusinessSuffix
        ),
        name2: handPresuffix(
          result.data.name2,
          result?.data?.handleRegion?.individualBusinessPrefix,
          result?.data?.handleRegion?.individualBusinessSuffix
        ),
        legal: "1",
        ...result?.data?.bankInfoDto,
        provinceCode: result?.data?.bankInfoDto?.provinceCode.map((item) => Number(item)),
        paymentAgency: String(result?.data?.bankInfoDto?.bankCode),
      })
      setAdvancedInfo({ Portrait: result?.data?.idCardHead })
      setIdCardL([{ filePath: result?.data?.idCardBack }])
      setIdCardR([{ filePath: result?.data?.idCardFront }])
      setIndividualPrefix(result?.data?.handleRegion?.individualBusinessPrefix)
      setIndividualSuffix(result?.data?.handleRegion?.individualBusinessSuffix)
      initDetail()
      handFileDataLists(result?.data?.extendsFiles)
      if (result?.data?.delegatedAgreement?.files?.length > 0) {
        form.setFieldValue("contracts", result?.data?.delegatedAgreement?.files)
      }
    }
  }
  const handGetUrlByFileId = async (fileIdList) => {
    let result = await getUrlByFileId({ zoom: false, fileIds: fileIdList.join(",") })?.then((res) =>
      res.data.map((item, index) => (fileIdList[index] ? item : undefined))
    )
    setDetailsIdCardL(result)
  }
  //获取建议名称
  const handGetOperRegionsRecommend = async () => {
    let id = form.getFieldValue("handleRegionId")
    // let needName = form.getFieldValue("needName")
    let params = {
      name: form.getFieldValue("legalName"),
    }
    console.log(params, "params")
    const result = await getOperRegionsRecommend(id, params)
    if (result.code == 200) {
      form.setFieldsValue({
        name: namelessType ? result?.data[0] : "",
        name1: namelessType ? result?.data[1] : "",
        name2: namelessType ? result?.data[2] : "",
      })
    } else {
      // message.warning("请选择执照办理地,输入姓名")
    }
  }
  const onValuesChange = debounce((changedValue: any) => {
    if (changedValue?.legalName) {
      handGetOperRegionsRecommend()
    } else if (changedValue?.legalName == "") {
      form.setFieldsValue({
        name: "",
        name1: "",
        name2: "",
      })
    } else if (changedValue?.needName == false) {
      // form.setFieldsValue({
      //   name: "",
      //   name1: "",
      //   name2: "",
      // })
    }
  }, 1000)
  useEffect(() => {
    init()
  }, [])
  useMemo(() => {
    if (namelessType && nameLessFlag) {
      handGetOperRegionsRecommend()
    }
  }, [namelessType, nameLessFlag])

  const initDetail = async () => {
    let handleRegionId = form.getFieldValue("handleRegionId")
    const result = await getOperRegionsDetails(handleRegionId)
    if (result?.code == 200) {
      setIndividualPrefix(result?.data?.individualBusinessPrefix)
      setIndividualSuffix(result?.data?.individualBusinessSuffix)

      setHandTaxDistrict(result.data)
      let resultData = result?.data?.extraFileConfigs.map((ie) => {
        return ie.fileName
      })
      handTaxList = resultData
    }
  }

  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <Button
              onClick={() => navigate(`/agencyBusiness/setup`)}
              icon={<RollbackOutlined />}
              style={{ color: "#1890FF" }}
            >
              返回
            </Button>
            <span style={{ paddingLeft: 4 }}>新增个体户开办</span>
          </Space>
        }
        breadCrumb={[
          { label: "业务代办" },
          { label: "工商税务开办" },
          { label: "个体户开办申请" },
          { label: "新增个体户开办" },
        ]}
      ></DetailPageHeader>
      <DetailPageContent>
        <Form
          form={form}
          name="basic"
          initialValues={{ remember: true, type: "0" }}
          // onFinish={onHandFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="newApplication-from"
          onValuesChange={onValuesChange}
        >
          {/*申报类型*/}
          <Card title="申报类型">
            <FormItem label="办理类型" name="handleType" initialValue={"INDIVIDUAL_BUSINESS"}>
              <Radio.Group
                // defaultValue={applyType}
                onChange={(e: any) => {
                  setApplyType(e.target.value)
                }}
              >
                {radioOptionEnum.map((item: any) => {
                  return (
                    <Radio key={item.value} value={item.value}>
                      {item.label}
                    </Radio>
                  )
                })}
              </Radio.Group>
            </FormItem>
          </Card>
          {/*办理地信息*/}
          <LocationInformation setNameLessFlag={setNameLessFlag} initDetail={initDetail} />
          {/*基本信息*/}
          <BasicInformation
            form={form}
            detailInfo={detailInfo}
            nameLessFlag={nameLessFlag}
            setNamelessType={setNamelessType}
            setNameLessFlag={setNameLessFlag}
            namelessType={namelessType}
            individualPrefix={individualPrefix}
            individualSuffix={individualSuffix}
            idCardL={idCardL}
            setIdCardL={setIdCardL}
            idCardR={idCardR}
            setIdCardR={setIdCardR}
            detailsCard={detailsCard}
            handGetOperRegionsRecommend={handGetOperRegionsRecommend}
            setAdvancedInfo={setAdvancedInfo}
            advancedInfo={advancedInfo}
            setFormationDisabled={setFormationDisabled}
            formationDisabled={formationDisabled}
          />
          {/*材料上传*/}
          <MaterialUpload
            form={form}
            detailInfo={detailInfo}
            handTaxDistrict={handTaxDistrict}
            taxArrNum={taxArrNum}
            setTaxArrNum={setTaxArrNum}
            taxPassNumUpload={taxPassNumUpload}
            setTaxPassNumUpload={setTaxPassNumUpload}
            history={history}
          />
          <div className="cardButton">
            <Card className="cardButton">
              <Button onClick={() => navigate(`/agencyBusiness/setup`)}>取消</Button>
              <Button
                type="primary"
                style={{ marginLeft: 10 }}
                onClick={save}
                loading={saveLoadings}
              >
                保存
              </Button>
              <Popconfirm
                placement="top"
                title={"提交"}
                description={"请仔细核对信息，确认提交"}
                okText="确认"
                onConfirm={onHandFinish}
                cancelText="取消"
              >
                <Button type="primary" style={{ marginLeft: 10 }} loading={loadings}>
                  提交申请
                </Button>
              </Popconfirm>
            </Card>
          </div>
        </Form>
      </DetailPageContent>
    </DetailPageContainer>
  )
})
NewApplication.defaultProps = defaultProps

export default NewApplication
