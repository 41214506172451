import Util from "./serviceOp"

export const httpRequestProxy = new Proxy(Util.getInstance(), {
  get(target: Util, p: string | symbol, receiver: any): any {
    return (...args) => {
      console.log(args, target, p)
      return target[p](...args).then((res) => {
        if (res?.data?.code >= 400) {
          throw res
        } else if (res?.response?.data?.code >= 400) {
          throw res.response
        }
        return res
      })
    }
  },
})
