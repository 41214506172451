import React, { useState, useEffect, useRef } from "react"
import { Button, Input, Space, message, Spin, Modal } from "antd"

import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import { throttle } from "lodash"
import { syncOrder, getOrderStatus } from "./service"
/**
 * 同步新再生网订单
 * @returns
 */
function UtilOrder() {
  const [orderIds, setOrderIds] = useState("")
  const [pwd, setPwd] = useState("")
  const [orderIdsList, setOrderIdsList] = useState<string[]>([])
  const [flag, setFlag] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [orderInfo, setOrderInfo] = useState<{
    count: number
    successNumber: number
    failNumber: number
    failMsg: string
  }>()

  const keyDownHandle = (e) => {
    if (e.keyCode === 13) {
      if (e.target.value === "zhangmengzi") {
        setFlag(true)
        setPwd("")
        message.success("密码正确，请输入同步的订单号")
      } else {
        setPwd("")
        message.error("密码错误请联系管理员")
      }
    }
  }
  /**
   * 订单号
   * @param e
   */
  const entryOrderIdHandle = (e) => {
    setOrderIds(e.target.value)
  }
  useEffect(() => {
    if (orderIds?.length) {
      setOrderIdsList(
        orderIds
          .replace(/ /, "")
          .replace(/\，/g, ",")
          .split(",")
          .filter((item) => item)
      )
    }
  }, [orderIds])
  /**
   * 获取同步订单状态
   */
  const getResStatus = async () => {
    try {
      const res: any = await getOrderStatus()

      if (!res?.data?.complete) {
        let timer = setTimeout(() => {
          getResStatus()
          clearTimeout(timer)
        }, 1000)
      } else {
        setOrderIds("")
        setOrderIdsList([])
        setDisabled(false)
        setOrderInfo(res?.data)
        setIsModalOpen(true)
      }
    } catch (e) {
      setDisabled(false)
    }
  }
  const submit = async () => {
    if (disabled) return
    try {
      const res: any = await syncOrder(orderIdsList)
      setDisabled(true)
      getResStatus()
    } catch (e) {
      setDisabled(false)
    }
  }
  return (
    <DetailPageContainer>
      <Modal
        title="订单同步"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
      >
        <div style={{ padding: 20 }}>
          <p>
            同步{orderInfo?.count}条，成功{orderInfo?.successNumber}条，失败{orderInfo?.failNumber}
          </p>

          <p>{orderInfo?.failMsg}</p>
        </div>
      </Modal>
      <DetailPageHeader
        title={
          <Space>
            <span>同步新再生网订单</span>
          </Space>
        }
        breadCrumb={[
          { label: "平台设置", path: "" },
          { label: "同步新再生网订单", path: "" },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <DetailPageContent>
        {disabled && (
          <div
            style={{
              zIndex: 1000,
              height: "100vh",
              width: "100vw",
              position: "fixed",
              top: 0,
              left: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin size="large" />
          </div>
        )}
        <Input
          type="password"
          style={{ width: 300 }}
          value={pwd}
          placeholder="请输入密码回车同步订单"
          onChange={(e) => setPwd(e.target.value)}
          onKeyDown={keyDownHandle}
        />
        {flag ? (
          <>
            <textarea
              placeholder="请输入订单号，多个订单号请用逗号隔开"
              style={{ width: 300, height: 200 }}
              value={orderIds}
              onChange={entryOrderIdHandle}
            />
            <ul>
              {orderIdsList &&
                orderIdsList.map((item, index) => {
                  return <li key={index}>{item}</li>
                })}
            </ul>
            {orderIdsList.length ? <h4>共匹配（{orderIdsList.length}）</h4> : null}
            <Button style={{ width: 140 }} type="primary" onClick={submit}>
              同步订单
            </Button>
          </>
        ) : null}
      </DetailPageContent>
    </DetailPageContainer>
  )
}
export default UtilOrder
