import {
  cloneElement,
  HTMLAttributes,
  PropsWithChildren,
  useState,
  MouseEvent,
  createContext,
  ReactNode,
  useRef,
  MutableRefObject,
  useEffect,
} from "react"
import { Drawer, Form, message } from "antd"
import { Button, Modal } from "antd"
import { FormInstance, FormProps } from "antd/es/form"
import { ModalProps } from "antd/es/modal"
import { useLoading } from "@/hooks/useLoading"
import { useForm } from "antd/es/form/Form"
import React from "react"
import "./index.scss"

const defaultProps = {
  trigger: <Button>使用 trigger 参数覆盖打开Modal Form按钮</Button>,
}

interface Props<T> {
  fieldProps?: FormProps<T>
  modalProps?: ModalProps
  trigger?: ReactNode
  onFinish?: (formData: T) => Promise<void>
  title?: string
  type?: "modal" | "drawer"
  formRef?: MutableRefObject<FormInstance<T> | undefined>
  disabled?: boolean
  formProps?: any
}

export type ModalFormProps<T> = Partial<typeof defaultProps> & Props<T> & HTMLAttributes<any>

export const ModalFormContext = createContext<{ form: FormInstance<any> } | undefined>(undefined)

function InnerForm<T>(props: PropsWithChildren<ModalFormProps<T>>) {
  const {
    trigger,
    fieldProps = {},
    onFinish,
    children,
    modalProps,
    title,
    type = "modal",
    formRef,
  } = {
    ...defaultProps,
    ...props,
  }
  const { form: outerForm } = fieldProps
  const [innerForm] = useForm<T>()

  const form = outerForm ? outerForm : innerForm
  useEffect(() => {
    if (formRef) formRef.current = form
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form])
  return (
    <ModalFormContext.Provider value={{ form }}>
      <Form {...fieldProps} form={form}>
        {children}
      </Form>
    </ModalFormContext.Provider>
  )
}

function ModalForm<T>(props: PropsWithChildren<ModalFormProps<T>>) {
  const {
    trigger,
    onFinish,
    children,
    modalProps,
    title,
    type = "modal",
    formRef: outerFormRef,
    disabled,
  } = {
    ...defaultProps,
    ...props,
  }
  const innerFormRef = useRef<FormInstance<T>>()
  const formRef = outerFormRef ? outerFormRef : innerFormRef
  const [visible, setVisible] = useState(false)
  const triggerProps = {
    // htmlType:'submit',
    ...trigger?.props,
    disabled,
    onClick: (e: MouseEvent<HTMLElement, MouseEvent>) => {
      setVisible(true)
      trigger?.props.onClick?.(e)
    },
  }
  const [onOk, loading] = useLoading(async () => {
    await formRef.current?.validateFields().catch((e) => {
      const needRequired = e.errorFields.some(
        (errorField: { errors: string[] }) => errorField.errors[0]?.indexOf("请") !== -1
      )
      if (needRequired) {
        message.error("请填选必填项")
      } else {
        message.error(e.errorFields[0].errors[0])
      }
      throw e
    })
    await onFinish?.(formRef.current?.getFieldsValue() as T)
    setVisible(false)
  })
  const cancel = (e: any) => {
    setVisible(false)
    modalProps?.onCancel?.(e)
  }
  const getUI = {
    modal: () => (
      <Modal
        okButtonProps={{ htmlType: "submit", loading }}
        okText={"提交"}
        open={visible}
        onOk={onOk}
        {...modalProps}
        onCancel={cancel}
        title={title}
        wrapClassName="invoiceModal"
        maskClosable={false}
      >
        <InnerForm formRef={formRef} {...props}>
          {children}
        </InnerForm>
      </Modal>
    ),
    drawer: () => (
      <Drawer
        open={visible}
        title={title}
        width={1200}
        footer={
          (
            <div style={{ textAlign: "right" }}>
              <Button style={{ marginRight: "12px" }} onClick={cancel}>
                取消
              </Button>
              <Button type="primary" onClick={onOk}>
                确认
              </Button>
            </div>
          ) as any
        }
        {...modalProps}
        onClose={cancel}
      >
        <InnerForm formRef={formRef} {...props}>
          {children}
        </InnerForm>
      </Drawer>
    ),
  }[type]
  return (
    <>
      {cloneElement(trigger, triggerProps)}
      {getUI()}
    </>
  )
}

export default ModalForm
