import React from "react"

export const withDefaults = <P, ExtraType>(
  component: React.FC<P>,
  defaultProps: Partial<P>,
  extraType?: ExtraType
) => {
  type Props = Partial<P> & Omit<P, keyof Partial<P>> // 可选的所有类型 & 在所有类型中排除默认的 效果：可以不填有默认的值，必须填没有默认的值
  component.defaultProps = defaultProps

  return component as React.FC<Props> & ExtraType
}
