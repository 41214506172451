import { Badge } from "antd"
import React from "react"
import { TaxStatusBooleanEnum } from "./constant"

export default [
  {
    title: "税区ID",
    dataIndex: "id",
  },
  {
    title: "税区名称名称",
    dataIndex: "name",
  },
  {
    title: "委托办理方",
    dataIndex: "organizationName",
  },
  {
    title: "税区状态",
    dataIndex: "status",
    render: (value) => <Badge {...TaxStatusBooleanEnum[value]} />,
  },
]
