import React from "react"
import SKTable from "../../../../components/SkTable/index"
import { Button } from "antd"
import { getOpEvidence } from "../promises"
import { useNavigate, Link } from "react-router-dom"
import "./index.scss"
/**
 * 存证模版配置
 * @returns
 */
const EvidenceConfigList = (props: { id: string }) => {
  const { id } = props
  const navigate = useNavigate()
  const handleRequestData = async (params: any) => {
    const reqData = {
      ...params,
      organizationId: id,
      size: params.pageSize,
    }
    const res: any = await getOpEvidence(reqData)
    return {
      data: res?.data.records,
      total: res?.data.total,
    }
  }
  const evidenceColumns: any = [
    {
      title: "存证场景",
      dataIndex: "secureEvidenceSceneTemplateDescription",
    },
    {
      title: "场景配置人",
      dataIndex: "createUserName",
    },
    {
      title: "存证链阶段概述",
      dataIndex: "secureEvidenceLinkNames",
    },
    {
      title: "状态",
      dataIndex: "status",
      render: (text: any, record: any) => {
        const statusName = ["停用", "启用"]
        return <span>{statusName[record.status]}</span>
      },
    },
    {
      title: "操作",
      dataIndex: "operation",
      fixed: "right",
      render: (text: any, record: any) => {
        return (
          <div className="table-operate">
            <a
              onClick={() =>
                navigate(`/evidence/platform/template/add`, {
                  state: {
                    organizationId: id,
                    id: record.id,
                    info: true,
                    current: 1,
                    isConnection: true, // 标记为客户管理-详情-模板
                    connectionStatus: record?.status,
                    type: "manage",
                  },
                })
              }
            >
              详情
            </a>
          </div>
        )
      },
    },
  ]

  return (
    <div className="evidence_config_list">
      <SKTable
        title={``}
        pagination={{
          pageSize: 10,
          showQuickJumper: true,
          showTotal(total, range) {
            return `总共${total}条`
          },
        }}
        request={handleRequestData}
        pageChangeWithRequest={true}
        columns={evidenceColumns}
        searchFlag={false}
      ></SKTable>
    </div>
  )
}

export default EvidenceConfigList
