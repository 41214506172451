import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import store from "./store"
// import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import { Provider } from "react-redux"
import reportWebVitals from "./reportWebVitals"
import "antd/dist/reset.css"
import { ConfigProvider } from "antd"
import locale from "antd/locale/zh_CN"
// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );   basename='/login'
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <ConfigProvider locale={locale}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </ConfigProvider>
)
reportWebVitals()

// ReactDOM.render(
//     <ConfigProvider locale={locale}>
//     <Provider store={store}>
//       <BrowserRouter >
//         <App />
//       </BrowserRouter>
//     </Provider>

//   </ConfigProvider>
//   ,document.getElementById("root"))
