// 检查给定的 id 是否都存在于最外层父级中
export function getChildrenIdsInParents(jsonData, idsToCheck) {
  const childIds: any = []

  // 遍历 jsonData 中的每个父级节点
  jsonData?.forEach((parent) => {
    // 检查当前父级节点的 id 是否存在于 idsToCheck 中
    if (idsToCheck.includes(parent.id)) {
      // 如果存在，则将当前父级节点的每个子级的 id 添加到结果数组中
      parent.children.forEach((child: any) => {
        // 检查子级的 id 是否存在于 idsToCheck 中，避免重复添加
        if (idsToCheck.includes(child.id) && !childIds.includes(child.id)) {
          childIds.push(child.id)
        }
      })
    }
  })

  return childIds
}
