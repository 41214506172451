import React from "react"
import { Outlet } from "react-router-dom"
import TopNavbar from "../../components/TopNavbar"
import LeftNavbar from "../../components/LeftNavbar"
import "./index.scss"
import { useSelector } from "react-redux"

const Evidence = () => {
  const roleList =
    useSelector((state: any) => state?.meunList.filter((ie: any) => ie.name == "存证平台")) || []
  return (
    <div className="platformSetup-container">
      <TopNavbar config={{ value: "evidence" }} />
      <div className="platformSetup-container-content">
        {<LeftNavbar lists={roleList[0]?.children} />}
        <Outlet />
      </div>
    </div>
  )
}
export default Evidence
