import CustomerMap from "@/components/CustomerMap"
import React, { memo, useContext } from "react"
import { OrderDetailContext } from "../../useOrderDetail"
import "./index.scss"

const defaultProps = {}
type props = {}
export type TraceInfoProps = Required<typeof defaultProps> &
  props &
  React.HTMLAttributes<HTMLDivElement>
export const TraceInfo: React.FC<React.PropsWithChildren<TraceInfoProps>> = memo((props) => {
  const context = useContext(OrderDetailContext)
  console.log(context?.trajectoryService, "context?.trajectoryService?.data")
  return (
    <>
      <div className="trajectory-map">
        <div className="trajectory-map-header">
          <p>
            <span style={{ color: "#7d7d7d" }}>运输里程</span>：-
          </p>
          <p style={{ padding: "0 16px" }}>
            <p>i</p>{" "}
            {context?.trajectoryService?.data?.data?.length
              ? (context?.trajectoryService.data as any)?.message
              : "当前车辆未在网"}
          </p>
        </div>
        <CustomerMap pathList={context?.trajectoryService?.data?.data}></CustomerMap>
      </div>
    </>
  )
})
TraceInfo.displayName = "物流轨迹"
TraceInfo.defaultProps = defaultProps
