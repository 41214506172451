import React, { useEffect, useState } from "react"
import { Button, Modal, Form, Col, Row, Input, Select, message } from "antd"
import { getPutUserListsUseoLists } from "../../../../service"
import "./index.scss"
import { RexIdNo } from "@/utils/RegExp"

type type_modal_operator = {
  (a: boolean): void
}

type type_user_modal = {
  isAdd: boolean
  addModal?: type_modal_operator | any
  searchParams: string | any
  userCrodList: any
  // cb?: () => void
}
const UserModal: React.FC<type_user_modal> = ({
  isAdd,
  addModal,
  searchParams,
  userCrodList,
  // cb,
}) => {
  const [form] = Form.useForm()
  const [messageApi, contextHolder] = message.useMessage()
  const handleCancel = () => {
    form.resetFields()
    addModal(false)
  }
  const onFinish = async (values: any) => {
    if (values.password !== values?.cfmPassword) {
      message.error("两次密码不一致，请确认后重试")
      return
    }
    const params = {
      ...values,
      id: searchParams?.id,
    }
    const result: any = await getPutUserListsUseoLists(params)
    if (result.code == 200) {
      messageApi.open({
        type: "success",
        content: "重置成功",
        onClose: () => {
          handleCancel()
          // cb()
        },
      })
    } else {
      messageApi.open({
        type: "error",
        content: result.message || "重置失败",
      })
    }
  }
  useEffect(() => {
    return () => {
      form.resetFields()
    }
  })
  return (
    <>
      <Modal
        open={isAdd}
        title="重置密码"
        onCancel={handleCancel}
        centered
        width={600}
        footer={null}
      >
        {contextHolder}
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600, paddingTop: 10 }}
          // initialValues={value}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row>
            <Col span={20}>
              <Form.Item label="姓名" name="userName">
                <span>{userCrodList?.name}</span>
              </Form.Item>
              <Form.Item
                label="新密码"
                name="password"
                style={{ paddingTop: 3 }}
                rules={[{ required: true, message: "请输入" }]}
              >
                <Input.Password placeholder="请输入" />
              </Form.Item>
              <Form.Item
                label="确认密码"
                name="cfmPassword"
                style={{ paddingTop: 3, marginBottom: 24 }}
                rules={[{ required: true, message: "请输入" }]}
              >
                <Input.Password placeholder="请输入" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item wrapperCol={{ span: 16 }} style={{ borderTop: "1px solid #f0f0f0" }}>
            <div
              className="form_footer"
              style={{ display: "flex", marginBottom: 10, paddingTop: 10 }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  textAlign: "right",
                  marginLeft: "110%",
                }}
              >
                <Button
                  style={{ marginRight: "12px" }}
                  onClick={(e) => {
                    handleCancel()
                  }}
                >
                  取消
                </Button>
                <Button type="primary" htmlType="submit">
                  确定
                </Button>
              </div>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
export default UserModal
