/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react"
import { data } from "./coordinate"
import Car from "@/assets/images/car.png"
import StartArea from "@/assets/images/start.png"
import EndArea from "@/assets/images/end.png"
declare let TMap: any
interface MAP {
  rootId?: string
  id?: string | number
  pathList?: any[] //行程轨迹信息 (接口直接返回)
  // isLoadMarker: boolean,
  // isPrevPathList: boolean,//是否有上一个轨迹
}

const CustomerMap = (props: MAP) => {
  const { rootId = "map_root", pathList = [] } = props

  const center = [39.912406, 116.402801] //天安门
  // const [map, setMap] = useState<any>(null)
  const map = useRef<any>(null)
  const [marker, setMarker] = useState<any>(null)
  const [logisticsLine, setLogisticsLine] = useState<any>(null)
  const [isLoadMarker, setIsLoadMarker] = useState<boolean>(false)
  const [isPrevPathList, setIsPrevPathList] = useState<boolean>(false) //是否有上一个轨迹
  useEffect(() => {
    initMap()
    return () => {
      map.current?.destroy && map.current.destroy()
    }
  }, [pathList])
  const trajectoryDrawing = () => {
    if (isLoadMarker) {
      if (isPrevPathList) {
        marker.stopMove(["car"])
        marker.setGeometries([])
        logisticsLine.remove("style_blue")
      }
    } else {
      setIsLoadMarker(true)
    }
    if (pathList.length === 0) {
      setIsPrevPathList(false)
      return
    }
    setIsPrevPathList(true)
    // 初始化路径信息
    let path = pathList.map((item) => new TMap.LatLng(item.lat, item.lon))
    console.log(path)
    // 创建LatLngBounds
    let latlngBounds = new TMap.LatLngBounds()
    for (let i = 0; i < path.length; i++) {
      latlngBounds.extend(path[i])
    }
    map.current.fitBounds(latlngBounds)
    let logisticsLines = new TMap.MultiPolyline({
      map: map.current, // 绘制到目标地图
      styles: {
        style_blue: new TMap.PolylineStyle({
          color: "#3777FF", //线填充色
          width: 4, //折线宽度
          borderWidth: 2, //边线宽度
          borderColor: "#FFF", //边线颜色
          lineCap: "round", //线端头方式
        }),
      },
      geometries: [
        {
          id: "style_blue",
          styleId: "style_blue",
          paths: path,
        },
      ],
    })
    setLogisticsLine(logisticsLines)
    let markers = new TMap.MultiMarker({
      map: map.current,
      styles: {
        //样式设置
        "car-down": new TMap.MarkerStyle({
          width: 40, //小车图片宽度（像素）
          height: 40, //高度
          anchor: {
            //图片中心的像素位置（小车会保持车头朝前，会以中心位置进行转向）
            x: 20,
            y: 20,
          },
          faceTo: "map", //取’map’让小车贴于地面，faceTo取值说明请见下文图示
          rotate: 180, //初始小车朝向（正北0度，顺时针一周为360度，180为正南）
          // 'src': './img/car.png',   //小车图片（图中小车车头向上，即正北0度）
          src: require("@/assets/car.png"),
        }),
        start: new TMap.MarkerStyle({
          width: 25,
          height: 35,
          anchor: { x: 16, y: 32 },
          src: require("@/assets/start.png"),
        }),
        end: new TMap.MarkerStyle({
          width: 25,
          height: 35,
          anchor: { x: 16, y: 32 },
          src: require("@/assets/end.png"),
        }),
      },
      geometries: [
        {
          //小车marker的位置信息
          id: "car", //因MultiMarker支持包含多个点标记，因此要给小车一个id
          styleId: "car-down", //绑定样式
          position: path[0], //初始坐标位置
        },
        {
          id: "start",
          styleId: "start",
          position: path[0],
        },
        {
          id: "end",
          styleId: "end",
          position: path[path.length - 1],
        },
      ],
    })
    markers.moveAlong(
      {
        car: {
          //设置让'car'沿'path'移动，速度70公里/小时
          path,
          speed: 7000,
        },
      },
      {
        autoRotation: true, //车头始终向前（沿路线自动旋转）
      }
    )
    setMarker(markers)
  }
  // 初始化地图
  const initMap = () => {
    let centerSpot = new TMap.LatLng(center[0], center[1])
    let maps = new TMap.Map(document.getElementById(rootId), {
      center: centerSpot,
      zoom: 11,
    })
    map.current = maps
    trajectoryDrawing()
  }
  return <div id={rootId} style={{ width: "100%", height: "450px" }}></div>
}

export default CustomerMap
