import { blobToCanvas } from "../card/dataFormatConversion"

type RGBA = [number, number, number, number]
export const eraseBgColor = async (
  options: { tolerance?: number; mainColor?: RGBA },
  blob: Blob
) => {
  const { tolerance = 38, mainColor } = options
  // const tempCanvas = await blobToCanvas(blob)
  // const tempBlob = await canvasToBlob(tempCanvas,'image/jpeg',0.1)
  // console.log(tempBlob,'temp')
  const originCanvas = await blobToCanvas(blob)
  const originContext = originCanvas.getContext("2d")
  if (!originContext) return Promise.reject("未获取到canvas上下文")
  const imgData = originContext?.getImageData(0, 0, originCanvas.width, originCanvas.height)
  if (!imgData) return Promise.reject("无数据")
  const [basicR, basicG, basicB] = imgData.data as any
  for (let index = 0; index < imgData.data.length; index += 4) {
    const r = imgData.data[index]
    const g = imgData.data[index + 1]
    const b = imgData.data[index + 2]

    if (
      Math.sqrt(
        (r - basicR) * (r - basicR) + (g - basicG) * (g - basicG) + (b - basicB) * (b - basicB)
      ) <= tolerance
    ) {
      imgData.data[index] = 0
      imgData.data[index + 1] = 0
      imgData.data[index + 2] = 0
      imgData.data[index + 3] = 0
    } else if (mainColor) {
      imgData.data[index] = mainColor[0]
      imgData.data[index + 1] = mainColor[1]
      imgData.data[index + 2] = mainColor[2]
      imgData.data[index + 3] = mainColor[3]
    }
  }
  originContext?.putImageData(imgData, 0, 0)
  return originCanvas
}
