/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
import { RollbackOutlined } from "@ant-design/icons"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import { Button, Form, Radio, Select, Space, message } from "antd"
import dayjs from "dayjs"
import Decimal from "decimal.js"
import React, { forwardRef, memo, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import {
  getCompanyList,
  getTissueList,
  getTradeStrategies,
  getTradeStrategiesEdit,
  getTradingSchemeDetails,
} from "../../promises"
import {
  getSettingFlag,
  getTradingScheme,
  handLentConfig,
  handSellerConfig,
  judge,
  settlementOptions,
  settlementOptionsTwo,
} from "../constant"
import { FieldType, FinishResult, RootFieldsValues } from "../interface"
//基础信息
import BasicInforMation from "./BasicInformation/index"
//购销方限定
import BuyerAndSeller from "./BuyerAndSeller"
//费率设定
import RateSetting from "./RateSetting/index"
//申请开票设定
import RequestBiling from "./RequestBilling/index"
//结算设定
import SettlementSetting from "./SettlementSetting/index"
//交易类型限定
import TransactionType from "./TransactionType/index"
import "./index.scss"
const { Option } = Select

export const NewTradingScheme = memo(() => {
  //form实例
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const history: any = useParams()
  const [tissueList, setTissueList] = useState<any>([]) //组织列表
  const [companyList, setCompanyList] = useState<any>([]) //缴税公司列表
  const [flag, setFlag] = useState<boolean>(false)
  const [needPurchase, setNeedPurchase] = useState<boolean>(true)
  const [needSupply, setNeedSupply] = useState<boolean>(true)
  const [settlementOptionsList, setTlementOptionsList] = useState<any>([])
  const [settlementOptionsTwoList, setTlementOptionsTwoList] = useState<any>([])

  //保存校验
  const onFinish = async (values: RootFieldsValues) => {
    console.log(values, "values")

    let newValue: any = {
      settlementOptionsList: settlementOptionsList,
      settlementOptionsTwoList: settlementOptionsTwoList,
    }
    const params: any = getTradingScheme(values, newValue)
    // const mapSome = hasDuplicateField(params?.strategyContractEntityList, "sort")
    // if (mapSome) {
    //   message.error("合同签署设定只能有一个最高优先级")
    //   return
    // }
    if (params.applyInvoiceConfig?.invoiceLimit?.length) {
      params.applyInvoiceConfig.invoiceLimit = params?.applyInvoiceConfig.invoiceLimit[0]
    }
    if (judge(params?.settlementConfig[0]?.settlementPaths)) {
      message.error("结算设定不能出现两条完全一致的配置项")
      return
    }
    console.log(params, "params")
    if (history?.id == "null") {
      const result: FinishResult | any = await getTradeStrategies({ ...params })
      if (result.code == 200) {
        message.success("发布成功")
        navigate(`/entity/transaction/tradingScheme`)
      }
    } else {
      params.id = history?.id
      const result: any = await getTradeStrategiesEdit(params)
      if (result.code == 200) {
        message.success("修改成功")
        navigate(`/entity/transaction/tradingScheme`)
      } else {
        message.error(result.message || "修改失败")
      }
    }
  }
  //错误验证
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo)
  }

  const init = async () => {
    if (history?.id == "null") return
    const result = await getTradingSchemeDetails(history?.id)
    if (result.code == 200) {
      const formList = result.data
      console.log(formList, "formList")
      if (formList?.applyInvoiceConfig?.invoiceLimit) {
        formList.applyInvoiceConfig.invoiceLimit = [formList?.applyInvoiceConfig?.invoiceLimit]
      }
      form.setFieldsValue({
        ...formList,
        ...formList.applyInvoiceConfig,
        ...formList.contractConfig,
        scope: formList?.tradeType?.scope,
        drawer:
          formList?.applyInvoiceConfig?.drawer == "ALL"
            ? ["BUYER", "SELLER"]
            : [formList?.applyInvoiceConfig?.drawer],
        whiteList: formList?.sellerConfig?.whiteList,
        sellerBlackList: formList?.sellerConfig?.blackList,
        sellerTypes: formList?.sellerConfig?.types,
        whiteLists: formList?.buyerConfig?.whiteList,
        buyerBlackList: formList?.buyerConfig?.blackList,
        buyerTypes: formList?.buyerConfig?.types,
        // limitAmountConfig: formList?.sellerConfig?.limitAmountConfig,
        requiredInvoicingCreditFlag: formList?.sellerConfig?.requiredInvoicingCreditFlag,
        limitAmountConfig: handSellerConfig(formList?.sellerConfig?.limitAmountConfig),
        valueAddTaxCollectionRate: Number(
          new Decimal(formList?.rateConfig?.valueAddTaxCollectionRate).mul(new Decimal("100"))
        ),
        valueAddTaxRate: Number(
          new Decimal(formList?.rateConfig?.valueAddTaxRate).mul(new Decimal("100"))
        ),
        withholdTaxRate: Number(
          new Decimal(formList?.rateConfig?.withholdTaxRate).mul(new Decimal("100"))
        ),
        incomeTaxRateReverse: Number(
          new Decimal(formList?.rateConfig?.incomeTaxRateReverse).mul(new Decimal("100"))
        ),
        settlementPathsOne: formList?.settlementConfig[0]?.settlementPaths,
        settlementPathsTwo: formList?.settlementConfig[1]?.settlementPaths,
        settlementPaths: handLentConfig(formList?.settlementConfig[2]?.settlementPaths) || [],
        enable: getSettingFlag(formList),
        time: [
          dayjs(formList?.effectiveTime, "YYYY-MM-DD"),
          dayjs(formList?.expirationTime, "YYYY-MM-DD"),
        ],
        operationAuth: {
          operationId: formList?.operationAuthList?.length
            ? formList?.operationAuthList?.map((item) => item.operationId)
            : [
                JSON.parse(
                  localStorage.getItem("operation_tissue") || "{}"
                )?.organizationId?.toString(),
              ],
          operationAuthType: "TRADE_STRATEGY",
        },
      })
    }
    if (getSettingFlag(result.data) == 3) {
      setFlag(true)
    }
  }
  //组织列表
  const tissueInit = async () => {
    const response = await getTissueList({ size: -1, searchCount: true })
    if (response.code == 200) {
      const result = response.data.records.map((ie) => {
        return {
          label: ie.name,
          value: ie.id,
        }
      })
      setTissueList(result)
    }
  }
  //查看缴税公司
  const handGetCompanyList = async () => {
    const response: any = await getCompanyList({})
    if (response.code == 200) {
      const resultData = response.data.map((ie) => {
        return {
          label: ie.receivingAccountName,
          value: ie.id,
        }
      })
      setCompanyList(resultData)
    }
  }
  useEffect(() => {
    init()
    tissueInit()
    handGetCompanyList()
  }, [])
  useEffect(() => {
    if (history?.id == "null") {
      form.setFieldsValue({
        settlementPathsOne: settlementOptions,
        settlementPathsTwo: settlementOptionsTwo,
        enable: 1,
      })
    }
  }, [history])
  const filterOption = (
    input: string,
    option?: { label: string; value: string; children: string }
  ) => {
    return (option?.children ?? "").toLowerCase().includes(input.toLowerCase())
  }
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <Button
              onClick={() => navigate("/entity/transaction/tradingScheme")}
              icon={<RollbackOutlined />}
              style={{ color: "#1890FF" }}
            >
              返回
            </Button>
            <span style={{ paddingLeft: 4 }}>
              {history?.id == "null" ? "新建交易方案" : "编辑交易方案"}{" "}
            </span>
          </Space>
        }
        breadCrumb={[
          { label: "交易中心", path: "" },
          { label: "交易方案", path: "" },
          { label: "新建交易方案", path: "" },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <DetailPageContent>
        <div className="NewTradingScheme_Content">
          <Form
            form={form}
            name="basic"
            colon={false}
            initialValues={{
              remember: true,
              needPurchaseContract: true,
              needSupplyContract: true,
              requiredInvoicingCreditFlag: false,
              buyerTypes: ["ENTERPRISE", "INDIVIDUAL_BUSINESS", "INDIVIDUAL", "OTHER"],
              sellerTypes: ["ENTERPRISE", "INDIVIDUAL_BUSINESS", "INDIVIDUAL", "OTHER"],
              operationAuth: {
                operationId: [
                  JSON.parse(
                    localStorage.getItem("operation_tissue") || "{}"
                  )?.organizationId?.toString(),
                ], //组织id,
                operationAuthType: "TRADE_STRATEGY",
              },
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            {/* 基础信息 */}
            <div className="NewTradingScheme_Content_basic">
              <div className="NewTradingScheme_Content_basic_title">
                <p>基础信息</p>
              </div>
              <div className="NewTradingScheme_Content_basic_input">
                <BasicInforMation form={form} />
              </div>
            </div>
            {/* 交易类型限定 */}
            <div className="NewTradingScheme_Content_type">
              <div className="NewTradingScheme_Content_type_title">
                <p>交易类型限定</p>
              </div>
              <div className="NewTradingScheme_Content_type_input">
                <TransactionType form={form} />
              </div>
            </div>
            {/* 购销方限定 */}
            <div className="NewTradingScheme_Content_seller">
              <div className="NewTradingScheme_Content_seller_title">
                <p>购销方限定</p>
              </div>
              <div className="NewTradingScheme_Content_seller_input">
                <BuyerAndSeller form={form} tissueList={tissueList} />
              </div>
            </div>
            {/* 费率设定 */}
            <div className="NewTradingScheme_Content_setting">
              <div className="NewTradingScheme_Content_setting_title">
                <p>费率设定</p>
              </div>
              <div className="NewTradingScheme_Content_setting_input">
                <RateSetting form={form} />
              </div>
            </div>
            {/* 结算设定 */}
            <div className="NewTradingScheme_Content_settlement">
              <div className="NewTradingScheme_Content_settlement_title">
                <p>结算设定</p>
                <span>若以下两个配置均不满足业务需求，可自定义结算路径</span>
              </div>
              <div className="NewTradingScheme_Content_settlement_input">
                <SettlementSetting
                  form={form}
                  flag={flag}
                  setFlag={setFlag}
                  companyList={companyList}
                  tissueList={tissueList}
                  setTlementOptionsList={setTlementOptionsList}
                  settlementOptionsList={settlementOptionsList}
                  setTlementOptionsTwoList={setTlementOptionsTwoList}
                  settlementOptionsTwoList={settlementOptionsTwoList}
                />
              </div>
            </div>

            {/* 合同设定 */}
            <div className="NewTradingScheme_Content_execution">
              <div className="NewTradingScheme_Content_execution_title">
                <p>合同设定</p>
              </div>
              <div className="NewTradingScheme_Content_execution_input">
                <div style={{ width: "100%", display: "flex" }}>
                  <Form.Item<FieldType> name="needPurchaseContract" label="采购合同">
                    <Radio.Group
                      onChange={(e: any) => {
                        setNeedPurchase(e.target.value)
                      }}
                    >
                      <Radio value={true}>需要验证</Radio>
                      <Radio value={false}>不需要验证</Radio>
                    </Radio.Group>
                  </Form.Item>
                  {needPurchase ? (
                    <Form.Item<FieldType> name="purchaseContractWhiteList" label="跳过校验购方名单">
                      <Select
                        mode="multiple"
                        placeholder="请选择"
                        style={{ width: 500 }}
                        maxTagCount={5}
                        allowClear
                        showSearch
                        options={tissueList}
                        filterOption={(input, option: any) =>
                          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                        }
                      />
                    </Form.Item>
                  ) : null}
                </div>
                <div style={{ width: "100%", display: "flex", paddingTop: 20 }}>
                  <Form.Item<FieldType> name="needSupplyContract" label="供货协议">
                    <Radio.Group
                      onChange={(e: any) => {
                        setNeedSupply(e.target.value)
                      }}
                    >
                      <Radio value={true}>需要验证</Radio>
                      <Radio value={false}>不需要验证</Radio>
                    </Radio.Group>
                  </Form.Item>
                  {needSupply ? (
                    <Form.Item<FieldType> name="supplyContractWhiteList" label="跳过校验购方名单">
                      <Select
                        mode="multiple"
                        placeholder="请选择"
                        style={{ width: 500 }}
                        maxTagCount={5}
                        allowClear
                        showSearch
                        options={tissueList}
                        filterOption={(input, option: any) =>
                          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                        }
                      />
                    </Form.Item>
                  ) : null}
                </div>
              </div>
            </div>
            {/* 合同签署设定 */}
            {/* <div className="NewTradingScheme_Content_execution">
              <div className="NewTradingScheme_Content_execution_title">
                <p>合同签署设定</p>
                <span>
                  每一行代表一种合同的签署方式，当满足多条时，按优先级进行选择，1优先级最高，数字越大优先级越低
                </span>
              </div>
              <div className="NewTradingScheme_Content_execution_input">
                <ExecutionSetting form={form} />
              </div>
            </div> */}
            {/* 申请开票设定 */}
            <div className="NewTradingScheme_Content_request">
              <div className="NewTradingScheme_Content_request_title">
                <p>申请开票设定</p>
              </div>
              <div className="NewTradingScheme_Content_request_input">
                <RequestBiling form={form} />
              </div>
            </div>

            <div className="NewTradingScheme_Content_button_save">
              <Button
                className="NewTradingScheme_Content_button_save_left"
                onClick={() => navigate(`/entity/transaction/tradingScheme`)}
              >
                取消
              </Button>
              <Button type="primary" htmlType="submit">
                立即发布
              </Button>
            </div>
          </Form>
        </div>
      </DetailPageContent>
    </DetailPageContainer>
  )
})

export default memo(
  forwardRef((props: any, ref: any) => <NewTradingScheme {...props} refInstance={ref} />)
)
