import React from "react"
import moment from "moment"
import { Badge } from "antd"

import {
  businessLicenseStatusEnum,
  taxRegistrationStatusEnum,
  applicationSourceStatusEnum,
  delegationAgreementStatusEnum,
  bleRecordingStatusEnum,
  examineStatusEnum,
} from "../constants"
export default [
  {
    title: "申请编号",
    dataIndex: "applyNo",
    key: "applyNo",
    fixed: "left",
  },
  {
    title: "办理区域",
    dataIndex: "orgName",
    key: "orgName",
    render: (text: string, record: any) => {
      return <div>{record?.handleRegion?.name}</div>
    },
  },
  {
    title: "法人姓名",
    dataIndex: "legalName",
    key: "legalName",
  },
  {
    title: "法人联系电话",
    dataIndex: "legalPhone",
    key: "legalPhone",
  },
  {
    title: "身份证号",
    dataIndex: "legalIdno",
    key: "legalIdno",
  },
  {
    title: "申请时间",
    dataIndex: "createTime",
    key: "createTime",
    render: (text: string) => {
      return <div>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</div>
    },
  },
  {
    title: "申请来源",
    dataIndex: "applySource",
    key: "applySource",
    render: (text) => {
      return <span>{applicationSourceStatusEnum[text]}</span>
    },
  },
  {
    title: "申请组织",
    dataIndex: "sourceOrg",
    key: "sourceOrg",
    render: (text: string, record: any) => {
      return <div>{record?.sourceOrg?.name}</div>
    },
  },
  {
    title: "申请人",
    dataIndex: "creator",
    key: "creator",
    render: (text: string, record: any) => {
      return <div>{record?.creator?.name}</div>
    },
  },
  {
    dataIndex: "signStatus",
    title: "委托协议",
    key: "signStatus",
    render: (value) => {
      return value ? <Badge {...delegationAgreementStatusEnum[value]} /> : "--"
    },
  },
  {
    dataIndex: "dualStatus",
    title: "双录视频",
    key: "dualStatus",
    render: (value) => {
      return value ? <Badge {...bleRecordingStatusEnum[value]} /> : "--"
    },
  },
  {
    dataIndex: "checkStatus",
    title: "审核状态",
    key: "checkStatus",
    render: (value) => <Badge {...examineStatusEnum[value]} />,
  },
  {
    dataIndex: "businessLicenseStatus",
    title: "营业执照",
    key: "businessLicenseStatus",
    render: (value) => <Badge {...businessLicenseStatusEnum[value]} />,
  },
  {
    title: "税务登记",
    dataIndex: "taxRegistrationStatus",
    key: "taxRegistrationStatus",
    render: (value) => <Badge {...taxRegistrationStatusEnum[value]} />,
  },
  // {
  //   dataIndex: "createdTime",
  //   title: "创建时间",
  //   render: (text: string) => {
  //     return <div>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</div>
  //   },
  // },
]
