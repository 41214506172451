import { Image, Space, Steps, Upload } from "antd"
import React, { useContext, memo } from "react"
import { OrderDetailContext } from "../../useOrderDetail"
import { DownloadOutlined } from "@ant-design/icons"
import { IMAGE_FALL_BACK } from "../../../../../../../constant/common"

const defaultProps = {}
type props = {}
export type TransactionInfoProps = Required<typeof defaultProps> &
  props &
  React.HTMLAttributes<HTMLDivElement>
export const TransactionInfo: React.FC<React.PropsWithChildren<TransactionInfoProps>> = memo(() => {
  const context = useContext(OrderDetailContext)
  const flows = context?.logisticsDetailService?.data?.flows as any[]
  return (
    <>
      <Steps
        progressDot
        current={flows?.length}
        direction="vertical"
        items={flows?.map((flow) => ({
          title: (
            <Space size={"small"}>
              <div>{flow.stageName}</div>
              <div style={{ color: "#00000073" }}>{flow.weightTime}</div>
            </Space>
          ),
          description: (
            <div>
              <Space align="end" wrap size={"large"}>
                {flow.images[0]?.pictureHttpUrl &&
                flow.images[0]?.pictureHttpUrl?.indexOf(".pdf") !== -1 ? (
                  <Upload
                    showUploadList={{ showDownloadIcon: true, showRemoveIcon: false }}
                    // @ts-ignore
                    fileList={flow.images.map((image) => ({
                      name: image.pictureFileName,
                      url: image.pictureHttpUrl,
                    }))}
                  />
                ) : (
                  flow.images?.map((image: any) => (
                    <>
                      <Space direction={"vertical"} key={image.id}>
                        {
                          <Image
                            fallback={IMAGE_FALL_BACK}
                            style={{ objectFit: "cover" }}
                            width={180}
                            height={122}
                            src={image.pictureHttpUrl}
                          />
                        }
                        <div>
                          <span style={{ fontSize: 14, color: "rgba(0, 0, 0, 0.85)" }}>
                            {image.pictureName || "-"}
                          </span>
                          <div
                            style={{
                              fontSize: 12,
                              display: "flex",
                              flexDirection: "column",
                              gap: 2,
                            }}
                          >
                            <div>采集时间：{image.createdTime || "-"}</div>
                            <div>采集地点：{image.address || "-"}</div>
                            <div>
                              (东经：
                              {image?.longitude
                                ? Number(image?.longitude)?.toFixed(2)
                                : "0.00"}{" "}
                              北纬：
                              {image?.latitude ? Number(image?.latitude)?.toFixed(2) : "0.00"})
                            </div>
                            <div>采集人：{image.createdBy || "-"}</div>
                          </div>
                        </div>
                      </Space>
                    </>
                  ))
                )}
              </Space>
            </div>
          ),
        }))}
      />
    </>
  )
})
TransactionInfo.displayName = "物流动态"
TransactionInfo.defaultProps = defaultProps
