import React from "react"
import { useAccessList } from "../../hooks/useAccess"
import { AccessEnum } from "../../contents/accessEnum"
interface AccessInterFace {
  accessible?: keyof typeof AccessEnum
  fallback?: React.ReactNode
}
/**
 * 权限显示组件
 * @param props
 * @returns
 */
const Access: React.FC<React.PropsWithChildren<AccessInterFace>> = (props) => {
  const { accessible = "" }: any = props
  const accessList = useAccessList() as string[]
  return <>{accessList.includes(accessible) ? props.children : null}</>
}
export default Access
