const TABLE_QUERY_PATH = [
  "tradingScheme",
  "contractTemplate",
  "procureOrder",
  "tissue",
  "setup",
  "user",
  "order",
  "enterprise",
  "consent",
  "product",
  "InvoicingTask",
]

export const isSaveKeys = () => {
  const path = window.location.pathname.split("/")
  return path.filter((p) => TABLE_QUERY_PATH.includes(p)).length
}

/**
 * 存储切换路由时页面存储的查询条件
 * @returns
 */
export const saveKey = () => {
  const orgID = sessionStorage.getItem("tenantId")
  const path = window.location.pathname.split("/")
  return `KEY_${path[path.length - 1]}_${orgID}`
}
