import React from "react"
import { connect } from "react-redux"
import "./index.scss"
type PropsTypes = {
  tenants: any[]
  changeOrgHandle: Function
}
/**
 * 选择企业
 * @param props
 * @returns
 */
function Tenants(props: PropsTypes) {
  const { tenants, changeOrgHandle } = props
  if (!tenants?.length) {
    return null
  }
  // store.dispatch(setToken(result?.data.access_token));
  // sessionStorage.setItem("token", result?.data.access_token || "");
  return (
    <div className="tenants">
      <div className="tenants_box">
        <h3>请选择企业</h3>
        <div className="tenants_box_content">
          {tenants?.map((item, index) => {
            return (
              <div
                key={index}
                className="tenants_box_item"
                onClick={async () => changeOrgHandle(item)}
              >
                {item.name}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
export default Tenants
