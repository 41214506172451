import React from "react"
import { Badge } from "antd"
import { financingOrderStatusEnum } from "../constant"

export default [
  {
    title: "供应商名称",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "状态",
    dataIndex: "bookCheckStatus",
    key: "bookCheckStatus",
    render: (value) => <Badge {...financingOrderStatusEnum[value]} />,
  },
  {
    title: "准入时间",
    dataIndex: "startBookTime",
    key: "startBookTime",
  },
]
