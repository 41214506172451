import React, { useEffect } from "react"
import { Row, Col, Input, Form, Image, Radio, Checkbox } from "antd"
import dayjs from "dayjs"
const FormItem = Form.Item

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 16,
  },
}

// const attriObj={
//   '1': '矿石企业',
//   '2':'利废企业',
//   '3':'税务局',
//   '4':'金融机构'
// }

const options = [
  { label: "存证功能", value: "存证功能" },
  { label: "取证功能", value: "取证功能" },
]

const Infos = (props: any) => {
  const { details, logo, businessLicense } = props
  const [form] = Form.useForm()
  const formData = () => {
    const value = JSON.parse(JSON.stringify(details))
    for (let item in value) {
      if (value[item] === null || value[item] === undefined) {
        value[item] = "-"
      }
    }
    const data = {
      ...value,
      establishmentDate:
        value?.establishmentDate !== "-"
          ? dayjs(new Date(value?.establishmentDate)).format("YYYY-MM-DD")
          : "-",
    }
    form.setFieldsValue(data)
  }
  useEffect(() => {
    formData()
  }, [details]) // eslint-disable-line
  return (
    <div className="customer_infos">
      <Form form={form} {...layout}>
        <Row>
          <Col span="12">
            <FormItem
              label="公司名称"
              name="name"
              rules={[{ required: true, message: "请输入公司名称，100字以内!", max: 100 }]}
            >
              <Input bordered={false} disabled value={form.getFieldValue("name")}></Input>
            </FormItem>
            <FormItem label="公司地址" name="address">
              <Input bordered={false} disabled></Input>
            </FormItem>
            <FormItem label="用户属性" name="attribute">
              <Radio.Group disabled>
                <Radio value={"1"}>矿石企业</Radio>
                <Radio value={"2"}>利废企业</Radio>
                <Radio value={"3"}>税务局</Radio>
                <Radio value={"4"}>金融机构</Radio>
              </Radio.Group>
              {/* <Input bordered={false} disabled value={attriObj[details?.attribute]}></Input> */}
            </FormItem>
            <FormItem label="用户权限" name="permission">
              <Checkbox.Group disabled options={options} />
              {/* <Input bordered={false} disabled></Input> */}
            </FormItem>
            <FormItem label="社会信用代码" name="socialCreditCode">
              <Input bordered={false} disabled></Input>
            </FormItem>
            {/* <FormItem label="社会期限设置"
          name="serverDateLimit"
          rules={[{ required: true, message: '请设置服务期限'}]}>
            <Input bordered={false} disabled></Input>
          </FormItem> */}
            <FormItem label="备注" name="remark">
              <Input bordered={false} disabled></Input>
            </FormItem>
            <FormItem label="公司标识">
              <span>{logo !== "" ? <Image width={150} src={logo}></Image> : "-"}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem
              label="联系人姓名"
              name="contactName"
              rules={[{ required: true, message: "请输入联系人姓名，50字以内!", max: 50 }]}
            >
              <Input bordered={false} disabled></Input>
            </FormItem>
            <FormItem
              label="联系人手机"
              name="contactPhone"
              rules={[{ required: true, message: "请输入联系人手机号!", max: 11 }]}
            >
              <Input bordered={false} disabled></Input>
            </FormItem>
            <FormItem label="公司成立日期" name="establishmentDate">
              <Input bordered={false} disabled></Input>
            </FormItem>
            <FormItem label="所属行业" name="industry">
              <Input bordered={false} disabled></Input>
            </FormItem>
            <FormItem name="introduction" label="公司简介及官网地址">
              <Input bordered={false} disabled></Input>
            </FormItem>
            <FormItem label="服务期限设置" name="serverDateLimit">
              <Input bordered={false} disabled></Input>
            </FormItem>
            <FormItem label="上传公司营业执照">
              <span>
                {businessLicense !== "" ? <Image width={150} src={businessLicense}></Image> : "-"}
              </span>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default Infos
