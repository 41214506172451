import React from "react"
import { Button, Modal } from "antd"

const ModalCom = (props: any) => {
  const {
    title = "",
    open = "",
    children,
    onCancel,
    onOk,
    cancelText = "取消",
    okText = "确认",
    maskClosable = false,
    width = 600,
    isShowFoolter = true,
    ...params
  } = props

  return isShowFoolter ? (
    <Modal
      open={open}
      title={title}
      onCancel={onCancel}
      onOk={onOk}
      cancelText={cancelText}
      okText={okText}
      maskClosable={maskClosable}
      width={width}
      {...params}
    >
      {children}
    </Modal>
  ) : (
    <Modal
      open={open}
      title={title}
      onCancel={onCancel}
      maskClosable={maskClosable}
      width={width}
      footer={null}
      {...params}
    >
      {children}
    </Modal>
  )
}

export default ModalCom
