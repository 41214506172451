export function getRealJsonData(baseStr: any) {
  if (!baseStr || typeof baseStr != "string") return null
  let jsonData = null
  try {
    jsonData = JSON.parse(baseStr)
  } catch (err) {
    return null
  }
  let needReplaceStrs: any = []
  loopFindArrOrObj(jsonData, needReplaceStrs)
  needReplaceStrs.forEach(function (replaceInfo: any) {
    let matchArr = baseStr.match(eval('/"' + replaceInfo.key + '":[0-9]{15,}/'))
    if (matchArr) {
      let str = matchArr[0]
      let replaceStr = str.replace('"' + replaceInfo.key + '":', '"' + replaceInfo.key + '":"')
      replaceStr += '"'
      baseStr = baseStr.replace(str, replaceStr)
    }
  })
  let returnJson = null
  try {
    returnJson = JSON.parse(baseStr)
  } catch (err) {
    return null
  }
  return returnJson
}

//遍历对象类型的
function getNeedRpStrByObj(obj: any, needReplaceStrs: any) {
  for (let key in obj) {
    // if (obj.hasOwnProperty(key)) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      let value = obj[key]
      if (typeof value == "number" && value > 9007199254740992) {
        needReplaceStrs.push({ key: key })
      }
      loopFindArrOrObj(value, needReplaceStrs)
    }
  }
}

//遍历数组类型的
function getNeedRpStrByArr(arr: any, needReplaceStrs: any) {
  for (let i = 0; i < arr.length; i++) {
    let value = arr[i]
    loopFindArrOrObj(value, needReplaceStrs)
  }
}
//递归遍历
function loopFindArrOrObj(value: any, needRpStrArr: any) {
  let valueTypeof = Object.prototype.toString.call(value)
  if (valueTypeof === "[object Object]") {
    needRpStrArr.concat(getNeedRpStrByObj(value, needRpStrArr))
  }
  if (valueTypeof === "[object Array]") {
    needRpStrArr.concat(getNeedRpStrByArr(value, needRpStrArr))
  }
}
//使用：
// let testString =
//   '{"a":{"b":{"n":[{"a":"138476506","ad":"us","se":"测",' +
//   '"e":"0","r":"0.0","s":"1","t":"2","c":"0","id1":12313352677239567885445,' +
//   '"id2":12313472667239127885446}]},"q":"52"}}';
// let data = getRealJsonData(testString); //直接放要解析的json字符串
