const withAllowClear = {
  allowClear: true,
}
export default {
  type: "object",
  labelWidth: 76,
  properties: {
    status: {
      title: "状态",
      type: "string",
      widget: "select",
      enum: ["15", "10", "20", "35", "25", "30", "40"],
      enumNames: ["审核拒绝", "已申请", "协议待签署", "待还款", "待放款", "放款失败", "还款完成"],
      placeholder: "请选择",
      props: withAllowClear,
    },
    timer: {
      bind: ["createTimeStart", "createTimeEnd"],
      title: "申请日期",
      type: "data",
      widget: "XZDateRange",
      props: withAllowClear,
    },
    timer1: {
      bind: ["tranDateStart", "tranDateEnd"],
      title: "放款日期",
      type: "data",
      widget: "XZDateRange",
      props: withAllowClear,
    },
    // timer2: {
    //   bind: ["createTimeStart", "createTimeEnd"],
    //   title: "还款日期",
    //   type: "data",
    //   widget: "XZDateRange",
    //   props: withAllowClear,
    // },
    keywords: {
      title: "搜索",
      type: "string",
      widget: "input",
      placeholder: "融资单号/融资产品/融资公司/借款人/核心企业/订单号",
      props: withAllowClear,
    },
  },
}
