/* eslint-disable prettier/prettier */
import React, { useState } from "react"
import { Input } from "antd"

interface XZInputProps {
  value?: any
  onChange: any
  placeholder?: string
  allowClear?: boolean
}
const XZInputProps: React.FC<XZInputProps> = (props) => {
  const { value, onChange, placeholder = "请输入", allowClear } = props
  const [currentValue, setCurrentValue] = useState(value || "")

  const handleCurrentValue = (e: any) => {
    setCurrentValue(e?.target.value)
  }
  const handlePropsValue = (e: any) => {
    e.stopPropagation()
    if (e.keyCode == 13) {
      // 回车事件
      onChange(currentValue)
    }
  }

  return (
    <Input
      value={currentValue}
      onKeyDown={handlePropsValue}
      onChange={handleCurrentValue}
      placeholder={placeholder}
      allowClear={allowClear}
    />
  )
}

export default XZInputProps
