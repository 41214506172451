/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Space, Collapse, DatePicker, Form, Input, Checkbox, Radio } from "antd"
import React, { memo, forwardRef, useImperativeHandle, useState, useEffect } from "react"
import { FieldType, FromProps } from "../../interface"
import { inputDefaultRules } from "@src/utils/RegExp"
import "./index.scss"
import { orderTypesOptions, channelOptions } from "../../constant"
const { Panel } = Collapse

export const TransactionType = (props: FromProps) => {
  const { form } = props
  useEffect(() => {
    //初始化默认
    form.setFieldsValue({
      restriction: ["ALL"],
      scope: ["ALL"],
      needSupplyAgreement: "true",
      relatedOrderPaid: "true",
      relatedOrderInvoiced: "true",
    })
  }, [])
  const handOrderOptions = (value) => {
    const resultData = value.filter((ie) => {
      if (ie == "ALL") {
        console.log(111)
      }
    })
    console.log(form.getFieldValue("scope"), "scope")
    console.log(value, "value")
  }
  return (
    <>
      {/* <Form.Item<FieldType> label="渠道限定" name="restriction">
        <Checkbox.Group options={channelOptions}></Checkbox.Group>
      </Form.Item> */}
      <Form.Item<FieldType> label="交易类型" name="scope">
        <Checkbox.Group options={orderTypesOptions} onChange={handOrderOptions}></Checkbox.Group>
      </Form.Item>
      {/* <Form.Item name="needSupplyAgreement" label="采购订单限制">
        <Radio.Group>
          <Radio value="true">需要供货协议</Radio>
          <Radio value="false">不需要供货协议</Radio>
        </Radio.Group>
      </Form.Item> */}
      {/* <Form.Item name="difference" label="补差单限制" className="orderPayment">
        <Form.Item name="relatedOrderPaid" label="关联订单货款支付要求">
          <Radio.Group>
            <Radio value="true">支付完成</Radio>
            <Radio value="false">无须检验是否支付完成</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="relatedOrderInvoiced" label="关联订单开票要求">
          <Radio.Group>
            <Radio value="true">开票完成</Radio>
            <Radio value="false">无须检验是否开票完成</Radio>
          </Radio.Group>
        </Form.Item>
      </Form.Item> */}
      {/* <Form.Item<FieldType>
        label="补差比例限制"
        name="maxDifferenceRatio"
        rules={[
          {
            required: true,
            message: "请输入补差比例限制",
          },
          {
            validator: async (rule: any, value: string) =>
              inputDefaultRules(value, "补差比例限制", 50, true), //特殊字符
          },
        ]}
      >
        <div className="NewTradingScheme_Collapse_maxDifferenceRatio">
          <Input placeholder="请输入补差比例限制" style={{ width: 264 }} />
          <span> %</span>
          <span className="NewTradingScheme_Collapse_orderPayment">
            单个采购订单补差金额占采购订单金额的比例,超过将不能创建订单
          </span>
        </div>
      </Form.Item> */}
    </>
  )
}

export default TransactionType
